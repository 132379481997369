import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import EditEmployeeModal from './EditEmployeeModal.js';

class EditEmployeeModalContainer extends Component {
    constructor(props) {
        super(props);
        var employee = this.props.employee;
        var employeeState = Boolean(employee && employee.id) ? (
            {
                id: employee.id,
                firstName: employee.firstName || '',
                lastName:  employee.lastName  || '',
                email:     employee.email     || '',
                team:      employee.team      || '',
                position:  employee.position  || '',
                division:  employee.division  || '',
                office:    employee.office    || '',
                city:      employee.city      || '',
                country:   employee.country   || '',
                policyHolder: employee.policyHolder === ' Yes', // The extra space is on purpose. See BE code
            }
        ) : (
            {
                firstName : '',
                lastName  : '',
                email     : '',
                team      : '',
                position  : '',
                division  : '',
                office    : '',
                city      : '',
                country   : '',
                policyHolder: null
            }
        );
        this.state = {
                        ...employeeState,
                     };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    isValid() {
        return Boolean(this.state.email && this.state.email.length>0);
    }

    async onSubmit(event) {
        event && event.preventDefault();

        if(!this.isValid()) {
            return;
        }

        var update = Boolean(this.state.id);
        var method = update ? 'putEmployeeById' : 'postEmployee';

        var employee = {
            firstName : this.state.firstName,
            lastName  : this.state.lastName,
            email     : this.state.email,
            team      : this.state.team,
            position  : this.state.position,
            division  : this.state.division,
            office    : this.state.office,
            city      : this.state.city,
            country   : this.state.country,
        };

        var dataObj = { companyEmployee : employee, employeeId: this.state.id };


        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});

        try {
            await DatabaseService[method](dataObj)
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
            if (this.props.isInsuranceCarrier) {
                dataObj = {
                    data : [
                        {
                            employeeAttributeName: 'policy_holder',
                            data: this.state.policyHolder ? 1 : 0
                        }
                    ],
                    employeeId: this.state.id
                }
                await DatabaseService.setEmployeeAttribute(dataObj)
            }
            await this.props.onRefresh()
            this.props.onClose();
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        } catch(e) {
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }

    }

    onChange(event, data) {
        var name = data.name;
        var value = data.type==='checkbox' ? data.checked : data.value;
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
    }

    render() {
        return (
            <EditEmployeeModal {...this.props}
                               {...this.state}
                               valid={this.isValid()}
                               update={Boolean(this.state.id)}
                               onChange={this.onChange}
                               onSubmit={this.onSubmit} />
        );
    }
}

export default connect()(withTranslation('employees')(EditEmployeeModalContainer));
