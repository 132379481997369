import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Color from 'color';
import _ from 'underscore';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import CreateCompanyForm from './CreateCompanyForm.js';

class CreateCompanyFormContainer extends Component {

    constructor(props){
		super(props);
        this.state = {name:'',
                      seats:'',
                      step: 1,
                      company:null,
                      companyTierId:null,
                      tiers:[]
                      };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSkip = this.onSkip.bind(this);
        this.onUploadCsv = this.onUploadCsv.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onChangeTier = this.onChangeTier.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
      this.loadData();
    }

    loadData() {
        this.setState({busy:true});
        DatabaseService.getOrganizationTiers({})
          .then(result => {
              var tiers = [];
              for(var i in result.tiers) {
                tiers.push({
                  key: result.tiers[i].tag,
                  text : result.tiers[i].title,
                  value : result.tiers[i].id
                })
              }
              this.setState({busy:false, tiers});
          }, error => {
              //window.alert('An error occurred - check logs');
              console.error(error);
          });
    }

    onChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
    }

    onSubmit(event) {
        event && event.preventDefault();

        var params = {
            companyName: this.state.name,
            companySeats: this.state.seats,
            companyTierId: this.state.companyTierId
        }

        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.addOrganization(params)
                       .then(result => {
                            this.setState({step:2, company:result.company})
                       })
                       .catch(error => {
                            console.error(error)
                            window.alert('An error occurred - check console');
                       })
                       .then(()=>{
                            this.props.onSetCompany(this.state.company);
                            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                       });
    }

    onChangeTier(event, {value}) {
      console.log("onChangeTier")
              console.log({companyTierId:value})
        this.setState({companyTierId:value}, this.loadData);
    }

    onUploadPhoto(propertyName) {
        console.log('onUploadPhoto', propertyName);
        this.setState({uploadPhotoProperty:propertyName});
    }

    onConfirmPhoto(propertyName, photo) {
        this.setState(state => (Utils.set({...state}, propertyName, photo)));
    }

    isValid() {
      console.log('isValud', _.isFinite(this.state.companyTierId))
        return Boolean(this.state.name) &&
                _.isFinite(Number(this.state.seats)) &&
                this.state.seats > 0 &&
                _.isFinite(this.state.companyTierId);
    }

    setFileSelector = (ref) => {
        this.fileSelector = ref;
    }

    onUploadCsv() {
        this.fileSelector.click();
    }

    onFileSelect(event) {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload = (e) => {
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.postEmployees({companyEmployees: {csv: reader.result}})
                .catch((error)=> {
                    console.error(error)
                    window.alert('An error occurred - check console');
                })
               .then(()=>{
                    this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                    this.props.onSelectCompany(this.state.company);
               });
    };
        reader.readAsText(file, 'UTF-8');
    }

    onSkip() {
        this.props.onSelectCompany(this.state.company);
    }

    render() {
        return (
            <CreateCompanyForm {...this.props}
                               {...this.state}
                               valid={this.isValid()}
                               onUploadCsv={this.onUploadCsv}
                               onFileSelect={this.onFileSelect}
                               onChangeTier={this.onChangeTier}
                               onChange={this.onChange}
                               onSubmit={this.onSubmit}
                               onSkip={this.onSkip}
                               setFileSelector={this.setFileSelector} />
      );
    }
}

export default withRouter(connect()(withTranslation('companies')(CreateCompanyFormContainer)));
