import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js'
import {Button, Message, Tab} from 'semantic-ui-react';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {setSidebarEnable} from 'actions/sidebar.js';
import {DeleteModal} from 'components/modals'
import {List} from 'components/list'
import {FlexRow} from 'components/views/views'
import UserSurveyForm from './UserSurveyForm'
import SurveyUploadForm from './SurveyUploadForm'

import {getSurveys} from 'reducers/surveys';
import {fetchSurveys} from 'actions/surveys';
import {getCampaigns} from 'reducers/campaigns';
import {fetchCampaigns} from 'actions/campaigns';
import {getEmployees} from 'reducers/employees';
import {fetchEmployees, searchEmployees} from 'actions/employees';
import {Buffer} from "buffer"

const styles = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '16px',
    },
    field: {
        margins: spacings.tiny
    },
    topPanel: {
        display: 'flex',
        marginBottom: 16
    }
}

const employeeFields = [
    {
        name: 'Name',
        key: 'title',
        default: true
    },
    {
        name: 'Email',
        key: 'email',
        default: true
    }
]

const campaignFields = [
    {
        name: 'Title',
        key: 'survey.title',
        default: true,
        searchable: true
    },
    {
        name: 'Category',
        key: 'category',
        default: true
    },
    {
        name: 'Image',
        key: 'image',
        type: 'image',
        default: true
    },
    {
        name: 'Tag',
        key: 'survey.tag',
        default: true,
        searchable: true
    },
    {
        name: 'Start Date',
        key: 'startDate',
        default: true
    },
    {
        name: 'Expiry Date',
        key: 'endDate',
        default: true
    },
    {
        name: 'Public',
        key: 'public',
        default: true
    },
    {
        name: 'Segment',
        key: 'team.name',
        default: true
    },
    {
        name: 'Completions',
        key: 'completionCount',
        default: true
    }
]


const surveyFields = [
    {
        name: 'Title',
        key: 'title',
        default: true,
        searchable: true
    },
    {
        name: 'Category',
        key: 'category',
        default: true
    },
    {
        name: 'Image',
        key: 'image',
        type: 'image',
        default: true
    },
    {
        name: 'Tag',
        key: 'tag',
        default: true,
        searchable: true
    }
]

const SurveyContainer = ({t, dispatch, fetchSurveys, fetchCampaigns, searchEmployees}) => {
    const [selectedItem, setSelectedItem] = useState()
    const [selectedItemType, setSelectedItemType] = useState()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [segments, setSegments] = useState([])
    const [update, setUpdate] = useState()
    const [userSurveyFormOpen, setUserSurveyFormOpen] = useState(false)
    const [surveyUploadFormOpen, setSurveyUploadFormOpen] = useState(false)
    const [functionCalls, setFunctionCalls] = useState([])
    const [error, setError] = useState()
    const [uploadType, setUploadType] = useState()
    const [uploadParams, setUploadParams] = useState({})
    const [uploadFormData, setUploadFormData] = useState()

    const fileSelector = useRef()

    const loadData = async () => {
        let result = await DatabaseService.getSegments()
        let segments = result.rows
        segments.forEach(segment => {
            segment.title = segment.name
            segment.tags = segment.segmentQuery?.tags?.split(',') || []
        })
        setSegments(segments)
    }

    const uploadSurveys = (params) => {
        params = params || {}
        fileSelector.current.click()
        setUploadType('surveys')
        setUploadParams(params)
    }

    const uploadSponsors = (params) => {
        params = params || {}
        fileSelector.current.click()
        setUploadType('sponsors')
        setUploadParams(params)
    }

    const separateSurveyUploads = async (tsv) => {
        let surveys = tsv.split(/Enabled\tSurvey\t/i)
        if (surveys.length) {
            //Since the first element can't be a survey
            surveys.shift()
        }
        let calls = surveys.map(surveyData => {
            let tagEnd = surveyData.indexOf('\t')
            let tag = surveyData.substr(0, tagEnd)
            surveyData = 'Enabled\tSurvey\t' + surveyData
            let uploadCall = async () => {
                let error
                var surveys = await DatabaseService.postSurvey({survey: {tsv: {base64: Buffer.from(surveyData).toString('base64')}}, ...uploadParams})
                .catch((e)=> {
                    error = e
                })
                if (surveys) {
                    surveys = surveys.surveys.map(survey => {
                        return {
                            title: survey.title,
                            id: survey.tag,
                            data: survey
                        }
                    })
                    return surveys
                } else {
                    return { error }
                }
            }
            return {
                tag: tag,
                call: uploadCall
            }
        })
        setFunctionCalls(calls)
    }

    const finishUpload = async (event) => {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload = async (e) => {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            switch (uploadType) {
                case 'surveys':
                    await separateSurveyUploads(reader.result)
                    break
                case 'sponsors':
                    setError()
                    await DatabaseService.postSponsors({sponsor: {tsv: {base64: Buffer.from(reader.result).toString('base64')}}})
                        .catch((error)=> {
                            console.error(error)
                            setError(error)
                        })
                    break
            }
            setUploadParams({})
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }
        reader.readAsText(file, 'UTF-8');
    }

    const removeSurvey = async () => {
        setDeleteModalOpen(false)
        await DatabaseService.removeSurvey({surveyId: selectedItem.id})
        setSelectedItem()
        setSelectedItemType()
        setUpdate(!update)
    }
    
    const removeCampaign = async () => {
        setDeleteModalOpen(false)
        await DatabaseService.removeCampaign({surveyId: selectedItem.survey.id, campaignId: selectedItem.id})
        setSelectedItem()
        setSelectedItemType()
        setUpdate(!update)
    }

    const removeItem = async (item, type) => {
        setSelectedItemType(type)
        setSelectedItem(item)
        setDeleteModalOpen(true)
    }

    const cancelModal = () => {
        setDeleteModalOpen(false)
        setUserSurveyFormOpen(false)
        setSurveyUploadFormOpen(false)
        setError()
        setSelectedItem()
        setSelectedItemType()
        setUploadFormData()
    }

    const validateCampaign = (campaign) => {
        return (
            (campaign.startDate instanceof Date || campaign.startNow) && (campaign.endDate instanceof Date || campaign.indefinite)
            || campaign.inactive || campaign.adhoc
        )
    }

    const addCampaigns = async (selectedSurveys) => {
        await Promise.all(selectedSurveys.map(async campaign => {
            if (campaign.valid) {
                const {segment_query, segment, startDate, endDate, startNow, indefinite, repeatable, refreshCreate, inactive, adhoc, isPublic} = campaign
                const segmentId = segment && segment_query === segment.title ? segment.id : null
                await DatabaseService.postCampaigns({
                    surveyId: campaign.data.id,
                    segmentId: segmentId,
                    startDate: startNow ? null : startDate,
                    endDate: indefinite ? null : endDate,
                    repeatable: repeatable,
                    adhoc: adhoc,
                    refreshCreate: refreshCreate,
                    inactive: inactive,
                    isPublic: isPublic
                })
            }
        }))
        cancelModal()
        setUpdate(!update)
    }

    const submitUserSurveyModal = async ({selectedCampaigns, selectedEmployees}) => {
        await Promise.all(selectedCampaigns.map(async campaign => {
            if (campaign.valid) {
                const {startDate, endDate, startNow, indefinite, refreshCreate} = campaign
                const users = selectedEmployees && selectedEmployees.length ? selectedEmployees.map(employee => {
                    return {
                        userProfileId: employee.data.userProfileId
                    }
                }) : []
                await DatabaseService.postUserSurveys({
                    surveyId: campaign.data.survey.id,
                    companySurveyId: campaign.data.id,
                    users: users,
                    startDate: startNow ? null : startDate,
                    endDate: indefinite ? null : endDate,
                    refreshCreate: refreshCreate
                })
            }
        }))
        cancelModal()
        setUpdate(!update)
    }

    const openUserSurveyModal = () => {
        setUserSurveyFormOpen(true)
        setError()
    }
    const openSurveyUploadModal = () => {
        setSurveyUploadFormOpen(true)
        setError()
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true))
        loadData()
    }, [])

    const panes = [
        {
          menuItem: 'Campaigns',
          pane: {
            content: (
                <Tab.Pane>
                    <List
                        getData={fetchCampaigns}
                        searchData={fetchCampaigns}
                        actions={[
                            {
                                name: 'trash',
                                invoke: (campaign) => removeItem(campaign, 'campaign')
                            }
                        ]}
                        update={update}
                        type='table'
                        fields={campaignFields}
                    />
                </Tab.Pane>
            )
          },
        },
        {
            menuItem: 'Survey Templates',
            pane: {
                content: (
                    <Tab.Pane>
                        <List
                            getData={fetchSurveys}
                            searchData={fetchSurveys}
                            actions={[
                                {
                                    name: 'trash',
                                    invoke: (survey) => removeItem(survey,'survey')
                                },
                                {
                                    name: 'upload',
                                    invoke: (survey) => {
                                        setSurveyUploadFormOpen(true)
                                        setUploadFormData({
                                            step: 4,
                                            selectedSurveys: [{
                                                title: survey.title,
                                                id: survey.tag,
                                                data: survey
                                            }]
                                        })
                                    }
                                }
                            ]}
                            update={update}
                            type='table'
                            fields={surveyFields}
                        />
                    </Tab.Pane>
                  )
            },
          }
      ]

    return (
        <div style={styles.content}>
            {
                error && 
                <Message error>
                    <Message.Header>{error.message}</Message.Header>
                </Message>
            }
            <div style={styles.topPanel}>
                <div>
                    <h2>{t('Add')}</h2>
                    <input ref={fileSelector} style={{display: 'none'}} type="file" accept='.tsv' onChange={finishUpload}/>
                    <Button onClick={openSurveyUploadModal}>{t('Add Survey and Campaign')}</Button>
                    <Button onClick={uploadSponsors}>{t('Add Sponsors')}</Button>
                    <Button onClick={openUserSurveyModal}>{t('Add survey to specific users')}</Button>
                </div>
            </div>
            <Tab panes={panes} renderActiveOnly={false}/>
            <DeleteModal open={deleteModalOpen}
                cancel={cancelModal}
                confirm={selectedItemType === 'survey' ? removeSurvey : removeCampaign}
                tableName={selectedItemType === 'survey' ? t('Survey') : t('Campaign')}
            />
            <SurveyUploadForm
                open={surveyUploadFormOpen}
                fetchSurveys={fetchSurveys}
                onUpload={uploadSurveys}
                segments={segments}
                validateCampaign={validateCampaign}
                functionCalls={functionCalls}
                finish={addCampaigns}
                cancel={cancelModal}
                modalError={error}
                tableFields={surveyFields}
                formData={uploadFormData}
            />
            <UserSurveyForm
                open={userSurveyFormOpen}
                fetchEmployees={searchEmployees}
                fetchCampaigns={fetchCampaigns}
                validateCampaign={validateCampaign}
                finish={submitUserSurveyModal}
                cancel={cancelModal}
                modalError={error}
                campaignTableFields={campaignFields}
                employeeTableFields={employeeFields}
                formData={uploadFormData}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {campaigns: getCampaigns(state.campaigns), surveys:getSurveys(state.surveys), employees:getEmployees(state.employees)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCampaigns: (params, force) => {
            return dispatch(fetchCampaigns(params, force))
        },
        fetchSurveys: (params, force) => {
            return dispatch(fetchSurveys(params, force))
        },
        fetchEmployees: (params, force) => {
            return dispatch(fetchEmployees(params, force))
        },
        searchEmployees: (params) => {
            return dispatch(searchEmployees(params))
        },
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('surveys')(SurveyContainer)));