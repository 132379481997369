import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Checkbox, Button, Modal} from 'semantic-ui-react';

import {getCategoryImageUrl } from '../tools.js';
import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';
import defaultCategoryImage from 'img/default-challenge-image.jpg';

var style = {
    ...styleDef,
    checkbox: {
        marginRight: spacings.tiny,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    description: {
        ...fonts.bodyL,
        lineHeight: '1.5',
        padding: spacings.base,
        textAlign: 'center',
        color: colors.themeDarkerGrey,
    },
    columns: {
    },
    leftColumn: {
        display: 'inline-block',
        width: '33%',
        verticalAlign: 'top',
    },
    rightColumn: {
        display: 'inline-block',
        width: '67%',
        verticalAlign: 'top',
    },
    challengeList: {
        overflowY: 'auto',
        height: '300px',
    },
    challengeImage: {
        position: 'absolute',
        left: '-45px',
        top: '10px',
        height: '40px',
        width: '60px',
        backgroundSize:'100%',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        
        borderRadius: '6px',
        boxShadow: shadow,
    },
    challengeRow: {
        position: 'relative',
        marginLeft: '50px',
        height: '60px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    challengeTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bannerImage: {
        marginTop: '50px',
        width: '150px',
        borderRadius: '6px',
        boxShadow: shadow,
    }
};



const CategoryInfoModal = ({
    t,
    busy,
    category,
    categoryFull,
    challenges,
    inviteMode,
    uninvitedCount,
    unregisteredCount,
    onCloneGoal,
    onChange,
    onClose,
    exportGoal,
    setExportLink
    }) => (
    <Modal open style={style.content} onClose={onClose} size='small'>
        <Modal.Header>{category.title}</Modal.Header>
        <Modal.Content>
            <a ref={setExportLink} style={{display: 'none'}} href="#" download={`${category.title}.json`}></a>
            <Modal.Description>
                <div style={style.subheader}>{category.category}</div>
                <div style={style.description}>{category.description}</div>
                {busy &&
                    <LoadInline centered className="notInDimmer" />
                }
                {!busy &&
                    <div style={style.columns}>
                        <div style={style.leftColumn}>
                            {categoryFull &&
                                <img style={style.bannerImage} src={getCategoryImageUrl(categoryFull)} alt='' />
                            }
                        </div>
                        <div style={style.rightColumn}>
                            <div style={style.header}>{t('Activities')}</div>
                            <div style={style.challengeList}>
                                {categoryFull && categoryFull.challenges.map(challenge=>(
                                    <div key={challenge.id} style={style.challengeRow}>
                                        <div style={{...style.challengeImage, backgroundImage: Utils.url((challenge.image && challenge.image.url) ? challenge.image.url : defaultCategoryImage)}} />
                                        <div style={style.challengeTitle}>{challenge.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                <br />
                <Button style={style.button} onClick={onCloneGoal}>{t('Clone Goal')}</Button>
                <br />
                <Button style={style.button} onClick={exportGoal}>{t('Export Goal')}</Button>
                <br />
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('categories')(CategoryInfoModal);
