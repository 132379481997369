import {SET_SURVEYS} from 'actions/types';

var surveysInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = surveysInitialState, action) => {
    switch(action.type) {
        case SET_SURVEYS: 
            return {...state,
                    list:action.surveys.map((c)=>(c.id)),
                    indexed: action.surveys.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    listLoaded:true,
                    count: action.count
                    };
        default:            
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getSurveys = state => state.list.map( id => state.indexed[id] );

