import React from 'react';
import { withTranslation } from 'react-i18next';
import {Segment, Message, Header} from 'semantic-ui-react'

const style = {
    segment: {
        textAlign: 'center',
        padding: '40px',
        margin: '10px',
    }
}

const ErrorPage = ({t, error}) => (
    <Segment style={style.segment}>
        <Header>{t('Error')}</Header>
        <Message negative>{error}</Message>
    </Segment>
);

const ErrorPageTranslated = withTranslation()(ErrorPage);
export default ErrorPageTranslated;


const withError = (WrappedComponent, navState, sideBarEnable) =>
    (props) => (
                props.error ?  (
                    <ErrorPageTranslated error={String(props.error)} />
                ) : ( 
                    <WrappedComponent {...props}/> 
                ))

export {withError};
