import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

export default (state = {spinner:false, overlay:false}, action) => {
    switch(action.type) {
        case SET_LOADING_SPINNER_ENABLE: 
            return {...state, spinner:action.enable};
        case SET_LOADING_OVERLAY_ENABLE: 
            return {...state, overlay:action.enable};
        default:            
            return state;
    }
}
