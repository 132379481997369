import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Button, Form, Input, Grid, Header, Message, Label, Dropdown} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, buttons, spacings} from 'style';
import optimitySetupImage from 'img/optimity-setup.png';
import sampleCSV from 'assets/Optimity-Team-Sample.csv';
import styleDef from './style.js';

var style = {
    ...styleDef,
    skipButton: {
        ...buttons.long,
        padding: '.78571429em 1.5em',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#fff',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0',
        marginBottom: '0',
    },
    container: {
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#fff',
    },
    header: {
        ...fonts.h3,
        padding: spacings.base,
    },
    subheader: {
        ...fonts.h4,
        padding: spacings.base,
    },
    form: {
        textAlign: 'left',
    },
    label: {
        color: '#fff',
    },
    buttonRow: {
        textAlign: 'center',
    },
    uploadButton: {
        color: '#fff',
        backgroundColor: colors.themeBlue,
    },
    info: {
        textAlign: 'center',
    },
    buttonInfo: {
        paddingTop: spacings.tiny,
    },
    backButton: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: spacings.base,
        left: spacings.base,
        color: colors.themeDarkGrey,
        fontSize: '30px',
    }
};

const CreateCompanyForm = ({t,
                    name,
                    seats,
                    valid,
                    step,
                    tiers,
                    setFileSelector,
                    onChangeTier,
                    onClose,
                    onChange,
                    onSubmit,
                    onSkip,
                    onUploadCsv,
                    onFileSelect}) => (
    
    <div style={style.container}>
        <Button onClick={onClose} style={style.backButton}>
            <Icon name='left chevron' />
        </Button>
        <div style={style.header}>Optimity</div>
        <div style={style.subheader}>{t('account_setup_progress', {step:step, total:2})}</div>
        {(step===1) &&
        <Form style={style.form} onSubmit={onSubmit}>
            <div style={style.info}>{t('company_create_info_message')}</div>
            <br />
            <br />
            <Form.Field>
                <label style={style.label}>{t('Company Name')}</label> 
                <Input name='name' value={name} onChange={onChange} />
            </Form.Field>
            <Form.Field>
                <label style={style.label}>{t('Number of Seats')}</label> 
                <Input name='seats' value={seats} onChange={onChange} />
            </Form.Field>
            <Form.Field>
                <label style={style.label}>{t('tier')}</label> 
                <Dropdown options={tiers}
                              onChange={onChangeTier}
                              selection
                              closeOnChange/>
            </Form.Field>
            
            <br/>
            <br/>
            <br/>
            <br/>
            <div style={style.buttonRow}>
            <Button style={style.button} disabled={!valid}>
                {t('Continue')}
            </Button>
            </div>
        </Form>
        }
        {(step===2) &&
            <div>
                <div>{t('company_create_csv_message')}</div>
                <input ref={setFileSelector} type="file" style={{display:'none'}} onChange={onFileSelect} />
                <a download="optimitysample.csv" href={sampleCSV}>
                    <img alt='' src={optimitySetupImage} />
                </a>
                <div>{t('download_csv_message')}</div>
                <br />
                <br />
                <Button style={{...style.button, ...style.uploadButton}} onClick={onUploadCsv}>
                    {t('upload_csv_button')}
                </Button>
                <br />
                <br />
                <br />
                <Button style={style.skipButton} onClick={onSkip}>
                    {t('skip_step')}
                </Button>
                <div style={style.buttonInfo}>{t('skip_csv_info')}</div>
            </div>
        }
    </div>
);

export default withTranslation('companies')(CreateCompanyForm);
