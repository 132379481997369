
import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import jstz from 'jstz';

import UserService from '../../services/UserService.js';
import {initNavbarState} from 'actions/navbar.js';
import {setSidebarEnable} from 'actions/sidebar.js';

import ForgotPassword from './ForgotPassword.js'

class ForgotPasswordContainer extends Component {

    constructor(props){
		super(props);
		this.state = {
            valid:false,
            email:'', 
            emailError:'',
            error:'',
        };
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    onSubmit() {
        if(this.isValid(true)) {
            this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:true});
            UserService.passwordReset(this.state.email)
                .then((result) => {
					//var timezone = jstz.determine();
					//return UserService.updateProfile({userProfile:{timeZone:timezone.name()}});
                })
                .catch((error) => {
                    this.setState({error:String(error), 
                                   password:''});
                    console.error(error);
                    //window.alert('error: ' + error);
                })
                .then(()=>{ //Always
                    this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:false});
                    this.props.history.push('/login', {notification: this.props.t('password_reset_message')})
                });
        }
    }

    onEmailChange(e) {
        this.setState({email: e.target.value}, this.isValid)
    }

    isValid(submit) {
		var valid = true;
		this.setState({emailError:''});

		if((this.state.email||'').length === 0){
            if(submit) {
			    this.setState({emailError:'Please enter your email address'});
            }
            valid = false;
		}
        this.setState({valid:valid});
		return valid;
    }

    render () {
        return (
            <ForgotPassword
                t={this.props.t}
                valid={this.state.valid}
                email={this.state.email}
                onEmailChange={this.onEmailChange}
                onSubmit={this.onSubmit}
                emailError={false}
                error={false}
            />
        );
    }
}

export default withRouter(connect()(withTranslation('authentication')(ForgotPasswordContainer)));