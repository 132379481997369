import DatabaseService from 'services/DatabaseService.js';
import {SET_FUTURE_ANNOUNCEMENTS, SET_PUBLIC_ANNOUNCEMENTS, SET_TARGETED_ANNOUNCEMENTS} from 'actions/types';

//Action Creators
export const setFutureAnnouncements = (announcements, count) => {
    return { type:SET_FUTURE_ANNOUNCEMENTS, announcements:announcements, count: count};
}

export const setPublicAnnouncements = (announcements, count) => {
    return { type:SET_PUBLIC_ANNOUNCEMENTS, announcements:announcements, count: count};
}

export const setTargetedAnnouncements = (announcements, count) => {
    return { type:SET_TARGETED_ANNOUNCEMENTS, announcements:announcements, count: count};
}

//Thunks
export const fetchAnnouncements = (params) => (dispatch) => {
    return new Promise(function(resolve, reject) {
        if (params.query && params.query !== '' && params.queryField !== '') {
            params[`like.${params.queryField}`] = params.query
        }
        if (params.offset) {
            params.start = params.offset
        }

        if (params.future) {
            params.state = 'pending'
            params.channels = true
            params.recurrences = true
        } else if (params.public) {
            params.state = 'sent'
        } else if (params.targeted) {
            params.state = 'sent'
        }

        DatabaseService.getAnnouncements(params).then(
            function(result){
                result.announcements = result.announcements.map(announcement => {
                    announcement.challengeId
                    return {
                        ...announcement,
                        ...(announcement.segmentType === 0 && !announcement.segmentId ? { segmentCount: 'All Users' } : {}),
                        ...(!announcement.challengeId && announcement.type === 1 ? { segmentCompleted: '--' } : {})
                    }
                })
                if (params.future) {
                    dispatch(setFutureAnnouncements(result.announcements, result.summary.total))
                } else if (params.public) {
                    dispatch(setPublicAnnouncements(result.announcements, result.summary.total))
                } else if (params.targeted) {
                    dispatch(setTargetedAnnouncements(result.announcements, result.summary.total))
                }
                resolve({data: result.announcements, count: result.summary.total})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
