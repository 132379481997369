import {SET_INVITATIONS, SET_PUBLIC_INVITATIONS} from 'actions/types';

var invitationsInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = invitationsInitialState, action) => {
    switch(action.type) {
        case SET_INVITATIONS: 
            return {
                ...state,
                list:action.invitations.map((c)=>(c.id))
            };
        case SET_PUBLIC_INVITATIONS:
            return {
                ...state,
                publicInvitations:action.invitations.map((c)=>(c.id))
            }
        default:
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getInvitations = state => state.list.map( id => state.indexed[id] );
export const getPublicInvitations = state => state.list.map( id => state.indexed[id] );

