import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Grid, Input, Button, Checkbox, Label, Dropdown, Tab, Form} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';
import Challenge from './Challenge.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'

var style = {
    content: {
        margin: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey,
        borderRadius: '6px',
    },
    body: {
        padding: spacings.base,
    },
    titleBar: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontSize: fontSizes.bodyL,
        padding: spacings.small,
        backgroundColor: colors.themeGrey,
    },
    addButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    addButtonRowVersion: {
        display: 'inline-block',
        backgroundColor: colors.themeGrey,
        color: colors.themeBlue,
        fontSize: fontSizes.h4,
        float: 'right',
        padding: '0',
        margin: '0',
    },
    addRow: {
        borderRadius: spacings.tiny,
        fontSize: fontSizes.bodyL, 
        padding: spacings.small,
        margin: spacings.tiny,
        borderRadius: spacings.micro,
        color: colors.themeBlue,
        border: '1px dashed #007fb9',
        backgroundColor: colors.themeGrey,
        fontStyle: 'italic',

    },
    trashIcon: {
        color: colors.warningRed,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '24px',
        float: 'right',
    },
};


const Types = [
       { key:0, value:0, text:"Activities"},
       { key:1, value:1, text:"Learn"},
       { key:2, value:2, text:"Action"},
       { key:3, value:3, text:"Test"},
       { key:4, value:4, text:"Assessments"}
];

const Orders = [
       { key:0, value:0, text:"Sequential"},
       { key:1, value:1, text:"Open / Random"},
];


const CategoryLevelSection = ({
                        t,
                        levelIndex,
                        sectionIndex,
                        categoryLevelSection,
                        languages,
                        onAddChallenge,
                        onDeleteTab,
                        onHideTab,
                        onChange,
                        valueChange,
                        onChangeDropdown,
                        onToggle,
                        onSelectChallengeType,
                        onAddChallengeAnswer,
                        onUploadPhoto,
                        onCancelPhoto,
                        getBasepath,
                        locale,
                        refSection,
                        refChallenge,
                        refChallengeAnswer,
                        onAutoCaption

                      }) => (
    <div style={style.content}>
        <div style={style.titleBar}>
            <Button type="button" onClick={()=>{refSection.current.showDeletePrompt(levelIndex, sectionIndex);}}
                                                style={{...style.iconButton, ...style.trashIcon}}>
                <Icon name="trash"/>
            </Button>
            <Button type="button" onClick={()=>onHideTab(levelIndex, sectionIndex)} style={{...style.iconButton, ...style.windowIcon}}>
                <Icon name={categoryLevelSection.isOpen ? "window minimize" : "window maximize"}/>
            </Button>
            <div>{t('section_title', {i:sectionIndex+1})}{categoryLevelSection.type !== undefined && ": " + Types[categoryLevelSection.type].text}</div>
        </div>
        <DeletePromptContainer ref = {refSection} onDeleteConfirmation = {onDeleteTab}/>
        <div style={style.body} id = {"sectionContent" + levelIndex + sectionIndex}>
            {(true || Boolean(categoryLevelSection.isOpen)) &&
                <div>
                    <div>{t('type_prompt')}</div>
                    <Dropdown options={Types}
                              name={'categoryLevels.' + levelIndex + '.categoryLevelSections.' + sectionIndex + '.type'}
                              value={categoryLevelSection.type}
                              onChange={onChangeDropdown}
                              selection
                              closeOnChange/>


                    <br />
                    <br />

                    <div>{t('order_prompt')}</div>
                    <Dropdown options={Orders}
                              name={'categoryLevels.' + levelIndex + '.categoryLevelSections.' + sectionIndex + '.order'}
                              value={categoryLevelSection.order}
                              onChange={onChangeDropdown}
                              selection
                              closeOnChange/>


                    <br />
                    <br />

                    <div>{t('count_repeats_prompt')}</div>
                    <Checkbox name={'categoryLevels.' + levelIndex + '.categoryLevelSections.' + sectionIndex + '.countRepeats'}
                              className='green' 
                              toggle
                              checked={Boolean(categoryLevelSection.countRepeats)} 
                              onChange={onToggle}/>

                    <br />
                    <br />
                    <div>{t('award_repeats_prompt')}</div>
                    <Checkbox name={'categoryLevels.' + levelIndex + '.categoryLevelSections.' + sectionIndex + '.awardRepeats'}
                              className='green' 
                              toggle
                              checked={Boolean(categoryLevelSection.awardRepeats)} 
                              onChange={onToggle}/>

                    <br />
                    <br />
                    {categoryLevelSection.order === 1 && 
                    <div>
                        <div>{t('num_challenges_prompt')}</div>
                        <Input name={'categoryLevels.' + levelIndex + '.categoryLevelSections.' + sectionIndex + '.numRequired'}
                                value={categoryLevelSection.numRequired}                            
                                onChange={onChange}
                                onFocus={onChange} />
                    </div>
                    }
                </div>
            }

            {categoryLevelSection.challenges.map((challenge, i)=>(
                <Challenge key={challenge.key || challenge.id}
                               levelIndex={levelIndex} 
                               sectionIndex={sectionIndex} 
                               challengeIndex={i}
                               challenge={challenge}
                               onChange={onChange}
                               onChangeDropdown={onChangeDropdown}
                               onToggle={onToggle}
                               languages={languages}
                               valueChange = {valueChange}
                               onSelectChallengeType={onSelectChallengeType}
                               onHideTab={onHideTab}
                               onDeleteTab={onDeleteTab}
                               onAddChallengeAnswer={onAddChallengeAnswer}
                               onUploadPhoto={onUploadPhoto}
                               onCancelPhoto={onCancelPhoto}
                               getBasepath={getBasepath}
                               locale={locale}
                               refChallenge = {refChallenge}
                               refChallengeAnswer = {refChallengeAnswer}
                               onAutoCaption = {onAutoCaption}
                               /> 
            ))}
            
            <div style = {style.addRow}>
                        {t('add_activity')}
                        <Button type='button' style={style.addButtonRowVersion} onClick={()=>onAddChallenge(levelIndex, sectionIndex)}>
                            <Icon name="plus"></Icon>
                        </Button>
                </div>
        </div>

    </div>
);

export default withTranslation('categories')(CategoryLevelSection);
