import React from 'react';
import { withTranslation } from 'react-i18next';
import {Form, Button, Dropdown, Checkbox, Modal, Input, Message} from 'semantic-ui-react';

import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';

const style = {
    billingForm: {
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
}

const BillingForm = ({t, 
                      valid,
                      firstName,
                      lastName,
                      company,
                      address,
                      email,
                      city,
                      state,
                      country,
                      provinces,
                      countries,
                      onChange,
                      onSubmit,
                      onChangeCountry,
                      onChangeProvince,
                        }) => (
    <Form onSubmit={onSubmit}>
        <div style={style.billingForm}>
            <Form.Group>
                <Form.Field>
                    <label>{t('First Name')}</label> 
                    <Input name='firstName' value={firstName} onChange={onChange} maxLength = "100"/>
                </Form.Field>

                <Form.Field>
                    <label>{t('Last Name')}</label> 
                    <Input name='lastName' value={lastName} onChange={onChange} maxLength = "100"/>
                </Form.Field>
            </Form.Group>

            <Form.Group>
                <Form.Field>
                    <label>{t('Company')}</label> 
                    <Input name='company' value={company} onChange={onChange} maxLength = "100"/>
                </Form.Field>

                <Form.Field>
                    <label>{t('Company Address')}</label> 
                    <Input name='address' value={address} onChange={onChange} maxLength = "200"/>
                </Form.Field>

                <Form.Field>
                    <label>{t('Email Address')}</label> 
                    <Input name='email' value={email} onChange={onChange} maxLength = "100"/>
                </Form.Field>
            </Form.Group>

            <Form.Group>
                <Form.Field>
                    <label>{t('City')}</label> 
                    <Input name='city' value={city} onChange={onChange} maxLength = "50"/>
                </Form.Field>
                <Form.Field>
                    <label>{t('Country')}</label>
                    <Dropdown options={countries}
                              value={country}
                              onChange={onChangeCountry}
                              selection
                              closeOnChange/>
                </Form.Field>
                <Form.Field>
                    <label>{t('Province/State')}</label>
                    <Dropdown options={provinces}
                              value={state}
                              onChange={onChangeProvince}
                              selection
                              closeOnChange/>
                </Form.Field>

            </Form.Group>

            <br />
            <br />
            <Button type='submit' style={style.button} disabled={!valid}>{t('Save')}</Button>            
        </div>
    </Form>
);

export default withTranslation('account')(BillingForm);
