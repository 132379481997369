import React from 'react';
import { withTranslation } from 'react-i18next';

import {Button, Form, Input, Grid, Header, Message, Label, Segment, Modal} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import Utils from 'utils/Utils.js';
import {colors, spacings, shadow, fontSizes, fonts} from 'style';


var style = {
    btnStyleYes: {
        float: 'right',
        backgroundColor: colors.warningRed,
        color: '#fff',
        margin: spacings.micro

    },

    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.themeDarkGrey,
        color: '#fff',
        margin: spacings.micro
    }
    
}



const DeletePrompt = ({t,
                       open,
                       onClose,
                       message = 'Are you sure you want to delete?',
                       deleteYes}) => (
                    <div>
                         <Modal closeIcon open={open} size="small" onClose = {onClose} onUnmount={Utils.fixBody}>
                         <Modal.Header>Delete</Modal.Header>
                         <Modal.Content>
                            {message}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button style={style.btnStyleNo} onClick ={onClose}>No</Button>
                            <Button style={style.btnStyleYes} onClick ={deleteYes}>Yes</Button>
                        </Modal.Actions>
                        </Modal>
                    </div>
                   );

export default withTranslation('misc')(DeletePrompt);
