import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Icon, Header, Checkbox } from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import Utils from 'utils/Utils.js';
import defaultRewardImage from 'img/optimity-logo.png';

const style = {
    item: {
        // display: 'flex',
        textAlign: 'center',
        padding: spacings.micro,
    },
    rewardImage: {
        width: '50px',
        maxHeight: '50px',
        margin: '0 1rem'
    },
    rewardPrice: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
    },
    rewardResources: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
        textAlign:'left'
    },
    gemIcon: {
        color: colors.pointsBlue
    }

};

const RewardSearchCard = ({reward}) => (
    <div key={reward.id} style={{display: 'flex'}}>
        <div>
            <div>
                <img src={Utils.deref(reward, 'asset.url', defaultRewardImage)} alt="" style={style.rewardImage} />
            </div>
            
        </div>
        <div>
            <div>
                <span style={style.rewardPrice}><Icon name="dollar"/>{reward.price / 100} {reward.currency?.code}</span>
            </div>
            <div>
                <span style={style.rewardResources}><Icon name="gem" style={style.gemIcon}/> {reward.resources}</span>
            </div>
        </div>
        <div style={style.item}>
            {reward.brand && <div>{reward.brand}</div>}
            <div>{reward.title}</div>
        </div>
        
    </div> 
);

export default withTranslation('reward')(RewardSearchCard);
