import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Hra from './Fwa.js';


class FwaContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, loaded : false,
                    surveys : [],
                    companySurvey:[],
                    complete : false
                };
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData();
    }

    async loadData() {
        try {
            this.setState({busy:true});
            var results = await DatabaseService.getHRAActivity({type : 'fwa'});
            console.log('results',results.totalEmployee)
            var survey = results.surveys[0] 
            var hraSurvey = {};
            var activities = survey.surveyPages || [];
           
            var totalActivities = activities.length || 0;
            hraSurvey.totalEmployee = results.totalEmployee || 0;
            hraSurvey.totalActivity = survey.numActivities  || 0;
            hraSurvey.percentageResponse = hraSurvey.totalEmployee && totalActivities ? survey.totalActivity / (hraSurvey.totalEmployee * totalActivities) * 100 : 0;
            hraSurvey.percentageResponse = hraSurvey.percentageResponse.toFixed(2)
            var risks = [];
            if(localStorage.inviteCode == 'aimhigher') {
                // hraSurvey.percentageResponse =45.5
                // risks = [
                //     {
                //         id : 1,
                //         name: "BMI",
                //         value : hraSurvey.totalEmployee * .50 | 1,
                //         progressValue : 0,
                //         insight: '2.12',
                //     },
                //     {
                //         id : 2,
                //         name: "Height",
                //         value : hraSurvey.totalEmployee * .43 | 1,
                //         progressValue : 0,
                //         insight: '168.03cm',
                //     },
                //     {
                //         id :3,
                //         name: "Weight",
                //         value : hraSurvey.totalEmployee * .56 | 1,
                //         progressValue : 0,
                //         insight: '60.49kg',
                //     },
                //     {
                //         id : 4,
                //         name: "Age",
                //         value : hraSurvey.totalEmployee * .71 | 1,
                //         progressValue : 0,
                //         insight: '32',
                //     },
                //     {
                //         id : 5,
                //         name: "Sex",
                //         value : hraSurvey.totalEmployee * .21 | 1,
                //         progressValue : 0,
                //         insight: '5f : 4m',
                //     },
                //     {
                //         id : 6,
                //         name: "Waist Circumference",
                //         value : hraSurvey.totalEmployee * .38 | 1,
                //         progressValue : 0,
                //         insight: '31.97cm',
                //     },
                //     {
                //         id : 7,
                //         name: "Smoking Behavior",
                //         value : hraSurvey.totalEmployee * .38 | 1,
                //         progressValue : 0,
                //         insight: '0.14/day',
                //     },
                //     {
                //         id : 8,
                //         name: "Drinking Behavior",
                //         value : hraSurvey.totalEmployee * .75 | 1,
                //         progressValue : 0,
                //         insight: '0.31/day',
                //     },
                //     {
                //         id : 9,
                //         name: "Overall Happiness",
                //         value : hraSurvey.totalEmployee * .77 | 1,
                //         progressValue : 0,
                //         insight: 'Positive Happiness',
                //     },
                //     {
                //         id : 10,
                //         name: "Resting Heart Rate",
                //         value : hraSurvey.totalEmployee * .45 | 1,
                //         progressValue : 0,
                //         insight: '22bpm',
                //     },
                //     {
                //         id : 11,
                //         name: "High Blood Pressure",
                //         value : hraSurvey.totalEmployee * .31 | 1,
                //         progressValue : 0,
                //     },
                //     {
                //         id : 12,
                //         name: "Cardivascular Disease in Family",
                //         value : hraSurvey.totalEmployee * .20 | 1,
                //         progressValue : 0,
                //     },
                //     {
                //         id : 13,
                //         name: "Type 2 Diabetes in Family",
                //         value : hraSurvey.totalEmployee * .2 | 1,
                //         progressValue : 0,
                //     },
                //     {
                //         id : 14,
                //         name: "Type 2 Diabetes",
                //         value : hraSurvey.totalEmployee * .5 | 1,
                //         progressValue : 0,
                //     },
                //     {
                //         id : 15,
                //         name: "Osteoarthritis",
                //         value : hraSurvey.totalEmployee * .14 | 1,
                //         progressValue : 0,
                //     },
                //     {
                //         id : 16,
                //         name: "Low Back Pain",
                //         value : hraSurvey.totalEmployee * .70 | 1,
                //         progressValue : 0,
                //     },
                // ]
            
            }
            else {
                activities.forEach(function(activity) {
                    risks.push(
                        {
                            id : activity.id,
                            name : activity.title,
                            value : activity.totalActivity || 0,
                            progressValue : 0,
                            progressType : '',
                            insight : 'N/A'
                        }
                    )
                });
            }
            var risksNew = this.applyProgressType(risks,hraSurvey.totalEmployee,totalActivities)
            hraSurvey.risks = risksNew;
            
            console.log('risk',hraSurvey)
            hraSurvey.surveyId = survey.id
            this.setState({loaded : true, surveys : hraSurvey, companySurvey :{
                enabled : (survey.companySurvey)? survey.companySurvey.enabled:false,
                dynamic : (survey.companySurvey)? survey.companySurvey.dynamic : false
            }})
        }catch(e){
            console.log(e)
        }finally{
            this.setState({busy:false})
        }
    }
    applyProgressType(risks,totalEmployee,totalActivities) {
        for(var i in risks) {
            var risk = risks[i]
            risk.progressValue = totalEmployee && totalActivities ? risk.value / (totalEmployee) * 100 : 0;

            if (risk.value > 75) {
              risk.progressType = 'success';
            } else if (risk.value > 50) {
              risk.progressType = 'info';
            } else if (risk.value > 25) {
              risk.progressType = 'warning';
            } else {
              risk.progressType = 'danger';
            }
        }
        return risks;
    }
    onToggleSurvey = async (newState) => {
        try{
            this.setState({
                busy : true,
                companySurvey : {
                    enabled : newState,
                }
            })

            var data = { surveyId : this.state.surveys.surveyId }
            if(newState) {
                var result = await DatabaseService.addSurveySubscription(data)
            } else {
                var result = await DatabaseService.removeSurveySubscription(data)
            }

            this.setState({
                companySurvey : {
                    enabled : (result.companySurvey) ? result.companySurvey.enabled : false,
                }
            })
        }
        catch(e){

        }
        finally{
            this.setState({busy:false})
        }
    }

    render() {
        return (
            <Hra  {...this.state}
                        onToggleSurvey={this.onToggleSurvey}
           
 
                       complete={this.state.complete}/>
      );
  }
}

export default withRouter(connect()(withTranslation('fwa')(FwaContainer)));
