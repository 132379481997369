import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, buttons, fontSizes, fonts, spacings, shadow} from 'style';
//import backgroundImage from './Competitions.jpg';
import {Card, Button, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';
import CompetitionCard from './CompetitionCard.js';
import EditCompetitionModalContainer from './modals/EditCompetitionModalContainer.js';
import LeadboardSelectModalContainer from './modals/LeadboardSelectModalContainer.js';
import UploadCompetitionTemplateModel from './modals/UploadCompetitionTemplateModel.js';
import Leaderboard from '../leaderboards/Leaderboard.js';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
        //maxWidth: '70rem'
        //maxWidth: '101rem'
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
        marginBottom: spacings.base,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeBlue,
        marginTop : spacings.large,
        marginBottom : spacings.base
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    field: {
        padding: spacings.tiny,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.long,
        //float : 'right'
    },
    buttonBase: {
      marginLeft: spacings.small,
      marginRight: spacings.small,
      ...buttons.base,
      textAlign:'center'
      //float : 'right'
    },
    resultRow: {
        marginTop: spacings.base,
        marginBottom: spacings.base,
    },
    image : {
      width: "1024px"
    }
};

const columns = 2;

const Competitions = function({
  t, 
  busy, 
  upcoming,
  active,
  history,
  newTemplate,
  onNewTemplate,
  onCloseNewTemplate,
  onNewCompetition,
  onCloseEditCompetitionModal,
  onCloseLeadboardSelectModal,
  seasonToEdit,
  leaderBoardsToSelect,
  redirectLeaderboard,
  navigateLeaderBoard,
  leaderboardRef,
  uploading,
  onExport
}) {
    return busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
    <div style={style.content}>
      <div style={style.header}>
        {t('Competitions')}
         { <Button style={style.buttonBase} onClick={onNewTemplate}>{'Upload Template'}</Button> }
      </div>

      {Boolean(newTemplate) &&
          <UploadCompetitionTemplateModel 
              onClose={onCloseNewTemplate} />
      }

      {Boolean(seasonToEdit) &&
          <EditCompetitionModalContainer 
              season={seasonToEdit}
              onClose={()=>onCloseEditCompetitionModal(null)}
              onExport={onExport}
              />
      }

      {Boolean(leaderBoardsToSelect) && 
        <LeadboardSelectModalContainer 
          leaderBoardsToSelect={leaderBoardsToSelect} 
          navigateLeaderBoard={navigateLeaderBoard}
          onClose={()=>onCloseLeadboardSelectModal()} />
      }
     
      <div>{t('Competitions Description')}</div>

      <div>
          <div style={style.subheader}>{t('Current Competition')}</div>
          <Card.Group>
            {active.map(function(competition, index) {
              return (
                <CompetitionCard
                  key={index}
                  competition={competition}
                  onEdit={onNewCompetition}
                  onSchedule={onNewCompetition}
                  leaderboardRef = {leaderboardRef}
                  redirectLeaderboard = {redirectLeaderboard}
                  onExport={onExport}
                />
              )
            })}
          </Card.Group>
      </div>
      <div>
          <div style={style.subheader}>{t('Upcoming Competitions')}</div>
          <Card.Group>
            {upcoming.map(function(competition, index) {
              return (
                <CompetitionCard
                  key={index}
                  competition={competition}
                  onEdit={onNewCompetition}
                  onSchedule={onNewCompetition}
                  onExport={onExport}
                  redirectLeaderboard = {redirectLeaderboard} 
                  leaderboardRef = {leaderboardRef}
                />
              )
            })}
          </Card.Group>
      </div>
      <div>
          <div style={style.subheader}>{t('Past Competitions')}</div>
          <Card.Group>
            {history.length ? history.map(function(competition, index) {
              return <CompetitionCard 
                key={competition.id} 
                competition={competition} 
                redirectLeaderboard = {redirectLeaderboard} 
                leaderboardRef = {leaderboardRef}
                onExport={onExport}
                />
            }) : t('No Past Competitions')}
          </Card.Group>
      </div>
    </div>
    )
};

export default withTranslation('competitions')(Competitions);
