import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Checkbox, Button, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';
import moment from 'moment';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, buttons, fontSizes, fonts, spacings, shadow} from 'style';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    field: {
        padding: spacings.tiny,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    resultRow: {
        marginTop: spacings.base,
        marginBottom: spacings.base,
    }
};

const Reports = ({t, 
                  busy, 
                  valid,
                  startDate,
                  endDate,
                  rewards,
                  engagement,
                  steps,
                  activities,
                  goalAnalysis,
                  result,
                  onChange,
                  onToggleFlag,
                  onSubmit,
                  }) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.header}>{t('Reports')}</div>
            <div style={style.section}>
            <form onSubmit={onSubmit}>
                <div style={style.subheader}>{t('Time Period')}</div>
                <div style={style.field}>
                    <input name='startDate' type='date' value={startDate} onChange={onChange} />
                </div>
                <div style={style.field}>
                    <input name='endDate' type='date' value={endDate} onChange={onChange} />
                </div>
                <br />
                <div style={style.subheader}>{t('Data')}</div>
                <div style={style.field}>
                    <Checkbox
                        label={t('Rewards')}
                        name='rewards'
                        checked={rewards}
                        onChange={()=>onToggleFlag('rewards')}
                        />
                </div>
                <div style={style.field}>
                    <Checkbox
                        label={t('Engagement')}
                        name='engagement'
                        checked={engagement}
                        onChange={()=>onToggleFlag('engagement')}
                        />
                </div>
                <div style={style.field}>
                    <Checkbox
                        label={t('Steps')}
                        name='steps'
                        checked={steps}
                        onChange={()=>onToggleFlag('steps')}
                        />
                </div>
                <div style={style.field}>
                    <Checkbox
                        label={t('Activities')}
                        name='activities'
                        checked={activities}
                        onChange={()=>onToggleFlag('activities')}
                        />
                </div>
                <div style={style.field}>
                    <Checkbox
                        label={t('goal_analysis')}
                        name='goalAnalysis'
                        checked={goalAnalysis}
                        onChange={()=>onToggleFlag('goalAnalysis')}
                        />
                </div>
                <br />
                <br />
                <Button style={style.button} type='submit' disabled={!valid}>{t('Generate Report')}</Button>
            </form>
            </div>
            <br />
            <br />
            {Boolean(result) &&
                <div style={style.section}>
                   
                    <div style={style.resultRow}>
                        <div style={style.subheader}>{t('Time Period')}</div>
                        <p>{t('start_date_prompt')} {moment(result.summary.startDate).format(t('mediumDate'))}</p>
                        <p>{t('end_date_prompt')}   {moment(result.summary.endDate  ).format(t('mediumDate'))}</p>
                    </div>


                    {Boolean(result.registrations) &&
                    <div style={style.resultRow}>
                        <div style={style.subheader}>{t('Members')}</div>
                        <p>{t('members_prompt')} {result.registrations.total || 0} / {result.registrations.employees || 0} {t('registered_members')} {/*result.registrations.total && <span>({result.registrations.total || 0} new)</span>*/}</p>
                        <p>{t('engagement_prompt')} {result.participation.count || 0} / {result.registrations.total || 0} {t('engaged_members')} ({(result.participation.count / result.registrations.total * 100).toFixed(2) || 0}%)</p>
                        <p>{t('trackers_prompt')} {result.devices.total || 0} ({(result.devices.total / result.registrations.total).toFixed(2)} {t('trackers_per_member')})</p>
                    </div>
                    }
                   
                    {Boolean(result.activities) &&
                    <div style={style.resultRow}>
                        <div style={style.subheader}>{t('Activities')}</div>
                        <p>{t('activities_prompt')} {result.activities.count || 0}</p>
                    </div>
                    }

                    {Boolean(result.steps) &&
                    <div style={style.resultRow}>
                        <div style={style.subheader}>{t('Steps')}</div>
                        <p>{t('steps_prompt')} {result.steps.count || 0}</p>
                    </div>
                    }
                    {Boolean(result.goals) &&
                    <div style={style.resultRow}>
                        <div style={style.subheader}>{t('Goal Analysis')}</div>
                        <ul>
                        {result.goals.map(goal=>(
                            <li key={goal.id}>
                                <p>{goal.title} {goal.id}</p>
                                <ul>
                                    <li>Users Subscribed: {goal.subscriptions}</li>
                                    <li>Activities Completed: {goal.activitiesCompleted}</li>
                                    {Boolean(goal.usersCompleted!==undefined) &&
                                        <li>Unique Users Completed Pathway: {goal.usersCompleted}</li>
                                    }
                                </ul>
                                {t('Activities')}
                                <ul>
                                    {goal.activities.map(activity=>(
                                        <li key={activity.id}>
                                            <p>{activity.title} ({activity.id})</p>
                                            <ul>
                                                <li>Times Completed: {activity.activitiesCompleted}</li>
                                                <li>Unique Users: {activity.usersCompleted}</li>
                                                <li>Unique Users Completed More Than Once: {activity.usersCompletedMoreThanOnce}</li>
                                                {Boolean(activity.minValue!==undefined) &&
                                                    <li>
                                                        <p>Min Value: {activity.minValue}</p>
                                                        <p>Max Value: {activity.maxValue}</p>
                                                        <p>Avg Value: {activity.avgValue}</p>
                                                    </li>
                                                }
                                            </ul>
                                            Answers
                                            <ul>
                                                {activity.answers.map((answer,i)=>(
                                                    <li key={i}>
                                                        <p>{answer.label}</p>
                                                        <ul>
                                                            <li>{answer.total} answers</li>
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                        </ul>
                    </div>
                    }
                    
                </div>
            }
        </div>
    )
);

export default withTranslation('reports')(Reports);
