import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import RiskProfiles from './RiskProfiles.js';


class RiskProfilesContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      riskProfiles:[]};
        this.onHRA = this.onHRA.bind(this)
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData();
    }

    async loadData() {
        try {
            this.setState({busy:true});
            
            var risks = [];
            var riskProfiles = [];
            if(localStorage.inviteCode == 'aimhigher' || true) {
                risks = [
                        {
                            id : 1,
                            name: "Overall",
                            value : 43,
                            progressValue : 56.5,
                            averagePercentage : 56.5
                        },
                        {
                            id:2,
                            name: "Heart Disease",
                            value : 43,
                            progressValue : 45.5,
                            averagePercentage : 45.5
                        },
                        {
                            id : 3,
                            name: "Diabetes",
                            value : 43,
                            progressValue : 45.5,
                            averagePercentage : 45.5
                        },
                        {
                            id : 4,
                            name: "Obesity",
                            value : 43,
                            progressValue : 80.5,
                            averagePercentage : 80.5
                        },
                        {
                            id : 5,
                            name: "Arthritis",
                            value : 43,
                            progressValue : 56.5,
                            averagePercentage : 56.5
                        },
                        {
                            id : 6,
                            name: "Depression",
                            value : 43,
                            progressValue : 65.5,
                            averagePercentage : 65.5
                        },
                        {
                            id : 7,
                            name: "Back Pain",
                            value : 43,
                            progressValue : 75.5,
                            averagePercentage : 75.5
                        },
                        {
                            id : 8, 
                            name: "Injuries",
                            value : 43,
                            progressValue : 20.5,
                            averagePercentage : 20.5
                        },
                        {
                            id : 9,
                            name: "Lung Disease",
                            value : 43,
                            progressValue : 90.5,
                            averagePercentage : 90.5
                        },
                    ]
                    riskProfiles.totalEmployee = 43;
                    var totalActivities = 1;
            }
            else {
                var results = await DatabaseService.getRiskProfile();
                console.log('results',results)
                var reports = results || [];
                var report = reports[0];

                riskProfiles.report = report;
                var reportAttributes = report.reportAttributes || [];
            
                var totalActivities = 1;
                riskProfiles.totalEmployee = report.totalEmployee || 0;
                riskProfiles.totalActivity = 1;
                riskProfiles.percentageResponse = riskProfiles.totalEmployee && totalActivities ? report.totalActivity / (riskProfiles.totalEmployee * totalActivities) * 100 : 0;
                reportAttributes.forEach(function(attribute) {
                    risks.push(
                        {
                            id : attribute.id,
                            name : attribute.label,
                            value : attribute.value,
                            countAnswers : attribute.countAnswers,
                            progressValue : 0,
                            insight : 0
                        }
                    )
                });
            }
            this.applyProgressType(riskProfiles.totalEmployee,risks,totalActivities)
            riskProfiles.risks = risks;
            console.log('risk',risks)
            this.setState({riskProfiles : riskProfiles})
        }catch(e){
            console.log(e)
        }finally{
            this.setState({busy:false})
        }
    }
    applyProgressType(totalEmployee,risks,totalActivities) {
        for(var i in risks) {
            var risk = risks[i]
            if (risk.progressValue === 0)
            risk.progressValue = totalEmployee && totalActivities ? risk.value / (totalEmployee) * 100 : 0;

            if (risk.progressValue >= 75) {
              risk.progressType = 'danger';
            } else if (risk.progressValue > 50) {
              risk.progressType = 'warning';
            } else if (risk.progressValue > 25) {
              risk.progressType = 'info';
            } else {
              risk.progressType = 'success';
            }
        }
    }
    onHRA(){
        this.props.history.push('/hra');
    }
    render() {
        return (
            <RiskProfiles
                       {...this.state}
                       onHRA = {this.onHRA}/>
      );
  }
}

export default withRouter(connect()(withTranslation('riskprofiles')(RiskProfilesContainer)));
