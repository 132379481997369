import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import {getCategories} from 'reducers/categories.js';
import {fetchCategories, toggleCategory} from 'actions/categories.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import EditObjectiveModal from './EditObjectiveModal.js';

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

class EditObjectiveModalContainer extends Component {
    constructor(props) {
        super(props);
        var objective = this.props.objective;
        var objectiveState = Boolean(objective && objective.id) ? (
            {
                id: objective.id,
                locale: objective.locale,
                locales: objective.locales,
                autoSubscribe: objective.autoSubscribe,
                companyId: objective.companyId,
                image: objective.image,
            }
        ) : (
            {
                locale: 'en',
                locales: {
                  en:{title:''},
                  fr:{title:''}
                },
                autoSubscribe: false,
                companyId: null,
                image: null,
            }
        );
        this.state = { valid:true,
                       busy: false,
                       objectiveCategories: {},
                       chars_left: 0,
                       ...objectiveState,
                     };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeLocale = this.onChangeLocale.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onToggleAutoSubscribe = this.onToggleAutoSubscribe.bind(this);
        this.onTogglePublic = this.onTogglePublic.bind(this);
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onConfirmPhoto = this.onConfirmPhoto.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
    }

    componentWillMount() {
        this.loadData(true);
    }

    onChangeDropdown(event, {value, name}) {
        console.log('onChangeDropdown', {name, value});
        this.setState(state => (Utils.set({...state}, name, value)));
    }

    loadData() { //Get categories, and objective categories for existing objective
        this.setState({busy:true});
        Promise.all([(this.state.id) ? DatabaseService.getObjective({objectiveId: this.state.id}) : null,
                     this.props.fetchCategories()
            ])
            .then(([result])=>{
                if(result) {
                    var objective = result.objective || {};
                    this.setState({objectiveCategories:this.hashObjectiveCategories(objective.objectiveCategories||[])});
                }
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
               this.setState({busy:false});
            });
    }

    hashObjectiveCategories(objectiveCategories) {
        objectiveCategories = objectiveCategories || [];
        return objectiveCategories.reduce((m,oc) => {m[oc.categoryId] = oc; return m;}, {});
    }

    trimLocales(locales) {
        var result = {};
        for(var key in locales) {
            if(this.state.preferredLanguage===key) {
                result[key] = locales[key];
            } else {
                var locale = locales[key];
                if(Boolean(locale.title)) {
                    result[key] = locales[key];
                }
            }
        }
        return result;
    }

    onSubmit(event) {
        event && event.preventDefault();

        if(this.state.busy) {
            return;
        }

        var update = Boolean(this.state.id);
        var method = update ? 'updateObjective' : 'addObjective';

        var objective = {
            id                    : this.state.id,
            locale                : this.state.locale,
            locales               : this.trimLocales(this.state.locales),
            objectiveCategories   : Object.keys(this.state.objectiveCategories).map(categoryId => ({...this.state.objectiveCategories[categoryId], categoryId})),
            autoSubscribe         : this.state.autoSubscribe,
            companyId             : this.state.companyId,
            image                 : this.state.image,
        }

        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService[method]({objective:objective, objectiveId: this.state.id})
            .then(result => {
            })
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                this.props.onRefresh();
                this.props.onClose();
                this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    onDelete() {
        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.removeObjective({objectiveId:this.props.objective.id})
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(result => {
                this.props.onRefresh();
                this.props.onClose();
                this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            })
    }


    validateObjectiveCategories(objectiveCategories) {
        var valid = true;
        Object.keys(objectiveCategories).forEach(key => {
            var oc = objectiveCategories[key];
            if(oc.enabled && !_.isFinite(oc.quality)) {
                valid = false;
            }
        });
        return valid;
    }

    isValid() {
        var locale = this.state.locales[this.state.locale];
        var objectiveCategoriesValid = this.validateObjectiveCategories(this.state.objectiveCategories);
        return (Boolean(locale) &&
                Boolean(locale.title) &&
                objectiveCategoriesValid);
    }

    onChangeLocale(event, {value}) {
        this.setState({locale:value});
    }

    onChange(event) {
        var name = event.target.name;
        var value = event.target.value;

        this.setState({chars_left : value.length});
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
    }

    onToggleAutoSubscribe() {
        this.setState(state => ({autoSubscribe:!state.autoSubscribe}));
    }

    onTogglePublic() {
        this.setState(state => ({companyId:Boolean(state.companyId) ? null : localStorage.companyId}));
    }

    onToggle(event, {name, checked}) {
        console.log('onToggle', {name, checked});
        this.setState(state => (Utils.set({...state}, name, checked)));
    }

    onUploadPhoto(propertyName) {
        console.log('onUploadPhoto', propertyName);
        this.setState({uploadPhotoProperty:propertyName});
    }

    onConfirmPhoto(propertyName, photo) {
        this.setState(state => (Utils.set({...state}, propertyName, photo)));
    }

    render() {
        return (
            <EditObjectiveModal {...this.props}
                                {...this.state}
                                update={Boolean(this.state.id)}
                                valid={this.isValid()}
                                master={localStorage.master==='true'}
                                languages={languages}
                                onSubmit={this.onSubmit}
                                onChange={this.onChange}
                                onChangeLocale={this.onChangeLocale}
                                onDelete={this.onDelete}
                                onToggle={this.onToggle}
                                onTogglePublic={this.onTogglePublic}
                                onToggleAutoSubscribe={this.onToggleAutoSubscribe}
                                onUploadPhoto={this.onUploadPhoto}
                                onConfirmPhoto={this.onConfirmPhoto}
                                onChangeDropdown={this.onChangeDropdown}
                                valueChange = {this.state.chars_left}
                                />
      );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {categories:getCategories(state.categories)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: (params, force) => dispatch(fetchCategories(params, force)),
        dispatch: dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('categories')(EditObjectiveModalContainer));
