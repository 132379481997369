import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Color from 'color';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE, ADD_COMPANY} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import AppStartupForm from './AppStartupForm.js';

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

class AppStartupFormContainer extends Component {

    constructor(props){
		super(props);
        this.state = {...this.getFormState(this.props.welcome),
                        currentPane: 'en',
                        chars_left: 0};
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onConfirmPhoto = this.onConfirmPhoto.bind(this);
        this.onChangeLocale = this.onChangeLocale.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        var welcome = nextProps.welcome;
        if(welcome && welcome!==this.props.welcome) {
            this.setState(this.getFormState(welcome));
        }
    }

    getFormState(welcome) {
        welcome = welcome || {};
        return {
            color: welcome.color     || '#379f07',
            locale: welcome.locale   || 'en',
            locales: welcome.locales || [],
            logo: welcome.logo       || undefined,
            photo: welcome.photo     || undefined,
        };
    }

    getColorString(color) {
        try {
            return Color(color).hex();
        } catch(e) {
            return '#000000';
        }
    }

    onTabChange = (event, data) => {
        console.log('Language', data);
        if(data.activeIndex == 0){
            this.setState({currentPane: 'en'});
        } else {
            this.setState({currentPane: 'fr'});

        }

    }

    onChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        console.log('onChange', {name, value});
        this.setState({chars_left: value.length});
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
    }

    trimAsset(asset) {
        return (asset && asset.dataURL) ? asset : undefined;
    }

    onSubmit(event) {
        event && event.preventDefault();

        var welcome = {
            color: this.getColorString(this.state.color),
            locale: this.state.locale,
            locales: this.state.locales,
            logo:  this.trimAsset(this.state.logo),
            photo: this.trimAsset(this.state.photo),
        };
        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.updateOrganization({welcome: welcome})
                       .then(result => {
                            this.props.dispatch({type: ADD_COMPANY, company:result.company});
                           this.props.onRefresh();
                       })
                       .catch(error => {
                            console.error(error)
                            window.alert('An error occurred - check console');
                       })
                       .then(()=>{
                            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                       });
    }

    onUploadPhoto(propertyName) {
        console.log('onUploadPhoto', propertyName);
        this.setState({uploadPhotoProperty:propertyName});
    }

    onConfirmPhoto(propertyName, photo) {
        this.setState(state => (Utils.set({...state}, propertyName, photo)));
    }

    onChangeLocale(event, {value}) {
        this.setState({locale:value});
    }

    isValid() {
        var locale = this.state.locales[this.state.locale];
        return (Boolean(locale) &&
                Boolean(locale.companyName) &&
                Boolean(locale.welcomerName) &&
                Boolean(locale.welcomerTitle) &&
                Boolean(locale.message));
    }

    render() {
        return (
            <AppStartupForm {...this.props}
                         {...this.state}
                         languages={languages}
                         colorString={this.getColorString(this.state.color)}
                         valid={this.isValid()}
                         onChange={this.onChange}
                         valueChange = {this.state.chars_left}
                         onSubmit={this.onSubmit}
                         onUploadPhoto={this.onUploadPhoto}
                         onConfirmPhoto={this.onConfirmPhoto}
                         onChangeLocale={this.onChangeLocale}
                         onTabChange = {this.onTabChange}
                         />
      );
    }
}

export default withRouter(connect()(withTranslation('account')(AppStartupFormContainer)));
