import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js'; // initialized i18next instance using reactI18nextModule

import AppContent from './modules/main/AppContent.js';
import store from './state/store.js'
import LocationWatcher from './modules/misc/LocationWatcher.js';
import DatabaseService from './services/DatabaseService.js'



/* FOR DEBUG - remove in production */
import _ from 'underscore';
import moment from 'moment';
import UserService from './services/UserService.js'
window.dev = {};
window.dev.store = store;
window.dev.DatabaseService = DatabaseService;
window.dev.UserService = UserService;
window.dev._ = _;
window.dev.moment = moment;
/* */



DatabaseService.setTranslator(i18n);


class App extends Component {
    render() {
      return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <Router>
                    <LocationWatcher>
                        <AppContent />
                    </LocationWatcher>
                </Router>
            </Provider>
        </I18nextProvider>
      );
    }
}




export default App;
