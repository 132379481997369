import React, { Component } from 'react';
import Utils from '../../utils/Utils.js'
import RoleUtils from '../../utils/RoleUtils'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import {getCompany} from 'reducers/companies.js';
import {fetchCompanies} from 'actions/companies.js';
import SideBar, {width} from './SideBar.js';
import { getUserRoles } from '../../reducers/user.js';
import DatabaseService from '../../services/DatabaseService.js';

class SideBarContainer extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        if(localStorage.ownerCompanyId)
            this.props.fetchCompanies()
                .catch((error)=> {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
    }

    onClick(e) {
        e.preventDefault();
        var href = e.currentTarget.getAttribute('href');
        this.props.history.push(href);
    }

    async onSignOut(e){
        e.preventDefault();
        await DatabaseService.signOut()
        window.location.href = '/login'
    }

    links() {        
        var links = [
            {href:"/dashboard",         title:"Dashboard",           icon:"bar chart"},
            //...(['135', '223', '224', '128', '244'].indexOf(localStorage.companyId) > -1 && [{href:"/marketing", title:"Marketing", icon:"line chart"}] || []),
            //...(['135', '229', '128'].indexOf(localStorage.companyId) > -1 && [{href:"/calendar", title:"Events", icon:"calendar"}] || []),
            {href:"/goals",             title:"Goals",               icon:"flag checkered"},
            {href:"/surveys",           title:"Surveys",             icon:"list ul"},
            {href:"/competitions",      title:"Challenges",          icon:"trophy"},
            {href:"/invitations",       title:"Invitations",         icon:"mail"},
            {href:"/rewards",           title:"Rewards",             iconClassName:"icon-rewards"},
            ...(localStorage.master === 'true' && [{href:"/rewardsV2",           title:"Rewards V2",             iconClassName:"icon-rewards"}] || []),
            {href:"/members",           title:"Members",             icon:"user"},
            {href:"/teams",             title:"Teams",               icon:"users"},
            {href:"/announcements",     title:"Announcements",       icon:"announcement"},
            {href:"/hra",               title:"HRA",                 icon:"law"},
            //{href:"/fwa",               title:"FWA",                 icon:"law" },
            ...(localStorage.master === 'true' && [{href:"/jobs",              title:"Jobs",                icon:"icon-gear"}] || []),
            {href:"/segments",          title:"Segments",            icon:"law"},
            {href:"/account",           title:"Account",             iconClassName:"icon-gear"},
            //{href:"/reports",           title:"Reports (Preview)",   icon:"tv"}
        ]

        let filter = RoleUtils.getPathFilter(this.props.roles)
        if (filter) {
            //if there is a list of accepted paths
            links = links.filter((link) => filter.includes(link.href))
        }

        // if (['128','299'].indexOf(localStorage.originalCompanyId) > -1 || localStorage.master === 'true') {
        //     links.push({href:"/exports",     title:"Exports", icon:"download" })
        // }

        if (localStorage.companyCanOwn === "true") {
            links.push({href:"/organizations",     title:"Switch Organization", icon:"arrow left" })
        }

        //always have a sign out button
        links.push({href:"/", title:"Sign Out", iconClassName:"icon-power", onClick:this.onSignOut})
        
        return links.map((link)=>({
            ...link, 
            className:(link.href==this.props.location.pathname ? 'active item' : 'item'  )
        }));
    }

    render() {
        return (
            <SideBar links={this.links()}
                     company={this.props.company}
                     style={this.props.style}
                     enable={this.props.enable}
                     userProfile={this.props.userProfile}
                     onClick={this.onClick}
                     avatarUrl={Utils.deref(this.props.userProfile, 'avatar.url') || ''} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    var userProfile = {firstName:'', lastName:'', team:{name:''}, company:{name:''}};
    var companyId = localStorage.companyId;
    var company = companyId ? getCompany(state.companies, companyId) : {};

    if(state.user.userProfile) {
        userProfile = {...userProfile, ...state.user.userProfile};
    }
    if(userProfile.team==null) {
        userProfile.team = {name:''};
    }
    if(userProfile.company==null) {
        userProfile.company = {name:''};
    }    
    return { 
        userProfile: userProfile, 
        enable: state.sidebar.enable, 
        company:company,
        roles: getUserRoles(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompanies: (params, force) => dispatch(fetchCompanies(params, force)),
        dispatch: dispatch
    };
};

export {width};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarContainer));
