import DatabaseService from './DatabaseService.js'
import store from '../state/store.js';

class UserService {
	signIn(username, password) {
		return DatabaseService.signIn(username, password)
	}

    signUp(username, password, password2, code, profile) {
        if (password != password2) {
            return Promise.resolve( "Password and Confirm did not match." );
        }

		return DatabaseService.signUp(username,password,code,profile)
    }

    passwordReset(email) {
        return DatabaseService.resetPassword(email)
    }
}

export default new UserService();
