import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import InviteEmployeesModal from './InviteEmployeesModal.js';

class InviteEmployeesModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { inviteMode:'new',
                       loadingEmployees: false,
                     };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit() {
        var params = { companyEmployee: { invited : true } };
        if(this.state.inviteMode === 'new') {
            params.where = { uninvited : true };
        }

        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.putEmployees(params)
            .catch((error)=> {
                console.error(error)
                window.alert('An error occurred - check console');
            })
           .then(()=>{
                this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                this.props.onClose();
           });
    }

    onChange(event, {value}) {
        this.setState({inviteMode:value});
    }

    render() {
        return (
            <InviteEmployeesModal {...this.props}
                                  {...this.state}
                                  onChange={this.onChange}
                                  onSubmit={this.onSubmit}
                                  />
        );
    }
}

export default connect()(withTranslation('employees')(InviteEmployeesModalContainer));
