import {SET_CATEGORIES, ADD_CATEGORY, ADD_CATEGORIES} from 'actions/types';

var categoriesInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = categoriesInitialState, action) => {
    switch(action.type) {
        case SET_CATEGORIES: 
            return {...state,
                    list:action.categories.map((c)=>(c.id)),
                    indexed: action.categories.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    listLoaded:true
                    };
        case ADD_CATEGORY: 
            return {...state,
                    indexed: {...state.indexed, [action.category.id]:action.category}
                    };
        case ADD_CATEGORIES: 
            return {...state,
                    indexed: action.categories.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed})
                    };
        default:            
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getCategories = state => state.list.map( id => state.indexed[id] );
export const getCategoriesByIds = (state, ids) => getCategories(state).filter(cat => ids.include(cat.id));
export const getEnabledCategories = state => getCategories(state).filter(c => (c.userCategory && c.userCategory.enabled===true));
export const getCategory  = (state, id) => state.indexed[id];

