import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Loader} from 'semantic-ui-react';

class LoadSpinner extends Component {
    render () {
        if(!this.props.enable) {
            return null;
        }
        return (
            <Loader active/>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({enable:(state.loading.spinner && !state.loading.overlay)});

export default connect(mapStateToProps)(LoadSpinner);
