import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Icon, Header, Checkbox } from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import Utils from 'utils/Utils.js';
import defaultRewardImage from 'img/optimity-logo.png';

const style = {
    item: {
        textAlign: 'center',
        padding: spacings.base,
    },
    rewardsImage: {
        width: '100px',
        maxHeight: '100px',
    },
    rewardRow: {
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        marginTop: spacings.tiny,
        marginBottom: spacings.tiny,
    },
    checkbox: {
        verticalAlign: 'middle',
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
        display: 'inline-block',
    },
    rewardPrice: {
        verticalAlign: 'middle',
        textAlign: 'right',
        display: 'inline-block',
        width: '80px',
    },
    rewardResources: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
        textAlign:'left'
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    editButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0',
        margin: '0',
    },
    gemIcon: {
        color: colors.pointsBlue
    }

};

const RewardCardV2 = ({t, rewardAction, onToggleRewardOption, onEdit, rewardCategory, allowEdit}) => {
    const {
        id,
        title,
        subtitle,
        asset,
        rewardOptions=[],
    } = rewardAction
    return (
    <Card key={id} style={style.item}>
        <div>
            {asset && <img src={asset.url} alt={asset.label} style={style.rewardsImage} />}
            {Boolean(onEdit) && Boolean(allowEdit) &&
                <Button style={style.editButton} onClick={()=>onEdit(rewardAction, rewardCategory)}><Icon name='edit'/></Button>
            }
        </div>
        <p>{title}</p>
        <p style={{fontSize: 'small'}}>{subtitle}</p>
        {Boolean(localStorage.master  === 'true') && <p style={{fontSize: 'small'}}>{id}</p>}

        {rewardOptions.map(rewardOption => {
            const { reward } = rewardOption
            return (
                <div key={rewardOption.id} style={style.rewardRow}>
                    <div style={style.rewardPrice}><Icon name="dollar"/>{reward.price / 100} {reward.currency?.code}</div>

                    <Checkbox style={style.checkbox}
                        disabled={!Boolean(allowEdit)}
                        className='green' 
                        key={rewardOption.id} 
                        toggle
                        checked={rewardOption.enabled}
                        onChange={()=>onToggleRewardOption(rewardAction, rewardOption)}/>

                    <div style={style.rewardResources}><Icon name="gem" style={style.gemIcon}/> {rewardOption.resources}</div>
                </div>
            )
        })}
    </Card> 
)};

export default withTranslation('rewards')(RewardCardV2);
