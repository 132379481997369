import {colors, shadow, fontSizes, fonts, spacings} from 'style';

const style = {
    form: {
        height: '100%',
        backgroundColor: 'white',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: spacings.large,
        paddingTop: spacings.large,
        boxShadow: shadow,
    },
    content: {
        height: '100%',
        color: colors.themeDarkerGrey,
    },
    bannerColumn: {
        padding: '0',
    },
    banner: {
        fontSize: fontSizes.h1,
        lineHeight: fontSizes.h1,
    },
    logo: {
        color: colors.themeTeal,
        paddingTop: '',
        fontSize: '1.3em',
        lineHeight: '1.3em',
    },
    welcome: {
        display: 'inline',
        color: colors.themeBlue,
        fontSize: fontSizes.h2,
        lineHeight: fontSizes.h2,
    },  
    errorLabel: {
        position: 'relative',
        margin: '0 10px -100% 0',
        float: 'right',
        right: '100%',
        bottom: '30px',
    },
    field: {
        position: 'relative',
    },
    input: {
        paddingLeft: '0px',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: '1px solid ' + colors.themeTeal,
        color: colors.themeTeal,
        boxShadow: 'none',
        borderRadius: '0',
        ...fonts.body,
    },
    inputError: {
        borderBottom: '1px solid ' + colors.errorRed,
        color: colors.errorRed,
    },
    inputLabel: {
        paddingTop: '10px',
        textAlign: 'left',
        color: colors.themeDarkerGrey,
        ...fonts.body,
    },
    topRow: {
        paddingBottom: '0',
    },
    topLink: {
        ...fonts.body,
        lineHeight: '1.5em',
        paddingTop: '20px',
        paddingRight: '60px',
        paddingBottom: '0px',
    },
    row: {
        padding: '0',
    },
    tealButton: {
        fontWeight: 'normal',
        ...fonts.body,
        lineHeight: '1.5',
        borderRadius: '100px',
        backgroundColor: colors.themeTeal,
        color: '#fff',
        width: '330px',
        boxShadow: shadow,
    },
    greyButton: {
        fontWeight: 'normal',
        ...fonts.body,
        lineHeight: '1.5',
        borderRadius: '100px',
        backgroundColor: colors.themeDarkerGrey,
        color: '#fff',
        width: '330px',
        opacity: '0.50',
        boxShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 4px 0px',
    },
    anchor: {
        color: colors.themeBlue,
        textDecoration: 'underline',
    },
};

export default style;
