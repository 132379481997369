import React from 'react';
import {Icon} from 'semantic-ui-react';

const style = {
    sortIcon: {
        float: 'right',
    },
};

const SortIcon = ({t, field, sortField, asc}) => (
    <Icon style={{...style.sortIcon, visibility: (field===sortField ? 'visible' : 'hidden')}} name={asc ? 'chevron up' : 'chevron down'} /> 
);

export default SortIcon;
