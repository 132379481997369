import {SET_TEAMS} from 'actions/types';


var teamsInitialState = {list:[], indexed:{}, loaded:false};
export default (state = teamsInitialState, action) => {
    switch(action.type) {
        case SET_TEAMS: 
            return {...state,
                    list:action.teams.map((c)=>(c.id)),
                    indexed:action.teams.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    loaded:true
                    };
        default:            
            return state;
    }
}

//selectors
export const isListLoaded = state => state.listLoaded;
export const getTeams = state => state.list.map( id => state.indexed[id] );
export const getTeam  = (state, id) => state.indexed[id];
