import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Grid, Input, Button, Checkbox, Label, Dropdown, Tab, Form} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';
import CategoryLevelSection from './CategoryLevelSection.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';

var style = {
    level: {
        margin: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey,
        borderRadius: '6px',
    },
    levelContent: {
        padding: spacings.base,
    },
    titleBar: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontSize: fontSizes.bodyL,
        padding: spacings.small,
        backgroundColor: colors.themeGrey,
    },
    addButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    addButtonRowVersion: {
        backgroundColor: colors.themeGrey,
        color: colors.themeBlue,
        fontSize: fontSizes.h4,
        padding: '0',
        margin: '0',
        float: 'right',
    },
    trashIcon: {
        color: colors.warningRed,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: fontSizes.h4,
        float: 'right',
    },
    addRow: {
        borderRadius: spacings.tiny,
        fontSize: fontSizes.bodyL, 
        padding: spacings.small,
        margin: spacings.tiny,
        borderRadius: spacings.micro,
        color: colors.themeBlue,
        border: '1px dashed #007fb9',
        backgroundColor: colors.themeGrey,
        fontStyle: 'italic',

    },
    
};



const CategoryLevel = ({t,
                        levelIndex,
                        categoryLevel,
                        languages,
                        onAddSection,
                        onAddChallenge,
                        onToggle,
                        onChange,
                        onChangeDropdown,
                        onSelectChallengeType,
                        onDeleteTab,
                        onHideTab,
                        valueChange,
                        onAddChallengeAnswer,
                        onUploadPhoto,
                        onCancelPhoto,
                        getBasepath,
                        locale,
                        refLevel,
                        refSection,
                        refChallenge,
                        refChallengeAnswer,
                        onAutoCaption
                        }) => (
    <div style={style.level}>
        <div style={style.titleBar}>
        <DeletePromptContainer ref = {refLevel} onDeleteConfirmation = {onDeleteTab} onClose = {() => {refLevel = null}}/>

            <Button type="button" onClick={() => {refLevel.current.showDeletePrompt(levelIndex);}}
                                                  style={{...style.iconButton, ...style.trashIcon}}>
                <Icon name="trash"/>
            </Button>
            <Button type="button" onClick={()=>onHideTab(levelIndex)} style={{...style.iconButton, ...style.windowIcon}}>
                <Icon name={categoryLevel.isOpen ? "window minimize" : "window maximize"}/>
            </Button>
            <div>{t('level_title', {i:levelIndex+1})}</div>
        </div>
        <div style={style.levelContent} id = {"levelContent" + levelIndex}>
            {(true || Boolean(categoryLevel.isOpen)) &&
                <div>
                    <div>{t('allow_repeat_prompt')}</div>
                    <Checkbox className='green' 
                              name={'categoryLevels.'+levelIndex+'.allowRepeat'}
                              toggle
                              title={t('repeat_tooltip')}
                              checked={Boolean(categoryLevel.allowRepeat)} 
                              onChange={onToggle}/>
                </div>
            }
            {categoryLevel.categoryLevelSections.map((categoryLevelSection, i)=>(
                <CategoryLevelSection key={categoryLevelSection.key || categoryLevelSection.id} 
                                levelIndex={levelIndex}
                                sectionIndex={i} 
                                categoryLevelSection={categoryLevelSection}
                                onAddChallenge={onAddChallenge}
                                onChange={onChange}
                                onChangeDropdown={onChangeDropdown}
                                onToggle={onToggle}
                                languages={languages}
                                valueChange = {valueChange}
                                onSelectChallengeType={onSelectChallengeType}
                                onHideTab={onHideTab}
                                onDeleteTab={onDeleteTab}
                                onAddChallenge={onAddChallenge}
                                onAddChallengeAnswer={onAddChallengeAnswer}
                                onUploadPhoto={onUploadPhoto}
                                onCancelPhoto={onCancelPhoto}
                                getBasepath={getBasepath}
                                locale={locale}
                                refSection = {refSection}
                                refChallenge = {refChallenge}
                                refChallengeAnswer = {refChallengeAnswer}
                                onAutoCaption = {onAutoCaption}/> 
            ))}
            <div style = {style.addRow}>
                        {t('add_section')}
                        <Button type='button' style={style.addButtonRowVersion} onClick={()=>onAddSection(levelIndex)}>
                            <Icon name="plus"></Icon>
                        </Button>
                </div>
        </div>
    </div>
);

export default withTranslation('categories')(CategoryLevel);
