import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {getCategories} from 'reducers/categories.js';
import {fetchCategories} from 'actions/categories.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import {initNavbarState} from 'actions/navbar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Category from './Category.js';

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];


class CategoryContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      categories:[],
                      categoryToDisplay:null,
                      ...this.getInitialCategoryState()
                     };
        this.onSubmit = this.onSubmit.bind(this);
    }


    getInitialCategoryState() {
        return {
              locales: {
                  en:{title:'', description:''},
                  fr:{title:'', description:''}
              },
              locale: 'en',
        };
    }

    componentWillMount() {
        this.props.dispatch(initNavbarState({enable:true, backButtonEnable:true}));
        this.props.dispatch(setSidebarEnable(false));
        this.loadData();
    }

    componentWillUnmount() {
        this.props.dispatch(initNavbarState({enable:false}));
    }

    onSubmit() {
    }

    loadData() {

    }
    render() {
        return (
            <Category {...this.state}
                      languages={languages}
                      onSubmit={this.onSubmit}
                      />
      );
  }
}

export default withRouter(connect()(withTranslation('categories')(CategoryContainer)));
