import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/semantic.min.css';
import 'cropperjs/dist/cropper.css';
import 'react-day-picker/lib/style.css';
import './css/imports.css';

import 'utils/polyfills.js';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

window.Buffer = window.Buffer || require("buffer").Buffer; 

ReactDOM.render(<App className="App"/>, document.getElementById('root'));
//registerServiceWorker();
