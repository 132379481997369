export const cropperDimensions = {
    asset: {
        width: 640,
        height: 360
    },
    logoAsset: {
        width: 600,
        height: 600
    },
    bannerAsset: {
        width: 640,
        height: 360
    }
}