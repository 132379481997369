import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Card, Button, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style'; 
import EditTeamModalContainer from './modals/EditTeamModalContainer.js';
import TeamAssignModalContainer from './modals/TeamAssignModalContainer.js';
import './teams.css';

var style = {
    loaderContainer: {
        height: '100%',
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
        marginBottom: spacings.base,
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
        marginTop: spacings.base,
    },
    teams: {
    },
    team: {
        margin: spacings.tiny,
        paddingTop: spacings.large,
        paddingBottom: spacings.base,
        paddingLeft: spacings.base,
        paddingRight: spacings.base,
        color: '#fff',
        textAlign: 'center',
        backgroundColor: colors.teamPurple,
        width: '214px',
        boxShadow: shadow,
        display: 'inline-block',
        minHeight: '250px',
        verticalAlign: 'top',
        cursor: 'pointer',
    },
    teamCount: {
        fontSize: fontSizes.bodyS,
    },
    teamPts: {
        fontSize: fontSizes.bodyS,
    },
    teamAvatarWrapper: {
        position: 'relative',
        height: '100px',
    },
    teamAvatar: {
        width: '80px',
        position: 'absolute',
        top: '10px',
        left: '50%',
        marginLeft: '-40px',
    },
    teamIcon: {
        fontSize: '80px',
        position: 'absolute',
        top: '10px',
        left: '50%',
        marginLeft: '-40px',
    },
    unassignedNotice: {
        cursor: 'pointer'
    },
    editButton: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        color: '#ffff',
        backgroundColor: colors.teamPurple,
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0px',
        margin: '0',
        verticalAlign: 'top',
    },
    editIcon: {
        margin: '0',
        opacity: '1',
    },
};

const Teams = ({t, 
                busy, 
                teams,
                teamToEdit,
                showTeamAssignModal,
                onRefresh,
                onShowEditTeam,
                onShowTeamAssign,
                onShowTeamDetails,
                onChangeTeam}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={{...style.header, display: 'inline'}}>{t('Teams')}</div>
                <Button style={style.button} onClick={()=>onShowEditTeam({})}>{t('Add New Team')}</Button>

                <div style={style.section}>
                {/* <div style={style.subheader}>{t('Company Teams')}</div> */}

                <Card.Group style={style.teams}>
                {teams.map(team=>(
                    <Card key={team.id} style={style.team} onClick={()=>onShowTeamDetails(team.id)}>
                        <div>{team.name}</div>
                        <div style={style.teamCount}>{t('team_count', {count: team.employeesCount})}</div>
                        <div style={style.teamAvatarWrapper}>
                        {Boolean(team.avatar && team.avatar.url) ? (
                            <img alt='' style={style.teamAvatar} src={team.avatar.url} />
                        ) : (
                            <i style={style.teamIcon} className='icon-team' />
                        )}
                        </div>
                        {/*
                        <div style={style.teamPts}>{t('count_pts', {count:team.points})}</div>
                        */}
                    </Card>
                ))}
                </Card.Group>
            </div>


            {Boolean(teamToEdit) &&
                <EditTeamModalContainer team={teamToEdit}
                                        onClose={()=>onShowEditTeam(null)}
                                        onRefresh={onRefresh} />
            }
        </div>
    )
);

export default withTranslation('teams')(Teams);
