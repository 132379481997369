import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import DateUtils from 'utils/DateUtils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import AnnouncementDetailsModal from './AnnouncementDetailsModal.js';



class AnnouncementDetailsModalContainer extends Component {
    constructor(props) {
        super(props);

        this.state = { valid:false,
                      loading:false,
                      busy:false,
                      validDateText:true,
                      pushNotification: true,
                      employees:[],
                      totalEmployees:null,
                      currentPage: 0,
                      limit:10,
                      sortAsc: true,
                      sortField: 'fullName',
        };
        this.onClose = this.onClose.bind(this);
        this.onNextPage = this.onNextPage.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    componentWillMount() {
        this.loadData(true);
    }

    sortBy(field) {
        var sortAsc = true;
        if(this.state.sortField===field) {
            sortAsc = !this.state.sortAsc;
        }
        this.setState({currentPage: 0, sortField:field, sortAsc}, this.loadData);
    }

    onNextPage(forward) {
        var totalEmployees = this.state.totalEmployees;
        var maxPages = Math.ceil(totalEmployees / this.state.limit);
        var currentPage = this.state.currentPage;
        if(forward) {
            currentPage = Math.min(maxPages, currentPage+1);
        } else {
            currentPage = Math.max(0, currentPage-1);
        }
        this.setState({currentPage}, ()=>this.loadData(false));
    }

    loadData(spinner) {
        var params = {
                announcementId: this.props.announcement.id,
                start: this.state.currentPage*this.state.limit,
                limit: this.state.limit,
                sortField: this.state.sortField,
                sortOrder: this.state.sortAsc ? 'ascending' : 'descending',
            }
        this.setState({busy:true, loading:(spinner)});
        DatabaseService.getAnnouncementRecipients(params)
            .then(result => {
                this.setState({employees:result.recipients,
                               totalEmployees:result.summary.total});
            })
            .catch(error => {
                window.alert('An error occurred, check logs');
                console.error(error);
            })
            .then(result => {
                this.setState({busy:false, loading:false});
            });
    }

    onClose() {
        this.props.onClose();
    }


    render() {
        var totalEmployees = this.state.totalEmployees;
        var maxPages = Math.ceil(totalEmployees / this.state.limit);
        return (
            <AnnouncementDetailsModal {...this.props}
                                   {...this.state}
                                   announcementType = {this.props.announcement.type}
                                   sortBy={this.sortBy}
                                   start={this.state.currentPage*this.state.limit+1}
                                   end={this.state.currentPage*this.state.limit+this.state.employees.length}
                                   atStart={this.state.currentPage===0}
                                   atEnd={(this.state.currentPage+1)>=maxPages}
                                   onNextPage={this.onNextPage}
                                   onClose={this.onClose} />
      );
    }
}

export default connect()(withTranslation('announcements')(AnnouncementDetailsModalContainer));
