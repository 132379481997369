import React from 'react';
import { withTranslation } from 'react-i18next';
import {Tab, Form, Button, Dropdown, Checkbox, Modal, Input, Message, Card, Grid, Icon, Image, Label, TextArea, Popup} from 'semantic-ui-react';
import Utils from 'utils/Utils.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import styleDef from './style.js';
import iPhoneMockup from 'img/iPhone_Mockup.png';

const style = {

    ...styleDef,
    image: {
        marginTop: spacings.base,
        marginBottom: spacings.base,
        marginLeft: 'auto',
        marginRight: 'auto',
        height:'300px',
    },
    ...styleDef,
    row: {
        backgroundColor: 'white',
        borderRadius: '10px',
        marginTop: '30px'
    },
    body: {
        maxWidth: '500px',
    },
    welcome: {
        fontSize: fontSizes.bodyL,
        color: colors.themeBlue,
        lineHeight: '2',
    },
    companyLogo: {
        height: '40px',
        marginBottom: spacings.base,
        backgroundPosition:'50% 50%',
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
    },
    chevronIcon: {
        marginTop: '8px',
        marginBottom: spacings.base,
        float: 'left',
    },

    message: {
        position: 'relative',
        backgroundColor: colors.themeBlue,
        color: '#fff',
        wordWrap:'break-word',
        whiteSpace:'pre-wrap',
        boxShadow: shadow,
        borderRadius: '10px',

    },
    messageContent: {
        padding: '10px',
        boxShadow: 'none',
    },
    messagePointer: {
        backgroundColor: 'inherit',
        position: 'absolute',
        bottom: '0px',
        left: '50%',
        marginBottom: '-12px',
        marginLeft: '-12px',
        width: '24px',
        height: '24px',
        transform: 'rotate(45deg)',
        boxShadow: 'inherit',
    },
    photoWrapper: {
        padding: '30px 0px 20px 0px',
    },
    photo:{
        position: 'relative',
        display: 'inline-block',
        width:'4em',
        height:'4em',
        borderRadius:'50%',
        border: '2px solid #fff',
        boxShadow: shadow,
        top: '1em',
        marginRight: '20px',
        marginTop: '-50px',
        objectFit: 'cover'
    },
    photoText: {
        display: 'inline-block',
        textAlign: 'left',
    },
    welcomerName: {
        fontWeight: 'bold',
    },
    welcomerTitle: {
        fontStyle: 'italic',
    },
    tealButton: {
        ...buttons.base,
        backgroundColor: colors.themeTeal,
    },

    billingForm: {
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        marginBottom: spacings.large,
        ...buttons.base,
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        margin: spacings.base,
    },
    langPrompt: {
        paddingRight: spacings.tiny,
    },
    colorField: {
        width: '200px',
    },
    colorPicker: {
        width:  '38px',
        height: '38px',
        verticalAlign: 'top',
        marginRight: spacings.tiny,
    },
    colorString: {
        display: 'inline-block',
        width: '100px',
        verticalAlign: 'top',
    },
    textarea: {
        resize: 'none',
        height: '300px',
        width: '300px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',

    },
    label: {
        fontWeight: 'bold',
        margin: '10px',
    },

    previewCard: {
        margin: '85px 22px',
        borderRadius: '10px',
        width: '310px',
        height: '540px'
    },

    iPhoneBackground: {
        float: 'right',
        margin: '100px',
        backgroundImage: `url(${iPhoneMockup})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        height: '750px',
        width: '350px'
    },

    pane: {
        padding:spacings.small,
        border:'1px solid ' + colors.themeDarkGrey1_2,
        borderTop:'none'
    },

    scrollDiv: {
        height: '310px',
        overflowY: 'auto'
    },

    gridMinWidth: {
        minWidth: '1000px',
        maxWidth: '1300px'
    },

    previewGrid: {
        width: '375px'
    }


}



export const LocalePane = ({t, data, locale, onChange, logo, photo, valid, onUploadPhoto, onSubmit, valueChange}) => (
    <div style={style.pane}>
            <Form.Group widths='equal'>
                <Form.Field>
                     <div style={style.label}>{t('company_name_prompt')}</div>
                     <Input fluid name={'locales.'+locale+'.companyName'} value={data ? data.companyName : ''} onChange={onChange} maxLength = "50"/>
                </Form.Field>
                <Form.Field>
                    <div style={style.label}>{t('welcomer_name_prompt')}</div>
              <Input fluid name={'locales.'+locale+'.welcomerName'} value={data ? data.welcomerName : ''} onChange={onChange} maxLength = "100"/>

                </Form.Field>
                <Form.Field>
                <div style={style.label}>{t('welcomer_title_prompt')}</div>
                 <Input fluid name={'locales.'+locale+'.welcomerTitle'} value={data ? data.welcomerTitle : ''} onChange={onChange} maxLength = "100"/>
                </Form.Field>
               </Form.Group>
               <div style={style.label}>{t('Welcome Message')}</div>
            <Form.Group>
                    <Popup
                        on='focus'
                        trigger = {
                            <TextArea name={'locales.'+locale+'.message'}
                            style={style.textarea}
                            value={data ? data.message : ''}
                            onChange={onChange}
                            onFocus={onChange}
                            maxLength = "1000" />
                        }
                        position = 'top right'
                        content = {(800-valueChange) + ' characters left'}
                        />
                <br />



                </Form.Group>
    </div>

);


export const AppPreview = ({t, data, locale, onChange, logo, photo, valid, onUploadPhoto, onSubmit}) => (
    <div>
        <div style={style.iPhoneBackground}>
            <Card style={style.previewCard}>
                <Card.Content>
                    <div style={style.container}>
                        <div style={style.containerDiv}>
                            <div style={style.segment}>
                                <Grid style={style.grid} textAlign="center">
                                    <Grid.Row style={style.row}>
                                        <Grid.Column verticalAlign="top" style={style.body}>
                                            <Icon disabled name = 'chevron left' style = {style.chevronIcon} color = 'grey' size='big' />
                                            {Boolean(logo && (logo.url || logo.dataURL)) &&
                                                <div style={{...style.companyLogo, backgroundImage:Utils.url(logo.dataURL || logo.url)}} />
                                            }
                                            <div style={style.scrollDiv}>
                                            <div style={style.message}>
                                                <div style={style.messagePointer} />
                                                <div style={{...style.message, ...style.messageContent}}>
                                                      {data ? data.message : ''}
                                                </div>
                                            </div>
                                            <div style={style.photoWrapper}>
                                               {Boolean(photo && (photo.url || photo.dataURL)) &&
                                                     <img style={style.photo} src={photo.dataURL || photo.url} alt="" />
                                              }
                                              <span style={style.photoText}>
                                                   <div style={style.welcomerName}>{data.welcomerName}</div>
                                                    <div style={style.welcomerTitle}>{data.welcomerTitle}</div>
                                              </span>
                                             </div>
                                             </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={style.row}>
                                        <Grid.Column verticalAlign="bottom" textAlign='center'>
                                             <Button style={style.tealButton}>{'Build Your Profile'}</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </Card.Content>
            </Card>
        </div>
    </div>
);


const AppStartupForm = ({t,
                         languages,
                         valid,
                         uploadPhotoProperty,
                         color,
                         colorString,
                         locale,
                         locales,
                         logo,
                         photo,
                         onChange,
                         onChangeLocale,
                         onSubmit,
                         onUploadPhoto,
                         onConfirmPhoto,
                         currentPane,
                         onTabChange,
                         valueChange
                        }) => (
    <Form onSubmit={onSubmit} style={style.gridMinWidth}>
       <Grid stackable divided='vertically'>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <div style={style.billingForm}>
                        <Form.Group>
                            <Form.Field>
                                <label>{t('company_logo_prompt')}</label>
                                {Boolean(logo && (logo.url || logo.dataURL)) &&
                                    <img style={style.imagePreview} src={logo.dataURL || logo.url} alt='' />
                                }
                                <Button type='button'
                                        style={style.uploadPhoto}
                                        onClick={()=>onUploadPhoto('logo.dataURL')}>
                                    {t('Upload Image')}
                              </Button>
                            </Form.Field>
                            <Form.Field>
                                <label>{t('welcomer_photo_prompt')}</label>
                                {Boolean(photo && (photo.url || photo.dataURL)) &&
                                   <img style={style.imagePreview} src={photo.dataURL || photo.url} alt='' />
                                }
                                <Button type='button'
                                     style={style.uploadPhoto}
                                     onClick={()=>onUploadPhoto('photo.dataURL')}>
                                     {t('Upload Image')}
                                 </Button>
                         </Form.Field>
                    </Form.Group>
                 <span style={style.langPrompt}>{t('preferred_language_prompt')}</span>
                 <Dropdown options={languages}
                      value={locale}
                      onChange={onChangeLocale}
                      selection
                      closeOnChange/>
            <br />
            <br />
            </div>
            <Tab panes={languages.map(language=>({
                                            menuItem:language.text,
                                            render:() => <LocalePane t={t}
                                                            locale={language.value}
                                                            data={locales[language.value]}
                                                            onChange={onChange}
                                                            logo = {logo}
                                                            photo = {photo}
                                                            valid={valid}
                                                            onUploadPhoto = {onUploadPhoto}
                                                            onSubmit = {onSubmit}
                                                            onFocus={onChange}
                                                            valueChange = {valueChange} />}))}
                onTabChange={onTabChange}/>
            <br />
            <Button type='submit' style={style.button} disabled={!valid}>{t('Save')}</Button>
                </Grid.Column>
                <Grid.Column style={style.previewGrid}>
                    <AppPreview t={t}
                            locale={currentPane}
                            data={locales[currentPane]}
                            onChange={onChange}
                            logo = {logo}
                            photo = {photo}
                            valid={valid}
                            onUploadPhoto = {onUploadPhoto}
                            onSubmit = {onSubmit} />
                </Grid.Column>

           </Grid.Row>
        {Boolean(uploadPhotoProperty) &&
            <ChoosePhotoContainer onConfirmPhoto={photo=>onConfirmPhoto(uploadPhotoProperty, photo)}
                                  onClose={onUploadPhoto}/>
             }
        </Grid>
    </Form>

);

export default withTranslation('account')(AppStartupForm);
