import React, { Component, useState, useEffect, createRef, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, useHistory} from 'react-router-dom';
import { connect } from 'react-redux';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {Buffer} from "buffer"
import CategoryInfoModal from './CategoryInfoModal.js';

const CategoryInfoModalContainer = ({dispatch, onClose, category}) => {
    const [busy, setBusy] = useState(false)
    const [inviteMode, setInviteMode] = useState('new')
    const [loadingEmployees, setLoadingEmployees] = useState(false)
    const [categoryFull, setCategoryFull] = useState()
    const history = useHistory()
    const exportLink = useRef()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        var params = {
            id: category.id,
            challenges : true,
            start: 0,
            limit: 10
        };
        setBusy(true)
        DatabaseService.getCategoryById(params)
            .then(result => {
                setCategoryFull(result.category)
            })
            .catch(error => {
                console.error(error);
                window.alert('Error removing work order: check logs');
            })
            .then(() => {
                setBusy(false)
            });
    }

    const onCloneGoal = () => {
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.getCategoryById({
            id: category.id,
            challenges:true,
            pathway:true,
            start : 0,
            limit : 1000
        })
        .then(result => {
            //Remove IDs and use object to initialize pathway editor.
            console.log('cloning:');
            console.log(result.category);
            sessionStorage.clonedCategory = JSON.stringify(Utils.removeIds(result.category));
        })
        .catch((error)=> {
            console.error(error)
            window.alert('An error occurred - check console');
        })
        .then(()=>{
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            onClose();
            history.push('/pathways/clone');
        });
    }

    

    const exportGoal = () => {
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.getCategoryById({
            id: category.id,
            challenges:true,
            pathway:true,
            start : 0,
            limit : 1000
        })
        .then(result => {
            return Utils.replaceAssetsWithBase64(result.category)
        })
        .then(category => {
            category = Utils.removeIds(category, true)
            let text = JSON.stringify(category);
            if (navigator && navigator.msSaveBlob) { // IE 10+
                var exportedFilename = exportLink.current.download;
                var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                navigator.msSaveBlob(blob, exportedFilename);
            } else {
                var encodedUri = `data:text/json;charset=utf-8,${encodeURIComponent(text)}`
                exportLink.current.setAttribute("href", encodedUri);
                exportLink.current.click();
            }
        })
        .catch((error)=> {
            console.error(error)
            window.alert('An error occurred - check console');
        })
        .then(()=>{
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            onClose();
        });
    }

    return (
        <CategoryInfoModal
            category={category}
            onClose={onClose}
            busy={busy}
            inviteMode={inviteMode}
            setExportLink={exportLink}
            loadingEmployees={loadingEmployees}
            categoryFull={categoryFull}
            onCloneGoal={onCloneGoal}
            exportGoal={exportGoal}
        />
    )
}

export default withRouter(connect()(withTranslation('categories')(CategoryInfoModalContainer)));
