import React from 'react';

const style={
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
};

const TestPage = (props) => (
    <div style={style.container}>
        TEST PAGE
    </div>
);

export default TestPage;
