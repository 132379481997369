import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import DateUtils from 'utils/DateUtils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import { getUserRoles } from '../../../reducers/user.js';

import EditAnnouncementModal from './EditAnnouncementModal.js';


function convert24HrTo12(hour) {
    if(hour==0) {
        return 12;
    } else if (hour <= 12) {
        return hour;
    }
    return hour - 12;
}
function convert12HrTo24(hour, isPm) {
    if(!isPm) {
        if(hour===12) {
            return 0;
        } else {
            return hour;
        }
    } else {
        if(hour===12) {
            return hour;
        } else {
            return hour + 12;
        }
    }
}

function getSendAt(state) {
    var sendAtMoment = moment(state.sendAt)
    return sendAtMoment.format(DateUtils.formatServer);
}

class EditAnnouncementModalContainer extends Component {
    constructor(props) {
        super(props);
        var announcement = this.props.announcement || {};
        var sendTo = announcement.segmentType
        if(sendTo==1 && announcement.segmentId) {
            sendTo = 0;
        }
        var allOptions = this.props.channels.reduce((m,channel)=>m.concat(channel.options),[]);
        allOptions = allOptions.map(option => ({id:option[0], value:option[1], isSegment:option[2]}));
        var isExistingAnnouncement = Boolean(announcement && announcement.id);
        var announcementState = isExistingAnnouncement ? (
            {
                id: announcement.id,
                locales: announcement.locales,
                locale: announcement.locale,
                announcementType: announcement.type,
                sendTo: announcement.segmentType,
                sendToSegment:_.findWhere(allOptions,{id:announcement.segmentId}),
                points: announcement.points,
                resources: announcement.resources,
                required: true,
                recurrenceType: announcement.recurrenceType,
                carousel: announcement.carousel,
                sendAt: announcement.sendAt ? moment(announcement.sendAt) : null,
                banner : announcement.banner,
                circleColor: {
                    en:{color : 'red'},
                    fr:{color : 'yellow'}
                },
                navigationType: announcement.navigation ? announcement.navigation.route : null,
                navigationURL: announcement.navigation ? announcement.navigation.url : null,
                navigationRoute: announcement.navigation ? announcement.navigation.route : null,
                buttonLabel: announcement.buttonLabel,
                notificationOnly: announcement.notificationOnly,
                isExistingAnnouncement
            }
        ) : (
            {
                locale: 'en',
                locales: {
                    en:{title:'', description:'', buttonLabel: '', navigationType: '', navigationURL: '', navigationRoute: ''},
                    fr:{title:'', description:'', buttonLabel: '', navigationType: '', navigationURL: '', navigationRoute: ''}
                },
                circleColor: {
                    en:{color : 'red'},
                    fr:{color : 'yellow'}
                },
                announcementType: 1,
                sendTo: 0,
                sendToSegment: null,
                points: '',
                resources: '',
                carousel : false,
                required: true,
                recurrenceType: null,
                sendAt: null,
                navigationType: '',
                navigationURL: '',
                navigationRoute: '',
                buttonLabel: '',
                notificationOnly: false,
                isExistingAnnouncement
            }
        );
        if(this.props.segmentId && !isExistingAnnouncement) {
            var segment = _.findWhere(allOptions,{id:this.props.segmentId});
            if(segment) {
                announcementState.sendToSegment = segment;
                announcementState.sendTo = 0;
            }
        }

        //deconstruct the navigation locales
        if (isExistingAnnouncement) {
            for (var locale in announcementState.locales) {
                if (announcementState.locales[locale].navigation) {
                    announcementState.locales[locale].navigationType = announcement.navigation.route;
                    announcementState.locales[locale].navigationURL = announcement.navigation.url;
                    announcementState.locales[locale].navigationRoute = announcement.navigation.route;
                } else {
                    announcementState.locales[locale].navigationType = null;
                    announcementState.locales[locale].navigationURL = null;
                    announcementState.locales[locale].navigationRoute = null;
                }
            }
        }

        this.state = { valid:false,
                       validDateText:true,
                       pushNotification: true,
                       employees:[],
                       members:[],
                       search: '',
                       chars_left: 0,
                       ...announcementState,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onChangeAnnouncementType = this.onChangeAnnouncementType.bind(this);
        this.onChangeNotificationOnly = this.onChangeNotificationOnly.bind(this);
        this.onChangeSendTo = this.onChangeSendTo.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onSelectSendToSegment = this.onSelectSendToSegment.bind(this);
        this.onDeleteAnnouncement = this.onDeleteAnnouncement.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onAddMember = this.onAddMember.bind(this);
        this.onRemoveMember = this.onRemoveMember.bind(this)
        this.doSearch = this.doSearch.bind(this);
        this.doSearchDebounced = _.debounce(this.doSearch, 300)
        this.deleteModalRef = React.createRef();
    }

    componentWillMount() {
        this.loadData();
    }

    checkRequired = (name, value) => {
        if((this.state.locales[this.state.locale] && this.state.locales[this.state.locale].title != "" && this.state.locales[this.state.locale].description != "")){
            this.setState({required:false})
        } else {
            this.setState({required:true})
        }

        var keys = Object.keys(this.state.locales);

        for(let i = 0; i < keys.length; i++){
            if( name && name.includes(keys[i])){
                this.state.circleColor[keys[i]].color = 'yellow'
            }
            if((this.state.locales[keys[i]] && this.state.locales[keys[i]].title != "" && this.state.locales[keys[i]].description != "")){
               this.state.circleColor[keys[i]].color = 'green'
            }

        }
        if((this.state.locales[this.state.locale] && this.state.locales[this.state.locale].title == "" && this.state.locales[this.state.locale].description == "")){
            this.state.circleColor[this.state.locale].color = 'red'
        }


    }

    loadData() {
        this.checkRequired();
        if(this.state.id && this.state.sendTo===1) {
            this.setState({loadingMembers:true});
            DatabaseService.getAnnouncementRecipients({announcementId: this.state.id, userProfiles:true})
                .then(result => {
                    this.setState({members:result.recipients.map(r => r.companyEmployee)});
                })
                .catch(error => {
                    window.alert('An error occurred, check logs');
                    console.error(error);
                })
                .then(result => {
                    this.setState({loadingMembers:false});
                });
        }
    }

    trimLocales(locales) {
        var result = {};
        for(var key in locales) {
            if(this.state.locale===key) {
                //the default locale is left alone
                result[key] = locales[key];
            } else {
                //only copies the locale if the required fields have been filled for that locale
                var locale = locales[key];
                if(Boolean(locale.title) ||
                   Boolean(locale.description)) {
                    result[key] = locales[key];
                }
            }
        }

        return result;
    }

    onSubmit(event) {
        event && event.preventDefault();

        var update = Boolean(this.state.id);
        var method = update ? 'updateAnnouncement' : 'addAnnouncement';

        var segment = undefined;

        if(this.state.sendTo===1) {
            segment = {
                companyEmployeeIds: this.state.members.map(employee => employee.id)
            };
        }

        var segmentType = (this.state.sendTo || (this.state.sendToSegment && this.state.sendToSegment.isSegment)) ? 1 : 0;

        if (!this.setNavigationLocale())
            return;


        var announcement = {...this.props.announcement,
                            locale: this.state.locale,
                            locales: this.trimLocales(this.state.locales),
                            type: this.state.announcementType,
                            segmentType: segmentType,
                            segmentId: this.state.sendToSegment ? this.state.sendToSegment.id : null,
                            points: this.state.points!=='' ? this.state.points : 0,
                            resources: this.state.resources!=='' ? this.state.resources : 0,
                            emailMode : 0,
                            segment:segment,
                            recurrenceType: this.state.recurrenceType,
                            sendAt: getSendAt(this.state),
                            carousel : this.state.carousel,
                            banner : this.state.banner,
                            notificationOnly: this.state.notificationOnly,
                            buttonLabel: this.state.buttonLabel ? this.state.buttonLabel : null,
                           };

        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService[method]({announcementId: announcement.id, announcement:announcement})
            .then( result=> {
                this.props.onRefresh();
                this.props.onClose();
            }, error => {
                window.alert('An error occurred - check console');
                console.error(error);
            }).finally(()=>{
                this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    setNavigationLocale() {
        var locales = this.state.locales;
        for (var locale in locales) {
            var navigation = null;
            if (locales[locale].navigationType === '/url') {
                if (!locales[locale].navigationURL) {
                    window.alert(`Please enter a url for locale ${locale}`);
                    return false;
                }

                navigation = {
                    "route":locales[locale].navigationType,
                    "url": locales[locale].navigationURL
                }
            } else if (locales[locale].navigationType === '/custom') {
                if (!locales[locale].navigationRoute) {
                    window.alert('Please enter a route');
                    return false;
                }

                navigation = {
                    "route": locales[locale].navigationRoute
                }
            } else if (locales[locale].navigationType !== '') {
                navigation = {
                    "route": locales[locale].navigationType
                }
            } else if (!locales[locale].navigationType && locales[locale].buttonLabel) {
                window.alert('Button has a label but no navigation route')
                return false;
            }

            locales[locale].navigation = navigation;
        }

        return true;
    }

    onDeleteAnnouncement(deleteYes) {
        if(deleteYes && this.props.announcement) {
            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.removeAnnouncement({announcementId: this.props.announcement.id})
                .then( result=> {
                    this.props.onRefresh();
                    this.props.onClose();
                }, error => {
                    window.alert('An error occurred - check console');
                    console.error(error);
                }).finally(()=>{
                    this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                });
        }
    }


    onClose() {
        this.props.onClose();
    }

    onChangeDropdown(event, {value, name}) {
        console.log('onChangeDropdown', {name, value});
        this.setState(state => (Utils.set({...state}, name, value)));
    }

    onChangeLocale = (event, {checked, value, name}) => {
        console.log('onChangeDropdown', {name, value});
        this.setState(state => (Utils.set({...state}, 'locale', value)), () => this.checkRequired());
    }

    onChangeAnnouncementType(event, {value}) {
        this.setState({announcementType: value})
    }

    onChangeNotificationOnly() {
        this.setState({notificationOnly: !this.state.notificationOnly})
    }

    onChangeSendTo(event, {value}) {
        this.setState({sendTo: value})
    }

    onChangeDate(dateLocal) {
        var utc = dateLocal ? moment.utc(dateLocal): null
        this.setState({sendAt: utc, validDateText:true});
    }

    onChangeCarousel = () => {
        this.setState({carousel:!this.state.carousel});
    }

    onChange(event) {
        console.log(this.state.locales)
        var name = event.target.name;
        var value = event.target.value;
        console.log("onChange", name, value)
        this.setState({chars_left: value.length});
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
        this.checkRequired(name, value);
    }

    onSelectSendToSegment(option) {
        this.setState({sendToSegment: option});
    }

    onAddMember(employee) {
        this.setState(state=>{
            if(_.findWhere(state.members,{id:employee.id})===undefined) {
                return {members:[employee].concat(state.members),
                        employees:[],
                        search:''}
            } else {
                return {};
            }
        });
    }

    onRemoveMember(employee) {
        this.setState(state=>({members:(state.members.filter(e => e.id!==employee.id))}));
    }

    onChangeSearch(event) {
        var search = event.target.value;
        this.setState({search: search}, this.doSearchDebounced);
    }

    doSearch() {
        if(this.state.search) {
            this.setState({loadingEmployees:true});
            DatabaseService.getEmployees({
                    'like.fullName' : this.state.search,
                    'like.email' : this.state.search,
                })
                .then(result => {
                    this.setState({employees:result.employees});
                })
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(result => {
                    this.setState({loadingEmployees:false});
                });
        } else {
            this.setState({employees:[]})
        }
    }

    isValid() {
        return Boolean(this.state.sendAt)
    }

    onUploadPhoto = () => {
        this.setState({choosePhotoIsOpen:!this.state.choosePhotoIsOpen});
    }

    onConfirmPhoto = (photo) => {
        this.setState(state => {
            let s = {...state};
            Utils.set(s, 'banner.dataURL', photo);
            Utils.set(s, 'banner.enabled', true);
            return s;
        });
    }

    onCancelPhoto = (name) => {
        this.setState(state => (Utils.set({...state}, 'banner.enabled', false)));
    }

    render() {
        var update = Boolean(this.state.id);
        return (
            <EditAnnouncementModal {...this.props}
                                   {...this.state}
                                   valid={this.isValid()}
                                   update={update}
                                   onAddMember={this.onAddMember}
                                   onRemoveMember={this.onRemoveMember}
                                   onSubmit={this.onSubmit}
                                   onChange={this.onChange}
                                   onChangeSearch={this.onChangeSearch}
                                   onChangeDropdown={this.onChangeDropdown}
                                   onChangeAnnouncementType={this.onChangeAnnouncementType}
                                   onChangeSendTo={this.onChangeSendTo}
                                   onSelectSendToSegment={this.onSelectSendToSegment}
                                   onDeleteAnnouncement={this.onDeleteAnnouncement}
                                   onClose={this.onClose}
                                    onCancelPhoto={this.onCancelPhoto}
                                    onUploadPhoto={this.onUploadPhoto}
                                    onConfirmPhoto={this.onConfirmPhoto}
                                   onChangeDate={this.onChangeDate}
                                   deleteModalRef = {this.deleteModalRef}
                                   valueChange = {this.state.chars_left}
                                   onChangeCarousel= {this.onChangeCarousel}
                                   onChangeLocale={this.onChangeLocale}
                                   onChangeNotificationOnly={this.onChangeNotificationOnly}
                                   />
      );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        roles: getUserRoles(state)
    }
}

export default connect(mapStateToProps)(withTranslation('announcements')(EditAnnouncementModalContainer));
