import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Input, Button, Label, Dropdown, Tab} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    title: {
        fontSize: fontSizes.h3
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
};


export const CategoryPane = ({t, data, locale, onChange}) => (
    <div>
        <div>{t('title_prompt')}</div> 
        <Input name={'locales.'+locale+'.title'} value={data ? data.title : ''} onChange={onChange} />
        <br />
        <br />
        <div>{t('description_prompt')}</div>
        <textarea name={'locales.'+locale+'.description'}
                  style={style.textarea}
                  value={data ? data.description : ''} 
                  onChange={onChange} />
    </div>
);

const Category = ({t, 
                  busy, 
                  category,
                  locale,
                  locales,
                  languages,
                  onChangeLocale,
                  onChange,
                  onSubmit}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <span style={style.langPrompt}>{t('preferred_language_prompt')}</span>
            <Dropdown options={languages}
                      value={locale}
                      onChange={onChangeLocale}
                      selection
                      closeOnChange/>

            <br />
            <br />
            <Tab panes={languages.map(language=>({
                                            menuItem:language.text,
                                            render:() => <CategoryPane t={t} 
                                                            locale={language.value}
                                                            data={locales[language.value]}
                                                            onChange={onChange} />}))} />

            <br />
            <br />
        </div>
    )
);

export default withTranslation('categories')(Category);
