import {SET_EMPLOYEES, SET_EMPLOYEES_RESULTS} from 'actions/types';

var employeesInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = employeesInitialState, action) => {
    switch(action.type) {
        case SET_EMPLOYEES: 
            return {...state,
                    list:action.employees.map((c)=>(c.id)),
                    indexed: action.employees.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    listLoaded:true,
                    count: action.count
                    };
        case SET_EMPLOYEES_RESULTS:
            return {
                ...state,
                searchResults: action.searchResults
            }
        default:            
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getEmployees = state => state.list.map( id => state.indexed[id] );

