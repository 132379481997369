import React, { Component } from 'react';
import DeletePrompt from './DeletePrompt';
import Utils from 'utils/Utils.js';

// Generic prompt used for deleting content.
// Accepts an argument on launch and passes it to callback on delete selected

class DeletePromptContainer extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen : false
        };
    }

    deleteConfirm = () =>{
        this.props.onDeleteConfirmation(true, this.state.arg1, this.state.arg2, this.state.arg3, this.state.arg4);
        this.closeDeletePrompt()
    }

    showDeletePrompt = (arg1, arg2, arg3, arg4) => {
        this.setState({
            isOpen : true,
            arg1,
            arg2,
            arg3,
            arg4
        });
    }

    closeDeletePrompt = () => {
        this.setState({isOpen:false});
    }

    render() {
        if(this.state.isOpen == false){
            return null;
        } 
        return (
            <DeletePrompt
                open = {this.state.isOpen}
                onClose = {this.closeDeletePrompt}
                deleteYes = {this.deleteConfirm}
                message = {this.props.message}
                />
            
      );
    }
}
export default DeletePromptContainer;