import React from 'react';
import DatabaseService from 'services/DatabaseService.js';

var Utils = {

    setAtts(ref, atts){
        if(ref) {
            Object.keys(atts).forEach( (key)=>{
                ref.setAttribute(key, atts[key]);
            });
        }
    },
    //Limit a string to a given length
    limitTo(str, lim) {
        str = str || '';
        var endIndex = Math.min(lim,str.length); 
        return str.substring(0,endIndex);
    },
    //Convert string to title case
    titleCase(str) {
        if(str) {
            var tokens = str.split(/\s+/);
            return tokens.map(token => (token.charAt(0).toUpperCase() + token.slice(1))).join(' ');
        } else {
            return '';
        }
    },
    padZeros(str, length) {
        var numZeros = length - str.length;
        for(var i = 0; i < numZeros; i++) {
            str = "0" + str;
        }
        return str;
    },
    //deep set an object, create new sub objects along the way if null
    set(o, path, val) {
        var result = o;
        var keys = path.replace(/\]\[|\]\./g,'.').replace(/[\]\[]/g,'.').split('.');
        var lastKey = keys.pop();
        while(keys.length > 0) {
            var nextKey = keys.shift();
            var next = result[nextKey]
            if(next==null) {
                if(!isNaN(Number(nextKey))) {
                    next = []
                    result[nextKey] = next;
                }
                else/* if(typeof next!=='object')*/ {
                    next = {}
                    result[nextKey] = next;
                }
            }
            result = next;
        }
        result[lastKey] = val;
        return o;
    },
    //deep dereference an object, provide a default value if a null is encountered along the way
    deref(o, path, def) {
        var result = o;
        var keys = path.split('.');
        var lastKey = keys.pop();
        var end = (typeof result==='object' && result!==null)===false;
        while(keys.length > 0 && !end) {
            var nextKey = keys.shift(); 
            result = result[nextKey];
            end = (typeof result==='object' && result!==null)===false;
        }
        return end ? def : result[lastKey];
    },
    delay(ms) {
		return new Promise(function(resolve, reject) {
            setTimeout(()=>{ resolve(); }, ms);
	    });
    },
    getPercent(val, total) {
        return ((val*100.0)/total); 
    },
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    url(src) { //wrap image src in url for css background
        return (src && src.length) ? 'url("'+src+'")' : '';
    },
    endlToBr(text) { //convert text with endlines into array of spans ending with br
        return ((text||'').split('\n')
                          .map((item, key) => (
                                React.createElement(
                                  "span",
                                  { key: key },
                                  item,
                                  React.createElement("br", null)
                                ))));
    },
    supportsVideoCapture() {
        return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    },

    JSON2CSV(json, headers) 
    {
        var o = "";
        var results = json;

        for(var j = 0; j < headers.length; j++)
        {
            headers[headers[j]] = j;
        }
        
        for(var i = 0; i < results.length; i++)
        {
            var result = results[i];
            
            var row = new Array(headers.length)
            for(var j = 0; j <  headers.length; j++ )
            {
                var prop = headers[j];
                var value = result[prop] || ""
                row[j] = value;
            }

            for(var j = 0; j < row.length; j++)
            {
                var value = (row[j] || "");
                value = value + "";
                //console.log(value)
                o += '"'+value.replace(/"/g,'""')+'"'
                if( j != row.length - 1)
                    o+=","
            }
            o += "\r\n";

        }
        
        var h = "";
        for(var i = 0; i < headers.length; i++)
        {
            h += headers[i];
            if( i != headers.length - 1)
                    h+=","
        }
        h += "\r\n"
        return h+o;
    },
    //Temporary work around for issue with semantic modals until fix is released
    fixBody() {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    },

    removeIds: function removeIds(o, keepTags) {
        const removeId = (o) => {
            if(o.id) {
                o.key = o.id;
                delete o.id;
            }
            if(o.tag && !keepTags)
                delete o.tag;
            return o;
        }

        removeId(o);
        for (let key in o) {
            let val = o[key];
            if(val!==null && typeof val === 'object') {
                removeIds(val, keepTags);
            }
        }
        return o;
    },
    replaceAssetsWithBase64: async function replaceAssetsWithBase64(o) {
        for (let key in o) {
            let val = o[key];
            if(val!==null && typeof val === 'object') {
                if (val.sourceUrl && val.url && val.mime.startsWith('image')) {
                    let image = await DatabaseService.remoteImage({base64Url: Buffer.from(val.sourceUrl ?? val.url).toString('base64')})
                    o[key] = {
                        dataURL: image.dataURL,
                        enabled: true
                    }
                } else {
                    o[key] = await replaceAssetsWithBase64(val);
                }
            }
        }
        return o;
    }
};

export default Utils;
