import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import {Link} from 'react-router-dom';
import {Button, Form, Input, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';


import AuthUtils from 'utils/AuthUtils.js';
import Utils from 'utils/Utils.js'
import backgroundImage from 'img/register-dark.png';
import {colors, fontSizes} from 'style';
import styleDef from './style.js';

var style = {
    ...styleDef,
    container: {
        minHeight: '100%',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundImage: Utils.url(backgroundImage),
    },
    createAct: {
        display: 'inline',
        color: colors.themeDarkerGrey,
        fontSize: fontSizes.h4,
        lineHeight: fontSizes.h4,
        fontStyle: 'italic',
    },
    column: {
        maxWidth: '400px'
    },
};

const SignUp = ({t, 
                valid,
                email, 
                password, 
                passwordConfirm, 
                inviteCode, 
                onSubmit, 
                onEmailChange, 
                onPasswordChange, 
                onPasswordConfirmChange, 
                onInviteCodeChange, 
                onTermsChange, 
                onBlur,
                emailError, 
                passwordError, 
                passwordConfirmError, 
                inviteCodeError, 
                error}) => (
    <div style={style.container}>
        <br />
        <Form style={style.form} noValidate onSubmit={onSubmit}>
            <Grid style={style.content} textAlign="center">
                <Grid.Row style={style.row}> 
                    <Grid.Column style={style.bannerColumn} verticalAlign="top">
                        <div style={style.banner}>
                            <i className='icon-myHealthSphere-logo' style={style.logo} />
                            <br />
                            <div style={style.welcome}>{t('Welcome!')}</div>
                            <br />
                            <div style={style.createAct}>{t('Create Your Account')}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row> 
                <Grid.Row> 
                    <Grid.Column style={style.column} verticalAlign="bottom">
                        <div style={style.inputLabel}>{t('Email Address')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(emailError.length ? style.inputError : {})}}
                                type="text"
                                name="userEmail"
                                value={email}
                                onChange={onEmailChange}
                                onBlur={onBlur}
                                required
                            />
                            {emailError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{emailError}</Label>
                            }
                        </Form.Field>        
                        <div style={style.inputLabel}>{t('Password')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(passwordError.length ? style.inputError : {})}}
                                type="password"
                                name="userPassword"
                                value={password}
                                onChange={onPasswordChange}
                                onBlur={onBlur}
                                placeholder={t('Password must be at least {{length}} characters', {length: AuthUtils.minPasswordLength})} 
                                required
                            />
                            {passwordError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{passwordError}</Label>
                            }
                        </Form.Field>        
                        <div style={style.inputLabel}>{t('Confirm Password')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(passwordConfirmError.length ? style.inputError : {})}}
                                type="password"
                                name="userPasswordConfirm"
                                value={passwordConfirm}
                                onChange={onPasswordConfirmChange}
                                onBlur={onBlur}
                                required
                            />
                            {passwordConfirmError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{passwordConfirmError}</Label>
                            }
                        </Form.Field>        
                        
                        <div style={style.inputLabel}>{t('Invite Code')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(inviteCodeError.length ? style.inputError : {})}}
                                type="text"
                                name="userInviteCode"
                                value={inviteCode}
                                onChange={onInviteCodeChange}
                                onBlur={onBlur}
                                required
                            />
                            {inviteCodeError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{inviteCodeError}</Label>
                            }
                        </Form.Field>        
                        {error.length > 0 &&
                            <Message negative>{error}</Message>
                        }
                        <br />
                        <br />
                        <Button style={valid ? style.tealButton : style.greyButton} type="submit" >{t('Sign Up')}</Button>
                        <br />
                        <br />
                        <Trans i18nKey="already_a_user"> 
                            Already have an account? <Link to='/login' style={style.anchor}>Log In</Link>
                        </Trans>
                        <br />
                        <br />
                    </Grid.Column>
                </Grid.Row> 
            </Grid>
        </Form>
        <br />
    </div>
);

export default withTranslation('authentication')(SignUp);
