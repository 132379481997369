import React from 'react';

import {colors} from 'style';

const Ring = ({completeFactor, style, width, height, r0, innerCirclePercent, innerCircle2Percent, color0, color1, opacity0, opacity1}) => {
        r0 = r0 ? Number(r0) : 1;
        completeFactor = Math.max(0,Math.min(1.0,completeFactor));
        var x = 0, y = 0, r = 100.1, startAngle = 0;
        var endAngle = completeFactor * Math.PI * 2.0;
        var largeArc = endAngle - startAngle <= Math.PI ? 0 : 1;
        var path = ['M', x, y,
                    'L', x+Math.cos(startAngle)*r, y-(Math.sin(startAngle)*r), 
                    'A', r, r, 0, largeArc, 0, x+Math.cos(endAngle)*r, y-(Math.sin(endAngle)*r),
                    'L', x, y
                   ].join(' ');
        return (
            <div style={style}>
                <svg id="theMap" width={width||"100px"} height={height||"100px"} viewBox="-100 -100 200 200">

                  <circle cx="0" cy="0" r={r0*100} fill={color0 || colors.themeTeal} opacity={opacity0 || 0.4}></circle>
                  <circle cx="0" cy="0" r={innerCirclePercent || "80"} fill="#fff"></circle>
                  <g id="arcs" transform=" withTranslation(0 0) rotate(-90) scale(1 -1)">
                    <path d={path} fill={color1 || colors.themeTeal} opacity={opacity1 || 1}></path>
                  </g>
                  <circle cx="0" cy="0" r={innerCircle2Percent || innerCirclePercent || "80"} fill="#fff"></circle>

                </svg>
            </div>
        );
};

export default Ring;
