import DatabaseService from 'services/DatabaseService.js';
import {SET_USER_TOKEN,
        SET_USER_NO_COMPANY,
        SET_USER_PROFILE,
        SET_USER_RANK,
        SET_USER_HEALTH,
        SET_USER_BADGE_NUMBERS,
        SET_USER_ANNOUNCEMENTS_BADGE_DATE,
        SET_USER_CATEGORIES,
        SET_USER_ROLES }  from 'actions/types'

//Action Creators
export const setUserToken = (token) => {
    return { type:SET_USER_TOKEN, token};
}
export const setUserProfile = (userProfile) => {
    return { type:SET_USER_PROFILE, userProfile};
}
export const setUserRoles = (roles) => {
    return { type:SET_USER_ROLES, roles }
}
export const setRoleAllowedRoutes = (routes) => {
    return {type: SET_ROLE_ALLOWED_ROUTES, routes}
}



//helpers
export const processUserProfile = (dispatch, userProfile) => {
    userProfile = {...userProfile, plugins:null};
    dispatch(setUserProfile(userProfile));
}



//Thunks
export const fetchMyProfile = (include) => (dispatch, getState) => {
    return new Promise(function(resolve, reject){
        DatabaseService.fetchMyProfile(include).then(function(result){
            processUserProfile(dispatch, result.userProfile);
            
            resolve(result);
        },function(error){
            console.log("user - fetchMyProfile: error", error);
            reject(error)
        });

    });
}

