import {INIT_NAVBAR_STATE,
        SET_NAVBAR_ENABLE,
        SET_NAVBAR_BACK_BUTTON_ENABLE,
        SET_NAVBAR_DONE_BUTTON_ENABLE,
        SET_NAVBAR_CLOSE_BUTTON_ENABLE,
        SET_NAVBAR_MENU_ENABLE,
        SET_NAVBAR_REWARDS_ENABLE,
        SET_NAVBAR_TITLE} from 'actions/types'

const navbarInitialState = {enable:false, 
        backButtonEnable:true, 
        closeButtonEnable:false, 
        doneButtonEnable:false, 
        menuEnable:false, 
        rewardsEnable:false,
        uploadEnable:false,
        mode:'normal',
        title:'', 
        closeIconOpts:null,
        doneIconOpts:null};
export default (state = navbarInitialState, action) => {
    switch(action.type) {
        case INIT_NAVBAR_STATE: 
            var newState = Object.assign(...Object.keys(action)
                            .filter((key)=>(key!=='type'))
                            .map((key)=>({[key]:action[key]}))
                        );
            return {...navbarInitialState, ...newState, 
                    closeButtonEnable:state.closeButtonEnable, doneButtonEnable:state.doneButtonEnable, closeIconOpts:state.closeIconOpts, doneIconOpts:state.doneIconOpts}; //intentionally not clobbering closeButtonEnable & doneButtonEnable as these are controlled via NavControl
        case SET_NAVBAR_ENABLE: 
            return {...state, enable:action.enable};
        case SET_NAVBAR_BACK_BUTTON_ENABLE: 
            return {...state, backButtonEnable:action.enable};
        case SET_NAVBAR_DONE_BUTTON_ENABLE: 
            return {...state, doneButtonEnable:action.enable, doneIconOpts:(action.doneIconOpts||null)};
        case SET_NAVBAR_CLOSE_BUTTON_ENABLE: 
            return {...state, closeButtonEnable:action.enable, closeIconOpts:(action.closeIconOpts||null)};
        case SET_NAVBAR_MENU_ENABLE: 
            return {...state, menuEnable:action.enable};
        case SET_NAVBAR_REWARDS_ENABLE: 
            return {...state, rewardsEnable:action.enable};
        case SET_NAVBAR_TITLE: 
            return {...state, title:action.title};
        default:            
            return state;
    }
}
