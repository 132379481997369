import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Card, Button, Checkbox, Icon, Search, Input, Modal, Message, Dropdown, Progress, Header, Popup} from 'semantic-ui-react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment-timezone'
import {ContentCard} from 'components/card'
import {List} from 'components/list'


const styles = {
    btnStyleYes: {
        float: 'right',
        backgroundColor: colors.themeTeal,
        color: '#fff',
        margin: spacings.micro

    },
    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.themeDarkGrey,
        color: '#fff',
        margin: spacings.micro
    },
    flex: {
        display: 'flex',
        flex: 1
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    field: {
        margin: spacings.tiny
    },
    leftIndent: {
        marginLeft: 8
    },
    horizontalFlex: {
        display: 'flex',
        flexDirection: 'row'
    },
    selectionList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        margin: spacings.base
    }
}

const renderValueHint = (field, item) => {
    if (field.key === 'value' && item.valueHint)
        return (
            <Popup
                trigger={(<i class="help circle icon" ></i>)}
            >
                {item.valueHint}
            </Popup>
        )
    return null
}

const renderHint = (field, item) => {
    if (field.key === 'type' && item.hint)
        return (
            <Popup
                trigger={(<i class="info circle icon" ></i>)}
            >
                <p style={{whiteSpace: 'pre-wrap'}}>{item.hint}</p>
            </Popup>
        )
    return null
}

const fieldRenderer = (field, item, update) => {
    switch (field.type) {
        case 'search': 
            return (
                <div style={styles.fieldContainer}>
                    <div style={styles.field}>
                        <h4>{field.name}</h4>
                        <Search
                            onResultSelect={(e, data) => {
                                update(item.id, field.key, data.result)
                                update(item.id, field.key + '_query', data.result.name)
                            }}
                            resultRenderer={field.itemRenderer}
                            onSearchChange={(e) => update(item.id, field.key + '_query', e.target.value)}
                            results={field.prefiltered ? field.data : field.data.filter(result =>
                                result.title.toLowerCase().includes(item[field.key + '_query'] ? item[field.key + '_query'].toLowerCase() : '')
                            )}
                            value={item[field.key + '_query']}
                        />
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item))
                    }
                </div>
            )
        case 'date':
            return (
                <div style={styles.fieldContainer}>
                    <div style={{...styles.field, maxWidth: 300}}>
                        <h4>{field.name}</h4>
                        <DateTimePicker
                            value={field.disabledKey && item[field.disabledKey] || field.disabledKeys && field.disabledKeys?.find(k => item[k]) ? null : item[field.key]}
                            step={60}
                            onChange={(value)=>update(item.id, field.key, value)}
                            onKeyPress={(event)=>{event.preventDefault()}}
                            disabled={field.disabledKey && item[field.disabledKey] || field.disabledKeys && field.disabledKeys?.find(k => item[k])}
                            max={(field.maxKey && item[field.maxKey]) || new Date(moment().add(3, 'years'))}
                            min={(field.minKey && item[field.minKey]) || new Date()}
                        />
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item, update))
                    }
                </div>
            )
        case 'checkbox':
            return (
                <div style={styles.fieldContainer}>
                    <div style={styles.field}>
                        <Checkbox
                            label={field.name}
                            onChange={(e, data)=> update(item.id, field.key, data.checked)}
                            checked={item[field.key]}
                        />
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item, update))
                    }
                </div>
            )
        case 'text':
            return (
                <div style={styles.fieldContainer}>
                    <div style={styles.field}>
                        <Input
                            label={field.name}
                            onChange={(e, data)=> update(item.id, field.key, data.value)}
                            value={item[field.key]}
                            placeholder= {field.key === 'value' ? item.placeholder : ""}
                        />
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item))
                    }
                    
                </div>
            )
        case 'dropdown':
            return (
                <div style={styles.fieldContainer}>
                    <div style={styles.field}>
                        <Dropdown
                            style={field.style ? field.style : {}}
                            options={field.options}
                            value={item[field.key]}
                            onChange={(e, data)=>update(item.id, field.key, data.value)}
                            selection
                            closeOnChange
                            clearable={!!field.clearable}
                        />
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item))
                    }
                </div>
            )
        case 'label':
            return (
                <div style={styles.fieldContainer}>
                    <div style={styles.field}>
                        <p>{field.name}</p>
                    </div>
                    {renderHint(field, item)}
                    {renderValueHint(field, item)}
                    {
                        field.extra && field.extra.map(extra => fieldRenderer(extra, item))
                    }
                </div>
            )
    }
}

export const customRenderer = fieldRenderer

const deleteModal = ({t, open, cancel, confirm, tableName}) => {
    return (
        <Modal open={open}>
            <Modal.Header>{t('delete_tooltip', {entry: tableName})}</Modal.Header>
            <Modal.Content>
                <div>{t('delete_modal_text')}</div>
            </Modal.Content>
            <Modal.Actions>
                <Button style={styles.btnStyleNo} onClick ={cancel}>No</Button>
                <Button style={styles.btnStyleYes} onClick ={confirm}>Yes</Button>
            </Modal.Actions>
        </Modal>
    )
}

export const DeleteModal = withTranslation('modals')(deleteModal)

const listMultiSelectModal = ({t, open, searchData, selectedItems, selectItem, cancel, next, tableName, fieldName, queryField, tableFields}) => {
    const [query, setQuery] = useState('')
    const [data, setData] = useState([])
    const [update, setUpdate] = useState(false)
    const [searching, setSearching] = useState(false)
    const loadData = async () => {
        setSearching(true)
        const data = await searchData({query, limit: 10, start: 0, queryField}, true)
        setData(data.data)
        setSearching(false)
    }

    useEffect(() => {
        loadData()
    }, [query])

    useEffect(() => {
        setUpdate(update => !update)
    }, [selectedItems])

    const refreshForm = () => {
        setQuery('')
        setData([])
    }

    const onSelectItem = (item) => {
        selectItem(item)
    }

    const onCancel = () => {
        refreshForm()
        cancel()
    }

    const onNext = () => {
        refreshForm()
        next()
    }
    return (
        <Modal open={open}>
            <Modal.Header>{t('add_tooltip', {entry: tableName})}</Modal.Header>
            <Modal.Content style={styles.horizontalFlex}>
                {
                    <div>
                        <div style={{flex: 3}}>
                            <Search
                                loading={searching}
                                onResultSelect={(e, {result}) => {
                                    onSelectItem(result)
                                    setQuery('')
                                }}
                                onSearchChange={(e) => {
                                    setQuery(e.target.value)
                                    setData([])
                                }}
                                showNoResults={!searching}
                                results={data}
                                value={query}
                                placeholder={t('search_placeholder')}
                            />
                        </div>
                        <div style={{flex: 1, marginTop: 50}}>
                            <div>{t('selected_items_prompt', {entry: fieldName})}</div>
                            <br />
                            <List
                                getData={() => {return {data: selectedItems.map(item => item.data)}}}
                                update={update}
                                actions={[
                                    {
                                        name: 'trash',
                                        invoke: (item)=>onSelectItem(item),
                                        color: colors.warningRed
                                    }
                                ]}
                                type='table'
                                fields={tableFields}
                            />
                        </div>
                    </div>
                }
                
            </Modal.Content>
            <Modal.Actions>
                <Button style={styles.btnStyleYes} onClick ={onNext}>Continue</Button>
                <Button style={styles.btnStyleNo} onClick ={onCancel}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}
export const ListMultiSelectModal = withTranslation('modals')(listMultiSelectModal)

export const selectionModal = ({t, open, fields, header, cancel, error, buttons}) => {
    return (
        <Modal open={open}>
            <Modal.Header>{header}</Modal.Header>
            {
                error && 
                <Message error>
                    <Message.Header>{t('error')}</Message.Header>
                    {error.message}
                </Message>
            }
            <div style={styles.selectionList}>
                {fields.map(field => (
                    <div>
                        <Button onClick={field.onClick}>
                            <Icon
                                name={field.icon}
                                size='huge'
                            />
                            {field.text}
                        </Button>
                    </div>
                ))}
            </div>

            <Modal.Actions>
                {
                    buttons && buttons.map(button => <Button style={styles.btnStyleYes} onClick ={button.onClick}>{t(button.text)}</Button>)
                }
                <Button style={styles.btnStyleNo} onClick ={cancel}>{t('Cancel')}</Button>
            </Modal.Actions>
        </Modal>
    )
}
export const SelectionModal = withTranslation('modals')(selectionModal)

export const listFormModal = ({t, open, items, fields, tableName, cancel, next, updateItem, cancelText}) => {
    return (
        <Modal open={open}>
            <Modal.Header>{t('add_tooltip', {entry: tableName})}</Modal.Header>
            <div>
                {
                    items.map(item => (
                        <div style={item.valid ? {padding: 8, backgroundColor: '#90dd90'} : {padding: 8}}>
                            <div style={styles.leftIndent}>
                                <div>
                                    <Header>{item.title}</Header>
                                    <p>{item.id}</p>
                                </div>
                            </div>
                            <div>
                            {
                                fields.map(field => {
                                    return fieldRenderer(field, item, updateItem)
                                })
                            }
                            </div>
                        </div>
                    ))
                }
            </div>
            <Modal.Actions>
                <Button style={styles.btnStyleYes} onClick={next}>{t('Submit')}</Button>
                <Button style={styles.btnStyleNo} onClick={cancel}>{cancelText || t('Cancel')}</Button>
            </Modal.Actions>
        </Modal>
    )
}
export const ListFormModal = withTranslation('modals')(listFormModal)

const basicFormModal = ({t, fields, item, open, tableName, cancel, next, updateItem}) => {
    return (
        <Modal open={open} size='large'>
            <Modal.Header>{t('add_tooltip', {entry: tableName})}</Modal.Header>
            <Modal.Content>
                {
                    fields.map(field => {
                        return fieldRenderer(field, item, updateItem)
                    })
                }
            </Modal.Content>
            <Modal.Actions>
                <Button style={styles.btnStyleYes} onClick={next}>{t('Submit')}</Button>
                <Button style={styles.btnStyleNo} onClick={cancel}>{t('Cancel')}</Button>
            </Modal.Actions>
        </Modal>
    )
}

export const BasicFormModal = withTranslation('modals')(basicFormModal)

const uploadProgressModal = ({t, open, tableName, total, calls, cancel, finish}) => {
    const [count, setCount] = useState(0)
    const [failures, setFailures] = useState([])
    const [successes, setSuccesses] = useState([])
    const [complete, setComplete] = useState(false)
    const [started, setStarted] = useState(false)
    const [update, setUpdate] = useState(false)

    const resetModal = () => {
        setCount(0)
        setFailures([])
        setSuccesses([])
        setComplete(false)
        setStarted(false)
    }

    const cancelModal = () => {
        resetModal()
        cancel()
    }

    const completeModal = () => {
        finish(successes)
        resetModal()
    }

    const uploadSurvey = async (call) => {
        let data = await call.call()
        if (data && data.error) {
            setFailures(failures => [...failures, 
                {
                    id: call.tag,
                    tag: call.tag,
                    error: data.error.message,
                    result: 'failed',
                    call: call.call,
                    styles: {backgroundColor: colors.warningRed}
                }
            ])
        } else if (data) {
            data = data.map(item => {
                return {
                    ...item,
                    result: item.title,
                    tag: item.id
                }
            })
            setSuccesses(successes => {
                let newSuccesses = [...successes, data].flat()
                return newSuccesses
            })
            setCount(count => Math.min(count+1, total))
        }
        setUpdate(update => !update)
    }

    const startUpload = async () => {
        resetModal()
        setStarted(true)
        for (var call of calls) {
            await uploadSurvey(call)
        }
        setComplete(true)
    }

    const rerunUpload = async () => {
        setComplete(false)
        setUpdate(update => !update)
        const retryFailures = failures
        setFailures([])
        for (var failure of retryFailures) {
            await uploadSurvey(failure)
        }
        setComplete(true)
    }

    return (
        <Modal open={open} size='large'>
            <Modal.Header>{t('upload_tooltip', {entry: tableName})}</Modal.Header>
            <Modal.Content>
                <Progress value={count} total={total} progress='ratio' />
                <List
                    getData={() => {return {data: failures.concat(successes)}}}
                    update={update}
                    actions={[]}
                    type='table'
                    fields={[
                        {
                            name: 'Tag',
                            key: 'tag',
                            default: true
                        },
                        {
                            name: 'Result',
                            key: 'result',
                            default: true
                        },
                        {
                            name: 'Error',
                            key: 'error',
                            default: true
                        }
                    ]}
                />
            </Modal.Content>
            <Modal.Actions>
                {complete && failures.length !== 0 && <Button style={styles.btnStyleYes} onClick={rerunUpload}>{t('Reupload')}</Button> }
                {complete && failures.length === 0 && <Button style={styles.btnStyleNo} onClick={completeModal}>{t('Continue')}</Button> }
                {!started && <Button style={styles.btnStyleYes} onClick={startUpload}>{t('Upload')}</Button> }
                {<Button style={styles.btnStyleNo} onClick={cancelModal}>{t('Cancel')}</Button> }
            </Modal.Actions>
        </Modal>
    )
}
export const UploadProgressModal = withTranslation('modals')(uploadProgressModal)