import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Icon, Header, Checkbox } from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import Utils from 'utils/Utils.js';
import defaultRewardImage from 'img/optimity-logo.png';

const style = {
    item: {
        textAlign: 'center',
        padding: spacings.base,
    },
    rewardsImage: {
        width: '100px',
        maxHeight: '100px',
    },
    rewardRow: {
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        marginTop: spacings.tiny,
        marginBottom: spacings.tiny,
    },
    checkbox: {
        verticalAlign: 'middle',
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
        display: 'inline-block',
    },
    rewardPrice: {
        verticalAlign: 'middle',
        textAlign: 'right',
        display: 'inline-block',
        width: '80px',
    },
    rewardResources: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
        textAlign:'left'
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    editButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0',
        margin: '0',
    }

};

const RewardGroupItem = ({t, rewards, onToggleReward, onEdit}) => (
    <Card key={rewards[0].id} style={style.item}>
        <div>
            <img src={Utils.deref(rewards[0], 'asset.url', defaultRewardImage)} alt="" style={style.rewardsImage} />
            {Boolean(onEdit) &&
                <Button style={style.editButton} onClick={()=>onEdit(rewards[0])}><Icon name='edit'/></Button>
            }
        </div>
        <p>{rewards[0].brand && <span>{rewards[0].brand}<br /></span>}{rewards[0].title}</p>

        {rewards.map(reward=>(
            <div key={reward.id} style={style.rewardRow}>
                <div style={style.rewardPrice}>${reward.price / 100} {reward.currency?.code}</div>

                <Checkbox style={style.checkbox}
                      className='green' 
                      key={reward.id} 
                      toggle
                      checked={Boolean(reward.companyReward && reward.companyReward.enabled)} 
                      onChange={()=>onToggleReward(reward)}/>

                <div style={style.rewardResources}><i style={style.star} className="icon-Optimollar"/> {reward.resources}</div>
            </div>
        ))}
    </Card> 
);

export default withTranslation('rewards')(RewardGroupItem);
