import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Button, Modal, Header, Input, Message, Label} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';

var style = {
    ...styleDef,
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
};


const GiftCardLimitModal = ({t, 
                          value, 
                          valid,
                          onChange, 
                          onClose, 
                          onSubmit}) => (
    <Modal open style={style.content} onClose={onClose} size='tiny'>
        <Modal.Header>{t('giftCardlimit_modal_header')}</Modal.Header>
        <Modal.Content>
        <form onSubmit={onSubmit}>
            <Modal.Description>
                <div style={style.description}>{Utils.endlToBr(t('giftCardlimit_modal_description'))}</div>
                <Input labelPosition='left' type='number' value={value} onChange={onChange} placeholder={t('giftCardlimit_modal_placeholder')}>
                <Label><i style={style.star} className="icon-Optimollar"/></Label>
                <input />
                </Input>
                <br />
                <br />
                <Button type='submit' style={style.button} disabled={!valid}>{t('giftCardlimit_modal_btn')}</Button>
            </Modal.Description>
        </form>
        </Modal.Content>
    </Modal>
);

export default withTranslation('rewards')(GiftCardLimitModal);
