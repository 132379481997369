import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Icon, Button, Header, Checkbox } from 'semantic-ui-react';

import {getCompetitionImageUrl } from './tools.js';
import Utils from 'utils/Utils.js';
import LoadInline from '../loading/LoadInline.js';
import {colors, spacings} from 'style';
import { fontSizes } from '../../style/index.js';

var style = {
    content: {
        padding: '1px',
        position:'relative'
    },
    loaderContainer: {
        marginLeft:'50%'
    },
    subtitle: {
        textAlign: 'center',
    },
    card: {
        overflow:'hidden',
        color: '#fff',
        backgroundColor: colors.themeWhite,

        width:'24rem',
    },
    header: {
        
        color:colors.themeDarkerGrey, 
    },
    description: {
        color:'#fff', 
    },
    meta: {
        color:'#fff', 
    },
    iconHeader: {
        padding:spacings.small, 
        width:'100%',
        color: colors.themeBlue,
        fontSize: fontSizes.bodyL,
        //backgroundColor: colors.themeTeal,
    },
    imageBanner: {
        position: 'relative',
        height:'13.5rem', 
        width:'24rem',
        backgroundSize:'cover',
        backgroundPositionY: 'center',
    },
    checkmark: {
        color : colors.themeTeal
    },
    toggle: {
        position: 'relative',
        top: '3px',
    },
    editButton: {
        //position: 'absolute',
        //top: '10px',
        //right: '10px',
        float : 'right',
        backgroundColor: colors.themeWhite,
        border: 'none',
        boxShadow: 'none',
        fontSize: '1.5rem',
        padding: '0px',
        margin: '0',
    },
    editIcon: {
        margin: '0',
        opacity: '1',
        color: colors.themeDarkGrey,
    },
    date : {
    	float :'right',
    	marginRight:'1rem',
    	color: colors.themeDarkerGrey,
    }
};

const ChallengeCard = ({
	t, 
    competition,
    onEdit,
    onClick,
    onClickChange,
    centered,
    onExport
}) => (

    <Card key={competition.id} className='nohover' onClick={onClick} style={style.card} centered={centered}>
        <div style={style.iconHeader}>
            {competition.title}
        </div>
        <div style={{...style.imageBanner, backgroundImage:Utils.url(getCompetitionImageUrl({competition}))}}></div>
    </Card>

);


export default withTranslation('competitions')(ChallengeCard);
