import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import MonthlyLimitModal from './MonthlyLimitModal.js';


class MonthlyLimitModalContainer extends Component {
    constructor(props) {
        super(props);
        var initialValue = typeof this.props.value === 'number' ? this.props.value : '';
        this.state = { value:initialValue, valid:true };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event && event.preventDefault();
        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});

        var data = {claimsLimit: (this.state.value === '') ? null : (parseFloat(this.state.value) * 100)};
        DatabaseService.updateRewardsPlugin(data)
            .then(result => {
            })
            .catch(error => {
                console.error(error);
            })
            .then(()=>{
                this.props.onRefresh && this.props.onRefresh();
                this.props.onClose();
                this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    onChange(event) {
        var value = event.currentTarget.value;
        var valid = this.isValid(value);
        this.setState({value, valid});
    }

    isValid(value) {
        if(value==='') return true;
        var num = parseFloat(value);
        return (_.isFinite(num) &&
                num >= 0);
    }

    render() {
        return (
            <MonthlyLimitModal {...this.props}
                               value={this.state.value}
                               valid={this.state.valid}
                               onSubmit={this.onSubmit}
                               onChange={this.onChange} />
      );
    }
}

export default connect()(withTranslation('rewards')(MonthlyLimitModalContainer));
