import React, {useState} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Dropdown, Button, Grid, Header, Message, Label, Card, Popup, Icon, Checkbox, Modal, Input, Search} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'underscore';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';

import RewardCardV2               from './RewardCardV2.js';
import RewardBudgetModalContainer    from '../modals/RewardBudgetModalContainer.js';
import DailyLimitModalContainer      from '../modals/DailyLimitModalContainer.js';
import MonthlyLimitModalContainer    from '../modals/MonthlyLimitModalContainer.js';
import QuarterlyLimitModalContainer    from '../modals/QuarterlyLimitModalContainer.js';
import YearlyLimitModalContainer    from '../modals/YearlyLimitModalContainer.js';
import EditRewardModalContainer      from '../modals/V2/EditRewardModalContainer.js';
import ResourceScalerModalContainer  from '../modals/ResourceScalerModalContainer.js';
import DeletePromptContainer         from 'modules/misc/DeletePromptContainer.js';
import RewardCard from './RewardCard.js';
import EditRewardV2ModalContainer from '../modals/V2/EditRewardV2ModalContainer.js';
import { List } from 'components/list.js';
import GiftCardLimitModalContainer from '../modals/GiftCardLimitModalContainer.js';
//$ {(employee.yearlyClaims > 0 ) ? (employee.yearlyClaims/100).toFixed(2) : 0}

//Removed Fields
//<div>{t('Expected Budget Depletion')}</div>
//<div>{budgetEnd}</div>

const supportedCategories = ['raffles', 'top_offers', 'discounts', 'charities', 
'other_offers', 'more_offers', 'gift_cards', 'points', 'contests', 'points_contests', 'gift_card_contests']

const editableRewardActionTypes = {
    1: 'RAFFLE',
    2: 'LOYALTY',
    4: 'DIRECT_REDEMPTION',
    5: 'CHARITY',
    6: 'EARN',
    7: 'CONTEST'
}
const incentiveTypes = [
    {key:null,  text:'None', value:null},
    {key:'RAFFLE',  text:'Raffle',  value:'RAFFLE'},
    {key:'OFFER',  text:'Offer',  value:'OFFER'},
    {key:'DONATION',  text:'Charity', value:'DONATION'},
    {key:'GIFT_CARD',  text:'Gift Card', value:'GIFT_CARD'},
]

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    title: {
        marginTop: spacings.base,
        fontSize: fontSizes.h4,
        color: colors.themeDarkerGrey,
        lineHeight: '1.5',
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        margin: spacings.base,
        padding: spacings.base,
    },
    actionButton: {
        ...buttons.base,
    },
    createAccountButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        ...buttons.base,
    },
    trashButton: {
        shadow: 'none',
        border: 'none',
        backgroundColor: '#fff',
        color: colors.warningRed,
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        padding: spacings.tiny,
        width: '60px',
    },
    approveButton: {
        shadow: 'none',
        border: 'none',
        backgroundColor: '#fff',
        color: colors.themeTeal,
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        padding: spacings.tiny,
        width: '60px',
    },
    budgetTotal: {
        color: colors.themeTeal,
        fontSize: fontSizes.h3,
    },
    budgetCurrency: {
        fontSize: fontSizes.body,
    },
    workOrderHeading: {
        color: colors.themeDarkerGrey,
    },
    pendingOrderRequests: {
        fontSize: fontSizes.bodyL
    },
    workOrderRow: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    toggleOpenReg: {
        marginLeft: spacings.micro,
    },
    btnStyleYes: {
        float: 'right',
        backgroundColor: colors.themeTeal,
        color: '#fff',
        margin: spacings.micro

    },
    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.themeDarkGrey,
        color: '#fff',
        margin: spacings.micro
    },
    tab: {
        ...buttons.secondary,
        borderRadius: 0,
        margin: '24px 0',
    },
    bottomShadow: `0px 5px ${colors.themeTeal}`,
    addButton: {
        color: colors.themeTeal,
        fontSize: '2rem',
        margin: '0 1rem',
        cursor: 'pointer'
    },
    searchReward: {
        margin: '2rem',
        float: 'right'

    }
};


const RewardsV2 = ({t, 
                  busy,
                  rewards: {
                    rewardCategories,
                    incentives,
                  },
                  loadingRewards,
                  onEditReward,
                  onEditRewardV2,
                  onEditRewardAction,
                  workOrders,
                  companyAccount,
                  companyPlugin,
                  company,
                  tangoCardAccount,
                  tangoBalance,
                  budgetEnd,
                  starsEnd,
                  budgetCount,
                  currencyCode,
                  giftcardLimit,
                  dailyLimit,
                  claimsLimit,
                  yearlyClaimsLimit,
                  quarterlyClaimsLimit,
                  resourceScaler,
                  showReplenishBudget,
                  showMonthlyLimitEdit,
                  showGiftCardLimitEdit,
                  showDailyLimitEdit,
                  showQuarterlyLimitEdit,
                  showYearlyLimitEdit,
                  showResourceScalerEdit,
                  showOtherCurrencyRewards,
                  showAddNewIncentive,
                  showAddRewardAction,
                  newRewardV2Category,
                  editReward,
                  editRewardV2,
                  editRewardAction,
                  rewardCategory,
                  currencies,
                  accountCreated,
                  onShowModal,
                  onShowOtherCurrencyRewards,
                  onToggleReward,
                  onToggleRewardOption,
                  onChangeTab,
                  currentTab,
                  onDeleteWorkOrder,
                  deleteModalRef,
                  onEditCustomReward,
                  onRefresh,
                  onCreateAccount,
                  onChangeDropdown,
                  onPolicyHolderRewards,
                  policyHolderRewards,
                  approveWorkOrderModalOpen,
                  openApproveWorkOrderModal,
                  closeApproveWorkOrderModal,
                  approveWorkOrder,
                  search,
                  onChangeSearch,
                  incentiveType,
                  onChangeIncentiveType}) => {
    if (busy) {
      return (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
        )
    } else {
        rewardCategories = rewardCategories || []
        const [categories, setCategories] = useState(rewardCategories
            .map(rewardCategory => ({
                key: rewardCategory.tag,
                text: rewardCategory.title,
                value: rewardCategory.tag
            })))
        // [
        //     // {key:'contests',  text:'Contests',  value:'contests'},
        //     {key:'raffles',  text:'Raffles',  value:'raffles'},
        //     // {key:'offers',  text:'Offers',  value:'offers'},
        //     {key:'charities',  text:'Charities',  value:'charities'},
        //     // {key:'points',  text:'Points',  value:'points'},
        //     {key:'gift_cards',  text:'Gift Cards',  value:'gift_cards'},
        // ]
        return (<div style={style.content}>
            <div style={style.title}>{t('Budget')}</div>
            {localStorage.master==='true' &&
                <div style={style.section}> 
                    <label>{t('policy_holder_rewards')}
                    <Popup
                        trigger={<Icon name='question circle outline' />}
                        content={t('policy_holder_rewards_info').split('\n').map(line => (<p>{line}</p>))}
                        position='right center'
                    />
                    </label>
                    <Checkbox 
                        toggle 
                        style={style.toggleOpenReg} 
                        checked={policyHolderRewards === true} 
                        onChange={onPolicyHolderRewards} 
                        disabled={policyHolderRewards === true} />
                </div>         
            }   
            {Boolean(!accountCreated) &&
            <div style={style.section}>
                <div style={style.title}>{t('Budget Account')}</div>
                <br />
                <hr />
                <br />
                <div>{t('budget_account_description')}</div>
                <br />
                <br />
                <span>{t('currency_prompt')}</span>
                <Dropdown name='companyAccount.currencyId'
                          options={currencies}
                          value={companyAccount.currencyId || ''}
                          onChange={onChangeDropdown}
                          selection
                          closeOnChange/>
                <br />
                <br />
                <Message warning>{t('budget_account_message')}</Message>
                <br />
                <br />
                <Button style={style.createAccountButton} 
                        onClick={onCreateAccount} 
                        disabled={(companyAccount.currencyId || '')===''}>
                    {t('Create Account')}
                </Button>
            </div>
            }

            {Boolean(accountCreated) &&
            <div>
                <Grid columns={4} style={style.section}>
                    <Grid.Row stretched>
                        {
                            localStorage.master === 'true' &&
                            <Grid.Column>
                                <div>{t('Total Balance')}</div>
                                <div style={style.budgetTotal}>${budgetCount} <span style={style.budgetCurrency}>{currencyCode}</span></div>
                            </Grid.Column>
                        }
                        <Grid.Column>
                            <div>{t('GiftCard Limit')}</div>
                            <div>{t('Daily Reward Points Limit')}</div>
                            <div>{t('Monthly Reward Claim Limit')}</div>
                            <div>{t('Quarterly Reward Claim Limit')}</div>
                            <div>{t('Yearly Reward Claim Limit')}</div>
                            <div>{t('Resource Cost Multiplier')}</div>
                            {localStorage.master==='true' && <div>{t('Tango Card Balance')}</div> }
                        </Grid.Column>
                        <Grid.Column>
                            <div>{giftcardLimit  ? ('$' + giftcardLimit) : t('Unlimited')}</div>
                            <div>{dailyLimit  ? (dailyLimit + ' ' + t('pts')) : t('Unlimited')}</div>
                            <div>{claimsLimit ? ('$' + (claimsLimit/100)) : t('Unlimited')}</div>
                            <div>{quarterlyClaimsLimit ? ('$' + (quarterlyClaimsLimit/100)) : t('Unlimited')}</div>
                            <div>{yearlyClaimsLimit ? ('$' + (yearlyClaimsLimit/100)) : t('Unlimited')}</div>
                            <div>{resourceScaler ? ('x' + resourceScaler) : t('Default')}</div>
                            {localStorage.master==='true' && <div>$ { tangoBalance }</div> }
                        </Grid.Column>
                        <Grid.Column>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showGiftCardLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showDailyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showMonthlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showQuarterlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showYearlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showResourceScalerEdit', true)}}>{t('edit')}</a></div>
                            {localStorage.master==='true' && <div>&nbsp;</div> }
                        </Grid.Column>
                    </Grid.Row>
                    {Boolean(workOrders && workOrders.length>0) && [
                        <Grid.Row stretched key='row1'>
                            <Grid.Column style={style.pendingOrderRequests}>
                                {t('Pending Order Requests')}
                            </Grid.Column>
                        </Grid.Row>,
                        <Grid.Row stretched key='row2'>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Submitted')}
                            </Grid.Column>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Amount')}
                            </Grid.Column>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Currency')}
                            </Grid.Column>
                        <Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    ].concat(_.sortBy(workOrders, 'createdAt').reverse().map(workOrder => (
                        <Grid.Row stretched key={workOrder.id} style={style.workOrderRow}>
                            <Grid.Column>
                                {moment(workOrder.createdAt).format(t('mediumDate'))}
                            </Grid.Column>
                            <Grid.Column>
                                {workOrder.amount / 100}
                            </Grid.Column>
                            <Grid.Column>
                                {workOrder.currency}
                            </Grid.Column>                                 
                            <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation ={onDeleteWorkOrder} />
                            <Grid.Column>
                                <span>
                                    {Boolean(localStorage.master == "true") && 
                                        <Modal open={approveWorkOrderModalOpen} trigger={<Button style={style.approveButton} icon="check circle" onClick={openApproveWorkOrderModal} />}>
                                            <Modal.Header>{t('confirm_workorder_modal_header')}</Modal.Header>
                                            <Modal.Content>{t('confirm_workorder_modal_detail')}</Modal.Content>
                                            <Modal.Actions>
                                                <Button style={style.btnStyleNo} onClick ={closeApproveWorkOrderModal}>No</Button>
                                                <Button style={style.btnStyleYes} onClick ={()=>{approveWorkOrder(workOrder)}}>Yes</Button>
                                            </Modal.Actions>
                                        </Modal>
                                    }
                                    <Button style={style.trashButton} icon='trash' onClick={() => {deleteModalRef.current.showDeletePrompt(workOrder)}} />
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )))}
                </Grid>
                <Button style={style.actionButton} onClick={()=>onShowModal('showReplenishBudget', true)}>{t('Replenish Budget')}</Button>

                <div>
                    <Button style={{...style.tab, ...(currentTab !== "catalog" && {boxShadow: style.bottomShadow})}} onClick={()=>{onChangeTab('rewards')}}>Rewards</Button>
                    <Button disabled={!Boolean(localStorage.master  === 'true')} style={{...style.tab, ...(currentTab === "catalog" && {boxShadow: style.bottomShadow})}} onClick={()=>{onChangeTab('catalog')}}>Catalog</Button>
                    {
                       Boolean(currentTab === 'catalog') && (
                            <>
                                <Dropdown name='IncentiveCategory'
                                    options={incentiveTypes}
                                    value={incentiveType}
                                    onChange={onChangeIncentiveType}
                                    style={style.searchReward}
                                    selection
                                    placeholder='Type'
                                    closeOnChange/>
                                <Input
                                    icon={{ name: 'search', circular: true, link: true }}
                                    placeholder='Search Reward...'
                                    style={style.searchReward}
                                    onChange={onChangeSearch}
                                    value={search}
                                />
                           </>
                       )
                       
                    }
                </div>
                {currentTab !== "catalog"
                    ?  loadingRewards 
                        ? (
                            <div style={style.loaderContainer}>
                                <LoadInline centered vcentered /> 
                            </div>
                        )
                        : <div style={style.section}>
                            <div>
                                {Boolean(rewardCategories && rewardCategories.length>0) &&
                                    rewardCategories.map(rewardCategory => (
                                    <div key={rewardCategory.id}>
                                        <div style={style.title}>
                                            <span>{rewardCategory.title}</span>
                                            { supportedCategories.includes(rewardCategory.tag) &&
                                                <Icon name='add circle' style={style.addButton} onClick={()=>onShowModal('showAddRewardAction', true, {newRewardV2Category: rewardCategory.tag})} />
                                            }
                                        </div>
                                        
                                        <Card.Group>
                                        {
                                            rewardCategory.rewardActions.length > 0
                                            ?
                                            rewardCategory.rewardActions.map(rewardAction =>
                                                <RewardCardV2 key={rewardAction.id} 
                                                    rewardAction={rewardAction}
                                                    onEdit={onEditRewardAction}
                                                    rewardCategory={rewardCategory.tag}
                                                    onToggleRewardOption={onToggleRewardOption}
                                                    allowEdit={Boolean(editableRewardActionTypes[rewardAction.type])}/>
                                            )
                                            :
                                            <RewardCardV2 // Empty State
                                                    rewardAction={{
                                                        title: 'Oops! No Rewards found',
                                                        subtitle: `Click '+' above to create a new reward under this section`
                                                    }}
                                                    onEdit={null}
                                                    rewardCategory={rewardCategory.tag}
                                                    onToggleRewardOption={onToggleRewardOption}/>
                                        }
                                        </Card.Group>
                                    </div>
                                ))}  
                            </div>
                        </div>
                    : (<>
                        {
                        loadingRewards 
                            ? (
                                <div style={style.loaderContainer}>
                                    <LoadInline centered vcentered /> 
                                </div>
                            )
                            : <div>
                                <div style={style.section}>
                                    <Card.Group>
                                        {Boolean(incentives && incentives.length>0) && 
                                            incentives.map(reward => (
                                                <RewardCard key={reward.id} 
                                                    reward={reward}
                                                    onEdit={onEditReward}
                                                    onToggleReward={onToggleReward}/>
                                            ))        
                                        }  
                                    </Card.Group>
                                </div>
                            </div>
                        }
                        </>
                    )
                }
            </div>
            }
            

            {showReplenishBudget && 
                <RewardBudgetModalContainer 
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showReplenishBudget', false)} />
            }
            {showGiftCardLimitEdit && 
                <GiftCardLimitModalContainer 
                    value={giftcardLimit ? parseInt(giftcardLimit) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showGiftCardLimitEdit', false)} />
            }
            {showDailyLimitEdit && 
                <DailyLimitModalContainer 
                    value={dailyLimit ? parseInt(dailyLimit) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showDailyLimitEdit', false)} />
            }
            {showMonthlyLimitEdit && 
                <MonthlyLimitModalContainer 
                    value={claimsLimit ? claimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showMonthlyLimitEdit', false)} />
            }
            {showQuarterlyLimitEdit && 
                <QuarterlyLimitModalContainer 
                    value={quarterlyClaimsLimit ? quarterlyClaimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showQuarterlyLimitEdit', false)} />
            }
            {showYearlyLimitEdit && 
                <YearlyLimitModalContainer 
                    value={yearlyClaimsLimit ? yearlyClaimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showYearlyLimitEdit', false)} />
            }
            {showResourceScalerEdit && 
                <ResourceScalerModalContainer 
                    value={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showResourceScalerEdit', false)} />
            }
            {showAddNewIncentive &&
                <EditRewardModalContainer
                    resourceScaler={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showAddNewIncentive', false)} />
            }
            {Boolean(editReward) &&
                <EditRewardModalContainer
                    reward={editReward}
                    resourceScaler={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onEditReward(null)} />
            }
            {showAddRewardAction &&
                <EditRewardV2ModalContainer
                    categories={categories}
                    onRefresh={onRefresh}
                    rewardCategory={newRewardV2Category}
                    onClose={()=>onShowModal('showAddRewardAction', false)} />
            }
            {Boolean(editRewardAction) &&
                <EditRewardV2ModalContainer
                    categories={categories}
                    rewardAction={editRewardAction}
                    rewardCategory={rewardCategory}
                    onRefresh={onRefresh}
                    onClose={()=>onEditRewardAction(null)} />
            }

        </div>    
    )
    }
};

export default withTranslation('rewards')(RewardsV2);
