import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {setSidebarEnable} from 'actions/sidebar.js';
import {Image} from 'semantic-ui-react';
import backgroundImage from './Marketing.png';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';

class MarketingContainer extends Component{
    constructor(props){
        super(props);
    }
    
    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
    }

   
    render() {
        return (
            <div style={{fontSize: fontSizes.body,
                lineHeight: '1.5',
                minHeight: '100%',
                padding: spacings.base,
                backgroundColor: colors.themeGrey}}>
                <Image src={backgroundImage}></Image>
            </div>
        )
    }
}

export default withRouter(connect()(withTranslation('marketing')(MarketingContainer)));
