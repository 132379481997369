import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import DateUtils from 'utils/DateUtils.js';

import Rewards from './Rewards.js';

const msPerYear = 1000*60*60*24*365;
const CurrencyCodes = {
    1: 'CAD',
    2: 'USD'
};
const Currencies = Object.keys(CurrencyCodes).map(id => ({key:id, value:id, text:CurrencyCodes[id]}))

class RewardsContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      showReplenishBudget:false,
                      showMonthlyLimitEdit:false, 
                      showYearlyLimitEdit:false, 
                      showQuarterlyLimitEdit:false, 
                      showDailyLimitEdit:false,
                      showResourceScalerEdit:false,
                      showOtherCurrencyRewards:false,
                      showAddNewIncentive:false,
                      editReward:null,
                      rewards:[],
                      accountCreated:true,
                      policyHolderRewards: false,
                      approveWorkOrderModalOpen: false
                      };
        this.onShowModal = this.onShowModal.bind(this);
        this.onShowOtherCurrencyRewards = this.onShowOtherCurrencyRewards.bind(this);
        this.onToggleReward = this.onToggleReward.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onDeleteWorkOrder = this.onDeleteWorkOrder.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onEditCustomReward = this.onEditCustomReward.bind(this);
        this.onCreateAccount = this.onCreateAccount.bind(this);
        this.deleteModalRef = React.createRef();
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData(true);
    }


    loadData(showSpinner) {
        showSpinner && this.setState({busy:true});
        Promise.all([            
            DatabaseService.getRewards({companyPlugin : true, companyAccount:true, balance : true}),
            DatabaseService.getCompanyPluginAttribute({tag:'rewards', name:'policyHolder'})
        ]).then(([rewardResult, pluginAttributeResult]) => {
            var companyAccount = rewardResult.companyAccount || {};
            var tangoCardAccount = rewardResult.balance || {exists:false};

            var budgetEnd = this.props.t('end_placeholder');
            if(tangoCardAccount.estimatedEnd) {
                var budgetDate = new Date(tangoCardAccount.estimatedEnd);
                if(budgetDate.getTime() < Date.now() + 2*msPerYear) {
                    budgetEnd = moment(budgetDate).format(this.props.t('mediumDate') || DateUtils.formatDisplay);
                }
            }
            var starsEnd = this.props.t('end_placeholder');
            if(rewardResult.estimatedCompanyStarsEnd){
                var starsDate = new Date(rewardResult.estimatedCompanyStarsEnd);
                if(starsDate.getTime() < Date.now() +  2*msPerYear) {
                    starsEnd = starsDate;
                }
            }


            var companyPluginAttributes  = Utils.deref(rewardResult, 'companyPlugin.companyPluginAttributes') || {};
            console.log('companyPluginAttributes', companyPluginAttributes);
            var dailyLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'dailyLimit'}),  'value');
            var quarterlyClaimsLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'quarterlyClaimsLimit'}),  'value');
            var yearlyClaimsLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'yearlyClaimsLimit'}),  'value');
            var claimsLimit = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'claimsLimit'}), 'value');
            var resourceScaler = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'resourceScaler'}), 'value');

            var budgetCount = (tangoCardAccount.balance / 100) || 0;

            var currencyCode = CurrencyCodes[companyAccount.currencyId] || "???";

            const tangoBalance = (rewardResult.tangoBalance ? rewardResult.tangoBalance.balance : 0) / 100;

            this.setState({
                    rewards:rewardResult.rewards,
                    workOrders: rewardResult.workOrders,
                    companyAccount,
                    companyPlugin: rewardResult.companyPlugin,
                    company: rewardResult.company,
                    tangoCardAccount,
                    tangoBalance,
                    budgetEnd,
                    starsEnd,
                    budgetCount,
                    currencyCode,
                    dailyLimit, 
                    claimsLimit,
                    quarterlyClaimsLimit,
                    yearlyClaimsLimit,
                    resourceScaler,
                    accountCreated:_.isFinite(companyAccount.currencyId),
                    policyHolderRewards: pluginAttributeResult.companyPluginAttribute && pluginAttributeResult.companyPluginAttribute.value === "1" ? true : false
                });    
        })
        .catch((error)=> {
            console.error('ERROR:');
            console.error(error);
        })
        .then(()=>{
            showSpinner && this.setState({busy:false});
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
        });
    }

    getRewardGroups() {
        if(!this.state.companyAccount) {
            return null;
        }

        var legacyRewards = [];
        var newRewards = [];
        var customRewards = [];
        var otherRewards = [];
        this.state.rewards.forEach((element, index, array) => {
            if(!element.catalog) {
                customRewards.push(element);
            } else {
                if(element.currencyId === this.state.companyAccount.currencyId) {
                    if(element.catalog === "tango_card") {
                        legacyRewards.push(element);
                    } else if(element.catalog === "tango_card_v2") {
                        newRewards.push(element);
                    }
                } else {
                    otherRewards.push(element);
                }
            }
        });
        return {
            legacyRewards,
            newRewards,
            customRewards,
            otherRewards,
            legacyRewardGroups: this.groupRewards(legacyRewards),
            newRewardGroups:    this.groupRewards(newRewards),
            otherRewardGroups:  this.groupRewards(otherRewards)
        };
    }

    groupRewards(rewards) {
        var map = {};
        var groups = [];
        for(var i = 0; i < rewards.length; i++) {
            var reward = rewards[i];
            var name = reward.brand || reward.title;
            if(!map[name]) {
                map[name] = [];
                groups.push(map[name]);
            }
            map[name].push(reward);
        }
        groups.sort(function(a,b) { 
            var c = a[0].title.toLowerCase();
            var d = b[0].title.toLowerCase();
            if(c < d) return -1;
            if(c > d) return 1;
            return 0;
        })
        return groups;
    }    

    onShowModal(modal, en) {
        this.setState({[modal]:en});
    }
    onShowOtherCurrencyRewards(en) {
        this.setState({showOtherCurrencyRewards:en});
    }

    onToggleReward(reward) {
        var originalRewards = this.state.rewards;
        var companyReward = reward.companyReward || {enabled:false};
        var updatedCompanyReward = {...companyReward, enabled:!companyReward.enabled};
        var updatedReward = {...reward, companyReward:updatedCompanyReward};
        var updatedRewards = this.state.rewards.map(c => c.id===reward.id ? updatedReward : c  );
        this.setState({rewards:updatedRewards})
        var method = updatedCompanyReward.enabled ? 'addCompanyReward' : 'removeCompanyReward';
        DatabaseService[method]({rewardId: reward.id}).catch(error => {
            console.error(error);
            window.alert('Error updating reward, see logs');
        })
    }

    onChangeDropdown(event, {value, name}) {
        this.setState(state => (Utils.set({...state}, name, value)));
    }

    onDeleteWorkOrder(deleteYes, workOrder) {
        if(deleteYes){
            var workOrders = this.state.workOrders;
            workOrders = workOrders.filter(wo => wo.id!==workOrder.id);
            this.setState({workOrders});
            DatabaseService.removeFund(workOrder)
            .then(result => {
            })
            .catch(error => {
                console.error(error);
                window.alert('Error removing work order: check logs');
            });
        }
    }
    openApproveWorkOrderModal() {
        this.setState({approveWorkOrderModalOpen: true})
    }
    closeApproveWorkOrderModal() {
        this.setState({approveWorkOrderModalOpen: false})
    }
    approveWorkOrder(workOrder) {
        if (workOrder) {
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.approveFund({workorderId: workOrder.id, paid: true})
            .then((result)=>{
                this.closeApproveWorkOrderModal()
                this.loadData(false);
            }).catch((error)=>{
                console.error(error);
                window.alert('Error approving work order: check logs');
                this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
            })
        }       
    }

    onCreateAccount() {
        if(this.state.companyAccount && _.isFinite(this.state.companyAccount.currencyId)) {
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.createCompanyAccount({currencyId : this.state.companyAccount.currencyId})
                .then(results => {
                    this.loadData(true);
                })
                .catch(error => {
                    console.error(error);
                    window.alert('Error removing work order: check logs');
                })
                .then(result => {
                    this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                });
        }
    }

    onRefresh() {
        this.loadData(false);
    }

    onEditCustomReward(reward) {
        this.setState({editReward:reward});
    }

    onPolicyHolderRewards = (event) => {
        var confirmation = window.confirm('Are you sure?\nThis action cannot be reverted.');
        this.setState({policyHolderRewards: !this.state.policyHolderRewards});
        
        //user has selected yes
        if (confirmation) {
            //update policyholder value
            var pluginAttribute = {
                name: 'policyHolder', 
                tag: 'rewards', 
                value: !this.state.policyHolderRewards, 
                type: 3
            }

            DatabaseService.updateCompanyPluginAttributeValue(pluginAttribute).then((result) => {                
                console.log(this.state.policyHolderRewards);
            })            
        }     
    }

    render() {
        return (
            <Rewards busy={this.state.busy}
                     {...this.state}
                     {...this.getRewardGroups()}
                     currencies={Currencies}
                     onShowModal={this.onShowModal}
                     onShowOtherCurrencyRewards={this.onShowOtherCurrencyRewards}
                     onToggleReward={this.onToggleReward}
                     onDeleteWorkOrder={this.onDeleteWorkOrder}
                     onRefresh={this.onRefresh}
                     onEditCustomReward={this.onEditCustomReward}
                     onChangeDropdown={this.onChangeDropdown}
                     onCreateAccount={this.onCreateAccount}
                     onPolicyHolderRewards={this.onPolicyHolderRewards}
                     deleteModalRef = {this.deleteModalRef}
                     openApproveWorkOrderModal={this.openApproveWorkOrderModal.bind(this)}
                     closeApproveWorkOrderModal={this.closeApproveWorkOrderModal.bind(this)}
                     approveWorkOrder={this.approveWorkOrder.bind(this)}/>
      );
  }
}

export default withRouter(connect()(withTranslation('rewards')(RewardsContainer)));
