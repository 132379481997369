import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import BillingForm from './BillingForm.js';



const Countries = [
    'Canada',
    'United States',
    'Other'
].map(s => ({key:s, text:s, value:s}));

const Provinces = {
    "Canada": [
        'District of Columbia',
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Nova Scotia',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Northwest Territories',
        'Yukon',
        'Nunavut'
    ].sort().map(s => ({key:s, text:s, value:s})),
    "United States": [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ].sort().map(s => ({key:s, text:s, value:s})),
    "default": []
}


class BillingFormContainer extends Component {

    constructor(props){
		super(props);
        this.state = {...this.getFormState(this.props.billing),
                        provinces: [],
                     };
        this.onChange = this.onChange.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        var billing = nextProps.billing;
        if(billing && billing!==this.props.billing) {
            this.setState(this.getFormState(billing));
        }
    }

    getFormState(billing) {
        billing = billing || {};
        return {
            firstName : billing.firstName || '',
            lastName  : billing.lastName  || '',
            company   : billing.company   || '',
            address   : billing.address   || '',
            email     : billing.email     || '',
            city      : billing.city      || '',
            state     : billing.state     || '',
            country   : billing.country   || '',
        };
    }

    componentWillMount() {
    }

    onChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState(prevState => (Utils.set({...prevState}, name, value)));
    }

    onSubmit(event) {
        event && event.preventDefault();

        var billing = {
            firstName : this.state.firstName,
            lastName  : this.state.lastName,
            company   : this.state.company,
            address   : this.state.address,
            email     : this.state.email,
            city      : this.state.city,
            state     : this.state.state,
            country   : this.state.country,
        };
        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.updateOrganization({billing: billing})
                       .then(result => {
                           this.props.onRefresh();
                       })
                       .catch(error => {
                            console.error(error)
                            window.alert('An error occurred - check console');
                       })
                       .then(()=>{
                            this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                       });
    }

    onChangeCountry(event, {value}) {
        var country = value;
        this.setState({country:country, province: '', states: this.getProvincesByCountry(country)});
    }

    onChangeProvince(event, {value}) {
        this.setState({state:value});
    }

    getProvincesByCountry(country) {
        if(Provinces.hasOwnProperty(country)) {
            return Provinces[country];
        } else {
            return Provinces['default'];
        }
    }

    isValid() {
        return (true);
    }

    render() {
        return (
            <BillingForm {...this.props}
                         {...this.state}
                         countries={Countries}
                         provinces={this.getProvincesByCountry(this.state.country)}
                         valid={this.isValid()}
                         onChange={this.onChange}
                         onChangeCountry={this.onChangeCountry}
                         onChangeProvince={this.onChangeProvince}
                         onSubmit={this.onSubmit}
                         />
      );
    }
}

export default withRouter(connect()(withTranslation('account')(BillingFormContainer)));
