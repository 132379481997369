import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {initNavbarState, setNavbarTitle} from 'actions/navbar.js';

import {getCompetitions, isListLoaded} from 'reducers/competitions.js';
import {fetchCompetitions} from 'actions/competitions.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import {addLeaderboard, clearLeaderboardRecords} from 'actions/leaderboards.js';

import Utils from 'utils/Utils.js';

import Competitions from './Competitions.js';


class CompetitionsContainer extends Component {

  // Initalization

  constructor(props){
	  super(props);
    this.state = {
      busy:false,
      upcoming : [],
      seasonToEdit : null,
      newTemplate : false,
      leaderBoardsToSelect: null
    };
    this.leaderboardRef = React.createRef();
    this.downloadLinkRef = React.createRef();
    //this.onNewCompetition = this.onNewCompetition.bind(this);
    //this.onCloseEditCompetitionModal = this.onCloseEditCompetitionModal.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(setSidebarEnable(true));
    this.props.dispatch(initNavbarState({enable:false}));
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    //var upcoming = [].concat(nextProps.competitions).concat(nextProps.competitions).concat(nextProps.competitions);
    //upcoming.push([].concat(nextProps.competitions).concat(nextProps.competitions))
    //upcoming.push([].concat(nextProps.competitions).concat(nextProps.competitions))
    //this.state.upcoming = upcoming;
  };

  // Logic


  loadData() {
      this.setState({busy:true});
      this.props.fetchCompetitions({}, true)
          .catch((error)=> {
              //window.alert('An error occurred - check logs');
              console.error(error);
          })
          .then((result)=>{
             this.setState({busy:false});
          });
  }

  // Events



  onNewCompetition = (event, season) => {
    event && event.preventDefault();

    this.setState({ seasonToEdit : season || {} });
  }

  onExport = (competition) => {
    const link = this.downloadLinkRef;
    
    async function exportCallback() {

      try {
        let result = await DatabaseService.exportCompetition({id: competition.id})
        
        result = await Utils.replaceAssetsWithBase64(result.competition)

        result = Utils.removeIds(result, true)
        let text = JSON.stringify(result)
        if (navigator && navigator.msSaveBlob) { // IE 10+
            const exportedFilename = link.current.download;
            const blob = new Blob([text], { type: 'text/json;charset=utf-8;' });
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const encodedUri = `data:text/json;charset=utf-8,${encodeURIComponent(text)}`
            const date = new Date();
            link.current.download = `${competition.title} - ${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.json`
            link.current.setAttribute("href", encodedUri);
            link.current.click(); 
        }
            
        } catch(err) {
          console.log('Error exporting! ' + err)
        }
      }
    exportCallback(); 
  }

  onCloseEditCompetitionModal = () => {
    //event && event.preventDefault();
    this.setState({seasonToEdit:null});
    //this.loadData();
  }

  onNewTemplate = (event, season) => {
    event && event.preventDefault();

    this.setState({ newTemplate: true });
  }

  onCloseNewTemplate = (event, season) => {
    event && event.preventDefault();

    this.setState({ newTemplate: false });
  }

  redirectLeaderboard = (competition) => {
    if (competition.leaderboard) {
      this.navigateLeaderBoard(competition.leaderboard);
    }
    // below allows selection of leaderboard
    else if (competition.leaderboards.length === 1) {
      this.navigateLeaderBoard(competition.leaderboards[0]);
    } else {
      this.setState({leaderBoardsToSelect: competition.leaderboards});
    }
  }

  navigateLeaderBoard = (leaderboard) => {
    this.props.history.push('leaderboards/' + leaderboard.id);
  }

  onCloseLeadboardSelectModal = () => {
    this.setState({leaderBoardsToSelect: null});
  }

  // Render

  render() {

    // For active



    var active = [...(this.props.active || [])];
    var startsAt = moment().startOf('month')

    active.sort(function(a ,b) {
      var aStartsAt = moment(a.startsAt);
      var aEndsAt = moment(a.endsAt)
      var bStartsAt = moment(b.startsAt);
      var bEndsAt = moment(b.endsAt);

      var id = (a.id - b.id)

      if(aStartsAt.isValid()) {

        if(bStartsAt.isValid()) {
          return (aStartsAt.valueOf() - bStartsAt.valueOf()) || (aEndsAt.valueOf() - bEndsAt.valueOf()) || id
        }
        return -1;
      }
      if(aEndsAt.isValid()) {
        if(bEndsAt.isValid())
          return (aEndsAt.valueOf() - bEndsAt.valueOf()) || id
        return (id);
      }
      return 1;
    })
    active.push({
      startsAt : startsAt.toDate(),
      endsAt : moment(startsAt).add(1,'month').toDate()
    })

    // For upcoming
    var upcoming = [...(this.props.upcoming || [])];//this.props.upcoming;
    var startsAt = moment().startOf('month')
    for(var i = 0; i < 6; i++) {
      startsAt.add(1,'month')
      var filler = {
        startsAt: startsAt.toDate(),
        endsAt: moment(startsAt).add(1,'month').toDate()
      };
      if(this.props.upcoming)
        for(var j = 0; j < this.props.upcoming.length; j++) {

          if(this.props.upcoming[j].startsAt && this.props.upcoming[j].endsAt
          && (new Date(this.props.upcoming[j].startsAt)).getTime() === filler.startsAt.getTime()
          && (new Date(this.props.upcoming[j].endsAt)).getTime() === filler.endsAt.getTime()) {
              filler = null;
              break;
            }
        }
      if(filler)
        upcoming.push(filler)
    }
    upcoming.sort(function(a ,b) {
      var aStartsAt = moment(a.startsAt);
      var aEndsAt = moment(a.endsAt)
      var bStartsAt = moment(b.startsAt);
      var bEndsAt = moment(b.endsAt);

      var id = (a.id - b.id)

      if(aStartsAt.isValid()) {

        if(bStartsAt.isValid()) {
          return (aStartsAt.valueOf() - bStartsAt.valueOf()) || (aEndsAt.valueOf() - bEndsAt.valueOf()) || id
        }
        return -1;
      }
      if(aEndsAt.isValid()) {
        if(bEndsAt.isValid())
          return (aEndsAt.valueOf() - bEndsAt.valueOf()) || id
        return (id);
      }
      return 1;
    })


    return (
      <>
      <a ref={this.downloadLinkRef} style={{display: 'none'}} href="#" download="export.json" ></a>
      <Competitions
        {...this.state}
        busy={this.state.busy}
        loaded={this.props.loaded}
        active={active}
        upcoming={upcoming}
        history={this.props.historicalCompetitions || []}
        newTemplate={this.state.newTemplate}
        onNewCompetition={this.onNewCompetition}
        onNewTemplate={this.onNewTemplate}
        onCloseNewTemplate={this.onCloseNewTemplate}
        onCloseEditCompetitionModal={this.onCloseEditCompetitionModal}
        onCloseLeadboardSelectModal={this.onCloseLeadboardSelectModal}
        seasonToEdit={this.state.seasonToEdit}
        leaderBoardsToSelect={this.state.leaderBoardsToSelect}
        leaderboardRef = {this.leaderboardRef}
        navigateLeaderBoard={this.navigateLeaderBoard}
        redirectLeaderboard = {this.redirectLeaderboard}
        onExport={this.onExport}
      />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var props = getCompetitions(state.competitions);
  props.loaded = isListLoaded(state.competitions)
  return {active: props.active, historicalCompetitions: props.history, upcoming:props.upcoming};
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompetitions: (params, force) => dispatch(fetchCompetitions(params, force)),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withTranslation('competitions')(CompetitionsContainer)));
