
var namespace = '';
export const ADD_ACTIVITIES                     = namespace + 'ADD_ACTIVITIES';
export const SET_ACTIVITIES_END                 = namespace + 'SET_ACTIVITIES_END';
export const CLEAR_ACTIVITIES                   = namespace + 'CLEAR_ACTIVITIES'; 


namespace = 'categories.';
export const SET_CATEGORIES                     = namespace + 'SET_CATEGORIES';
export const ADD_CATEGORY                       = namespace + 'ADD_CATEGORY';
export const ADD_CATEGORIES                     = namespace + 'ADD_CATEGORIES';

namespace = 'competitions.';
export const SET_COMPETITIONS                   = namespace + 'SET_COMPETITIONS';
export const ADD_COMPETITION                    = namespace + 'ADD_COMPETITION';
export const ADD_COMPETITIONS                   = namespace + 'ADD_COMPETITIONS';

namespace = 'companies.';
export const SET_COMPANIES                     = namespace + 'SET_COMPANIES';
export const ADD_COMPANY                       = namespace + 'ADD_COMPANY';
export const ADD_COMPANIES                     = namespace + 'ADD_COMPANIES';

namespace = '';
export const SET_LOADING_SPINNER_ENABLE         = 'SET_LOADING_SPINNER_ENABLE';
export const SET_LOADING_OVERLAY_ENABLE         = 'SET_LOADING_OVERLAY_ENABLE';

namespace = 'navbar.';
export const INIT_NAVBAR_STATE                  = namespace + 'INIT_NAVBAR_STATE';
export const SET_NAVBAR_ENABLE                  = namespace + 'SET_NAVBAR_ENABLE';
export const SET_NAVBAR_BACK_BUTTON_ENABLE      = namespace + 'SET_NAVBAR_BACK_BUTTON_ENABLE';
export const SET_NAVBAR_DONE_BUTTON_ENABLE      = namespace + 'SET_NAVBAR_DONE_BUTTON_ENABLE';
export const SET_NAVBAR_CLOSE_BUTTON_ENABLE     = namespace + 'SET_NAVBAR_CLOSE_BUTTON_ENABLE';
export const SET_NAVBAR_MENU_ENABLE             = namespace + 'SET_NAVBAR_MENU_ENABLE';
export const SET_NAVBAR_REWARDS_ENABLE          = namespace + 'SET_NAVBAR_REWARDS_ENABLE';
export const SET_NAVBAR_TITLE                   = namespace + 'SET_NAVBAR_TITLE';

namespace = 'leaderboards.';
export const SET_LEADERBOARDS                   = namespace + 'SET_LEADERBOARDS';
export const SET_TEAM_CHALLENGE_LEADERBOARDS    = namespace + 'SET_TEAM_CHALLENGE_LEADERBOARDS';
export const SET_TEAM_CHALLENGE_INFO            = namespace + 'SET_TEAM_CHALLENGE_INFO';
export const ADD_LEADERBOARD                    = namespace + 'ADD_LEADERBOARD';
export const ADD_LEADERBOARD_RECORDS            = namespace + 'ADD_LEADERBOARD_RECORDS';
export const SET_LEADERBOARD_RECORDS            = namespace + 'SET_LEADERBOARD_RECORDS';
export const ADD_CHALLENGE_RECORDS              = namespace + 'ADD_CHALLENGE_RECORDS';
export const SET_LEADERBOARD_RECORDS_END        = namespace + 'SET_LEADERBOARD_RECORDS_END';
export const CLEAR_LEADERBOARD_RECORDS          = namespace + 'CLEAR_LEADERBOARD_RECORDS';

namespace = 'objectiveCategories.';
export const ADD_OBJECTIVE_CATEGORY             = namespace + 'ADD_OBJECTIVE_CATEGORY';
export const ADD_OBJECTIVE_CATEGORIES           = namespace + 'ADD_OBJECTIVE_CATEGORIES';


namespace = 'objectives.';
export const SET_OBJECTIVES                     = namespace + 'SET_OBJECTIVES';
export const ADD_OBJECTIVE                      = namespace + 'ADD_OBJECTIVE';


namespace = 'plugins.';
export const SET_PLUGINS                        = namespace + 'SET_PLUGINS';


namespace = 'rewards.';
export const SET_REWARDS                        = namespace + 'SET_REWARDS';


namespace = 'teams.';
export const SET_TEAMS                          = namespace + 'SET_TEAMS';

namespace = 'surveys.';
export const SET_SURVEYS                        = namespace + 'SET_SURVEYS';

namespace = 'campaigns.';
export const SET_CAMPAIGNS                      = namespace + 'SET_CAMPAIGNS';

namespace = 'employees.';
export const SET_EMPLOYEES                      = namespace + 'SET_EMPLOYEES';
export const SET_EMPLOYEES_RESULTS              = namespace + 'SET_EMPLOYEES_RESULTS';

namespace = 'jobs.';
export const SET_JOBS                           = namespace + 'SET_JOBS';
export const SET_SEARCH_JOBS                    = namespace + 'SET_SEARCH_JOBS';

namespace = 'invitations.';
export const SET_INVITATIONS                    = namespace + 'SET_INVITATIONS';
export const SET_PUBLIC_INVITATIONS             = namespace + 'SET_PUBLIC_INVITATIONS';

namespace = 'announcements.';
export const SET_FUTURE_ANNOUNCEMENTS           = namespace + 'SET_FUTURE_ANNOUNCEMENTS';
export const SET_PUBLIC_ANNOUNCEMENTS           = namespace + 'SET_PUBLIC_ANNOUNCEMENTS';
export const SET_TARGETED_ANNOUNCEMENTS         = namespace + 'SET_TARGETED_ANNOUNCEMENTS';

namespace = 'segments.';
export const SET_SEGMENTS                       = namespace + 'SET_SEGMENTS';

namespace = '';
export const SET_SIDEBAR_ENABLE                 = namespace + 'SET_SIDEBAR_ENABLE';
export const TOGGLE_SIDEBAR                     = namespace + 'TOGGLE_SIDEBAR';


namespace = '';
export const SET_USER_TOKEN                     = namespace + 'SET_USER_TOKEN';
export const SET_USER_LOGGED_IN                 = namespace + 'SET_USER_LOGGED_IN';
export const SET_USER_COMPANY                   = namespace + 'SET_USER_COMPANY';
export const SET_USER_PROFILE                   = namespace + 'SET_USER_PROFILE'; 
export const SET_USER_ROLES                     = namespace + 'SET_USER_ROLES';
export const SET_ROLE_ALLOWED_ROUTES            = namespace + 'SET_ROLE_ALLOWED_ROUTES';



