import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import {Tab, Button, Modal, Header, Input, Label, Message, Dropdown, Popup} from 'semantic-ui-react';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import styleDef from './style.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

const categories = [
    {key:'Raffle',  text:'Raffle',  value:'Raffle'},
    {key:'Points',  text:'Points', value:'Points'},
    {key:'Offer',  text:'Offer', value:'Offer'},
    {key:'Charity',  text:'Charity',  value:'Charity'},
    {key:'Gift Card',  text:'Gift Card', value:'Gift Card'},
    {key:'e-Resource',  text:'e-Resource', value:'e-Resource'},
]


var style = {
    ...styleDef,
    saveButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    updateButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    deleteButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
        color: colors.warningRed,
        backgroundColor: '#fff',
    },
    uploadButton: {
        ...buttons.short,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        margin: spacings.small,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    columns: {
    },
    column: {
        display: 'inline-block',
        width: '50%',
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    
    charactersLeftDesc: {
        float : 'right',
        marginBottom: '10px'
    }
};

const RewardLocalePane = ({t, data, valueChange, onChange}) => (
    <div>
    <br />
    <Popup trigger={
         <Input  value={data ? data.title : ''} 
         name= {'title'}
         placeholder = {t('title_prompt')}
         onChange={onChange}
         onFocus={onChange}
         maxLength = "50"/>
    }
        position = 'right center'
        content = {(50-valueChange) + ' characters left'}
        on='focus' />
    <br />
    <br />
  
    <Popup trigger={
          <textarea 
          name = {'description'}
          placeholder ={t('description_prompt')}
          style={style.textarea}
          value={data ? data.description : ''} 
          onChange={onChange}
          onFocus={onChange}
          maxLength = "400" />
    }
        position = 'top right'
        content = {(400-valueChange) + ' characters left'}
        on='focus' />
   
</div>
);

const EditRewardModalContainer = ({ t, dispatch, resourceScaler, reward, onClose, onRefresh}) => {
    const deleteModalRef = React.createRef();
    const [locale, setLocale] = useState('en')
    const [locales, setLocales] = useState({
        en:{title:'', description:''},
        fr:{title:'', description:''}
      })
      const [value, setValue] = useState('')
      const [resources, setResources] = useState('')
      const [levelReq, setLevelReqs] = useState(1)
      const [id, setId] = useState()
      const [asset, setAsset] = useState()

      const [valueChange, setValueChange] = useState(0)

      const [category, setCategory] = useState()

      const [preferredLanguage, setPreferredLanguage] = useState()
      const [uploadPhotoProperty, setUploadPhotoProperty] = useState()
      const update = Boolean(id)
    useEffect(() => {
        if (Boolean(reward && reward.id)) {
            setId(reward.id)
            setLocale(reward.locale)
            setValue(parseInt(reward.price/100))
            setResources(reward.resources)
            setAsset(reward.asset)
            setLevelReqs(reward.levelReq)
            setLocales(locales => {
                return {
                    ...locales,
                    ...reward.locales
                }
            })
            setCategory(reward.analyticsCategory)
        }
    }, [reward])

    const trimLocales = (locales) => {
        var result = {};
        for(var key in locales) {
            if(preferredLanguage===key) {
                result[key] = locales[key];
            } else {
                var locale = locales[key];
                if(Boolean(locale.title) ||
                   Boolean(locale.description)) {
                    result[key] = locales[key];
                }
            }
        }
        return result;
    }

    const onSubmit = (event) => {
        event && event.preventDefault();
        var method = update ? 'updateReward' : 'addReward';

        var reward = {
            locale  : locale,
            locales : trimLocales(locales),
            price   : value*100,
            asset   : asset,
            levelReq: levelReq || 1,
            resources : resources,
            analyticsCategory: category
        }

        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService[method]({reward:reward, rewardId: id})
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                onRefresh && onRefresh();
                onClose();
                dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    const onDelete = (deleteYes) => {
        if(deleteYes){
            dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.removeReward({rewardId: id})
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(result => {
                onRefresh();
                onClose();
                dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
            })
        }
    }

    const isValid = () => {
        var selectedLocale = locales[locale];
        return Boolean(selectedLocale) &&
                Boolean(selectedLocale.title) &&
                Boolean(selectedLocale.description) &&
                _.isFinite(value) &&  _.isFinite(resources) && ((value > 0) || (resources > 0));
    }

    const onChangePreferredLanguage = (event, {value}) => {
        setPreferredLanguage(value)
    }

    const onChangeCategory = (event, {value}) => {
        setCategory(value)
    }

    const onChangeValue = (event) => {
        var value = event.target.value;
        setValueChange(value.length)
        setValue(value)
    }

    const onChangeResources = (event) => {
        var value = event.target.value;
        setValueChange(value.length)
        setResources(value);
    }

    const onChangeLocales = (event, locale) => {
        var key = event.target.name;
        var value = event.target.value;
        setValueChange(value.length)
        setLocales(locales => {
            let newLocales = {...locales}
            newLocales[locale][key] = value
            return newLocales
        })
    }

    const onUploadPhoto = (propertyName) => {
        console.log('onUploadPhoto', propertyName);
        setUploadPhotoProperty(propertyName)
    }

    const onConfirmPhoto = (photo) => {
        setAsset(asset => {
            return {
                ...asset,
                dataUrl: photo
            }
        })
    }

    return (
        <Modal open style={style.content} onClose={onClose} size='small'>
            <Modal.Header>{update ? t('updateincentive_modal_header') : t('addnewincentive_modal_header')}</Modal.Header>
            <Modal.Content>
            <form onSubmit={onSubmit}>
                <Modal.Description>
                    <br />
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>{t('Primary Language')}</div>
                            <Dropdown options={languages}
                                    value={preferredLanguage}
                                    onChange={onChangePreferredLanguage}
                                    selection
                                    closeOnChange/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>{t('Category')}</div>
                            <Dropdown options={categories}
                                    value={category}
                                    onChange={onChangeCategory}
                                    selection
                                    closeOnChange/>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Tab panes={languages.map(language=>({
                        menuItem:language.text,
                        render:() =>
                            <RewardLocalePane t={t} 
                                locale={language.value}
                                data={locales[language.value]}
                                onChange={(event) => onChangeLocales(event, language.value)}
                                valueChange = {valueChange}
                            />
                        }))}
                    />
                    
                    <br />
                    <div sytle={style.columns}>
                        <div style={style.column}>
                            {Boolean(asset && (asset.url || asset.dataURL)) &&
                                <img style={style.imagePreview} src={asset.dataURL || asset.url} alt='' />
                            }
                            <Button type='button' 
                                    style={style.uploadButton} 
                                    onClick={()=>onUploadPhoto('asset.dataURL')}>
                                {t('Upload Image')}
                            </Button>
                        </div>
                        <div style={style.column}>
                            <div>{t('monetary_value_prompt')}</div>
                            <Input name='value'
                                label={{ basic: true, content:'$'}} 
                                type='number' 
                                value={value} 
                                onChange={onChangeValue} /> 
                            <br />
                            <br />
                            <div>{t('reward_value_prompt')}</div>
                            <Input name='resources' 
                                labelPosition='left'
                                type='number' value={resources} 
                                onChange={onChangeResources}>
                            <Label><i style={style.star} className="icon-Optimollar"/></Label>
                            <input />
                            </Input> 
                            <br />
                            <br />
                            {resourceScaler &&
                            <div>
                                <div>{t('reward_scaled_value_prompt')}</div>
                                <Input
                                    labelPosition='left'
                                    type='number' value={resources * resourceScaler || ""} 
                                    >
                            <Label><i style={style.star} className="icon-Optimollar"/> x {resourceScaler}</Label>
                            <input disabled />
                            </Input> 
                            </div> }
                        </div>
                    </div>
                    <br />
                    <br />
                    <DeletePromptContainer ref={deleteModalRef} onDeleteConfirmation = {onDelete} />
                    {update &&
                        <Button style={buttons.delete} type='button' onClick={() => {deleteModalRef.current.showDeletePrompt()}}>{t('Delete')}</Button>            
                    }
                    <Button
                        type='submit'
                        style={update ? style.updateButton : style.saveButton}
                        disabled={!isValid()}>
                        {update ? t('Save') : t('Create')}
                    </Button>
                    <br />
                    {Boolean(uploadPhotoProperty) &&
                        <ChoosePhotoContainer
                            onConfirmPhoto={photo=>onConfirmPhoto(photo)}
                            onClose={onUploadPhoto}
                        />
                    }
                </Modal.Description>
            </form>
            </Modal.Content>
        </Modal>
    )
}

export default connect()(withTranslation('rewards')(EditRewardModalContainer));
