import React, { Component, useState, useEffect, useRef, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';

import {Button, Modal,Grid,Dropdown, Header, Input, Message, Search, Checkbox, Icon} from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';

var style = {

    loaderContainer: {
        paddingTop: '150px',
        paddingBottom: '150px',
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    container : {
        marginTop : '30px',
        textAlign : 'center',
        marginRight : 'auto',
        marginLeft : 'auto'
        
    },
    profilestag : {
        display:'inline-block',
        padding:'10px',
        borderRadius: '100px',
        color: '#fff',
        backgroundColor:'#FFA500',
        marginRight:'1'
    }
};

const UploadCompetitionTemplateModel = ({t, onClose}) => {
    const [isPublic, setIsPublic] = useState(false)
    const [complete, setComplete] = useState()
    const [template, setTemplate] = useState()
    const [banner, setBanner] = useState()
    const [error, setError] = useState()
    const [uploading, setUploading] = useState(false)
    const [activitiesAsset, setActivitiesAsset] = useState({})

    const templateSelector = useRef()
    const bannerSelector = useRef()

    const activitiesFileRefs = useRef([])

    const uploadJSON = async (type) => {
        templateSelector.current.click()
    }

    const uploadBanner = async (type) => {
        bannerSelector.current.click()
    }

    useEffect(() => {
        if (!template)
            return

        if (template.banner?.dataURL) {
            setBanner(template.banner.dataURL)
            setComplete(true)
        }

        let totalActivities = 0
        if (_.isArray(template?.competitionCategories)) {
            for(let competitionCategory of template.competitionCategories) {
                if (_.isArray(competitionCategory?.category?.activities)) {
                    for (let activity of competitionCategory.category.activities) {
                        if (!activity.tag) {
                            
                            setError(`Missing Activity Tag for ${activity.title}`)
                        }
                        setActivitiesAsset(activitiesAsset => ({
                            ...activitiesAsset,
                            [activity.tag]:  activity.image?.dataURL || null
                        }))

                        totalActivities += 1
                    }   
                }
            }
        }

        activitiesFileRefs.current = Array(totalActivities)
            .fill()
            .map((_, i) => activitiesFileRefs.current[i] || createRef());
    }, [template])

    const saveTemplate = async (event) => {
        var file = event.target.files[0];
        event.target.value=null;
        setError()

        var reader = new FileReader();
        reader.onload = async (e) => {
            let result = e.target.result
            try {
                let parsed = JSON.parse(result)
                setTemplate(parsed)
            } catch(e) {
                setError('Invalid JSON')
            }
            setComplete(!!result && !!banner)
        }
        reader.readAsText(file, 'UTF-8');
    }

    const saveBanner = async (event) => {
        var file = event.target.files[0];
        event.target.value=null;
        setError()

        var reader = new FileReader();
        reader.onload = async (e) => {
            let result = e.target.result
            setBanner(result)
            setComplete(!!template && !!result)
        }
        reader.onerror = () => {
            setError('Invalid Image')
        }
        reader.readAsDataURL(file);
    }

    const saveActivitiesAssets = (activityTag) => async (event) => {
 
        var file = event.target.files[0];
        event.target.value=null;
        setError()

        var reader = new FileReader();
        reader.onload = async (e) => {
            let result = e.target.result
            setActivitiesAsset(activitiesAsset => ({
                ...activitiesAsset,
                [activityTag]:  result
            }))
        }
        reader.onerror = () => {
            setError('Invalid Image')
        }
        reader.readAsDataURL(file);
    }

    const uploadCompetition = async () => {
        setUploading(true)
        setError()
        await DatabaseService.createCompetition({
            data: template,
            public: isPublic,
            assetDataURL: banner,
            activitiesAssetMap: activitiesAsset
        }).then(() => {
            setUploading(false)
            onClose()
        }, (e) => {
            setUploading(false)
            setError(e.message)
        })
    }

    return (
        <Modal open style={style.content} size='large' onClose={onClose}>
            <Modal.Header>{'Upload Challenge Template'}</Modal.Header>
            <Modal.Content> 
                <Modal.Description>
                    <input ref={templateSelector} style={{display: 'none'}} type="file" accept='.json' onChange={saveTemplate}/>
                    <input ref={bannerSelector} style={{display: 'none'}} type="file" accept='.jpg,.jpeg,.png' onChange={saveBanner}/>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={4}><b>{'Template JSON'}</b></Grid.Column>
                            <Grid.Column width={12}><Button type="button" style={style.button} onClick={uploadJSON}>{'Upload'}</Button> {template && <Icon name="check circle"></Icon>}</Grid.Column>
                        </Grid.Row> 
                        <Grid.Row>
                            <Grid.Column width={4}><b>{'Public'}</b></Grid.Column>
                            <Grid.Column width={12}><Checkbox toggle checked={isPublic} style={style.togglePacks} onChange={() => setIsPublic(!isPublic)} /></Grid.Column>
                        </Grid.Row> 
                        <Grid.Row>
                            <Grid.Column width={4}><b>{'Template Banner'}</b></Grid.Column>
                            <Grid.Column width={12}><Button type="button" disabled={!!error} style={style.button} onClick={uploadBanner}>{'Upload'}</Button> { banner && <Icon name="check circle"></Icon> } </Grid.Column>
                        </Grid.Row>

                        {
                            Object.keys(activitiesAsset).map((activityTag, i) => (
                            
                                <Grid.Row key={i} >
                                    <Grid.Column width={4}><b>{`${i+1}. Activity Image (${activityTag}):`}</b></Grid.Column>
                                    <Grid.Column width={12}>
                                        <Button type="button" style={style.button} disabled={!!error} onClick={()=>activitiesFileRefs.current[i].click()}>{'Upload'}</Button>
                                        {activitiesAsset[activityTag] && <Icon name="check circle"></Icon>}
                                    </Grid.Column>
                                    <input 
                                        ref={el => activitiesFileRefs.current[i] = el} 
                                        style={{display: 'none'}} 
                                        type="file" accept='.jpg,.jpeg,.png' 
                                        onChange={saveActivitiesAssets(activityTag)}
                                    />
                                </Grid.Row> 
                                
                            ))
                        }
                    </Grid>
                    
                    <div>{ error }</div>
                    <p>Note: Combined size of all the assets should be less than 5 MB.</p>
                    <Button type="button" style={style.button} onClick={uploadCompetition} disabled={!complete || uploading}>{uploading ? 'Saving...' : t('Save')}</Button> 
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

export default connect()(withTranslation('competitions')(UploadCompetitionTemplateModel));