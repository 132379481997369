import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Icon, Grid, Button, Modal, Dropdown} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';
import defaultAvatar from 'img/default-avatar.png';

var style = {
    ...styleDef,
    checkbox: {
        marginRight: spacings.tiny,
    },
    grid: {
        maxHeight: '400px',
        overflowY: 'scroll',
    },
    gridRow: {
        cursor: 'pointer',
        borderBottom: '1px solid ' + colors.themeDarkGrey,
    },
    teamsPopup: {
        background: '#fff',
        position: 'absolute',
        right: '-20px',
        top: '20px',
        left: 'auto',
        borderRadius: '9px',
        border: '1px solid ' + colors.themeDarkGrey,
        zIndex: '1000',
        minWidth: '200px',
        maxHeight: '200px',
        overflowX: 'auto',
    },
    dropdown: {
        position: 'relative',
    }
};



const TeamAssignModal = ({t,
                          employees,
                          teams,
                          selectedEmployee,
                          onClose,
                          onChangeTeam,
                          onClickRow,
                          }) => (
    <Modal open style={style.content} onClose={onClose} size='small'>
        <Modal.Header>{t('team_assign_modal_header')}</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid style={style.grid}>
                {employees.map((employee,i)=>(
                    <Grid.Row key={employee.id} style={{...style.gridRow,...(i===0 && {borderTop: 'none'})}} onClick={()=>onClickRow(employee)}>
                        <Grid.Column width={2}>
                            <Icon name="user outline" size='large' />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {employee.email}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {employee.fullName}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <div className='ui dropdown' style={style.dropdown}>
                                <Icon name='chevron down' />
                                {Boolean(selectedEmployee && selectedEmployee.id===employee.id) &&
                                    <div style={style.teamsPopup} className='menu visible transition'>
                                        {teams && teams.map(team=>(
                                            <div key={team.id} className='item' onClick={(event)=>onChangeTeam(event, employee, team.id)}>
                                                {team.name}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>     
                ))}
                </Grid>

                <br />
                <br />
                <Button style={style.button} onClick={onClose}>{t('Done')}</Button>
                <br />
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('teams')(TeamAssignModal);
