import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Grid, Header, Message, Label, Segment, Checkbox} from 'semantic-ui-react';
import Color from 'color';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, fonts, buttons} from 'style';
import ProgressBar from 'modules/misc/ProgressBar.js';
var style = {
    loaderContainer: {
        height: '100%',
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
        marginTop: spacings.base
    },
    progressBar: {
        height: '24px',
        backgroundColor: '#fff',

    },
    toggle: {
    },
    progresswrapper : {
        position : "relative",
        top : "-24px"
    }
};

const colorMap = {
    success : '#5cb85c',
    info : '#5bc0de',
    warning : '#f0ad4e',
    danger : '#d9534f'
}

const Fwa = ({t, 
                loaded,
                busy, 
                surveys,
                companySurvey,
                onToggleSurvey
               }) => (
    busy && !loaded ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={{...style.header, display: 'inline'}}>{t('page_title')}
                </div>
            <div style={style.section}>
            <div>
                <h3>{t('Enable FWA')} <Checkbox className='green' style={style.toggle} toggle checked={Boolean(companySurvey.enabled)} onClick={()=>{!busy && onToggleSurvey(!companySurvey.enabled)}}/></h3>
            </div>
            <hr/>
            <Grid stackable>
                <Grid.Row>
                        <Grid.Column width={4}><b>{t('Title')}</b>
                        </Grid.Column>
                        <Grid.Column width={8}><b>{t('Percentage completed', {count:surveys.percentageResponse})}</b>
                        </Grid.Column>
                        <Grid.Column width={4}><b>{t('Insight')}</b>
                        </Grid.Column>
                </Grid.Row> 
                {surveys.risks.map((risk)=>(
                        <Grid.Row key={risk.id}>
                             <Grid.Column width={4}>{risk.name}
                            </Grid.Column>
                            <Grid.Column width={8}><div style={style.progresswrapper}><span>{risk.value}/{surveys.totalEmployee}</span><br/>
                            <ProgressBar style={style.progressBar} value={risk.progressValue} total={100} barColor={colorMap.info} borderColor={colorMap.info}/></div>
                            </Grid.Column>
                            <Grid.Column width={4}>{risk.insight}
                            </Grid.Column>                   
                        </Grid.Row>
                ))}       
            </Grid>
            </div>
        </div>
    )
);

export default withTranslation('fwa')(Fwa);
