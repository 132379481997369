import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import _, { values } from 'underscore';

import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Button, Icon, Grid, Search, Popup} from 'semantic-ui-react';
import { customRenderer } from 'components/modals'


const styles = {
    warningIcon: {
        background: 'none',
        color: colors.warningRed
    },
    trashIcon: {
        background: 'none',
        color: colors.themeBlack
    },
    checkmark: {
        background: 'none',
        color: 'green'
    }
}

  const SegmentConditionRow = ({setDirty, term, updateTerm, search, segmentOptions, deleteTerm, t}) => {
    const [searchQuery, setSearchQuery] = useState()
    const [searchResults, setSearchResults] = useState([])
    const [searching, setSearching] = useState(false)
    const [termValues, setTermValues] = useState({})
    const [fields, setFields] = useState([
        {
            width: 4,
            key: 'type',
            type: 'dropdown',
            style: { minWidth: 150, width: '100%' },
            options: segmentOptions,
            clearable: true
        }
    ])

    const [operators, setOperators] = useState([])
    const [valueField, setValueField] = useState({
        width: 3,
        key: 'value',
        type: 'none'
    })

    const segmentOption = segmentOptions.find(option => option.value === termValues.type)

    const loadTerm = async () => {
        if (term.fields?.length > 2) {
            let valueField = term.fields.find(field => field.key === 'value')
            let fields = term.fields.filter(field => field.key !== 'value')
            let operatorField = term.fields.find(field => field.key === 'operator')
            setValueField(valueField)
            setFields(fields)
            setOperators(operatorField.options)
            let termValues = {...term}
            delete termValues.fields
            setTermValues(termValues)
        }
    }

    useEffect(() => {
        loadTerm()
    }, [])

    const searchData = async () => {
        setSearching(true)
        let data = await search(segmentOption?.search, searchQuery, function(data) {
            setSearchResults(data)
            setSearching(false)
        })
    }

    const update = async (termValues, id, field, value) => {
        let option = segmentOption
        if (fields.length === 1) {
            option = segmentOptions.find(option => option.value === value) || {operators: []}
            const newFields = fields.concat([
                {
                    width: 4,
                    key: 'search',
                    type: 'search',
                    custom: true
                },
                {
                    width: 3,
                    key:'operator',
                    type: 'dropdown',
                    style: { minWidth: '6em' },
                    clearable: true
                }
            ])
            let operators = option.operators.map(operator => {
                operator = operator.key ? operator : {key: operator, text: operator}
                return {value: operator.key, text: operator.text}
            })
            let newValueField = {...valueField, type: option?.valueField}
            setOperators(operators)
            setFields(newFields)
            setValueField(field => { return {...field, type: option?.valueField}})
            updateTerm(term, undefined, {
                type: value,
                fields: formatFields(newFields, newValueField, operators)
            })
        } else if (field === 'type') {
            option = segmentOptions.find(option => option.value === value) || {operators: []}
            setSearchQuery('')
            setSearchResults([])
            setOperators(option.operators.map(operator => {
                operator = operator.key ? operator : {key: operator, text: operator}
                return {value: operator.key, text: operator.text}
            }))
            setTermValues({[field]: value})
            setValueField(field => { return {...field, type: option?.valueField}})
        } else if (field === 'search' && termValues.type === 'survey_questions' && value?.challengeAnswers) {
            setValueField(field => {
                return {
                    ...field,
                    type: 'dropdown',
                    options: value.challengeAnswers.map(answer => {
                        return {
                            ...answer,
                            label: null,
                            value: answer.data,
                            text: answer.label
                        }
                    }),
                    clearable: true
                }
            })
        }
        setTermValues(values => { return {
            ...values,
            [field]: value,
            hint: option?.hint,
            valueHint: option?.valueHint,
            placeholder: option?.placeholder
        }})
    }

    useEffect(() => {
        if (searchQuery && !searchResults?.find(result => result.title === searchQuery)) {
            searchData()
        }
    }, [searchQuery])

    const validate = () => {
        return termValues.type && termValues.operator &&
        (termValues.value || valueField.type === 'none') && (!segmentOption.search || searchResults?.find(result => result.title === searchQuery))
    }

    const formatFields = (newFields, newValueField, newOperators) => {
        newFields = newFields || fields
        newValueField = newValueField || valueField
        newOperators = newOperators || operators
        return newFields.concat(newValueField).map(field => {
            return (field?.key === 'operator') ?
            {...field, options: newOperators } : field
        })
    }

    useEffect(() => {
        if (validate()) {
            let data = {
                ...termValues,
                fields: formatFields(),
                complete: true
            }
            updateTerm(term, null, data)
        } else if (term.complete) {
            //Tell container that the condition is not complete
            setDirty()
        }
    }, [termValues])

    return (
        <Grid.Row style={{display: 'flex', alignItems: 'center', paddingTop: 0, paddingBottom: 0}}>
            {
                formatFields(fields).map(field => {
                    return (
                        <Grid.Column width={field.width} style={{paddingLeft: 4, paddingRight: 4}}>
                        {
                            field.custom ? segmentOption?.search && field.key === 'search' ?
                            <Search
                                loading={searching}
                                onResultSelect={(e, {result}) => {
                                    update(termValues, null, field.key, result)
                                    setSearchQuery(result.title)
                                }}
                                onSearchChange={(e) => {
                                    setSearchQuery(e.target.value)
                                    setSearchResults([])
                                }}
                                showNoResults={!searching}
                                results={searchResults}
                                value={searchQuery}
                            />
                            : null
                            :
                            customRenderer(field, termValues, (...args) => update(termValues, ...args))
                        }
                        </Grid.Column>
                    )
                })
            }
            <Grid.Column style={{paddingLeft: 4, paddingRight: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} width={2} >
                {
                    validate() ?
                    <div style={{display: 'flex'}}>
                        <Icon style={styles.checkmark} name={'checkmark'} />
                    </div>
                    : fields.length > 1 
                        ?   <div style={{display: 'flex'}}>
                            <Popup
                                trigger={<Icon style={styles.warningIcon} name={'exclamation'} />}
                            >
                                {t('incomplete_filter')}
                            </Popup>
                                
                            </div>
                        : null
                }
                {
                    fields.length > 1 ?
                    <Button style={styles.trashIcon} onClick={() => deleteTerm(term)}>
                        <Icon name='trash' />
                    </Button>
                    : null
                }
            </Grid.Column>
        </Grid.Row>
    )
}

export default withTranslation('segments')(SegmentConditionRow);