import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Icon, Image, Button, Dropdown, Checkbox, Modal, Input, Message, Popup} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import defaultAvatar from 'img/default-avatar.png';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';

var style = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: colors.themeGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    form: {
        padding: spacings.base,
    },
    saveButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    deleteButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
        color: colors.warningRed,
        backgroundColor: '#fff',
    },
    buttonRow: {
        height: '50px',
    },
    columnContainer: {
    },
    leftColumn: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '40%',
    },
    rightColumn: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '55%',
        marginLeft:'5%',
        paddingLeft: spacings.small,
    },
    employeeList: {
        height: '275px',
        overflowY: 'scroll',
        paddingLeft: '0px',
    },
    teamMemberList: {
        height: '350px',
        padding:"0px",
        margin:"0px",
        overflowY: 'scroll',
    },
    teamMemberRow: { listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    teamMemberName: {
        //fontWeight: 'bold',
        display: 'inline-block',
        verticalAlign:'middle',
        width: '70%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: spacings.base,
    },
    removeTeamMemberButton: {
        float: 'right',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.warningRed,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
    addButton: {
        float: 'right',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.themeBlue,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
    avatar: {
        display: 'inline-block',
        width: '50px',
        boxShadow: shadow,
        border: '5px solid #fff',
    },
    employeeRow: {
        listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    employeeName: {
        //fontWeight: 'bold',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: spacings.base,
    },
    unregistered: {
        //display: 'inline-block',
        width: '100px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    teamIcon: {
        color: colors.warningRed,
    },
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    assets: {
        minHeight: '100px'
    },
    asset: {
        cursor: 'pointer',
        display: 'inline-block',
        height: '100px',
        width: '100px',
        objectFit:'contain',
        boxShadow: shadow,
        border: '5px solid #fff',
        margin: spacings.base,
    },
};


const EditTeamModal = ({t, 
                        name,
                        search,
                        valid,
                        uploadPhotoProps,
                        teamAssets,
                        teamAssetMap,
                        avatar,
                        avatarAssetId,
                        update,
                        onDelete,
                        onChange,
                        onClose, 
                        onSubmit,
                        onChangeSearch,
                        loadingEmployees,
                        onAddTeamMember,
                        employees,
                        teamMembers,
                        onRemoveTeamMember, 
                        onUploadPhoto,
                        onConfirmPhoto,
                        onSelectAsset,
                        getAvatar,
                        deleteModalRef,
                        valueChange
                        }) => (
    <Modal open style={style.content} onClose={onClose} size='large'>
        <Modal.Header>{update ? t('Edit Team') : t('Create Team')}</Modal.Header>
        <Modal.Content>
        <form style={style.form} onSubmit={onSubmit}>
            <Modal.Description>
                <div>
                    <br />
                   <Popup trigger={
                    <Input 
                        name='name' 
                        value={name} 
                        onChange={onChange}
                        onFocus={onChange}
                        placeholder = {t('team_name_prompt')}
                        maxLength = '50' />

                     }
                     position = 'right center'
                     content = {(50-valueChange) + ' characters left'}
                     on='focus' />
    <            br />
                 <br />

                    <div>{t('team_picture_prompt')}</div>
                    {Boolean(getAvatar(avatar, avatarAssetId)) &&
                        <Image style={style.avatar} src={getAvatar(avatar, avatarAssetId)} alt='' />
                    }
                    <br />
                    <br />
                    <Button type='button' 
                            style={style.uploadPhoto} 
                            onClick={()=>onUploadPhoto({field:'avatar.dataURL'})}>
                        {t('upload_photo_button_text')}
                    </Button>
                    {
                        localStorage.master === 'true' &&
                        <div>
                            <div>{t('select_asset_prompt')}</div>
                            <div style={style.assets}>
                                {teamAssets.map(asset => (
                                    <Image key={asset.id} 
                                        style={style.asset} 
                                        src={asset.url} 
                                        onClick={()=>onSelectAsset(asset)} />
                                ))}
                            </div>
                        </div>
                    }
                    

                    <div style={style.columnContainer}>
                        <div style={style.leftColumn}>
                            <div>{t('add_team_members_prompt')}</div>
                            <Input placeholder={t('search_placeholder')} value={search} onChange={onChangeSearch} fluid />


                            {Boolean(!loadingEmployees) &&
                            <ul style={style.employeeList}>
                                {employees.map((employee,i) => (
                                    <li key={employee.id} style={{...style.employeeRow, ...(i==0 && {borderTop:'none'}) }}>
                                        {Boolean(employee.segmentId) ? (
                                                <Icon style={style.teamIcon} name='users' title={t('in_team_hover')} />
                                            ) : (
                                                <Icon name='user' title={t('no_team_hover')} />
                                        )}
                                        <Button type="button" style={style.addButton} onClick={()=>onAddTeamMember(employee)}>
                                            <Icon name='add' />
                                        </Button>
                                        <div style={style.employeeName}>
                                            {employee.fullName && <div>{employee.fullName}</div>}
                                            {employee.email}
                                            {(!employee.userProfileId) &&
                                                <div style={style.unregistered}>{t('(Unregistered)')}</div>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            }
                            {Boolean(loadingEmployees) &&
                                <div style={style.loaderWrapper}>
                                    <LoadInline centered vcentered className="notInDimmer" style={style.loader} />
                                </div>
                            }
                            
                        </div>
                        <div style={style.rightColumn}>
                            <div>{t('members_prompt')}</div>
                            <ul style={style.teamMemberList}>
                                {console.log(teamMembers)}
                            {teamMembers.map((employee,i) => (
                                <li key={employee.id} style={{...style.teamMemberRow, ...(i==0 && {borderTop:'none'}) }}>
                                    <Button type="button" style={style.removeTeamMemberButton} onClick={()=>onRemoveTeamMember(employee)}>
                                        <Icon name='trash' />
                                    </Button>
                                    <Image style={style.avatar} src={Utils.deref(employee, 'userProfile.avatar.url', defaultAvatar)} />
                                    <div style={style.teamMemberName}>
                                        {employee.fullName && <div>{employee.fullName}</div>}
                                        {employee.email}
                                        {(!employee.userProfileId) &&
                                            <div style={style.unregistered}>{t('(Unregistered)')}</div>
                                        }
                                    </div>
                                    
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <DeletePromptContainer ref={deleteModalRef} onDeleteConfirmation = {onDelete} />
                    <div style={style.buttonRow}>
                        {Boolean(update) &&
                            <Button style={buttons.delete} type='button' onClick={() => {deleteModalRef.current.showDeletePrompt()}}>{t('Delete')}</Button>            
                        }
                        <Button style={style.saveButton} type='submit'>{t('Save')}</Button>            
                    </div>
                </div>
            </Modal.Description>
        </form>
        </Modal.Content>
        {Boolean(uploadPhotoProps) &&
            <ChoosePhotoContainer onConfirmPhoto={photo=>onConfirmPhoto(uploadPhotoProps, photo)}
                                  onClose={onUploadPhoto}
                                  width={uploadPhotoProps.width}
                                  height={uploadPhotoProps.width}/>
        }
    </Modal>
);

export default withTranslation('teams')(EditTeamModal);
