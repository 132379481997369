import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import {ListMultiSelectModal, ListFormModal} from 'components/modals'

const UserSurveyForm = ({t, open, fetchCampaigns, fetchEmployees, validateCampaign, finish, modalError, cancel, campaignTableFields, employeeTableFields}) => {
    const [selectedCampaigns, setSelectedCampaigns] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [step, setStep] = useState(0)
    const [error, setError] = useState()

    const updateSelectedCampaign = (id, field, value) => {
        const survey = selectedCampaigns.find(survey => survey.id === id)
        if (survey) {
            setSelectedCampaigns(surveys => {
                const index = surveys.indexOf(survey)
                const newSurveys = [...surveys]
                newSurveys[index][field] = value
                newSurveys[index].valid = validateCampaign(newSurveys[index])
                return newSurveys
            })
        }
    }

    const cancelModal = () => {
        setStep(0)
        setError()
        setSelectedCampaigns([])
        setSelectedEmployees([])
        cancel()
    }

    const nextForm = () => {
        setStep(step => step+1)
        setError()
    }

    const submit = () => {
        finish({selectedEmployees, selectedCampaigns})
        cancelModal()
    }
    
    useEffect(() => {
        if (modalError) {
            setError(modalError)
        }
    }, [modalError])

    const selectItem = (item, type) => {
        switch (type) {
            case 'surveys':
                setSelectedCampaigns(surveys => {
                    const existing =surveys.find(survey => survey.id === item.survey.tag)
                    if (!existing) {
                        return [
                            ...surveys,
                            {
                                title: item.survey.title,
                                id: item.survey.tag,
                                data: item
                            }
                        ]
                    } else {
                        return [
                            ...surveys.slice(0, surveys.indexOf(existing)),
                            ...surveys.slice(surveys.indexOf(existing) + 1)
                        ]
                    }
                })
                return
            case 'employees':
                setSelectedEmployees(employees => {
                    const existing = employees.find(employee => employee.id === item.email)
                    if (!existing) {
                        return [
                            ...employees,
                            {
                                title: item.title,
                                id: item.email,
                                data: item
                            }
                        ]
                    } else {
                        return [
                            ...employees.slice(0, employees.indexOf(existing)),
                            ...employees.slice(employees.indexOf(existing) + 1)
                        ]
                    }
                })
                return
        }
    }

    useEffect(() => {
        if (open) {
            setStep(1)
        }
    }, [open])

    return (
        <div>
            <ListMultiSelectModal
                open={step === 1}
                searchData={fetchCampaigns}
                queryField={['survey.tag', 'survey.title']}
                selectItem={(item) => selectItem(item, 'surveys')}
                selectedItems={selectedCampaigns}
                cancel={cancelModal}
                next={nextForm}
                tableName={t('Campaign')}
                fieldName={t('Survey')}
                tableFields={campaignTableFields}
            />
            <ListMultiSelectModal
                open={step === 2}
                searchData={fetchEmployees}
                queryField={'email'}
                selectItem={(item) => selectItem(item, 'employees')}
                selectedItems={selectedEmployees}
                cancel={cancelModal}
                next={nextForm}
                tableName={t('Campaign')}
                fieldName={t('Employees')}
                tableFields={employeeTableFields}
            />
            <ListFormModal
                open={step === 3}
                items={selectedCampaigns}
                updateItem={updateSelectedCampaign}
                fields={[
                    {
                        name: t('Start Date'),
                        key: 'startDate',
                        disabledKey: 'startNow',
                        maxKey: 'endDate',
                        type: 'date',
                        extra: [
                            {
                                name: t('Start Now'),
                                key: 'startNow',
                                type: 'checkbox'
                            },
                            {
                                name: t('End Date'),
                                key: 'endDate',
                                disabledKey: 'indefinite',
                                minKey: 'startDate',
                                type: 'date',
                                extra: [
                                    {
                                        name: t('Indefinite'),
                                        key: 'indefinite',
                                        type: 'checkbox'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: t('Show (Test)'),
                        key: 'refreshCreate',
                        type: 'checkbox'
                    }
                ]}
                tableName={t('Campaigns')}
                cancel={cancelModal}
                next={submit}
                cancelText={t('Close')}
            />
        </div>
    )
}

export default withTranslation('surveys')(UserSurveyForm);