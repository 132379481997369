import DatabaseService from 'services/DatabaseService.js';
import {SET_COMPETITIONS, 
        ADD_COMPETITION,
        ADD_COMPETITIONS} from 'actions/types';
import {getCompetition, 
        getCompetitions, 
        isListLoaded}  from 'reducers/competitions.js';

//Action Creators
export const setCompetitions = (result) => {
    return { type:SET_COMPETITIONS, active: result.active, history: result.history, upcoming: result.upcoming };
}

export const addCompetition = (competitions) => {
    return { type:ADD_COMPETITION, competitions:competitions };
}

export const addCompetitions = (competitions) => {
    return { type:ADD_COMPETITIONS, competitions:competitions };
}



//Thunks
export const fetchCompetitions = (params={}, force) => (dispatch, getState) => {
    if(!force) {
        var state = getState();
        if(isListLoaded(state.competitions)) {
            return Promise.resolve(getCompetitions(state.competitions));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.getSeasons(Object.assign(params, {
        	competitions : true,
        	seasons : false,
        	active : true,
        	history : true,
        	upcoming : true
        })).then(
            function(result){ 
                dispatch(setCompetitions(result));
                resolve(result);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}

