import DatabaseService from 'services/DatabaseService.js';
import {SET_EMPLOYEES, SET_EMPLOYEES_RESULTS} from 'actions/types';
import {getEmployees, isListLoaded}  from 'reducers/employees.js';
import { param } from 'jquery';

//Action Creators
export const setEmployees = (employees, count) => {
    return { type:SET_EMPLOYEES, employees:employees, count: count };
}

export const setEmployeeSearchResults = (employees, count) => {
    return { type:SET_EMPLOYEES_RESULTS, searchResults: employees, count: count}
}

//Thunks
export const fetchEmployees = (params) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        params['summary.total']=true // hack to fix the pagination
        DatabaseService.getEmployees(params).then(
            function(result){ 
                console.log(result)
                result.employees = result.employees.map(employee => {
                    return {
                        title: employee.fullName,
                        fields: employee && [{
                            label: 'Email',
                            text: employee.email,
                        }],
                        ...employee
                    }
                })
                dispatch(setEmployees(result.employees, result.summary.total))
                resolve({data: result.employees, count: result.summary.total})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}

export const searchEmployees = (params) => (dispatch, getState) => {
    params['summary.total']=true // hack to fix the pagination
    if (params.query && params.query !== '' && params.queryField !== '') {
        if (Array.isArray(params.queryField)) {
            for (var i in params.queryField) {
                params[`like.${params.queryField[i]}`] = params.query
            }
        } else {
            params[`like.${params.queryField}`] = params.query
        }
    }
    delete params.query
    return new Promise(function(resolve, reject) {
        DatabaseService.getEmployees(params).then(
            function(result){ 
                console.log(result)
                result.employees = result.employees.map(employee => {
                    return {
                        title: employee.fullName,
                        fields: employee && [{
                            label: 'Email',
                            text: employee.email,
                        }],
                        description: employee.email,
                        ...employee
                    }
                })
                dispatch(setEmployeeSearchResults(result.employees, result.summary.total))
                resolve({data: result.employees, count: result.summary.total})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
