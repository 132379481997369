import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import jstz from 'jstz';
import _ from 'underscore';

import UserService from '../../services/UserService.js';
import {initNavbarState} from 'actions/navbar.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import Utils from 'utils/Utils.js';
import AuthUtils from 'utils/AuthUtils.js';

import SignUp from './SignUp.js';

class SignUpContainer extends Component {

    constructor(props){
		super(props);
		this.state = {valid:false,
                      email:'', 
					  password:'', 
					  passwordConfirm:'', 
					  inviteCode:'', 
                      emailError:'',
                      passwordError:'', 
                      passwordConfirmError:'', 
                      inviteCodeError:'', 
                      error:''};    

		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
		this.onInviteCodeChange = this.onInviteCodeChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
        this.onBlur = this.onBlur.bind(this);
		this.isValid = this.isValid.bind(this);
        this.isValidDebounced = _.debounce(this.isValid, 1000);
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(false));
        this.props.dispatch(initNavbarState({enable:false}));
    }

	onEmailChange(event) {
		this.setState({email: event.target.value}, this.isValidDebounced);
	}

	onPasswordChange(event) {
		this.setState({password: event.target.value}, this.isValidDebounced);
	}

	onPasswordConfirmChange(event) {
		this.setState({passwordConfirm: event.target.value}, this.isValidDebounced);
	}
	
    onInviteCodeChange(event) {
		this.setState({inviteCode: event.target.value}, this.isValidDebounced);
	}

    onBlur(event) {
        console.log('onBlur');
        this.isValid();
    }

	onSubmit(event) {
		console.log('onSubmit:' + JSON.stringify(this.state));

        if(this.isValid(true)) {
            this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:true});
			var timezone = jstz.determine();
            UserService.signUp(this.state.email, this.state.password, this.state.passwordConfirm, this.state.inviteCode, {tz:timezone.name()})
                .then((result) => {
                })
                .catch((error) => {
                    this.setState({error:String(error),password:'', passwordConfirm:''});
                    console.error(error);
                    //window.alert('error: ' + error);
                })
                .then(()=>{ //Always
                    this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:false});
                });
        }

		event.preventDefault();
	}
	
	isValid(submit, blur) {
		var valid = true;

		this.setState({emailError:'',passwordError:'', passwordConfirmError:'', inviteCodeError:''});

		if((this.state.email).length === 0){
            if(submit) {
			    this.setState({emailError:this.props.t('Please enter your email address')});
            }
            valid = false;
		}

		if(this.state.email.length > 0 && !Utils.validateEmail(this.state.email)){
            if(submit) {
			    this.setState({emailError:this.props.t('Invalid email address')});
            }
            valid = false;
		}

		if((this.state.password).length === 0){
            if(submit) {
			    this.setState({passwordError:this.props.t('Please choose your password')});
            }
            valid = false;
		}

		if(0 < this.state.password.length && this.state.password.length < AuthUtils.minPasswordLength){
			this.setState({passwordError:this.props.t('Password must be at least {{length}} characters', {length: AuthUtils.minPasswordLength})});
            valid = false;
		}

		if((this.state.passwordConfirm).length === 0){
            if(submit) {
			    this.setState({passwordConfirmError:this.props.t('Please confirm your password')});
            }
            valid = false;
		}

		if((this.state.password.length>0 && this.state.passwordConfirm.length>0) && this.state.password!=this.state.passwordConfirm){
			this.setState({passwordConfirmError:this.props.t('This field should be identical to the password field')});
            valid = false;
		}

		if((this.state.inviteCode).length === 0){
            if(submit) {
			    this.setState({inviteCodeError:this.props.t('Please enter invite code')});
            }
            valid = false;
		}


        this.setState({valid:valid});

		return valid;	
	}


    render() {
        var t = this.props.t; 
        return (
            <SignUp valid={this.state.valid}
                    email={this.state.email}
                    password={this.state.password}
                    passwordConfirm={this.state.passwordConfirm}
                    inviteCode={this.state.inviteCode}
                    onSubmit={this.onSubmit}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                    onPasswordConfirmChange={this.onPasswordConfirmChange}
                    onInviteCodeChange={this.onInviteCodeChange}
                    onBlur={this.onBlur}
                    emailError={this.state.emailError}
                    passwordError={this.state.passwordError}
                    passwordConfirmError={this.state.passwordConfirmError}
                    inviteCodeError={this.state.inviteCodeError}
                    error={this.state.error} />
      );
  }
}

export default withRouter(connect()(withTranslation('authentication')(SignUpContainer)));
