import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Dropdown, Button, Grid, Header, Message, Label, Card, Popup, Icon, Checkbox, Modal} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'underscore';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';

import RewardGroupItem               from './RewardGroupItem.js';
import RewardBudgetModalContainer    from './modals/RewardBudgetModalContainer.js';
import DailyLimitModalContainer      from './modals/DailyLimitModalContainer.js';
import MonthlyLimitModalContainer    from './modals/MonthlyLimitModalContainer.js';
import QuarterlyLimitModalContainer    from './modals/QuarterlyLimitModalContainer.js';
import YearlyLimitModalContainer    from './modals/YearlyLimitModalContainer.js';
import EditRewardModalContainer      from './modals/EditRewardModalContainer.js';
import ResourceScalerModalContainer  from './modals/ResourceScalerModalContainer.js';
import DeletePromptContainer         from 'modules/misc/DeletePromptContainer.js';

//$ {(employee.yearlyClaims > 0 ) ? (employee.yearlyClaims/100).toFixed(2) : 0}

//Removed Fields
//<div>{t('Expected Budget Depletion')}</div>
//<div>{budgetEnd}</div>

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    title: {
        marginTop: spacings.base,
        fontSize: fontSizes.h4,
        color: colors.themeDarkerGrey,
        lineHeight: '1.5',
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        margin: spacings.base,
        padding: spacings.base,
    },
    actionButton: {
        ...buttons.base,
    },
    createAccountButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        ...buttons.base,
    },
    trashButton: {
        shadow: 'none',
        border: 'none',
        backgroundColor: '#fff',
        color: colors.warningRed,
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        padding: spacings.tiny,
        width: '60px',
    },
    approveButton: {
        shadow: 'none',
        border: 'none',
        backgroundColor: '#fff',
        color: colors.themeTeal,
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        padding: spacings.tiny,
        width: '60px',
    },
    budgetTotal: {
        color: colors.themeTeal,
        fontSize: fontSizes.h3,
    },
    budgetCurrency: {
        fontSize: fontSizes.body,
    },
    workOrderHeading: {
        color: colors.themeDarkerGrey,
    },
    pendingOrderRequests: {
        fontSize: fontSizes.bodyL
    },
    workOrderRow: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    toggleOpenReg: {
        marginLeft: spacings.micro,
    },
    btnStyleYes: {
        float: 'right',
        backgroundColor: colors.themeTeal,
        color: '#fff',
        margin: spacings.micro

    },
    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.themeDarkGrey,
        color: '#fff',
        margin: spacings.micro
    }
};


const Rewards = ({t, 
                  busy, 
                  rewards,
                  newRewards,
                  customRewards,
                  otherRewards,
                  workOrders,
                  companyAccount,
                  companyPlugin,
                  company,
                  tangoCardAccount,
                  tangoBalance,
                  budgetEnd,
                  starsEnd,
                  budgetCount,
                  currencyCode,
                  dailyLimit,
                  claimsLimit,
                  yearlyClaimsLimit,
                  quarterlyClaimsLimit,
                  resourceScaler,
                  legacyRewardGroups,
                  newRewardGroups,
                  otherRewardGroups,
                  showReplenishBudget,
                  showMonthlyLimitEdit,
                  showDailyLimitEdit,
                  showQuarterlyLimitEdit,
                  showYearlyLimitEdit,
                  showResourceScalerEdit,
                  showOtherCurrencyRewards,
                  showAddNewIncentive,
                  editReward,
                  currencies,
                  accountCreated,
                  onShowModal,
                  onShowOtherCurrencyRewards,
                  onToggleReward,
                  onDeleteWorkOrder,
                  deleteModalRef,
                  onEditCustomReward,
                  onRefresh,
                  onCreateAccount,
                  onChangeDropdown,
                  onPolicyHolderRewards,
                  policyHolderRewards,
                  approveWorkOrderModalOpen,
                  openApproveWorkOrderModal,
                  closeApproveWorkOrderModal,
                  approveWorkOrder}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.title}>{t('Budget')}</div>
            {localStorage.master==='true' &&
                <div style={style.section}> 
                    <label>{t('policy_holder_rewards')}
                    <Popup
                        trigger={<Icon name='question circle outline' />}
                        content={t('policy_holder_rewards_info').split('\n').map(line => (<p>{line}</p>))}
                        position='right center'
                    />
                    </label>
                    <Checkbox 
                        toggle 
                        style={style.toggleOpenReg} 
                        checked={policyHolderRewards === true} 
                        onChange={onPolicyHolderRewards} 
                        disabled={policyHolderRewards === true} />
                </div>         
            }   
            {Boolean(!accountCreated) &&
            <div style={style.section}>
                <div style={style.title}>{t('Budget Account')}</div>
                <br />
                <hr />
                <br />
                <div>{t('budget_account_description')}</div>
                <br />
                <br />
                <span>{t('currency_prompt')}</span>
                <Dropdown name='companyAccount.currencyId'
                          options={currencies}
                          value={companyAccount.currencyId || ''}
                          onChange={onChangeDropdown}
                          selection
                          closeOnChange/>
                <br />
                <br />
                <Message warning>{t('budget_account_message')}</Message>
                <br />
                <br />
                <Button style={style.createAccountButton} 
                        onClick={onCreateAccount} 
                        disabled={(companyAccount.currencyId || '')===''}>
                    {t('Create Account')}
                </Button>
            </div>
            }

            {Boolean(accountCreated) &&
            <div>
                <Grid columns={4} style={style.section}>
                    <Grid.Row stretched>
                        {
                            localStorage.master === 'true' &&
                            <Grid.Column>
                                <div>{t('Total Balance')}</div>
                                <div style={style.budgetTotal}>${budgetCount} <span style={style.budgetCurrency}>{currencyCode}</span></div>
                            </Grid.Column>
                        }
                        
                        <Grid.Column>
                            <div>{t('Daily Reward Points Limit')}</div>
                            <div>{t('Monthly Reward Claim Limit')}</div>
                            <div>{t('Quarterly Reward Claim Limit')}</div>
                            <div>{t('Yearly Reward Claim Limit')}</div>
                            <div>{t('Resource Cost Multiplier')}</div>
                            {localStorage.master==='true' && <div>{t('Tango Card Balance')}</div> }
                        </Grid.Column>
                        <Grid.Column>
                            <div>{dailyLimit  ? (dailyLimit + ' ' + t('pts')) : t('Unlimited')}</div>
                            <div>{claimsLimit ? ('$' + (claimsLimit/100)) : t('Unlimited')}</div>
                            <div>{quarterlyClaimsLimit ? ('$' + (quarterlyClaimsLimit/100)) : t('Unlimited')}</div>
                            <div>{yearlyClaimsLimit ? ('$' + (yearlyClaimsLimit/100)) : t('Unlimited')}</div>
                            <div>{resourceScaler ? ('x' + resourceScaler) : t('Default')}</div>
                            {localStorage.master==='true' && <div>$ { tangoBalance }</div> }
                        </Grid.Column>
                        <Grid.Column>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showDailyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showMonthlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showQuarterlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showYearlyLimitEdit', true)}}>{t('edit')}</a></div>
                            <div><a href='#' onClick={e=>{e.preventDefault(); onShowModal('showResourceScalerEdit', true)}}>{t('edit')}</a></div>
                            {localStorage.master==='true' && <div>&nbsp;</div> }
                        </Grid.Column>
                    </Grid.Row>
                    {Boolean(workOrders && workOrders.length>0) && [
                        <Grid.Row stretched key='row1'>
                            <Grid.Column style={style.pendingOrderRequests}>
                                {t('Pending Order Requests')}
                            </Grid.Column>
                        </Grid.Row>,
                        <Grid.Row stretched key='row2'>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Submitted')}
                            </Grid.Column>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Amount')}
                            </Grid.Column>
                            <Grid.Column style={style.workOrderHeading}>
                                {t('Currency')}
                            </Grid.Column>
                        <Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    ].concat(_.sortBy(workOrders, 'createdAt').reverse().map(workOrder => (
                        <Grid.Row stretched key={workOrder.id} style={style.workOrderRow}>
                            <Grid.Column>
                                {moment(workOrder.createdAt).format(t('mediumDate'))}
                            </Grid.Column>
                            <Grid.Column>
                                {workOrder.amount / 100}
                            </Grid.Column>
                            <Grid.Column>
                                {workOrder.currency}
                            </Grid.Column>                                 
                            <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation ={onDeleteWorkOrder} />
                            <Grid.Column>
                                <span>
                                    {Boolean(localStorage.master == "true") && 
                                        <Modal open={approveWorkOrderModalOpen} trigger={<Button style={style.approveButton} icon="check circle" onClick={openApproveWorkOrderModal} />}>
                                            <Modal.Header>{t('confirm_workorder_modal_header')}</Modal.Header>
                                            <Modal.Content>{t('confirm_workorder_modal_detail')}</Modal.Content>
                                            <Modal.Actions>
                                                <Button style={style.btnStyleNo} onClick ={closeApproveWorkOrderModal}>No</Button>
                                                <Button style={style.btnStyleYes} onClick ={()=>{approveWorkOrder(workOrder)}}>Yes</Button>
                                            </Modal.Actions>
                                        </Modal>
                                    }
                                    <Button style={style.trashButton} icon='trash' onClick={() => {deleteModalRef.current.showDeletePrompt(workOrder)}} />
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )))}
                </Grid>
                <Button style={style.actionButton} onClick={()=>onShowModal('showReplenishBudget', true)}>{t('Replenish Budget')}</Button>

                <div style={style.title}>{t('Incentives Catalog')}</div>
                <div style={style.section}>
                    <Card.Group>
                        {Boolean(newRewardGroups && newRewardGroups.length>0) && 
                            newRewardGroups.map(rewards => (
                                <RewardGroupItem key={rewards[0].id} 
                                                 rewards={rewards}
                                                 onToggleReward={onToggleReward}/>
                        ))}  
                    </Card.Group>
                    <div style={style.title}>{t('Legacy Incentives Catalog')}</div>
                    <Card.Group>
                        {Boolean(legacyRewardGroups && legacyRewardGroups.length>0) && 
                            legacyRewardGroups.map(rewards => (
                                <RewardGroupItem key={rewards[0].id} 
                                                 rewards={rewards} 
                                                 onToggleReward={onToggleReward}/>
                        ))}  
                    </Card.Group>
                    <div style={style.title}>{t('Other Currencies')}</div>
                    <Message warning>{t('other_currency_message')}</Message>
                    {showOtherCurrencyRewards ? (
                        <div>
                            <a href='#' onClick={e=>{e.preventDefault(); onShowOtherCurrencyRewards(false)}}>{t('hide_other_currency')}</a>
                            <Card.Group>
                                {Boolean(otherRewardGroups && otherRewardGroups.length>0) && 
                                    otherRewardGroups.map(rewards => (
                                        <RewardGroupItem key={rewards[0].id} 
                                                         rewards={rewards}
                                                         onToggleReward={onToggleReward}/>
                                ))}  
                            </Card.Group>
                        </div>
                    ) : (
                        <div>
                            <a href='#' onClick={e=>{e.preventDefault(); onShowOtherCurrencyRewards(true)}}>{t('see_other_currency')}</a>
                        </div>
                    )}
                </div>
                <div style={style.title}>{t('Custom Incentives')}</div>
                <Button style={style.actionButton} onClick={()=>onShowModal('showAddNewIncentive', true)}>{t('Add New Incentive')}</Button>
                <div style={style.section}>
                    <Card.Group>
                        {Boolean(customRewards && customRewards.length>0) && 
                            customRewards.map(reward => (
                                <RewardGroupItem key={reward.id} 
                                                 rewards={[reward]}
                                                 onEdit={onEditCustomReward}
                                                 onToggleReward={onToggleReward}/>
                        ))}  
                    </Card.Group>
                </div>
            </div>
            }


            {showReplenishBudget && 
                <RewardBudgetModalContainer 
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showReplenishBudget', false)} />
            }
            {showDailyLimitEdit && 
                <DailyLimitModalContainer 
                    value={dailyLimit ? parseInt(dailyLimit) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showDailyLimitEdit', false)} />
            }
            {showMonthlyLimitEdit && 
                <MonthlyLimitModalContainer 
                    value={claimsLimit ? claimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showMonthlyLimitEdit', false)} />
            }
            {showQuarterlyLimitEdit && 
                <QuarterlyLimitModalContainer 
                    value={quarterlyClaimsLimit ? quarterlyClaimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showQuarterlyLimitEdit', false)} />
            }
            {showYearlyLimitEdit && 
                <YearlyLimitModalContainer 
                    value={yearlyClaimsLimit ? yearlyClaimsLimit/100 : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showYearlyLimitEdit', false)} />
            }
            {showResourceScalerEdit && 
                <ResourceScalerModalContainer 
                    value={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showResourceScalerEdit', false)} />
            }
            {showAddNewIncentive &&
                <EditRewardModalContainer
                    resourceScaler={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onShowModal('showAddNewIncentive', false)} />
            }
            {Boolean(editReward) &&
                <EditRewardModalContainer
                    reward={editReward}
                    resourceScaler={resourceScaler ? parseFloat(resourceScaler) : null}
                    onRefresh={onRefresh}
                    onClose={()=>onEditCustomReward(null)} />
            }

        </div>
    )
);

export default withTranslation('rewards')(Rewards);
