import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Button, Dropdown, Message, Grid, GridColumn} from 'semantic-ui-react';
import moment from 'moment';

import Keys from '../../keys/keys.js';
import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';


var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    newEmployeesNotice: {
        cursor: 'pointer'
    },
    employeeTableWrapper: {
        overflowX: 'auto',
        marginTop: spacings.tiny,
    },
    employeeTable: {
        minWidth: '100%',
        textAlign: 'left',
        padding: spacings.small,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        color: colors.themeDarkerGrey,
        borderSpacing: '0px',
        borderCollapse: 'collapse',
    },
    employeeTableTR: {
    },
    employeeTableTD: {
        padding: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
    employeeTableTH: {
        padding: spacings.tiny,
        backgroundColor: colors.themeGrey,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        cursor: 'pointer',
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '16px',
    },
    trashIcon: {
        color: colors.warningRed,
    },
    footer: {
        color: colors.themeDarkerGrey,
        paddingTop: spacings.base,
        fontSize: fontSizes.bodyS,
    },
    footerButtons: {
        float: 'right',
    },
    footerButton: {
        backgroundColor: '#fff',
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
    sendIcon: {
    },
    sentIcon: {
        color: '#379f07'
    },
    grid : {
        maxWidth:'500px'
    }
};

var exports = [{
    data : 'userProfiles',
    label : 'User Profile Data'
},{
    data : 'activities',
    label : 'Activity Data'
},{
    data : 'activity_questions',
    label : 'Activity Question Data'
},{
    data : 'activity_answers',
    label : 'Activity Answer Data'
},{
    data : 'challenges',
    label : 'Challenge Data'
},{
    data : 'challenge_questions',
    label : 'Challenge Question Data'
},{
    data : 'challenge_answers',
    label : 'Challenge Answer Data'
},{
    data : 'objectives',
    label : 'Objective Data'
},{
    data : 'categories',
    label : 'Category Data'
},{
    data : 'category_levels',
    label : 'Category Level Data'
},{
    data : 'category_level_sections',
    label : 'Category Level Section Data'
},{
    data : 'subscriptions',
    label : 'Category Subscription Data'
},{
    data : 'records',
    label : 'Season Record Data'
},{
    data : 'seasons',
    label : 'Season Data'
},{
    data : 'leaderboards',
    label : 'Leaderboard Data'
}, {
    data: 'risk_scores',
    label : 'Risk Score Data'
}, {
    data: 'surveys',
    label : 'Surveys'
}, {
    data: 'survey_pages',
    label : 'Survey Pages'
}]

var projectExports = [{
    data : 'average_gems_per_user',
    label : 'Average Gems Per User Per Day'
},{
    data : 'announcements',
    label : 'Announcements Summary'
}]

const Exports = ({
        t ,
        loading,
        companyId,
        sessionToken
    }) => (
    loading ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.header}>{t('Exports')}</div>

            <form method="post" action={Keys.API_URL + '/organizations/'+companyId+'/data'}>
                <Grid verticalAlign={'middle'} style={style.grid}>
                    <Grid.Row columns={2}>
                        <Grid.Column>All Data</Grid.Column>
                        <Grid.Column><Button style={style.button} type="submit">Download</Button></Grid.Column>
                    </Grid.Row>
                </Grid>
                <input type='hidden' name='_sessionToken' value={sessionToken}/>
                
            </form>

            {exports.map(({data,label}, index) => (
                <form method="post" key={index} action={Keys.API_URL + '/organizations/'+companyId+'/exports'}>
                    <Grid verticalAlign={'middle'} style={style.grid}>
                        <Grid.Row columns={2}>
                            <Grid.Column>{label}</Grid.Column>
                            <Grid.Column><Button style={style.button} type="submit">Download</Button></Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <input type='hidden' name='data' value={data}/>
                    <input type='hidden' name='_sessionToken' value={sessionToken}/>
                    
                </form>
            ))}

            {projectExports.map(({data,label}, index) => (
                <form method="post" key={index} action={Keys.API_URL + '/organizations/'+companyId+'/projectExports'}>
                    <Grid verticalAlign={'middle'} style={style.grid}>
                        <Grid.Row columns={2}>
                            <Grid.Column>{label}</Grid.Column>
                            <Grid.Column><Button style={style.button} type="submit">Download</Button></Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <input type='hidden' name='data' value={data}/>
                    <input type='hidden' name='_sessionToken' value={sessionToken}/>
                    
                </form>
            ))}
        </div>
    )
);

export default withTranslation('exports')(Exports);
