import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Image, Icon, Button, Modal, Input, Dropdown, Search, Message} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';
import defaultAvatar from 'img/default-avatar.png';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'

var style = {
    ...styleDef,
    updateButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    columnContainer: {
        margin: spacings.base
    }
};

const AddAdminModal = ({t,
                        search,
                        employees,
                        companyRoles,
                        loadingEmployees,
                        onChangeSearch, 
                        onChangeAdminRole,
                        adminRole,
                        onClose, 
                        onSelectEmployee,
                        error,
                        onAddAdmin}) => (
    <Modal open style={style.content} onClose={onClose} size='large'>
        <Modal.Header>{t('Add Administrators')}</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                {
                    error &&
                    <Message error>{error}</Message>
                }
                <div style={style.columnContainer}>
                    <div>
                        <div>{t('employee_prompt')}</div>
                        <Search
                            loading={loadingEmployees}
                            onResultSelect={(e, data) => onSelectEmployee(data.result)}
                            onSearchChange={onChangeSearch}
                            results={employees}
                            value={search}
                        />
                        <br />
                        <br />
                        <div>{t('roles_prompt')}</div>
                        <Dropdown
                            style={style.roleDropdown}
                            options={companyRoles}
                            onChange={onChangeAdminRole}
                            value={adminRole}
                            selection
                            closeOnChange
                        />
                    </div>
                </div>

                <Button style={style.updateButton} onClick={onAddAdmin}>{t('Save')}</Button>
                <br />
                <br />
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('account')(AddAdminModal);
