import React from 'react';
import { withTranslation } from 'react-i18next';

import {Button, Form, Input, Grid, Header, Message, Label, Segment, Modal, Icon} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import Utils from 'utils/Utils.js';
import {colors, spacings, shadow, fontSizes, fonts} from 'style';

var style={
    photo:{
        width: 'auto',
        height:'15em'
    },
    video:{
        width: '15em',
        height:'15em'
    },
    column:{
        width: '20em'
    },
    videoContainer: {
        position: 'relative',
        display: 'inline-block',
        minHeight: '150px'
    },
    cameraButtonContainer: {
        position: 'absolute',
        left: '50%',
        bottom: '5px'
    },
    cameraButton: {
        position: 'relative',
        left: '-50%',
        margin: '0'
    },
    imageByteButtonGroup: {
        textAlign: 'center',
        height: '50px',
    },
    imageTypeButton: {
        backgroundColor: colors.themeTeal,
        color: '#fff',
        fontWeight: 'normal',
        display: 'inline-block',
        width: '200px',
        borderRadius: '100px',
        boxShadow: shadow,
        ...fonts.body,
        lineHeight: '1.5',
        marginLeft: spacings.base,
        marginRight: spacings.base,
    },
    imageTypeSearch: {
        display: 'inline-block',
        fontWeight: 'normal',
        boxShadow: shadow,
        ...fonts.body,
        borderRadius: '5px',
        lineHeight: '1.5',
        marginLeft: spacings.base,
        marginRight: spacings.base,
    },
    uploadIcon : {
        marginLeft: spacings.micro
    },
    flex: {
        display: "flex",
        gap: "1rem",
        justifyContent: "center"
     },
};



const ChoosePhoto =   ({t,
                        error, 
                        open, 
                        aspectRatio,
                        onChoosePhoto, 
                        onTakePhoto, 
                        supportsVideoCapture,
                        showVideoCapture, 
                        onCapturePhoto,
                        onConfirmPhoto, 
                        onClose, 
                        onCrop, 
                        onCropperReady, 
                        setFileSelector, 
                        setCropper, 
                        setVideo, 
                        setCanvas, 
                        onFileSelect, 
                        photo, 
                        onEnterPressed,
                        isLoading,
                        croppedPhoto,
                        onSelectOriginalPhoto}) => (
        <Modal closeIcon open={open} size="large" onClose={onClose} onUnmount={Utils.fixBody}>
        <Modal.Header>{t('Select Photo')}</Modal.Header>
        <Modal.Content>
            {error.length > 0 &&
                <Message negative>{error}</Message>
            }
            <br />

            <input ref={setFileSelector} type="file" accept="image/*;capture=camera" style={{display:'none'}} onChange={onFileSelect} />
            <div style={style.imageByteButtonGroup}>
                <Button type="button" style={style.imageTypeButton} onClick={onChoosePhoto}>{t('Choose Photo')}<Icon name='upload' iconposition = 'right' style={style.uploadIcon} /></Button>
                <Label circular size='huge' style={{backgroundColor: '#fff'}}> Or </Label>
                <Input placeholder = 'choosephoto.com' style={style.imageTypeSearch} icon='cloud download' loading={isLoading} iconposition= 'right' onKeyPress={onEnterPressed} />
            </div>

            {(photo||showVideoCapture) &&
            <Segment>
                <Grid textAlign="center" verticalAlign="top">
                    {showVideoCapture &&
                    <Grid.Column style={style.column}>
                        <Header>{t('Camera:')}</Header>
                        <span style={style.videoContainer}>
                            <video style={style.video} ref={setVideo} id="video" width="1024" height="1024" autoPlay></video>
                            <div style={style.cameraButtonContainer}>
                                <Button size="small" circular color='teal' icon="camera" onClick={onCapturePhoto} style={style.cameraButton}></Button>
                            </div>
                        </span>
                        <canvas ref={setCanvas} id="canvas" width="1024" height="1024" style={{display:'none'}}></canvas>
                    </Grid.Column>
                    }
                    <Grid.Column style={style.column}>
                        <Header>{t('Crop Photo:')}</Header>
                        {photo &&
                            <Cropper ref={setCropper} src={photo} style={style.photo} aspectRatio={aspectRatio} guides={false} zoomable={false} rotatable={false} dragMode='none' responsive={true} autoCrop={true} cropend={onCrop} ready={onCropperReady} viewMode={2}  />
                        }
                    {!photo &&
                        <div style={{...style.photo, width:'15em', backgroundColor:'grey'}}></div>
                    }
                    </Grid.Column>
                    <Grid.Column style={style.column}>
                        <Header>{t('Result Photo:')}</Header>
                        {croppedPhoto &&
                            <img src={croppedPhoto} style={style.photo} />
                        }
                        {!croppedPhoto &&
                            <div style={{...style.photo, width:'15em', backgroundColor:'grey'}}></div>
                        }
                    </Grid.Column>
                </Grid>
            </Segment>
            }
            {croppedPhoto &&
                <div style={style.flex}>
                    <div style={style.imageByteButtonGroup}>
                        <Button type="button" style={style.imageTypeButton} onClick={onSelectOriginalPhoto}>{t('select_original_photo')}</Button>
                    </div>
                    <div style={style.imageByteButtonGroup}>
                        <Button type="button" style={style.imageTypeButton} onClick={onConfirmPhoto}>{t('Confirm')}</Button>
                    </div>
                    
                </div>
            }
            

        </Modal.Content>
        </Modal>
  );

export default withTranslation('misc')(ChoosePhoto);
