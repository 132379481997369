import React, { Component } from 'react';
import LeadboardSelectModal from './LeadboardSelectModal.js';

class LeadboardSelectModalContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LeadboardSelectModal {...this.props} />
        );
    }
}


export default LeadboardSelectModalContainer;
