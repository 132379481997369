import {SET_USER_TOKEN,
        SET_USER_LOGGED_IN,
        SET_USER_COMPANY,
        SET_USER_PROFILE,
        SET_USER_ROLES,
        SET_ROLE_ALLOWED_ROUTES,
        SET_USER_RANK,
        SET_USER_HEALTH,
        SET_USER_BADGE_NUMBERS,
        SET_USER_ANNOUNCEMENTS_BADGE_DATE} from 'actions/types';



const initialState = {loggedIn:false, token:'', userProfile:null, company:null, badgeNumbers:{}, roles: null};
export default (state = initialState, action) => {
    switch(action.type) {
        case SET_USER_TOKEN: 
            return {...state, token:action.token};
        case SET_USER_LOGGED_IN: 
            return {...state, loggedIn:action.loggedIn};
        case SET_USER_COMPANY:
            return {...state, company:action.company};
        case SET_USER_PROFILE: 
            return {...state, userProfile:action.userProfile};
        case SET_USER_ROLES:
            return {...state, roles: action.roles};
        case SET_ROLE_ALLOWED_ROUTES:
            return {...state, roleAllowedRoutes: action.routes}
        default: 
            return state;
    }
}

export const getIsLoggedIn = state => state.loggedIn;
export const getUserProfileWithDefaults = state => {
    var userProfile = {firstName:'', lastName:'', birthDate:'', team:null, company:null};

    if(state.userProfile) {
        userProfile = {...userProfile, ...state.userProfile}; 
    }
    if(userProfile.team===null) {
        userProfile.team = {name:''};
    }
    if(userProfile.company===null) {
        userProfile.company = {name:''};
    }

    return userProfile;
}
export const getUserProfileCompanyAnnouncementsBadgeDate = state => {
    var result = null;
    if(state.userProfile) {
        result = state.userProfile.companyAnnouncementsBadgeDate;
    }
    return result;
}

export const getUserRoles = state => {
    var roles = [];

    if (state.user.roles) {
        roles = state.user.roles.map((role) => { return role.lc_name; })
    }
    return roles;
}