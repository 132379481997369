import {colors, fontSizes, buttons, spacings} from 'style';

var style = {
    content: {
        textAlign: 'center',
        padding: spacings.xlarge,
    },
    title: {
        fontSize: fontSizes.h3,
        color: '#fff',
        lineHeight: '1.5',
    },
    button: {
        ...buttons.long,
        padding: '.78571429em 1.5em',
        cursor: 'pointer',
        backgroundColor: '#fff',
        color: colors.themeDarkerGrey,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0',
        marginBottom: '0',
    },
};

export default style;
