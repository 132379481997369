import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import DateUtils from 'utils/DateUtils.js';

import RewardsV2 from './RewardsV2.js';

const msPerYear = 1000*60*60*24*365;
const CurrencyCodes = {
    1: 'CAD',
    2: 'USD'
};

const Currencies = Object.keys(CurrencyCodes).map(id => ({key:id, value:id, text:CurrencyCodes[id]}))

class RewardsV2Container extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      showReplenishBudget:false,
                      showGiftCardLimitEdit:false, 
                      showMonthlyLimitEdit:false, 
                      showYearlyLimitEdit:false, 
                      showQuarterlyLimitEdit:false, 
                      showDailyLimitEdit:false,
                      showResourceScalerEdit:false,
                      showOtherCurrencyRewards:false,
                      showAddNewIncentive:false,
                      showAddRewardAction:false,
                      newRewardV2Category:null,
                      editReward:null,
                      editRewardV2: null,
                      editRewardAction: null,
                      rewardCategory: null,
                      rewards:[],
                      accountCreated:true,
                      policyHolderRewards: false,
                      approveWorkOrderModalOpen: false,
                      currentTab: 'rewards',
                      loadingRewards: true,
                      searching: false,
                      search: null,
                      incentiveType: null
                      };
        this.onShowModal = this.onShowModal.bind(this);
        this.onShowOtherCurrencyRewards = this.onShowOtherCurrencyRewards.bind(this);
        this.onToggleReward = this.onToggleReward.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onDeleteWorkOrder = this.onDeleteWorkOrder.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onEditCustomReward = this.onEditCustomReward.bind(this);
        this.onCreateAccount = this.onCreateAccount.bind(this);
        this.deleteModalRef = React.createRef();
        this.onChangeTab = this.onChangeTab.bind(this);
        this.onEditReward = this.onEditReward.bind(this);
        this.onEditRewardV2 = this.onEditRewardV2.bind(this);
        this.onEditRewardAction = this.onEditRewardAction.bind(this);
        this.onToggleRewardOption = this.onToggleRewardOption.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.doSearchDebounced = _.debounce(this.doSearch, 300)
        this.onChangeIncentiveType = this.onChangeIncentiveType.bind(this);
        
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData(true);
    }


    loadData(showSpinner) {
        showSpinner && this.setState({busy:true});
        Promise.all([            
            DatabaseService.getRewardCategories({
                companyPlugin : true, companyAccount:true, balance : true, 
                ...(this.state.incentiveType && {type: this.state.incentiveType})
            }),
            DatabaseService.getCompanyPluginAttribute({tag:'rewards', name:'policyHolder'})
        ]).then(([rewardResult, pluginAttributeResult]) => {
            var companyAccount = rewardResult.companyAccount || {};
            var tangoCardAccount = rewardResult.balance || {exists:false};

            var budgetEnd = this.props.t('end_placeholder');
            if(tangoCardAccount.estimatedEnd) {
                var budgetDate = new Date(tangoCardAccount.estimatedEnd);
                if(budgetDate.getTime() < Date.now() + 2*msPerYear) {
                    budgetEnd = moment(budgetDate).format(this.props.t('mediumDate') || DateUtils.formatDisplay);
                }
            }
            var starsEnd = this.props.t('end_placeholder');
            if(rewardResult.estimatedCompanyStarsEnd){
                var starsDate = new Date(rewardResult.estimatedCompanyStarsEnd);
                if(starsDate.getTime() < Date.now() +  2*msPerYear) {
                    starsEnd = starsDate;
                }
            }


            var companyPluginAttributes  = Utils.deref(rewardResult, 'companyPlugin.companyPluginAttributes') || {};
            console.log('companyPluginAttributes', companyPluginAttributes);
            var dailyLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'dailyLimit'}),  'value');
            var quarterlyClaimsLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'quarterlyClaimsLimit'}),  'value');
            var yearlyClaimsLimit  = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'yearlyClaimsLimit'}),  'value');
            var claimsLimit = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'claimsLimit'}), 'value');
            var resourceScaler = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'resourceScaler'}), 'value');
            var giftcardLimit = Utils.deref(_.findWhere(companyPluginAttributes, {name: 'giftcardLimit'}), 'value');
            var budgetCount = (tangoCardAccount.balance / 100) || 0;

            var currencyCode = CurrencyCodes[companyAccount.currencyId] || "???";

            const tangoBalance = (rewardResult.tangoBalance ? rewardResult.tangoBalance.balance : 0) / 100;

            this.setState({
                    rewards: {
                        rewardCategories: rewardResult.rewardCategories || [],
                        incentives: rewardResult.rewards || []
                    },
                    workOrders: rewardResult.workOrders,
                    companyAccount,
                    companyPlugin: rewardResult.companyPlugin,
                    company: rewardResult.company,
                    tangoCardAccount,
                    tangoBalance,
                    budgetEnd,
                    starsEnd,
                    budgetCount,
                    currencyCode,
                    dailyLimit,
                    giftcardLimit,
                    claimsLimit,
                    quarterlyClaimsLimit,
                    yearlyClaimsLimit,
                    resourceScaler,
                    accountCreated:_.isFinite(companyAccount.currencyId),
                    policyHolderRewards: pluginAttributeResult.companyPluginAttribute && pluginAttributeResult.companyPluginAttribute.value === "1" ? true : false,
                    loadingRewards: false,
                });    
        })
        .catch((error)=> {
            console.error('ERROR:');
            console.error(error);
        })
        .then(()=>{
            showSpinner && this.setState({busy:false});
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
        });
    }

    onShowModal(modal, en, rest={}) {
        this.setState({[modal]:en, ...rest});
    }
    onShowOtherCurrencyRewards(en) {
        this.setState({showOtherCurrencyRewards:en});
    }
    
    onToggleReward(reward) {
        // const updatedReward = {...reward, enabled:false};
        // const updatedIncentives = this.state.rewards.incentives.map(c => c.id===reward.id ? updatedReward : c  );
        // this.setState({rewards: {...this.state.rewards, incentives:updatedIncentives}})
        // const method = updatedReward.enabled ? 'updateReward' : 'removeReward';
        // DatabaseService[method]({rewardId: reward.id}).catch(error => {
        //     console.error(error);
        //     window.alert('Error updating reward, see logs');
        // })
    }

    onChangeDropdown(event, {value, name}) {
        this.setState(state => (Utils.set({...state}, name, value)));
    }

    onDeleteWorkOrder(deleteYes, workOrder) {
        if(deleteYes){
            var workOrders = this.state.workOrders;
            workOrders = workOrders.filter(wo => wo.id!==workOrder.id);
            this.setState({workOrders});
            DatabaseService.removeFund(workOrder)
            .then(result => {
            })
            .catch(error => {
                console.error(error);
                window.alert('Error removing work order: check logs');
            });
        }
    }
    openApproveWorkOrderModal() {
        this.setState({approveWorkOrderModalOpen: true})
    }
    closeApproveWorkOrderModal() {
        this.setState({approveWorkOrderModalOpen: false})
    }
    approveWorkOrder(workOrder) {
        if (workOrder) {
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.approveFund({workorderId: workOrder.id, paid: true})
            .then((result)=>{
                this.closeApproveWorkOrderModal()
                this.loadData(false);
            }).catch((error)=>{
                console.error(error);
                window.alert('Error approving work order: check logs');
                this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
            })
        }       
    }

    onCreateAccount() {
        if(this.state.companyAccount && _.isFinite(this.state.companyAccount.currencyId)) {
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.createCompanyAccount({currencyId : this.state.companyAccount.currencyId})
                .then(results => {
                    this.loadData(true);
                })
                .catch(error => {
                    console.error(error);
                    window.alert('Error removing work order: check logs');
                })
                .then(result => {
                    this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                });
        }
    }

    onRefresh() {
        this.loadData(false);
    }

    onEditCustomReward(reward) {
        // this.setState({editReward:reward});
    }

    onChangeSearch(e) {
        const search = e.target.value
        this.setState({search}, this.doSearchDebounced);
    }

    onChangeIncentiveType(event, {value}) {
        this.setState({incentiveType: value}, this.doSearchDebounced)
    }

    async doSearch() {
        this.setState({loadingRewards:true});
        try {
            const result = await DatabaseService.getRewards({
                ...this.state.search && ({query: this.state.search}),
                ...(this.state.incentiveType && {type: this.state.incentiveType})
            })
            this.setState({rewards: { incentives: result.rewards, rewardCategories: [] }})
        } catch(err) {
            window.alert(err.message || 'An error occurred - check logs');
            console.error(err);
        } finally {
            this.setState({loadingRewards:false});
        }
    }

    onPolicyHolderRewards = (event) => {
        var confirmation = window.confirm('Are you sure?\nThis action cannot be reverted.');
        this.setState({policyHolderRewards: !this.state.policyHolderRewards});
        
        //user has selected yes
        if (confirmation) {
            //update policyholder value
            var pluginAttribute = {
                name: 'policyHolder', 
                tag: 'rewards', 
                value: !this.state.policyHolderRewards, 
                type: 3
            }

            DatabaseService.updateCompanyPluginAttributeValue(pluginAttribute).then((result) => {                
                console.log(this.state.policyHolderRewards);
            })            
        }     
    }

    onChangeTab(tab) {
        this.setState({currentTab: tab});
        this.reloadRewards(tab)
    }

    async reloadRewards(tab) {
        this.setState({loadingRewards:true})
        let updatedRewards = {
            rewardCategories: [],
            incentives: [],
        }
        let res = []
        if (tab == 'catalog') {
            res = await DatabaseService.getRewards()
            updatedRewards.incentives = res.rewards
            this.setState({rewards: updatedRewards, search: '', loadingRewards:false})
        } else {
            res = await DatabaseService.getRewardCategories()
            updatedRewards.rewardCategories = res.rewardCategories
            this.setState({rewards: updatedRewards, loadingRewards:false})
        } 
    }

    onEditReward(reward) {
        this.setState({editReward:reward});
    }

    onEditRewardV2(reward) {
        this.setState({editRewardV2:reward});
    }

    onEditRewardAction(rewardAction, rewardCategory) {
        this.setState({editRewardAction:rewardAction, rewardCategory});
    }

    async onToggleRewardOption(rewardAction, rewardOption) {       

        let rewardActionResources = rewardAction.resources
        if (rewardAction.rewardOptions.length > 0) {
            for(let i=0; i<rewardAction.rewardOptions.length; i++) {
                if (!rewardOption.enabled && rewardAction.rewardOptions[i].id == rewardOption.id) {
                    rewardActionResources = rewardOption.resources
                    break
                } else if (rewardAction.rewardOptions[i].enabled && rewardAction.rewardOptions[i].id != rewardOption.id) {
                    rewardActionResources = rewardAction.rewardOptions[i].resources
                    break
                }
                
            }
        }

        const updatedRewardAction = {
            id: rewardAction.id,
            resources: rewardActionResources,
            rewardOptions: [{
                id: rewardOption.id,
                rewardId: rewardOption.rewardId,
                enabled: !rewardOption.enabled
            }]
        }

        try {
            await DatabaseService.updateRewardAction(updatedRewardAction)
            // Update UI
            let categoryToUpdate = null
            let categoryIndex = null
            const rewardCategories = [...this.state.rewards.rewardCategories]
            for(let i=0; i<rewardCategories.length; i++) {
                const currentCategory = rewardCategories[i]
                if (currentCategory.id == rewardAction.rewardCategoryId){
                    categoryToUpdate = currentCategory
                    categoryIndex = i
                    break
                }
            }

            if (!categoryToUpdate)
                throw new Error("Categoy not found")
            
            const updatedRewardActions = categoryToUpdate.rewardActions.map(action => ({
                ...action,
                ...(action.id == rewardAction.id && {
                    rewardOptions : action.rewardOptions.map(option => ({
                        ...option,
                        ...(option.id == rewardOption.id && {enabled: !option.enabled})
                    }))
                })
            }))
            rewardCategories[categoryIndex] = {...categoryToUpdate, rewardActions: updatedRewardActions}
            this.setState({rewards: {...this.state.rewards, rewardCategories}})
        } catch(err) {
            window.alert(err.message || 'An error occurred - check logs');
            console.error(err)
        }
    }

    render() {
        return (
            <RewardsV2 busy={this.state.busy}
                     {...this.state}
                     currencies={Currencies}
                     onShowModal={this.onShowModal}
                     onShowOtherCurrencyRewards={this.onShowOtherCurrencyRewards}
                     onToggleReward={this.onToggleReward}
                     onChangeTab={this.onChangeTab}
                     onChangeSearch={this.onChangeSearch}
                     onDeleteWorkOrder={this.onDeleteWorkOrder}
                     onRefresh={this.onRefresh}
                     onEditCustomReward={this.onEditCustomReward}
                     onEditReward={this.onEditReward}
                     onEditRewardV2={this.onEditRewardV2}
                     onEditRewardAction={this.onEditRewardAction}
                     onToggleRewardOption={this.onToggleRewardOption}
                     onChangeIncentiveType={this.onChangeIncentiveType}
                     onChangeDropdown={this.onChangeDropdown}
                     onCreateAccount={this.onCreateAccount}
                     onPolicyHolderRewards={this.onPolicyHolderRewards}
                     deleteModalRef = {this.deleteModalRef}
                     openApproveWorkOrderModal={this.openApproveWorkOrderModal.bind(this)}
                     closeApproveWorkOrderModal={this.closeApproveWorkOrderModal.bind(this)}
                     approveWorkOrder={this.approveWorkOrder.bind(this)}/>
      );
  }
}

export default withRouter(connect()(withTranslation('rewards')(RewardsV2Container)));
