import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {Button, Form, Input, Grid, Header, Message, Label, Segment, Modal, Checkbox} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import Utils from 'utils/Utils.js';
import {colors, spacings, shadow, fontSizes, fonts} from 'style';
// Generic prompt used for deleting content.
// Accepts an argument on launch and passes it to callback on delete selected

var style = {
    btnStyleYes: {
        float: 'right',
        backgroundColor: 'rgb(40, 184, 190)',
        color: '#fff',
        margin: spacings.small,
    },

    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.warningRed,
        color: '#fff',
        margin: spacings.small,
    },

    chkBoxes: {
        margin: spacings.micro,
        fontSize: fontSizes.h4,
    },
    
}

class SelectCSVColumn extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen : false,
            currentList: []
        };
    }

    confirm = () =>{
        this.props.onConfirmation(this.state.currentList);
        this.closePrompt()
    }

    showPrompt = () => {
        this.setState({
            isOpen : true,
            currentList: []
        });
    }

    closePrompt = () => {
        this.setState({isOpen:false});
    }

    onChange =(event, {value}) =>{
        var tempList = this.state.currentList;

        if(tempList.includes(value)){
            var index = tempList.indexOf(value);
            tempList.splice(index, 1);
        } else {
            tempList.push(value);
        }
        console.log(tempList);
        this.setState({currentList: tempList});

    }

    render() {
        if(this.state.isOpen == false){
            return null;
        } 
        return (
            <div>
                 <Modal closeIcon open={this.state.isOpen } size = 'small' onClose = {this.closePrompt} onUnmount={Utils.fixBody}>
                         <Modal.Header>Select Columns</Modal.Header>
                         <Modal.Content style = {style.modalContent}> 
                         {Boolean(this.props.columns) && this.props.columns.map(column => (
                            <div key={column.key}>
                              <Checkbox style = {style.chkBoxes} label={column.title} value = {column.key} onChange = {this.onChange} />  
                            </div>
                        ))}

                            
                           

                        </Modal.Content>
                        <Modal.Actions>
                            <Button style={style.btnStyleYes} onClick ={this.confirm}>Yes</Button>
                            <Button style={style.btnStyleNo} onClick ={this.closePrompt}>No</Button>
                        </Modal.Actions>
                </Modal>

            </div>
            
      );
    }
}
export default SelectCSVColumn;