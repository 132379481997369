import DatabaseService from 'services/DatabaseService.js';
import {SET_COMPANIES, 
        ADD_COMPANY,
        ADD_COMPANIES} from 'actions/types';
import {getCompany, 
        getCompanies, 
        isListLoaded}  from 'reducers/companies.js';
import {getCompanyWithNotifications} from 'reducers';

//Action Creators
export const setCompanies = (companies) => {
    return { type:SET_COMPANIES, companies:companies };
}

export const addCompany = (company) => {
    return { type:ADD_COMPANY, company:company };
}

export const addCompanies = (companies) => {
    return { type:ADD_COMPANIES, companies:companies };
}



//Thunks
export const fetchCompanies = (params, force) => (dispatch, getState) => {
    if(!force) {
        var state = getState();
        if(isListLoaded(state.companies)) {
            return Promise.resolve(getCompanies(state.companies));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.getOrganizations(params).then(
            function(result){ 
                var companies = result.companies || [];
                for(var i in companies) {
                    if(companies[i].id == localStorage.ownerCompanyId) {
                        var item = companies.splice(i,1);
                        companies.unshift(item[0])
                    }
                }
                dispatch(setCompanies(companies));
                resolve(result.companies);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}

