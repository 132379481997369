import React from 'react';
import { withTranslation } from 'react-i18next';
import {Button, Dropdown, Checkbox, Modal, Input, Message} from 'semantic-ui-react';
import SortIcon from 'modules/misc/SortIcon.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';

var style = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: colors.themeGrey,
    },
    employeeTableWrapper: {
        marginTop: spacings.tiny,
    },
    employeeTable: {
        minWidth: '100%',
        textAlign: 'left',
        padding: spacings.small,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        color: colors.themeDarkerGrey,
        borderSpacing: '0px',
        borderCollapse: 'collapse',
    },
    employeeTableTR: {
    },
    employeeTableTD: {
        padding: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
    employeeTableTH: {
        padding: spacings.tiny,
        backgroundColor: colors.themeGrey,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        cursor: 'pointer',
    },
    footer: {
        color: colors.themeDarkerGrey,
        paddingTop: spacings.base,
        fontSize: fontSizes.bodyS,
    },
    footerButtons: {
        float: 'right',
    },
    footerButton: {
        backgroundColor: '#fff',
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
};


const AnnouncementDetailsModal = ({ t, 
                                 busy,
                                 employees,
                                 points,
                                 resources,
                                 announcementType,
                                 totalEmployees,
                                 start,
                                 end,
                                 sortField,
                                 sortAsc,
                                 atStart,
                                 atEnd,
                                 sortBy,
                                 onNextPage,
                                 onClose,
                               }) => (
    <Modal open style={style.content} onClose={onClose} size='large'>
        <Modal.Content>
            <Modal.Header>{t('Completion')}</Modal.Header>
            <Modal.Description>
                <div style={style.employeeTableWrapper}>
                <table style={style.employeeTable} cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr style={style.employeeTableTR}>
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {t('Email')}
                            </th>
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {t('Name')}
                            </th>
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {t('Position')}
                            </th>
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {t('Team')}
                            </th>
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {t('Status')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map(employee=>(
                            <tr style={style.employeeTableTR} key={employee.id}>
                                <td style={style.employeeTableTD}>{employee.companyEmployee.email}</td>
                                <td style={style.employeeTableTD}>{employee.companyEmployee.fullName}</td>
                                <td style={style.employeeTableTD}>{employee.companyEmployee.position}</td>
                                <td style={style.employeeTableTD}>{employee.companyEmployee.team}</td>
                                <td style={{...style.employeeTableTD, textAlign: 'center'}}>
                                    {Boolean(announcementType == 2) ? (Boolean(employee.userChallenge && employee.userChallenge.state===1) ? t('Completed') : t('Not Completed')) : (Boolean(employee.seen) ? t('Seen') : t('Unseen'))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                <div style={style.footer}>
                    <span style={style.footerButtons}>
                        <Button disabled={atStart} onClick={()=>onNextPage(false)}>{t('Previous')}</Button>
                        <Button disabled={atEnd}   onClick={()=>onNextPage(true) }>{t('Next')}</Button>
                    </span>
                    <div style={{...(busy && {visibility: 'hidden'})}}>{t('table_footer', {start:start, end:end, total:totalEmployees})}</div>
                </div>
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('announcements')(AnnouncementDetailsModal);
