import React, { Component, useState, useEffect, useRef, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {Button, Popup, Icon, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';
import moment from 'moment';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons,fonts} from 'style';
import AddSegmentModalContainer from './modals/AddSegmentModalContainer.js';
import PushSegmentModalContainer from './modals/PushSegmentModalContainer.js';
import EditAnnouncementModalContainer from 'modules/announcements/modals/EditAnnouncementModalContainer.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';
import {List} from 'components/list'
import {getSegments} from 'reducers/segments';
import {fetchSegments} from 'actions/segments';

import {getJobs} from 'reducers/jobreporting';
import {fetchJobs} from 'actions/jobreporting';


var style = {
    loaderContainer: {
        height: '100%',
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
        marginTop: spacings.base
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    }
};

const segmentFields = [
    {
        name: 'ID',
        key: 'id',
        default: false,
        searchable: false,
        master: true
    },
    {
        name: 'Name',
        key: 'name',
        default: true,
        searchable: true
    },
    {
        name: 'Dynamic',
        key: 'dynamic',
        tooltip: 'If the segment accepts new members',
        default: true
    },
    {
        name: 'Dynamic Expiry',
        key: 'expiry',
        tooltip: 'Date the segment stops accepting new members',
        default: true
    },
    {
        name: 'Members',
        key: 'members',
        default: true
    },
    {
        name: 'Created At',
        key: 'createdAt',
        type: 'date',
        default: true
    }
]

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

const SegmentsContainer = ({ t, dispatch, fetchSegments, fetchJobs }) => {
    const [busy, setBusy] = useState(false)
    const [channels, setChannels] = useState([])
    const [recurrences, setRecurrences] = useState([])
    const [addSegmentModalOpen, setAddSegmentModalOpen] = useState(false)
    const [segmentDetail, setSegmentDetail] = useState()
    const [segmentForAnnouncement, setSegmentForAnnouncement] = useState()
    const [responseMessage, setResponseMessage] = useState()
    const [responseAction, setResponseAction] = useState()
    const [error, setError] = useState()
    const deleteModalRef = createRef();
    const downloadLink = useRef()
    const failedEmailsLink = useRef()

    const iterableExport = async (segment) => {
        let result = await DatabaseService.postSegmentIterableExport(segment)
        if (result?.invalidEmails?.length) {
            let text = result?.invalidEmails.join('\n')
            if (navigator && navigator.msSaveBlob) { // IE 10+
                var exportedFilename = failedEmailsLink.current.download;
                var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                navigator.msSaveBlob(blob, exportedFilename);
            } else {
                var encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
                failedEmailsLink.current.download = `${segment.name} - iterable failed emails.csv`
                failedEmailsLink.current.setAttribute("href", encodedUri);
            }
            onResponseMessage(`${result?.invalidEmails?.length} emails were rejected by iterable. You can download the full list`, null, {text: 'here', invoke: downloadIterableFailedEmails})
        } else {
            onResponseMessage(`All emails are uploaded to iterable`)
        }
    }

    const downloadIterableFailedEmails = () => {
        if (navigator && navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            failedEmailsLink.current.click(); 
        }
    }

    const loadData = async () => {
        try {
            setBusy(true)
            if(localStorage.inviteCode == 'aimhigher') {
                //Not Used
                let segments = [
                    {
                        id : 1,
                        name: "Baby Boomer Women",
                        value : 38,
                        progressValue : 0,
                        tags : ["Female", "Between 35 and 55"],
                        members : 2370
                    },
                    {
                        id : 2,
                        name: "Millennials",
                        value : 43,
                        progressValue : 0,
                        tags : ["Between 20 and 30"],
                        members : 2405
                    },
                    {
                        id : 3,
                        name: "San Fran Team",
                        value : 56,
                        progressValue : 0,
                        tags : ["San Francisco"],
                        members : 220
                    },
                    {
                        id : 4,
                        name: "Physically Active Men",
                        value : 71,
                        progressValue : 0,
                        tags : ["Male", "Step Challenge > 1000 Steps"],
                        members : 2160
                    },
                    {
                        id: 5,
                        name: "Canadian Offices",
                        value : 21,
                        progressValue : 0,
                        tags : ["Vancouver", "Ottawa"],
                        members : 1120
                    },
                    {
                        id : 6,
                        name: "Future Nutritionists",
                        value : 38,
                        progressValue : 0,
                        tags : ["Completed Nutrition Challenge"],
                        members : 1980
                    },
                    {
                        id : 7,
                        name: "Disengaged",
                        value : 38,
                        progressValue : 0,
                        tags : ["Engagement < 30%"],
                        members : 567
                    },
                    {
                        id : 8,
                        name: "Individuals at risk",
                        value : 75,
                        progressValue : 0,
                        tags : ["HRA < 30%"],
                        members : 278
                    },
                    {
                        id : 9,
                        name: "Top 20%",
                        value : 18,
                        progressValue : 0,
                        tags : ["Top 20% on Leaderboard"],
                        members : 809
                    },
                ]
            }
            var queueParams = {
                channels : true,
                recurrences : true,
                limit : 1,
                sortField : 'sendAt',
                sortOrder : 'ascending'
            };
            var queueResult = await DatabaseService.getAnnouncements(queueParams);
            setChannels(queueResult.channels)
            setRecurrences(queueResult.recurrences)
        }catch(e){
            console.error(e);
        }finally{
            setBusy(false)
        }
    }

    const onResponseMessage = (message, error, responseAction) => {
        setResponseMessage(message)
        setError(error)
        setResponseAction(responseAction)
    }

    const resetMessage = () => {
        setResponseAction()
        setResponseMessage()
        setError()
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true));
        loadData()
    }, [])

    const getData = async (params) => {
        let results = await fetchSegments(params)
        let jobs = await fetchJobs({...params, 'like.and': true, 'like.jobType': 'build-segment', 'like.status': 'Started'})
        jobs = jobs.data.map(job => {
            return {
                name: `${job.name} - In Progress`
            }
        })
        results.data = jobs.concat(results.data)
        return results
    }

    const onExportSegmentMembers = (segment) => {
        var link = downloadLink;
        
        function exportCallback() {

            return DatabaseService.getSegmentMembersExport({ teamId: segment.id })
            .then(resp => {
                   return resp.text()}
            ).then(text => {
               if (navigator && navigator.msSaveBlob) { // IE 10+
                   var exportedFilename = link.current.download;
                   var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                   navigator.msSaveBlob(blob, exportedFilename);
               } else {
                   var encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
                   var date = new Date();
                   link.current.download = `${segment.name} - ${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`
                   link.current.setAttribute("href", encodedUri);
                   link.current.click(); 
               }
               
           },
           function (err) {
               console.log('Error exporting! ' + err)
           })
       }
       exportCallback(); 
    }
    const onDeleteSegment = (deleteYes, segment) => {
        if(deleteYes) {
            dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
            DatabaseService.removeTeam({teamId: segment.id})
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(()=>{
                    dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                    loadData();
                });
        }
    }

    return (
        busy ? (
            <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
            </div>
        ) : (
            <div style={style.content}>
                {
                    responseMessage && 
                    <Message error={error}>
                        {responseMessage}
                        &nbsp;
                        {responseAction ?
                        <span style={{color: 'blue', cursor: 'pointer'}} onClick={responseAction.invoke}>{responseAction.text}</span>
                        : null}
                    </Message>
                }
                <div style={{...style.header, display: 'inline'}}>{t('Segments')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={style.button} onClick={()=>{
                    resetMessage()
                    setAddSegmentModalOpen(true)
                }} >{t('Add Segment')}</Button>
                </div>
    
                <div style={style.section}>
                <a ref={downloadLink} style={{display: 'none'}} href="#" download="export.csv"></a>
                <a ref={failedEmailsLink} style={{display: 'none'}} href="#" download="failedEmails.txt"></a>

                <List
                    getData={getData}
                    searchData={fetchSegments}
                    type='table'
                    actions={[
                        {
                            name: 'share',
                            invoke: (segment)=>{
                                resetMessage()
                                setSegmentDetail(segment)
                            },
                            color: colors.themeDarkGrey
                        },
                        ...( localStorage.master === 'true' ? [{
                            name: 'cloud upload',
                            invoke: (segment)=>iterableExport(segment),
                            color: colors.themeLightBlue
                        }] : []),
                        {
                            name: 'announcement',
                            invoke: (segment)=>{
                                resetMessage()
                                setSegmentForAnnouncement(segment)
                            },
                            color: colors.themeDarkGrey
                        },
                        {
                            name: 'download',
                            invoke: (segment)=>{
                                resetMessage()
                                onExportSegmentMembers(segment)
                            },
                            color: colors.themeDarkGrey
                        },
                        {
                            name: 'trash',
                            invoke: (segment) => {
                                resetMessage()
                                deleteModalRef.current.showDeletePrompt(segment)
                            },
                            color: colors.warningRed
                        }
                    ]}
                    fields={localStorage.master === 'true' ? [
                        ...segmentFields, 
                        {
                            name: 'Iterable List',
                            key: 'iterableId',
                            default: true,
                            searchable: true
                        }] : segmentFields
                    }
                    update={busy}
                />
                </div>
                {addSegmentModalOpen && 
                    <AddSegmentModalContainer
                        onRefresh={loadData}
                        onClose={()=>setAddSegmentModalOpen(false)}
                        onMessage={onResponseMessage}
                    />
                }
                {segmentDetail && 
                    <PushSegmentModalContainer
                        segment ={segmentDetail}
                        onRefresh={loadData}
                        onClose={()=>setSegmentDetail(null)} />
                }
                {Boolean(segmentForAnnouncement) &&
                    <EditAnnouncementModalContainer segmentId={segmentForAnnouncement.id}
                                                    recurrences={recurrences}
                                                    channels={channels}
                                                    languages={languages}
                                                    onRefresh={loadData}
                                                    onClose={()=>setSegmentForAnnouncement(null)}/>
                }
                                
                <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation ={onDeleteSegment} />
            </div>
    
        )
    );
}
const mapStateToProps = (state, ownProps) => {
    return {
        segments: getSegments(state.segments),
        jobs: getJobs(state.jobreporting)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSegments: (params) => {
            return dispatch(fetchSegments(params))
        },
        fetchJobs: (params) => {
            return dispatch(fetchJobs(params))
        },
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('segments')(SegmentsContainer)));