import {Component} from 'react';
import {withRouter} from 'react-router-dom';

class LocationWatcher extends Component {
    componentDidUpdate(prevProps) {
        var oldLocation = prevProps.location;
        var currentLocation = this.props.location;
        if (currentLocation !== oldLocation) {
            this.onLocationChange(currentLocation, oldLocation);
        }
    }

    onLocationChange(current, old) {
        window.scrollTo(0, 0);
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(LocationWatcher);
