import {SET_SEGMENTS} from 'actions/types';

var segmentInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = segmentInitialState, action) => {
    switch(action.type) {
        case SET_SEGMENTS: 
            return {
                ...state,
                list:action.segments.map((c)=>(c.id))
            };
        default:
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getSegments = state => state.list.map( id => state.indexed[id] );

