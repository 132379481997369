import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Reports from './Reports.js';


class ReportsContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false,
                      startDate:'',
                      endDate:'',
                      rewards:true,
                      engagement:true,
                      steps:true,
                      activities:true,
                      goalAnalysis:false,
                      result:null};
        this.onToggleFlag = this.onToggleFlag.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
    }

    onToggleFlag(flag) {
        this.setState(prevState => ({[flag]: !this.state[flag]}));
    }

    onChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    onSubmit(event) {
        event && event.preventDefault();

        var startDate = moment(this.state.startDate, "YYYY-MM-DD").toISOString();
        var endDate   = moment(this.state.endDate, "YYYY-MM-DD").toISOString();
        DatabaseService.getReport({
            startDate  : startDate,
            endDate    : endDate,
            rewards    : this.state.rewards,
            engagement : this.state.engagement,
            steps      : this.state.steps,
            activities : this.state.activities,
            goals      : this.state.goalAnalysis,
        }).then(result => {
            this.setState({result:result})
        })
    }

    isValid() {
        return (
           Boolean(this.state.startDate) &&  
           Boolean(this.state.endDate)  
        );
    }

    render() {
        return (
            <Reports busy={this.state.busy}
                       {...this.state}
                       valid={this.isValid()}
                       onChange={this.onChange}
                       onToggleFlag={this.onToggleFlag}
                       onSubmit={this.onSubmit}
                       />
      );
  }
}

export default withRouter(connect()(withTranslation('reports')(ReportsContainer)));
