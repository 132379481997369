import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import jstz from 'jstz';
import _ from 'underscore';

import {getCompanies} from 'reducers/companies.js';
import {fetchCompanies} from 'actions/companies.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Companies from './Companies.js';
import { getUserRoles } from '../../reducers/user.js';

class CompaniesContainer extends Component {

    constructor(props){
		super(props);
        this.state = { showCreateCompany:false };
        this.onSelectCompany = this.onSelectCompany.bind(this);
        this.onClickCreateCompany = this.onClickCreateCompany.bind(this);
        this.onRemoveCompany = this.onRemoveCompany.bind(this);
        this.onExportCompanyTree = this.onExportCompanyTree.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
    }

    componentWillMount() {
        delete localStorage.companyId;
        delete localStorage.inviteCode;
        this.props.dispatch(setSidebarEnable(false));
        this.loadData();
    }

    loadData() {
        this.setState({busy:true});
        this.props.fetchCompanies({}, true)
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                this.setState({busy:false});
            });
    }

    onSetCompany(company) {
        localStorage.companyId = company.id;
        localStorage.inviteCode = company.code;
    }

    onSelectCompany(company) {
        this.onSetCompany(company)
        if (this.props.roles.indexOf('sales') >= 0 || this.props.roles.indexOf('leadsgen') >= 0) {
            this.props.history.push('/members');
        } else {
            this.props.history.push('/dashboard');
        }
    }

    onClickCreateCompany(en) {
        this.setState({showCreateCompany:en})
    }

    async onSignOut(e){
        e.preventDefault();
        await DatabaseService.signOut()
        window.location.href = '/login'
    }

    onRemoveCompany(company) {
        this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.removeOrganization({childCompanyId:company.id})
                .then(()=> {
                    return this.loadData(false);
                })
                .catch((error)=> {
                    console.error(error)
                    window.alert('An error occurred - check console');
                })
                .then(()=>{
                    this.props.dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
                });
    }

    findRelatedCompanies(company) {
        let companies = this.props.companies || [];
        let relatedCompanyIds = {};
        let includedCompanies = {};
        relatedCompanyIds[company.id] = true;
        if(company.ownerCompanyId) {
            relatedCompanyIds[company.ownerCompanyId] = true;
        }
        includedCompanies[company.id] = true;
        let change = true;
        while(change) {
            console.log('pass');
            change = false;
            for(var i = 0; i < companies.length; i++) {
                let c = companies[i];
                if(!includedCompanies[c.id]) {
                    if(relatedCompanyIds[c.id] || relatedCompanyIds[c.ownerCompanyId]) {
                        change = true;
                        relatedCompanyIds[c.id] = true;
                        if(c.ownerCompanyId) {
                            relatedCompanyIds[c.ownerCompanyId] = true;
                        }
                        includedCompanies[c.id] = true;
                    }
                }
            }
        }
        return Object.keys(relatedCompanyIds).map(id => this.props.companyIndex[id]);
    }

    getCsvRow(company) {
        let name  = company.name || '';
        name      = name.replace(/,/,'');
        name      = name.replace(/\r/,'');
        name      = name.replace(/\n/,'');
        let seats = company.seats;
        return name + "," + seats + "\r\n";
    }

    onExportCompanyTree(company) {
        var companies = this.props.companies;
        companies = _.sortBy(companies, 'name');
        var total = companies.reduce((total, company) => total+company.seats);
        let csv = "data:text/csv;charset=utf-8,";
        csv += "Company Name,Number of Seats\r\n";
        csv += "Total,"+total+"\r\n";
        for(var i = 0; i < companies.length; i++) {
            csv += this.getCsvRow(companies[i]);
        }
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");

        if (navigator && navigator.msSaveBlob) { // IE 10+
            var exportedFilenmae = "company_seats.csv";
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "company_seats.csv");
            document.body.appendChild(link);
            link.click();
        }
    }


    render() {
        return (
            <Companies busy={this.state.busy}
                       showCreateCompany={this.state.showCreateCompany}
                       companies={this.props.companies}
                       onSelectCompany={this.onSelectCompany}
                       onSetCompany={this.onSetCompany}
                       onExportCompanyTree={this.onExportCompanyTree}
                       onClickCreateCompany={this.onClickCreateCompany}
                       onRemoveCompany={this.onRemoveCompany}
                       onSignOut={this.onSignOut} />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
        companies:getCompanies(state.companies),
        companyIndex: state.companies.indexed,
        roles: getUserRoles(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompanies: (params, force) => dispatch(fetchCompanies(params, force)),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('companies')(CompaniesContainer)));
