import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import {FlexRow} from 'components/views/views'
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {SelectionModal, ListMultiSelectModal, ListFormModal, UploadProgressModal} from 'components/modals'

const styles = {
    field: {
        margins: spacings.tiny
    },
}

const SurveyUploadForm = ({t, open, onUpload, fetchSurveys, finish, cancel, segments, validateCampaign, functionCalls, modalError, tableFields, formData}) => {
    const [step, setStep] = useState(0)
    const [selectedSurveys, setSelectedSurveys] = useState([])
    const [uploadCalls, setUploadCalls] = useState([])
    const [error, setError] = useState()
    
    useEffect(() => {
        if (open && formData) {
            setStep(formData.step)
            setSelectedSurveys(formData.selectedSurveys)
        } else if (open) {
            setStep(1)
        } else {
            setStep(0)
        }
    }, [open, formData])

    const nextForm = () => {
        setStep(step => step+1)
        setError()
    }

    const cancelModal = () => {
        setSelectedSurveys([])
        setStep(0)
        cancel()
    }

    const finishUpload = (surveys) => {
        if (surveys && surveys.length) {
            setSelectedSurveys(surveys.flat())
            setStep(4)
        }
    }

    const finishModal = () => {
        setSelectedSurveys([])
        setStep(0)
        finish(selectedSurveys)
    }

    const updateSelectedSurvey = (id, field, value) => {
        const survey = selectedSurveys.find(survey => survey.id === id)
        if (survey) {
            setSelectedSurveys(surveys => {
                const index = surveys.indexOf(survey)
                const newSurveys = [...surveys]
                newSurveys[index][field] = value
                newSurveys[index].valid = validateCampaign(newSurveys[index])
                return newSurveys
            })
        }
    }

    useEffect(() => {
        if (functionCalls?.length && open) {
            setUploadCalls(functionCalls)
            setStep(2)
        }
    }, [functionCalls])


    const selectItem = (item) => {
        setSelectedSurveys(surveys => {
            const existing = surveys.find(survey => survey.id === item.tag)
            if (!existing) {
                return [
                    ...surveys,
                    {
                        title: item.title,
                        id: item.tag,
                        data: item
                    }
                ]
            } else {
                return [
                    ...surveys.slice(0, surveys.indexOf(existing)),
                    ...surveys.slice(surveys.indexOf(existing) + 1)
                ]
            }
        })
    }
    
    useEffect(() => {
        if (modalError) {
            setError(modalError)
        }
    }, [modalError])

    return (
        <div>
            <SelectionModal
                open={step === 1}
                fields={[
                    {
                        icon: 'search',
                        text: 'Select an existing survey',
                        onClick: () => setStep(3)
                    },
                    {
                        icon: 'upload',
                        text: 'Upload a new survey',
                        onClick: () => onUpload()
                    },
                    localStorage.master==='true' &&
                    {
                        icon: 'upload',
                        text: 'Upload a new public survey',
                        onClick: () => onUpload({public: true, ownerCompanyId: localStorage.originalCompanyId})
                    }
                ]}
                header={t('select_or_upload_prompt')}
                cancel={cancelModal}
                error={error}
            />
            <UploadProgressModal
                open={step === 2}
                tableName={t('Survey')}
                total={uploadCalls.length}
                calls={uploadCalls}
                cancel={cancelModal}
                finish={finishUpload}
            />
            <ListMultiSelectModal
                open={step === 3}
                searchData={fetchSurveys}
                queryField={['tag', 'title']}
                selectItem={selectItem}
                selectedItems={selectedSurveys}
                cancel={cancelModal}
                next={nextForm}
                tableName={t('Campaign')}
                fieldName={t('Survey')}
                tableFields={tableFields}
            />
            <ListFormModal
                open={step === 4}
                items={selectedSurveys}
                updateItem={updateSelectedSurvey}
                fields={[
                    {
                        name: t('Start Date'),
                        key: 'startDate',
                        disabledKeys: ['startNow', 'adhoc'],
                        maxKey: 'endDate',
                        type: 'date',
                        extra: [
                            {
                                name: t('Start Now'),
                                key: 'startNow',
                                type: 'checkbox'
                            },
                            {
                                name: t('End Date'),
                                key: 'endDate',
                                disabledKeys: ['indefinite', 'adhoc'],
                                minKey: 'startDate',
                                type: 'date',
                                extra: [
                                    {
                                        name: t('Indefinite'),
                                        key: 'indefinite',
                                        type: 'checkbox'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: t('Segment'),
                        key: 'segment',
                        type: 'search',
                        itemRenderer: ({title, tags}) => (
                            <div>
                                <h3>{title}</h3>
                                <FlexRow style={{flexWrap: 'wrap'}}>
                                    {
                                        tags && tags.map(tag => (
                                            <p style={styles.field}>{tag}</p>
                                        ))
                                    }
                                </FlexRow>
                            </div>
                        ),
                        data: segments
                    },
                    localStorage.master==='true' && {
                        name: t('Inactive (Test)'),
                        key: 'inactive',
                        type: 'checkbox',
                        extra: [
                            {
                                name: t('Repeatable (Test)'),
                                key: 'repeatable',
                                type: 'checkbox'
                            },
                            {
                                name: t('Show (Test)'),
                                key: 'refreshCreate',
                                type: 'checkbox'
                            },
                            {
                                name: t('Ad-hoc'),
                                key: 'adhoc',
                                type: 'checkbox'
                            },
                            {
                                name: t('Public'),
                                key: 'isPublic',
                                type: 'checkbox'
                            }
                        ]
                    }
                ]}
                tableName={t('Campaigns')}
                cancel={cancelModal}
                next={finishModal}
                cancelText={t('Close')}
            />
        </div>
    )
}

export default withTranslation('surveys')(SurveyUploadForm)