import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Button, Modal, Header, Input, Message} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';

var style = {
    ...styleDef,
};


const MonthlyLimitModal = ({t, 
                          value, 
                          valid,
                          onChange, 
                          onClose, 
                          onSubmit}) => (
    <Modal open style={style.content} onClose={onClose} size='tiny'>
        <Modal.Header>{t('monthlylimit_modal_header')}</Modal.Header>
        <Modal.Content>
        <form onSubmit={onSubmit}>
            <Modal.Description>
                <div style={style.description}>{Utils.endlToBr(t('monthlylimit_modal_description'))}</div>
                <Input label='$' type='number' value={value} onChange={onChange} placeholder={t('monthlylimit_modal_placeholder')} /> 
                <br />
                <br />
                <br />
                <Button type='submit' style={style.button} disabled={!valid}>{t('monthlylimit_modal_btn')}</Button>
            </Modal.Description>
        </form>
        </Modal.Content>
    </Modal>
);

export default withTranslation('rewards')(MonthlyLimitModal);
