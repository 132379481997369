import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Modal, Dropdown, Button, Icon, Grid, GridRow} from 'semantic-ui-react';
import { EXPORT_TYPES } from '../LeaderboardContainer';

var getOptions = (leaderboards) => {
    return leaderboards.map((leaderboard)=>{
        return {
            key: leaderboard.id,
            text: leaderboard.title,
            value: leaderboard.id
        }
    }) 
}

const styles = {
    section: {
        position: "relative",
        border: "1px solid black",
        padding: "1rem"

    },
    exportButton: {
        margin: "1rem 0",
        position: "absolute",
        bottom: 0
    }
}

const LeadboardExportSelectModal = ({
    t,
    onSelectExportType,
	onClose
}) => (
    <Modal open size="medium" onClose={onClose}>
        <Modal.Header>{t('select_export_type')}</Modal.Header>
        <Modal.Content>
            <Grid columns='equal'>
                <Grid.Column style={styles.section}>
                    <h3>{t('with_team_title')}</h3>
                    <p>{t('with_team_description')}</p>
                    <div>
                        <Button  onClick={() => onSelectExportType(EXPORT_TYPES.individualWithManualTeam)}>
                            <Icon name="download" />
                            {t('team_export')}
                        </Button>
                    </div>
                </Grid.Column>
                <Grid.Column style={styles.section}>
                    <h3>{t('without_team_title')}</h3 >
                    <div >{t('without_team_description')}</div>
                    <div style={styles.exportButton}>
                        <Button onClick={() => onSelectExportType(EXPORT_TYPES.individual)}>
                            <Icon name="download" />
                            {t('export')}
                        </Button>
                    </div >
                </Grid.Column>
            </Grid>
        </Modal.Content>
    </Modal>
);

export default withTranslation('leaderboards')(LeadboardExportSelectModal);
