import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import PushSegmentModal from './PushSegmentModal.js';


class PushSegmentModalContainer extends Component {

    constructor(props){
		super(props);
        var segment = this.props.segment;
        this.state = {busy:true,segment : segment,goals: null,company:null,categories:null,categoryToDisplay:null};
        this.onToggleCategory = this.onToggleCategory.bind(this);
        this.onClose = this.onClose.bind(this);
        this.loadData();
    }

    async loadData() {
        try{
            var goals = await DatabaseService.getCategories();
            var company = await DatabaseService.getOrganization();
            this.state.segment.count = Math.round(company.usersCount * this.state.segment.value / 100);
            var start = Math.floor(Math.random() * (goals.categories.length - 4));
            var categories = goals.categories.slice(start, start+4)
            this.setState({goals:goals,company:company,categories : categories});
        }
        catch(e){
            console.log(e)
        }
        finally{
            this.setState({busy:false})
        }
    }
    onClose() {
        this.props.onClose();
    }
    onToggleCategory(category) {
        var companyCategory = category.companyCategory || {enabled:false};
        var updatedCompanyCategory = {...companyCategory, enabled:!companyCategory.enabled};
        var temp = (updatedCompanyCategory.enabled)?this.addHabit(category.id):this.removeHabit(category.id)
        var updatedCategory = {...category, companyCategory:updatedCompanyCategory};
        var updatedCategories = this.state.categories.map(c => c.id===category.id ? updatedCategory : c  );
        this.setState({categories:updatedCategories})
    }
    async addHabit(id) {
        var result = await DatabaseService.goals_categoryId_subscribe({categoryId: id})
        return result
    }

    async removeHabit(id) {
        var result = await DatabaseService.goals_categoryId_unsubscribe({categoryId: id})
        return result;
    }
    render() {
        return (
            <PushSegmentModal 
                        {...this.state}
                        onClose={this.onClose}
                        onToggleCategory={this.onToggleCategory}/>
      );
  }
}

export default withRouter(connect()(withTranslation('segments')(PushSegmentModalContainer)));
