import React from 'react';
import { connect } from 'react-redux';
import {Loader} from 'semantic-ui-react';

var style={
    centered: {
        marginLeft:'50%'
    },
    vcentered: {
        display: 'block',
        position: 'relative',
        top: '50%',
    }
}
const LoadInline = (props) => {
    return props.disable ? null : <Loader style={{...(props.centered ? style.centered : {}), ...(props.vcentered ? style.vcentered : {}), ...props.style}} active inline className={props.className}/>;
}

const mapStateToProps = (state, ownProps) => ({disable:(state.loading.overlay || state.loading.spinner)});
export default connect(mapStateToProps)(LoadInline);
