import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Icon, Button, Header, Checkbox } from 'semantic-ui-react';

import {getCategoryImageUrl } from './tools.js';
import Utils from 'utils/Utils.js';
import LoadInline from '../loading/LoadInline.js';
import {colors} from 'style';

var style = {
    content: {
        padding: '1px',
        position:'relative'
    },
    loaderContainer: {
        marginLeft:'50%'
    },
    subtitle: {
        textAlign: 'center',
    },
    card: {
        overflow:'hidden',
        color: '#fff',
        backgroundColor: colors.themeBlue,
    },
    header: {
        fontSize: '1em',
        color:'#fff', 
    },
    description: {
        color:'#fff', 
    },
    meta: {
        color:'#fff', 
    },
    iconHeader: {
        height:'42px', 
        padding:'10px 0px 0px 10px', 
        width:'100%',
        color: '#fff',
        backgroundColor: colors.themeTeal,
    },
    imageBanner: {
        position: 'relative',
        height:'158px', 
        width:'100%',
        backgroundSize:'100%',
        backgroundPositionY: 'center',
    },
    checkmark: {
        position: 'absolute',
        bottom: '-15px',
        right: '5px',
        width: '42px',
        height: '42px',
        paddingTop: '8px',
        boxShadow: 'none',
        borderWidth: '3px',
        borderStyle: 'solid',
        color: colors.themeTeal,
        borderColor: colors.themeTeal,
        borderRadius: '50%',
        backgroundColor: '#fff',
    },
    toggle: {
        position: 'relative',
        top: '3px',
    },
    editButton: {
        //position: 'absolute',
        //top: '10px',
        //right: '10px',
        float: 'right',
        color: '#ffff',
        backgroundColor: colors.themeBlue,
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0px',
        margin: '0',
        verticalAlign: 'top',
    },
    editIcon: {
        margin: '0',
        opacity: '1',
    }
};

const CategoryCard = ({t, 
                       category, 
                       onToggleCategory,
                       onClickCategory,
                       onEdit}) => (
        <Card key={category.id} className='nohover' style={style.card} onClick={onClickCategory ? ()=>onClickCategory(category):null}>
            <div style={style.iconHeader}>
                <Icon size='large' className={category.icon}></Icon> 
                {category.title}
            </div>
            <div style={{...style.imageBanner, backgroundImage:Utils.url(getCategoryImageUrl(category))}}>
            </div>
            <Card.Content>
                <Card.Header style={style.header}>
                    {category.levelReq > 1 &&
                        <Card.Meta style={style.meta}>Level {category.levelReq}</Card.Meta>
                    }
                    <div>
                        <Checkbox className='green' style={style.toggle} toggle checked={Boolean(category.companyCategory && category.companyCategory.enabled)} onChange={e=>{e.stopPropagation(); onToggleCategory(category)}}/>
                        {Boolean(onEdit) &&
                            <Button style={style.editButton} 
                                    className="categories-editButton" 
                                    onClick={e=>{e.stopPropagation(); onEdit(category)}}>
                                <Icon style={style.editIcon} name='edit'/>
                            </Button>
                        }
                    </div>
                </Card.Header>
            </Card.Content>
        </Card>
);


export default withTranslation('categories')(CategoryCard);
