
import {colors, fontSizes, fonts, spacings, shadow, layout, buttons} from 'style';

const style = {
    blank: {
        width: '100%',
        backgroundColor: 'white',
    },
    container: {
        backgroundColor: 'white',
    },
    content: {
        ...fonts.body,
        lineHeight: '1.5',
        position: 'relative',
        padding: '40px 0px 20px 0px',
        textAlign: 'center',
        height: '50%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'white',
        borderRadius: '5px',
        color: colors.themeDarkerGrey,
    },
    header:{
        fontSize: fontSizes.h2,
        lineHeight: '1.5',
        color: colors.themeBlue,
        marginBottom: spacings.base,
    },
    pageCount: {
        color: colors.themeBlue,
        marginBottom: spacings.small
    },
    description: {
        marginTop: spacings.base,
    },
    body: {
        margin: '20px 0px 20px 0px',
    },
    spacer: {
        height: '150px',
    },
    footer: {
        textAlign: 'center',
        ...fonts.body,
        lineHeight: '1.5',
        width: '100%',
        position: 'fixed',
        paddingBottom: '20px',
        left: '0',
        bottom: '0',
        backgroundColor: '#fff',
        zIndex: '50',
    },
    footerFade: {
        position: 'absolute',
        top: '-50px',
        height: '50px',
        width: '100%',
        backgroundColor: '#fff',
        background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
        pointerEvents: 'none',
    },
    footerLink: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'table',
        height: '20px',
        color: '#000',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    buttonWrapper: {
        paddingBottom: '30px',
    },
    checkmarkButton: {
        margin: '0px',
        padding: '0px',
        width: '42px',
        height: '42px',
        boxShadow: 'none',
        borderWidth: '3px',
        borderStyle: 'solid',
        color: colors.themeTeal,
        borderColor: colors.themeTeal,
        borderRadius: '50%',
        backgroundColor: '#fff',
    },
    checkmark: {
        width: '42px',
        height: '42px',
        paddingTop: '8px',
        boxShadow: 'none',
        border: 'none',
        color: colors.themeTeal,
        backgroundColor: 'rgba(255,255,255,0)',
        opacity: '1',
        borderRadius: '50%',
    },
    tealButton: {
        ...buttons.long,
        backgroundColor: colors.themeTeal,
    },
};
export default style;
