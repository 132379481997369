import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Container, Card, Image, Label, Icon, Button, Modal, Message, Grid, Popup} from 'semantic-ui-react';

import {withError} from 'modules/misc/ErrorPage.js';
import Utils from 'utils/Utils.js'
import LoadInline from '../loading/LoadInline.js';
import {colors, fontSizes, fonts, layout, shadow, spacings, buttons} from 'style';
import defaultAvatar from 'img/default-avatar.png';
import ChallengeCard from 'modules/competitions/ChallengeCard.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import CategoryCard from 'modules/competitions/CategoryCard.js';
import _ from 'underscore';
import { PageNavigationBar } from 'components/tables'
import { ContentCard } from 'components/card.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';
import LeaderboardExportSelectModalContainer from "modules/leaderboards/modals/LeadboardExportSelectModalContainer.js"

var style = {
    content: {
        position:'relative',
        ...fonts.body,
        lineHeight: '1.5',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
        lineHeight: '1.5',
        ...fonts.body,
    },
    backgroundContainer:{
        backgroundColor : colors.themeGrey
    },
    body: {
        maxWidth: layout.screenWidth,
        boxShadow: layout.screenShadow,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'white',
        borderRadius: spacings.base
    },
    recordBarContainer: {
        position: 'relative',
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0'
    },
    del: {
        position:'absolute',
        display: 'inline-block',
        borderRadius: '50%',
        height: '48px',
        width: '48px',
        fontSize: '0.8em',
        verticalAlign: 'middle',
        textAlign: 'center',
        top:'0px',
        left:'0px',
        lineHeight: '46px',
        backgroundColor:'white',
        border:'2px solid lightgrey',
        textIndent:'2px'

    },
    rank: {
        display: 'inline-block',
        height: '100%',
        fontSize: '1.5em',
        width: '60px',
        verticalAlign: 'middle',
        textAlign: 'center',
        position: 'relative',
        top: '3px',
        lineHeight: '75px',
        color: colors.themeLightBlue,
    },
    avatarContainer: {
        display: 'inline-block',
        height: '100%',
        fontSize: '1.5em',
        width: '180px',
        verticalAlign: 'middle',
        textAlign: 'left',
        position: 'relative',
        top: '-1px',
        lineHeight: '75px',
    },
    avatarWrapper: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        height: '48px',
        width: '34px',
        verticalAlign: 'middle',
    },
    avatar: {
        backgroundPosition:'50% 50%',
        backgroundSize:'cover',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#fff',
        borderRadius: '50%',
        height: '48px',
        width: '48px',
        verticalAlign: 'top',
    },
    recordBarBody: {
        //position: 'absolute',
        /*paddingLeft: '120px',*/
        // marginRight: '115px',
        left: '0px',
        top: '0px',
        //width: '100%',
        //height: '100%',
    },
    personInfo: {
        marginTop: '20px',
        width: '100%',
        textOverflow: 'ellipsis'
    },
    personalInfoItem: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        fontWeight: 'bold',
        color: colors.themeDarkerGrey,
    },
    recordPoints: {
        height: '100%',
        width: '100px',
        color: colors.pointsYellow,
        float: 'right',
        fontSize: '1.1em',
        fontWeight: 'normal',
        paddingTop: '38px',
        textAlign: 'right',
        //marginRight: spacings.base
        
    },
    icon: {
        position: 'relative',
        top: '2px',
        left:'-5px',
    },
    progressBar: {
        marginTop: '-2px',
        height: '10px',
        width: '100%',
        backgroundColor: colors.themeBlue,
        background: 'linear-gradient(to right, '+colors.themeBlue+', '+colors.themeTeal+')',
        borderRadius: '4px',
    },


    recordBar: {
        position: 'relative',
        padding: '8px',
        height: '64px',
        backgroundColor: '#F0F0FF',
        borderTopRightRadius: '32px',
        borderBottomRightRadius: '32px'
    },
    textContainer: {
        display: 'table',
        tableLayout: 'fixed',
        width:'100%',
        height:'100%',
        paddingRight:'64px',
    },
    textClipper: {
        verticalAlign: 'middle',
        display: 'table-row',
        width:'100%',
        height: '100%',
        minWidth: '100px'
    },
    myRow: {
        backgroundColor: colors.themeTeal,
        borderRadius: '6px',
    },
    myPoints: {
        color: '#fff',
    },
    myRank: {
        color: '#fff',
    },
    myProgressBar: {
        backgroundColor: '#fff',
        background: '#fff',
    },
    myInfo: {
        color: '#fff',
    },
    clickable: {
        cursor: 'pointer',
    },
    contentPic: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
        lineHeight: '1.5',
        ...fonts.body,
    },
   
  
    starIcon: {
        position: 'absolute',
        left: '6px',
        top: '6px',
        fontSize: '1.5em',
        opacity: '1',
        color: '#FFF'
    },
 
    loader: {
        marginTop: '10px',
    },
    loaderContainer: {
        height: '100%',
    },

    participants: {
        fontSize: fontSizes.body,
        color:colors.themeTeal
    },
    segment: {
        backgroundColor: 'white',
        borderRadius: '9px',
        boxShadow: shadow,
        margin: spacings.small,
        textAlign: 'center',
    },
    leaderboardSegment: {
      backgroundColor: 'white',
      borderRadius: '9px',
      boxShadow: shadow,
      margin: spacings.small,
      padding: spacings.base,
    },
    title: {
        padding: spacings.base,
        fontSize: fontSizes.h3,
        color: colors.themeBlue,
        textAlign: 'center',
        lineHeight: '2.5',
    },
    h3: {
        fontSize: fontSizes.h3,
        color: colors.themeBlue,
        padding: spacings.base,
    },
    h4: {
        color: colors.themeBlue,
        ...fonts.h4,
    },
    banner: {
        width: '100%',
        borderTopRightRadius: '9px',
        borderTopLeftRadius: '9px',
        height: '300px',
        objectFit: 'cover'
    },
    cardContent: {
        padding: spacings.base,
    },
    competitionTitle: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    checkmark: {
        color : colors.themeTeal
    },

    h4Milestone : {
        ...fonts.h4,
        color : colors.themeBlue,
        marginTop : spacings.base,
        marginBottom : spacings.base,
        textAlign: 'left'
    },
    bodyMilestones : {
        ...fonts.bodyL,
        justifyContent: 'left',
        color : colors.themeDarkerGrey
    },
    back : {
       fontSize: fontSizes.h4,
       position: 'absolute',
       color: colors.themeDarkerGrey,
       cursor: 'pointer'
    },
    starIconMilestone : {
        color: colors.pointsYellow,
        verticalAlign: 'baseline'
    },
    resourcesIcon : {
        color: colors.pointsBlue,
    },
    pointsCell : {
        padding : '0px'
    },
    slimRow : {
        paddingBottom: spacings.micro,
        paddingTop :spacings.micro
    },
    gridContent : {
        marginTop: '32px',
    },
    segmentBoxes : {
        backgroundColor: 'white',
        borderRadius: '9px',
        boxShadow: shadow,
        textAlign: 'left',
        padding: spacings.base,
        height: '350px',
        width: '470px',
        marginTop: '-16px'
    },
    avatarLabelContainer: {
        width: '100%', 
        height:'32px',
        marginTop: '-3px',
        padding: '4px',
        marginLeft: '8px'
    },
    avatarLabel: {
        textAlign: 'right',
        backgroundColor: '#fff',
        color: colors.themeBlue,
        fontWeight: 'normal',
        position: 'relative',
        fontSize: '14px',
        borderRadius: '100px',
        marginRight: '4px'

    },
    pointsLabel: {
        color: colors.pointsYellow,
    },
    checkmarkMilestone : {
        color : 'green'
    },
    x : {
        color : 'red'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    iconButtonTrashIcon: {
 
        opacity: '1',
        color: colors.warningRed,
    },
};

const rankSortFunc = x => {
    var rank = x.rank;
    if(rank === null || rank === undefined) {
        return Number.MAX_VALUE;
    }
    return rank;
}
const ranks = {
    1:{color: colors.pointsYellow},
    2:{color: '#C0C0C0'},
    3:{color: '#CD7F32'},
}
const avatars = {
    1:{borderColor: colors.pointsYellow},
    2:{borderColor: '#C0C0C0'},
    3:{borderColor: '#CD7F32'},
}

const Leaderboard = ({t,
                      records,
                      leaderboard,
                      targetPoints,
                      column,
                      busy,
                      onClickChange,
                      start,
                      limit,
                      total,
                      isMember,
                      isClickable,
                      getName,
                      getAvatars,
                      formatLeaderboardValue,
                      onExport,
                      downloadLinkRef,
                      onShare,
                      shareLinkRef,
                      deleteModalRef,
                      changePage,
                      onDeleteMember,
                      isHovering,
                      handleMouseOver,
                      handleMouseOut,
                      onSelectExportType,
                      showExportSelectModalContainer,
                      setShowExportSelectModalContainer,
                    }) => {
return busy ? (
        <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
        </div>
) : (
<div style={style.backgroundContainer}>
    {Boolean(leaderboard) &&
    <div>   
            {/* <div style={style.title}>{leaderboard.season.title}</div> */}

            <div style={style.contentPic}>
                <div style={style.segment}>
                <img style={style.banner} alt='' src={Boolean(leaderboard.banner && leaderboard.banner.url) ? leaderboard.banner.url : defaultObjectiveImage} />
                <div style={style.cardContent}>
                    <div style={{...style.h3, ...style.competitionTitle}}>{leaderboard.title}</div>
                    <Icon style={style.checkmark} name={leaderboard.type == 'teams' ? 'users' : 'user'}/>
                     {leaderboard.type == 'teams' ? 'Team Competition' : 'Individual Competition'}
                     <div style = {{...style.participants}}>
                     {total == 1 ? total + ' ' + t(leaderboard.type == 'teams' ? 'Team' : 'Participant') : total + ' ' + t(leaderboard.type == 'teams' ? 'Teams' : 'Participants')}
                     </div>
                </div>
                </div>
        </div>
    

    {Boolean(showExportSelectModalContainer) && 
        <LeaderboardExportSelectModalContainer
            onSelectExportType={onSelectExportType}
            onClose={()=>setShowExportSelectModalContainer(false)} />
      }


     <div className="full-height" style={{...style.contentPic, ...style.gridContent}}>
                     <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                <div style={{...style.segmentBoxes, marginLeft: '16px'}}>
                                {Boolean(leaderboard.season) && Boolean(leaderboard.season.seasonCategories) && <div>
                                    <div style={style.h4Milestone}>{t('Applicable Categories')}</div>
                                    <Card.Group className = 'borderless' style = {{fontSize: '3px'}}>{leaderboard.season.seasonCategories.map(a => {
                                        if(a.category)
                                            return <CategoryCard category={a.category} key = {a.id} noToggle={true}/>
                                    })}</Card.Group>
                                </div>}
                                </div>
                                </Grid.Column>
                                <Grid.Column>
                                <div style={{...style.segmentBoxes}}>
                                    <div style={style.h4Milestone}>{t('Target Milestones')}</div>
                                    {Boolean(leaderboard.season) && Boolean(leaderboard.season.seasonMilestones) && leaderboard.leaderboardRewards.length &&<Grid style={style.bodyMilestones}>{leaderboard.season.seasonMilestones.map(function(a, index) {
                                        return <Grid.Row key={index} style={style.slimRow}>
                                                <Grid.Column width={6}>
                                                    {a.points > 0 && <span>{a.points} {t('Points')}</span>}
                                                    {a.resources > 0 && <span>{a.resources} {t('Resources')}</span>}
                                                    {a.actions > 0 && <span>{a.actions} {t('Actions')}</span>}
                                                    {a.activities > 0 && <span>{a.activities} {t('Activities')}</span>}
                                                    {a.steps > 0 && <span>{a.steps} {t('Steps')}</span>}
                                                    {a.glasses > 0 && <span>{a.glasses} {t('Glasses')}</span>}
                                                    {a.duration > 0 && <span>{Math.floor(a.duration/60)} {t('Minutes')}</span>}
                                                    {a.floors > 0 && <span>{a.floors} {t('Floors')}</span>}
                                                    {a.photos > 0 && <span>{a.photos} {t('Photos')}</span>}
                                                </Grid.Column>
                                                <Grid.Column width={3} style={style.pointsCell}>
                                                    <Icon style={style.starIconMilestone} className="notClickable icon-star"/>{a.rewardPoints}
                                                </Grid.Column>
                                                <Grid.Column width={3} style={style.pointsCell}>
                                                        <Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>{a.rewardResources}
                                                </Grid.Column>
                                            </Grid.Row>
                                    })}</Grid>|| <em>({t('None')})</em> }
                                    <div style={style.h4Milestone}>{t('Ranking Bonuses')}</div>
                                    {Boolean(leaderboard) && Boolean(leaderboard.leaderboardRewards) && leaderboard.leaderboardRewards.length && <Grid style={style.bodyMilestones}>{_.sortBy(leaderboard.leaderboardRewards, rankSortFunc).map(function(a, index) {
                                            return <Grid.Row key={index} style={style.slimRow}>
                                                <Grid.Column width={2}>
                                                    #{a.rank || leaderboard.leaderboardRewards.length + '+'}
                                                </Grid.Column>
                                                <Grid.Column width={3} style={style.pointsCell}>
                                                    <Icon style={style.starIconMilestone} className="notClickable icon-star"/>{a.rewardPoints}
                                                </Grid.Column>
                                                <Grid.Column width={3} style={style.pointsCell}>
                                                    <Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>{a.rewardResources}
                                                </Grid.Column>                                                
                                                </Grid.Row>
                                        })}</Grid> || <em>({t('None')})</em> }
                                </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
  

    <div className="full-height" style={{...style.content, ...style.contentPic}}>
        
        <div style={{...style.body, ...style.leaderboardSegment}}>
            <div style={{...style.title}}> 
                <div style={style.h4Milestone}>{t('Leaderboard')}</div>
                <div>
                    { localStorage.master==='true' && <>
                        <a ref={shareLinkRef} style={{display: 'none'}} href="#" target='_blank' ></a>
                        <Button  onClick={() => onShare()}>
                            <Icon name="share" />
                            Share
                        </Button>
                    </> }
                    <a ref={downloadLinkRef} style={{display: 'none'}} href="#" download="export.csv" ></a>
                    <Button  onClick={() => leaderboard.type == 'teams' ? onExport() : setShowExportSelectModalContainer(true)}>
                        <Icon name="download" />
                        Export
                    </Button>
                </div>
                
               
            </div>
            <Grid>
            {records && leaderboard && records.map((record) => {
                let scope = record.team ? 'team' : 'userProfile'
                return (
                    
               
                    <Grid.Row key={record.id} style={{...style.recordBarContainer, ...(isMember(record) ? style.myRow : {}), ...(isClickable(record) ? style.clickable : {})}} onClick={() => onClickChange(record)}>

                        <Grid.Column width={1} style={{...style.rank, ...(ranks[record.rank]), ...(isMember(record) ? style.myRank : {})}}>
                            {record.rank}
                        </Grid.Column>
                        
                        <Grid.Column width={1} style={style.avatarContainer}  onMouseEnter={()=>handleMouseOver(record)} onMouseLeave={()=>handleMouseOut(record)}>
                            {getAvatars(record[scope]).map((url,avatarIndex)=>(
                                <div key={avatarIndex}>{record.isHovering}
                                        <div  style={style.avatarWrapper}><div style={{...style.avatar, ...(avatars[record.rank]), backgroundImage:Utils.url(url ? url : defaultAvatar)}} /><div style={{...style.avatarWrapper, ...style.clickable, ...style.del, visibility: record.isHovering ? 'visible' : 'hidden'}} onClick={()=>{
                                    deleteModalRef.current.showDeletePrompt(record)
                                }}><Icon style={style.iconButtonTrashIcon} name='trash' /></div></div>
                                        
                                        
                                </div>
                                
                            ))}
                        </Grid.Column>

                        <Grid.Column width={11} style={{...style.recordBarBody}}>
                            <div style={style.personInfo}>
                                <div style={{...style.personalInfoItem, ...(isMember(record) ? style.myInfo : {}), display: 'flex'}}>
                                {getName(record[scope])}
                                {leaderboard.type !== 'teams' && ` (${record[scope].email})`}
                                {leaderboard.type !== 'teams' && record[scope].anonymous && <Popup
                                    trigger = {
                                        <Icon name='user secret' style = {{margin: "0 1rem"}} />
                                    }
                                    content= "Anonymous user"
                                />}
                                <div style={style.avatarLabelContainer}>
                                        {Boolean(record.userLeaderboardReward) && 
                                            <span style={{...style.avatarLabel, ...style.pointsLabel, width: '20px'}} >
                                            <Icon className="notClickable icon-star"/>
                                                {record.userLeaderboardReward.rewardPoints}
                                            </span>
                                        }
                                        {Boolean(record.userLeaderboardReward) && 
                                            <span style={{...style.avatarLabel, width: '20px'}} >
                                            <Icon className="notClickable icon-Optimollar"/>
                                                {record.userLeaderboardReward.rewardResources}
                                            </span>
                                        }
                                        {Boolean(record.userSeasonMilestone) &&
                                        <Popup
                                            trigger = {
                                                <Icon name='checkmark' style = {{...style.checkmarkMilestone}} />
                                            }
                                            content= "✅ symbolizes the achievement of a Season Milestone."
                                        />
                                        }
                                </div>
                                </div> 
                            </div>
                            <div style={{...style.progressBar, ...(isMember(record) ? style.myProgressBar : {}), width:(record[scope].anonymous ? '0%' : String(Utils.getPercent(record[column], targetPoints))+'%')}} />
                        </Grid.Column>
                        <Grid.Column width={2} style={{...style.recordPoints, ...(isMember(record) ? style.myPoints : {})}}>
                            <i style={style.icon} className={leaderboard.icon} />
                            {formatLeaderboardValue(record, column)}
                        </Grid.Column>
                    </Grid.Row>
                    
                )
            })
            }
            </Grid>
            { total > 0 && <PageNavigationBar
                busy={busy}
                total={total}
                offset={Math.floor(start/limit)}
                limit={limit}
                size={records.length}
                onNextPage={changePage}
            />
            }
        </div>
    </div>
    </div>
    }
    <br />
    <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation ={onDeleteMember} message='Are you sure you want to remove this user from the challenge?' />
</div>

)};



export default withTranslation('leaderboards')(Leaderboard);
