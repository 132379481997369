import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Modal, Dropdown} from 'semantic-ui-react';

var getOptions = (leaderboards) => {
    return leaderboards.map((leaderboard)=>{
        return {
            key: leaderboard.id,
            text: leaderboard.title,
            value: leaderboard.id
        }
    }) 
}

const LeaderboardSelectModal = ({
    t,
    leaderBoardsToSelect,
    navigateLeaderBoard,
	onClose
}) => (
    <Modal open size="mini" onClose={onClose}>
        <Modal.Header>{t('select_leaderboard_header')}</Modal.Header>
        <Modal.Content>
            <Dropdown 
                fluid
                selection 
                options={getOptions(leaderBoardsToSelect)}
                placeholder={t('leaderboard_select_placeholder')}
                onChange={(e, { value }) => {
                    navigateLeaderBoard({id: value})
                }} />
        </Modal.Content>
    </Modal>
);

export default withTranslation('competitions')(LeaderboardSelectModal);
