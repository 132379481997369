import {SET_JOBS} from 'actions/types';

var jobsInitialState = {list:[], searchList:{}, indexed:{}, listLoaded:false, };
export default (state = jobsInitialState, action) => {
    switch(action.type) {
        case SET_JOBS:
            return {
                ...state,
                list:action.jobs.map((c)=>(c.id))
            };
        default:
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getJobs = state => state.list.map( id => state.indexed[id] );