import DatabaseService from 'services/DatabaseService.js';
import {SET_CATEGORIES, 
        ADD_CATEGORY,
        ADD_CATEGORIES} from 'actions/types';
import {getCategory, 
        getCategories, 
        isListLoaded}  from 'reducers/categories.js';
import {getCategoryWithNotifications} from 'reducers';

//Action Creators
export const setCategories = (categories) => {
    return { type:SET_CATEGORIES, categories:categories };
}

export const addCategory = (category) => {
    return { type:ADD_CATEGORY, category:category };
}

export const addCategories = (categories) => {
    return { type:ADD_CATEGORIES, categories:categories };
}



//Thunks
export const fetchCategories = (params, force) => (dispatch, getState) => {
    if(!force) {
        var state = getState();
        if(isListLoaded(state.categories)) {
            return Promise.resolve(getCategories(state.categories));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.getCategories(params).then(
            function(result){ 
                dispatch(setCategories(result.categories));
                resolve(result.categories);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}

export const toggleCategory = (id) => (dispatch, getState) => {
    var state = getState();
    var category = getCategory(state.categories, id);
    console.log('Category', category);
    var companyCategory = category.companyCategory;
    var enabled = !(companyCategory && companyCategory.enabled===true);
    var method = enabled ? 'goals_categoryId_subscribe' : 'goals_categoryId_unsubscribe';

    //immediate update for latency compensation 
    dispatch(addCategory({...category, companyCategory:{...companyCategory, enabled}}));

    return new Promise(function(resolve, reject) {
        DatabaseService[method]({categoryId: id}).then(
            function(result){ 
                var companyCategory = result.companyCategory;
                resolve(companyCategory);
            },
            function(error){ 
                //Server update failed, revert to old state
                dispatch(addCategory(category));
                console.error(error);
                reject(error); 
            });
    });
}
