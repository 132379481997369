import {SET_COMPANIES, ADD_COMPANY, ADD_COMPANIES} from 'actions/types';

var companiesInitialState = {list:[], indexed:{}, listLoaded:false};
export default (state = companiesInitialState, action) => {
    switch(action.type) {
        case SET_COMPANIES: 
            return {...state,
                    list:action.companies.map((c)=>(c.id)),
                    indexed: action.companies.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    listLoaded:true
                    };
        case ADD_COMPANY: 
            return {...state,
                    indexed: {...state.indexed, [action.company.id]:action.company}
                    };
        case ADD_COMPANIES: 
            return {...state,
                    indexed: action.companies.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed})
                    };
        default:            
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getCompanies = state => state.list.map( id => state.indexed[id] );
export const getCompany  = (state, id) => state.indexed[id];
