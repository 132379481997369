import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {setSidebarEnable} from 'actions/sidebar.js';
import {initNavbarState} from 'actions/navbar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Objectives from './Objectives.js';


class ObjectivesContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      objectives:[],
                      objectiveToEdit:null};
        this.onShowEditObjective = this.onShowEditObjective.bind(this);
        this.onCloneObjective = this.onCloneObjective.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onToggleObjective = this.onToggleObjective.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(initNavbarState({enable:true, backButtonEnable:true, title: this.props.t('Manage Objectives')}));
        this.props.dispatch(setSidebarEnable(false));
        this.loadData(true);
    }

    componentWillUnmount() {
        this.props.dispatch(initNavbarState({enable:false}));
    }

    onShowEditObjective(objective) {
        //fixme - grab goals here
        this.setState({objectiveToEdit:objective});
    }

    onCloneObjective(objective) {
        //fixme - grab goals here

        //this.setState({objectiveToEdit:objective});
    }

    loadData(showSpinner) {
        showSpinner && this.setState({busy:true});
        DatabaseService.getObjectives()
            .then(result=>{
               this.setState({objectives:result.objectives});
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
               showSpinner && this.setState({busy:false});
            });
    }
    
    onRefresh() {
        this.loadData(false);
    }

    onToggleObjective(objective) {
        var originalObjectives = this.state.objectives;
        var companyObjective = objective.companyObjective || {enabled:false};
        var updatedCompanyObjective = {...companyObjective, enabled:!companyObjective.enabled};
        var updatedObjective = {...objective, companyObjective:updatedCompanyObjective};
        var updatedObjectives = this.state.objectives.map(c => c.id===objective.id ? updatedObjective : c  );
        this.setState({objectives:updatedObjectives})
        var method = updatedCompanyObjective.enabled ? 'subscribeObjective' : 'unsubscribeObjective';
        DatabaseService[method]({objectiveId: objective.id}).catch(error => {
            console.error(error);
            window.alert('Error updating objective, see logs');
        })
    }

    render() {
        return (
            <Objectives {...this.state}
                        onShowEditObjective={this.onShowEditObjective}
                        onCloneObjective={this.onCloneObjective}
                        onRefresh={this.onRefresh}
                        onToggleObjective={this.onToggleObjective}/>
      );
  }
}

export default withRouter(connect()(withTranslation('categories')(ObjectivesContainer)));
