import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/fr';
import packageDetails from '../package.json';

var debug = process.env.NODE_ENV=='development';
i18n.on('languageChanged', lng => {
        moment.locale(lng);
    });
i18n.use(XHR)
    .use(LanguageDetector)
    .init({
      nsSeparator: ':::',
      fallbackLng: 'en',
      fallbackNS: 'translation',
      debug: debug,
      parseMissingKeyHandler:parseMissingKeyHandler,
      appendNamespaceToMissingKey: true,

      saveMissing: true,
      missingKeyHandler: missingKeyHandler,

      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false
      },
      detection: {
          order: ['querystring', 'navigator', 'localStorage', 'cookie', 'htmlTag'],
      },
      backend: {
        loadPath: process.env.PUBLIC_URL+'/locales/{{lng}}/{{ns}}.json?v='+packageDetails.version+'.'+packageDetails.buildno,
      },
      lng: (localStorage.selectedLanguage ? localStorage.selectedLanguage : undefined),
    });


function missingKeyHandler(lng, ns, key, fallbackValue) {
    saveMissingKey(i18n.language, ns, key, fallbackValue);
}

function parseMissingKeyHandler(key) {
    return debug ? ('MISSING KEY "' + key + '"') : '';
}

function hashNsKey(nsKey) {
    var i  = nsKey.indexOf(':');
    var ns = nsKey.slice(0,i);
    var key= nsKey.slice(i+1);
}

function saveMissingKey(lng, ns, key, val) {
    var missingKeys = window.missingKeys = window.missingKeys || {};
    var locale = missingKeys[lng] = missingKeys[lng] || {};
    var namespace = locale[ns] = locale[ns] || {};
    namespace[key] = val;
}

window.getMissingKeys = () => JSON.stringify(window.missingKeys, null, 4);
window.i18n = i18n;

i18n.translateErrorCode = function(code, status) {
    var errorCode = (code && code > 1) ? code : status;
    return this.t('error_' + errorCode) || this.t('error_generic');
}

export default i18n;
