import React from 'react';
import SideBarContainer, {width as sideBarWidth} from 'modules/navigation/SideBarContainer.js';
import {colors} from 'style';
import NavBarContainer from  'modules/navigation/NavBarContainer.js';
import LoadSpinner from      'modules/loading/LoadSpinner.js';
import LoadOverlay from      'modules/loading/LoadOverlay.js';

import SessionTimer from      'modules/misc/SessionTimer.js';

var navBarHeights = {
    normal:'47px',
    light:'85px',
};
var sideBarTransitionTime = '0.3s'
var style = {
    topMenu: {
        position: 'fixed',
        top: '0',
        left: '0',  
        width: '100%',
        //transition: 'transform ease ' + sideBarTransitionTime,
        zIndex: '50'
    },
    content: {
        position:'relative', 
        width:'100%', 
        height:'100%', 
        //transition: 'transform ease ' + sideBarTransitionTime
    },
    sideBar: {
        zIndex: '100',
    }
}

const DefaultLayout = (props) => {
    var navBarHeight = navBarHeights[props.navbarMode];
    var paddingTop   = props.showNavbar   ? navBarHeight : '0px';
    var paddingLeft  = props.showSidebar ? sideBarWidth+'px' : '0px';
    var left         = props.showSidebar ? '0px' : '-'+sideBarWidth+'px';
    document.body.style.backgroundColor = colors.themeGrey;
    return (
        <div>
            {props.showNavbar &&
                <div style={{...style.topMenu, height:navBarHeight}}>
                    <NavBarContainer />
                </div>
            }
            { props.showSidebar && <SideBarContainer style={{...style.sideBar}} /> }
            <div style={{...style.content, paddingTop, paddingLeft}}>
                {props.children}
            </div>
            <LoadSpinner />
            <LoadOverlay />
            <SessionTimer />
        </div>
    );
};

export default DefaultLayout;
