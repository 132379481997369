import React from 'react';
import { withTranslation } from 'react-i18next';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Card, Icon, Button, Image} from 'semantic-ui-react';

const styles = {
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    trashIcon: {
        background: 'none',
        color: colors.warningRed
    }
}

const contentCard = ({item, actions, onClick, icons}) => {
    return (
        <Card onClick={onClick}>
            <Card.Content><Card.Header>{item.banner}</Card.Header></Card.Content>
            <Image size='large' src={item.image} wrapped ui={false} />
            <Card.Content>
                <Card.Header>{item.title}</Card.Header>
                {
                    item.fields &&
                    item.fields.map((field) => {
                        return (
                            <Card.Description>
                                <h4>{field.label}</h4>
                                {field.text}
                            </Card.Description>
                        )
                    })
                }
            </Card.Content>
            <Card.Content extra style={styles.cardActions}>
                {
                    actions && actions.map(action => (
                        <Button style={styles.trashIcon} onClick={() => action.invoke(item)}>
                            <Icon name={action.name} />
                        </Button>
                    ))
                }
                {
                    icons && icons.map(icon => (
                        <div>
                            <Icon style={icon.style} name={icon.name} />
                        </div>
                    ))
                }
            </Card.Content>
        </Card>
    )
}

export const ContentCard = withTranslation('card')(contentCard)
