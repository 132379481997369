import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Checkbox, Button, Modal} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';
import defaultAvatar from 'img/default-avatar.png';

var style = {
    ...styleDef,
    checkbox: {
        marginRight: spacings.tiny,
    },
};



const InviteEmployeesModal = ({t,
                               inviteMode,
                               uninvitedCount,
                               unregisteredCount,
                               onSubmit,
                               onChange,
                               onClose}) => (
    <Modal open style={style.content} onClose={onClose} size='mini'>
        <Modal.Header>{t('Invite Members')}</Modal.Header>
        <Modal.Content>
            <Modal.Description>

                <Checkbox
                    radio
                    label={t('new_users_option', {count:uninvitedCount})}
                    name='inviteMode'
                    value='new'
                    checked={inviteMode === 'new'}
                    onChange={onChange}
                    style={style.checkbox}/>
                <br />
                <Checkbox
                    radio
                    label={t('unregistered_users_option', {count: unregisteredCount})}
                    name='inviteMode'
                    value='unregistered'
                    checked={inviteMode === 'unregistered'}
                    onChange={onChange}
                    style={style.checkbox}/>

                <br />
                <br />
                <Button style={style.button} onClick={onSubmit}>{t('Invite')}</Button>
                <br />
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('employees')(InviteEmployeesModal);
