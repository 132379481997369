import DatabaseService from 'services/DatabaseService.js';
import {SET_INVITATIONS, SET_PUBLIC_INVITATIONS} from 'actions/types';

//Action Creators
export const setInvitations = (invitations, count) => {
    return { type:SET_INVITATIONS, invitations:invitations, count: count};
}

export const setPublicInvitations = (invitations, count) => {
    return { type:SET_PUBLIC_INVITATIONS, invitations:invitations, count: count};
}

//Thunks
export const fetchInvitations = (params) => (dispatch) => {
    return new Promise(function(resolve, reject) {
        if (params.query && params.query !== '' && params.queryField !== '') {
            params[`like.${params.queryField}`] = params.query
        }
        if (params.offset) {
            params.start = params.offset
        }
        DatabaseService.getInvitations(params).then(
            function(result){
                console.log(result)
                result.invitations = result.rows.map(invitation => {
                    return {
                        ...invitation
                    }
                })
                if (params?.where?.companyEmployeeId) {
                    dispatch(setPublicInvitations(result.invitations, result.count))
                } else {
                    dispatch(setInvitations(result.invitations, result.count))
                }
                resolve({data: result.invitations, count: result.count})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
