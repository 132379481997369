
var mode = process.env.REACT_APP_ENV || 'production';

var LocalKeys = {
    API_URL                 : 'http://localhost:4040/v4/admin',
}

var Local2Keys = {
    API_URL                 : 'http://localhost:5050/v4/admin',
}

var Local3Keys = {
    API_URL                 : 'http://localhost:6060/v4/admin',
}

var Local4Keys = {
    API_URL                 : 'http://localhost:7070/v4/admin',
}

var Local5Keys = {
    API_URL                 : 'http://localhost:8080/v4/admin',
}

var DevKeys = {
    API_URL                 : 'https://dev-rest.myoptimity.com/v4/admin',
}

var Dev1Keys = {
    API_URL                 : 'https://dev-rest.myoptimity.com/v4/admin',
}

var Dev2Keys = {
    API_URL                 : 'https://optimity-dev-us-central-rest.azurewebsites.net/v4/admin',
}

var Staging2Keys = {
    API_URL                 : 'https://optimity-prod-us-central-rest-staging.azurewebsites.net/v4/admin',
}

var ProdKeys = {
    API_URL                 : 'https://rest.myoptimity.com/v4/admin',
};

var Prod2Keys = {
    API_URL                 : 'https://us.rest.myoptimity.com/v4/admin',
};

var Prod3Keys = {
    API_URL                 : 'https://carrot.rest.myoptimity.com/v4/admin',
};

var Prod4Keys = {
    API_URL                 : 'https://carrot-us.rest.myoptimity.com/v4/admin',
};

var ProdSureifyKeys = {
    API_URL                 : 'https://sureify-rest.myoptimity.com/v4/admin',
}

var ProdPrescryptiveKeys = {
    API_URL                 : 'https://prescryptive.rest.myoptimity.com/v4/admin',
};

var ProdDemo = {
    API_URL                 : 'https://demo.rest.myoptimity.com/v4/admin',
};

var ProdCGS = {
    API_URL                 : 'https://cgs.rest.myoptimity.com/v4/admin',
};

var ProdStageCGS = {
    API_URL                 : 'https://cgs-stage.rest.myoptimity.com/v4/admin',
};

var Keys = null;
switch(mode) {
    case 'local':
        Keys = LocalKeys; 
    break;
    case 'local2':
        Keys = Local2Keys; 
    break;
    case 'local3':
        Keys = Local3Keys; 
    break;
    case 'local4':
        Keys = Local4Keys; 
    break;
    case 'local5':
        Keys = Local5Keys; 
    break;
    case 'dev':
        Keys = DevKeys; 
    break;
    case 'dev1':
        Keys = Dev1Keys; 
    break;
    case 'dev2':
        Keys = Dev2Keys; 
    break;
    case 'staging2':
        Keys = Staging2Keys; 
    break;
    case 'production':
        Keys = ProdKeys; 
    break;
    case 'prod2':
        Keys = Prod2Keys; 
    break;
    case 'prod3':
        Keys = Prod3Keys; 
    break;
    case 'prod4':
        Keys = Prod4Keys; 
    break;
    case 'prodsureify':
        Keys = ProdSureifyKeys; 
    break;
    case 'prodprescryptive':
        Keys = ProdPrescryptiveKeys;
    break;
    case 'proddemo':
        Keys = ProdDemo
    break;
    case 'prodstagecgs':
        Keys = ProdStageCGS
    break;
    case 'prodcgs':
        Keys = ProdCGS
    break;
    default: 
        Keys = DevKeys;
}


export default Keys;
