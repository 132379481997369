// This component is for use with 
// react-day-picker
import React, {Component} from 'react';
import moment from 'moment';
import {Dropdown} from 'semantic-ui-react';
import Utils from 'utils/Utils.js';

const currentYear = new Date().getFullYear();

// Component will receive date, locale and localeUtils props
class YearMonthForm extends Component {
    constructor(props) {
        super(props);
        this.state = {year:'', month:''};
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }

    handleMonthChange(e, d) {
        var yearValue = this.year.props.value; 
        var monthValue = d.value; 
        this.props.onChange(moment(new Date(yearValue, monthValue)));
    };

    handleYearChange (e, d) {
        console.log('handleYearChange ref=', this.year);
        console.log('handleYearChange ref.value=', this.year.props.value);
        console.log('handleYearChange', e, e.currentTarget.form, d);
   
        var yearValue = d.value; 
        var monthValue = this.month.props.value; 
        this.props.onChange(moment(new Date(yearValue, monthValue)));
    };

    render() {
      const months = this.props.localeUtils.getMonths(this.props.locale);

      const years = [];

      var year = this.props.date.getFullYear();
      var yearStr = String(this.props.date.getFullYear())
      for (let i = year-100; i <= year+100; i++) {
        years.push(i);
      }
        
      var month = this.props.date.getMonth();
      var monthStr = months[month];


      return (
        <div className="DayPicker-Caption">
          <Dropdown ref={(r)=>{this.month=r}} 
                    placeholder='Month' scrolling
                    options={months.map((m,i)=>({key:i, value:i, text:m}))} 
                    text={monthStr} 
                    value={month} 
                    onChange={this.handleMonthChange} />

          <Dropdown key={year} //hack to force new component - fixes issue where selected item is not scrolled into view
                    style={{marginLeft:'25px'}} ref={(r)=>{this.year=r}} 
                    placeholder='Year' scrolling 
                    options={years.map(y=>({key:y, value:y, text:y}))} 
                    text={yearStr} 
                    value={year} 
                    onChange={this.handleYearChange} />


        </div>
      );
    }
}


export default YearMonthForm;
