import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {fetchTeams} from 'actions/teams.js';
import {getTeam, getTeams} from 'reducers/teams.js';

import TeamAssignModal from './TeamAssignModal.js';

class TeamAssignModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { inviteMode:'new', 
                       loadingEmployees: false,
                       selectedEmployee:null,
                     };
        this.onChangeTeam = this.onChangeTeam.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
    }

    onChangeTeam(event, employee, teamId) {
        event && event.stopPropagation();

        this.setState({selectedEmployee:null});
        
        this.props.onChangeTeam(employee, teamId);
    }

    onClickRow(employee) {
        this.setState(state => ({selectedEmployee:((state.selectedEmployee && employee && state.selectedEmployee.id===employee.id) ? null : employee)}));
    }

    render() {
        return (
            <TeamAssignModal {...this.props}
                             {...this.state}
                             onChangeTeam={this.onChangeTeam}
                             onClickRow={this.onClickRow} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    var team = getTeam(state.teams, ownProps.match.params.id) || {};
    var teams = getTeams(state.teams);
    return {team, teams};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTeams: () => dispatch(fetchTeams()),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('teams')(TeamAssignModalContainer)));
