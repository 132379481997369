import DatabaseService from 'services/DatabaseService.js';
import {SET_LEADERBOARDS, 
        SET_TEAM_CHALLENGE_LEADERBOARDS, 
        ADD_LEADERBOARD,
        ADD_LEADERBOARD_RECORDS,
        SET_LEADERBOARD_RECORDS,
        CLEAR_LEADERBOARD_RECORDS,
        ADD_CHALLENGE_RECORDS,
        SET_TEAM_CHALLENGE_INFO} from 'actions/types';
import {getLeaderboard, 
        getLeaderboards,
        getTeamChallengeLeaderboards, 
        isListLoaded,
        isTeamChallengeListLoaded}  from 'reducers/leaderboards.js'

//Action Creators
export const setLeaderboards = (leaderboards) => {
    return { type:SET_LEADERBOARDS, leaderboards:leaderboards };
}

export const setTeamChallengeLeaderboards = (leaderboards) => {
    return { type:SET_TEAM_CHALLENGE_LEADERBOARDS, leaderboards:leaderboards };
}

export const addLeaderboard = (leaderboard) => {
    return { type:ADD_LEADERBOARD, leaderboard:leaderboard };
}

export const addLeaderboardRecords = (records, leaderboardId, column) => {
    return { type:ADD_LEADERBOARD_RECORDS, records:records, leaderboardId:leaderboardId, column };
}
export const setLeaderboardRecords = (records, leaderboardId, column, start, total) => {
    return { type:SET_LEADERBOARD_RECORDS, records, leaderboardId, column, start, total};
}
export const clearLeaderboardRecords = () => {
    return { type:CLEAR_LEADERBOARD_RECORDS };
}

export const addChallengeRecords = (challengeRecords) => {
    return { type:ADD_CHALLENGE_RECORDS, challengeRecords };
}

export const setTeamChallengeInfo = (teamChallengeInfo) => {
    return { type:SET_TEAM_CHALLENGE_INFO, teamChallengeInfo };
}


//Thunks
export const fetchLeaderboards = (force) => (dispatch, getState) => {
    var leaderboard = null;
    if(!force) {
        var state = getState();
        if(isListLoaded(state.leaderboards)) {
            return Promise.resolve(getLeaderboards(state.leaderboards));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.fetchLeaderboards().then(
            function(result){ 
                dispatch(setLeaderboards(result.leaderboards));
                resolve(result.leaderboards);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}


export const fetchLeaderboard = (id, force) => (dispatch, getState) => {
    if(!force) {
        var leaderboard = getLeaderboard(getState().leaderboards, id);
        if(leaderboard) {
            return Promise.resolve(leaderboard);
        }
    }
    
    return new Promise(function(resolve, reject) {
        DatabaseService.fetchLeaderboard({leaderboardId:id}).then(
            function(result){ 
                dispatch(addLeaderboard(result.leaderboard));
                resolve(result.leaderboard);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}

export const fetchLeaderboardAndRecords = (leaderboard, start, limit) => (dispatch, getState) => {
        var params = {
            seasonId : leaderboard.seasonId || undefined,
            categoryId : leaderboard.categoryId || undefined,
            challengeId : leaderboard.challengeId || undefined,
            teamId : (leaderboard.team ? leaderboard.team.id : leaderboard.teamId) || undefined,
            scope : leaderboard.scope,
            type : leaderboard.type,
            start: start,
            limit : limit
        };
        return new Promise(function(resolve, reject) {
            DatabaseService.fetchRecords(params).then(
                function(result){ 
                    var records = result.records;
                    var {start, total} = result.summary
                    var column = leaderboard.column || 'points';
                    dispatch(setLeaderboardRecords(records, leaderboard.id, column, start, total))
                    resolve({records, start, total})
                },
                function(error){ 
                    console.error(error);
                    reject(error); 
                });
        });
}





export const fetchTeamChallengeLeaderboards = (force) => (dispatch, getState) => {
    var leaderboard = null;
    if(!force) {
        var state = getState();
        if(isTeamChallengeListLoaded(state.leaderboards)) {
            return Promise.resolve(getTeamChallengeLeaderboards(state.leaderboards));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.fetchTeamChallengeLeaderboards().then(
            function(result){ 
                var leaderboards = result.leaderboards;
                var challengeRecords = leaderboards.map(l => ({leaderboardId:l.id, challengeRecord:l.challengeRecord}));
                var summary = result.summary;
                var teamChallengeInfo ={invitations:summary.invitations, 
                                        active:summary.active, 
                                        completed:summary.completed};
                leaderboards = leaderboards.map(l=>({...l, challengeRecord:null}));
                dispatch(setTeamChallengeLeaderboards(result.leaderboards));
                dispatch(addChallengeRecords(challengeRecords));
                dispatch(setTeamChallengeInfo(teamChallengeInfo));
                resolve(result.leaderboards);
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
