import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Card, Label, Icon, Button, Message, Popup } from 'semantic-ui-react';

import Utils from 'utils/Utils.js';
import Ring from 'modules/misc/Ring.js';

import {colors, fontSizes, fontFamilies, spacings, shadow} from 'style';

var style = {
    card: {
        width: '110px',
        overflow:'hidden',
        color: colors.themeBlue,
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
        margin: spacings.base,
        marginLeft:"auto",
        marginRight:"auto"
    },
    iconHeader: {
        position: 'relative',
        padding:'0px 0px 0px 0px', 
        width:'110px',
        height:'110px',
        marginLeft:'auto',
        marginRight:'auto',
        color: 'rgb(51, 51, 51)',
        backgroundColor: '#fff',
    },
    ring:{
        position: 'absolute'
    },
    header: {
        fontSize: '1em',
        color: colors.themeBlue,
    },
    cardContent: {
        border: 'none',
        position: 'relative',
        color: colors.themeBlue,
    },
    value: {
        fontFamily: fontFamilies.base,
        fontSize: fontSizes.bodyS,
        textAlign: 'center',
    },
    header: {
        textAlign: 'center',
        color: colors.themeDarkerGrey,
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        paddingBottom: spacings.base,
    },
    icon: {
        position: 'absolute',
        width: '50px',
        height: '50px',
        fontSize:'40px',
        top: '50%',
        left: '50%',
        marginTop: '-25px',
        marginLeft: '-25px',
    },
    caret: {
        color: 'rgb(51, 51, 51)',
    },
};

const EngagementCard = ({t, title, text, progress, delta, deltaUnit, iconClass, color0, opacity0, color1, opacity1, tooltip}) => {
    let ring = () => <div style={style.iconHeader}>
        <Ring style={style.ring} 
                completeFactor={progress} 
                width="110px" 
                height="110px" 
                innerCirclePercent="90"
                color0={color0} opacity0={opacity0}
                color1={color1} opacity1={opacity1} />
        <Icon style={style.icon} size='huge' className={iconClass}>
        {delta!==null ? (
            <div style={{...style.value, color:(color1 || colors.themeDarkerGrey)}}>
                {delta>=0 ? <i style={style.caret} className='icon-arrow-up'/> : 
                            <i style={style.caret} className='icon-arrow-down'/>} 
                {Math.abs(delta).toFixed(0)} 
                {deltaUnit}
            </div>
        ) : (
            <div style={{...style.value, color:(color1 || colors.themeDarkerGrey)}}>
                --     
            </div>
        )}
        </Icon> 
    </div>
    return (
        <Card style={style.card}>
            <div style={style.header}>
                <div>{title}</div>
                <div>{text}</div>
            </div>
            {tooltip ?
            <Popup content={tooltip} trigger={ring()}/>
            : ring()}
        </Card>
    )
}
export default withTranslation('dashboard')(EngagementCard);
