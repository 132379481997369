import React, { createRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {Button, Dropdown, Checkbox, Modal, Input, Message, Form} from 'semantic-ui-react';

import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import DatabaseService from 'services/DatabaseService.js';
import { useDispatch } from 'react-redux';
import { SET_LOADING_OVERLAY_ENABLE } from 'actions/types/index.js';

var style = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: colors.themeGrey,
    },
    saveButton: {
        ...buttons.base,
        ...buttons.mediumWidth,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    buttonRow: {
        height: '50px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        alignItems: 'center',
        margin: "1rem"
    }
};


const AwardBonusGemsModal = ({ t, 
                                 onDeleteAnnouncement,
                                 onClose,
                                 deleteModalRef,
                                 required,
                                 roles,
                                 ...props
                               }) => {

    const fileSelector = createRef()
    const [gemsToAward, setGemsToAward] = useState()
    const [awardGemsFile, setAwardGemsFile] = useState()
    const [userProfilesToAward, setUserProfilesToAward] = useState()
    const dispatch = useDispatch()

    const onUploadCsv = () => {
        setAwardGemsFile(null)
        setUserProfilesToAward(null)
        fileSelector.current.click();
    }

    const onFileSelect = (event) => {
        const [file,] = event.target.files

        if (file.type != 'text/csv'){
            window.alert('Invalid CSV File')
            return
        }
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        event.target.value=null
        
        const reader = new FileReader();
        reader.onerror= (e) => { 
            window.alert(e)
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false})
        };
        reader.onload = async (e) => {
            setAwardGemsFile(file.name)
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            setUserProfilesToAward({csv: reader.result})
        };
        reader.readAsText(file, 'UTF-8');
    }

    const onSubmit = async (event) => {
        event && event.preventDefault();
        
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        try {
            const result = await DatabaseService.awardBonusGems({
                resources: gemsToAward,
                companyEmployees: userProfilesToAward
            })
            
            onClose();
        } catch(err) {
            window.alert('An error occurred - check logs');
            console.error(err)
        } finally {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }
    }
    
    return (
        <Modal open style={style.content} onClose={onClose} size='small'>
            <Modal.Content>
                {/* <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation = {onDeleteAnnouncement} /> */}
                <Modal.Description>
                    <Modal.Header>
                        <div style={{...style.flex, fontSize: fontSizes.h4}}>
                            Award Bonus Gems
                        </div>
                    </Modal.Header>
                    <Form onSubmit={onSubmit}>
                        <div style={style.flex}>
                            <span>Gems: </span>
                            <input type='number' placeholder='Enter gems' 
                                style={{width:'auto', padding: '0.5rem'}}
                                onChange={(e)=>setGemsToAward(e.target.value)}
                                />
                            
                        
                        </div>
                        <div style={style.flex}>
                            <input ref={fileSelector} type="file" style={{display:'none'}} onChange={onFileSelect} />
                            <span>Upload Users (CSV): </span>
                            <Button type='button' style={style.button} 
                                onClick={onUploadCsv}>{awardGemsFile ? t('Upload Success') : t('Upload Users')}
                            </Button>
                            <p style={style.centerAlign}>{awardGemsFile}</p>
                        </div>
                        <div style={style.flex}>
                            {`NOTE - csv must include the title "email" (all lowercase) in column A followed by list of emails in rows below it`}
                        </div>
                            
                        <div style={style.flex}>
                            <Button style={style.saveButton} disabled={!Boolean(gemsToAward) || !Boolean(userProfilesToAward)} type='submit'>{t('Save')}</Button>
                        </div>
                       
                        
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

export default withTranslation('announcements')(AwardBonusGemsModal);
