import {SET_REWARDS} from 'actions/types';


var rewardsInitialState = {list:[], indexed:{}, loaded:false};
export default (state = rewardsInitialState, action) => {
    switch(action.type) {
        case SET_REWARDS: 
            return {...state,
                    list:action.rewards.map((c)=>(c.id)),
                    indexed:action.rewards.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    loaded:true
                    };
        default:            
            return state;
    }
}

//selectors
export const isListLoaded = state => state.listLoaded;
export const getRewards = state => state.list.map( id => state.indexed[id] );
