import Utils from 'utils/Utils.js';
import moment from 'moment';

export const getName = (scopeObj) => {
    if(scopeObj.hasOwnProperty('fullName')) {
        return Utils.titleCase(scopeObj.fullName||'');
    } else if (scopeObj.hasOwnProperty('name')) {
        return Utils.titleCase(scopeObj.name||'');
    } else if(Array.isArray(scopeObj)) {
        return scopeObj.map(person=>Utils.titleCase(person.firstName||'')).join(', ');
    }
}

export const formatLeaderboardValue = (record, column) => {
    column = column || 'points';
    var value = record[column];

    if(column==='duration') {
      var minutes = Math.floor(value/60);
      var seconds = value%60;
      return  minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

    return value;
}
