import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Button, Modal,Card,Dropdown, Header, Input, Message} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons,fonts} from 'style';
import styleDef from './style.js';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container/Container';
import CategoryCard from '../../categories/CategoryCard.js';
var style = {
    ...styleDef,
    header : {
        ...fonts.h2,
        color: colors.themeDarkerGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    container : {
        marginTop : '30px',
        textAlign : 'center',
        marginRight : 'auto',
        marginLeft : 'auto'
        
    }
};


const PushSegmentModal = ({t,busy,
                            segment,
                            categories, 
                            onClose,
                            onToggleCategory
                        }) => ( 
    busy ? (
            <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
            </div>
                        ) : (
    <Modal open style={style.content} onClose={onClose} size='large'>
        <Modal.Header>{segment.name}</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div style={style.hearders}>{t('Suggested Content')}
                <Card.Group>
                {categories.map(category=>(
                    <CategoryCard key={category.id}
                                  category={category}
                                  onEdit={null}
                                  onToggleCategory={onToggleCategory}/>
                ))}
                </Card.Group>
                </div>
                
                <div style={style.container}>
                <Button type="button" style={style.button} onClick={onClose}>{t('Done')}</Button> 
                </div>  
            </Modal.Description>
        </Modal.Content>
    </Modal>
    )
                        
);

export default withTranslation('segments')(PushSegmentModal);
