import React, {useState} from 'react'
import {withTranslation} from 'react-i18next'
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import { Button, Dropdown, Input, Popup, Checkbox, Icon } from 'semantic-ui-react'
import {FlexRow} from 'components/views/views'

const styles = {
    footer: {
        color: colors.themeDarkerGrey,
        paddingTop: spacings.base,
        fontSize: fontSizes.bodyS,
        marginTop: 8,
        marginBottom: 8
    },
    footerButtons: {
        float: 'right'
    }
}

const pageSizes = [
    {key:'10',  text:'Show 10 entries',  value:10},
    {key:'20',  text:'Show 20 entries',  value:20},
    {key:'50',  text:'Show 50 entries',  value:50},
    {key:'100', text:'Show 100 entries', value:100},
]

//Fields is used by tables and blocks showing table specific features for now
const PageSize = ({limit, onChangeLimit, search, toggleField, fields, selectedFields, setSearchField, searchField}) => {
    fields =  fields && fields.filter(field => field.master !== true || localStorage.master === 'true')
    const searchable = fields && fields.find(field => field.searchable)
    return (
        <FlexRow style={{justifyContent: 'space-between', marginBottom: 8, alignItems: 'top'}}>
            <FlexRow>
                {
                    limit &&
                    <Dropdown
                        options={pageSizes}
                        value={limit}
                        onChange={(e, {value}) => onChangeLimit(value)}
                        selection
                        closeOnChange
                    />
                }
                {
                    fields &&
                    <Popup trigger={<Button basic>{'Columns'}<Icon name='caret down' /></Button>}
                        basic
                        position='bottom right'
                        hoverable
                        on='click'
                        content = {Boolean(fields) && fields.map(field =>(
                                <div key = {field.key}>
                                    <Checkbox
                                        label = {field.name || field.title}
                                        checked = {selectedFields.find(f => f.key === field.key)}
                                        onChange = {() => toggleField(field)}
                                        value = {field.key}
                                    />
                                </div>
                            ))
                        }
                    />
                }
            </FlexRow>
            <FlexRow style={{justifyContent: 'flex-end'}}>
                {
                    searchable &&
                    <Dropdown
                        placeholder={'Search Field'}
                        options={fields.filter(field => field.searchable).map(field => {
                            return {
                                ...field,
                                text: field.name || field.title,
                                value: field.key
                            }
                        })}
                        onChange={(e, {value}) => setSearchField(value)}
                        selection
                        closeOnChange
                    />
                }
                {
                    searchable &&
                    <Input
                        placeholder='Search...'
                        onChange={(e, data) => search(data.value)}
                        disabled={!searchField}
                    />
                }
            </FlexRow>
        </FlexRow>
    )
}

const pageNavigationButtons = ({t, atStart, atEnd, onNextPage}) => (
    <span style={styles.footerButtons}>
        <Button disabled={atStart} onClick={()=>onNextPage(false)}>{t('Previous')}</Button>
        <Button disabled={atEnd} onClick={()=>onNextPage(true) }>{t('Next')}</Button>
    </span>
)
const PageNavigationButtons = withTranslation('tables')(pageNavigationButtons)

const PageNavigation = ({t, total, offset, limit, onNextPage, busy, size}) => {
    return (
        <div style={styles.footer}>
            <PageNavigationButtons onNextPage={onNextPage} atStart={offset===0} atEnd={offset===Math.ceil(total/limit) - 1}/>
            <div style={{...(busy && {visibility: 'hidden'})}}>{t('table_footer', {start:offset*limit+1, end:offset*limit+size, total:total})}</div>
        </div>
    )
}

export const PageSizeBar = withTranslation('tables')(PageSize)
export const PageNavigationBar = withTranslation('tables')(PageNavigation)