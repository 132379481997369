import styled from 'styled-components'

export const FlexView = styled.div`
    display: flex;
    flex: 1;
`

export const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`