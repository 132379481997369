import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js'
import {Button, Tab} from 'semantic-ui-react';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {setSidebarEnable} from 'actions/sidebar.js';
import {List} from 'components/list'
import InvitationUploadForm from './InvitationUploadForm'

import {getInvitations} from 'reducers/invitations';
import {fetchInvitations} from 'actions/invitations';
import {Buffer} from "buffer"
const styles = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    topPanel: {
        display: 'flex',
        marginBottom: 16
    }
}

const invitationFields = [
    {
        name: 'Code',
        key: 'code',
        default: true,
        searchable: true
    },
    {
        name: 'Uses',
        key: 'uses',
        default: true
    },
    {
        name: 'Link',
        key: 'link'
    },
    {
        name: 'Single Use',
        key: 'singleUse'
    }
]

const InvitationsContainer = ({t, dispatch, fetchInvitations}) => {
    const [update, setUpdate] = useState()
    const [invitationModal, setInvitationModal] = useState()
    const [response, setResponse] = useState() 
    const [error, setError] = useState()

    useEffect(() => {
        dispatch(setSidebarEnable(true))
    }, [])

    const fetchPublicInvitations = (params) => {
        params = {
            ...params,
            public: true
        }
        return fetchInvitations(params)
    }

    const fetchSingleUserInvitations = (params) => {
        params = {
            ...params,
            singleUse: true
        }
        return fetchInvitations(params)
    }

    const uploadInvitation = async (event, type) => {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload = async (e) => {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
            switch(type) {
                case 'reward':
                    var result = await DatabaseService.postInvitationRewards({
                        invitationRewards: {csv: {base64: Buffer.from(reader.result).toString('base64')}}
                    })
                    .catch((error)=> {
                        console.error(error)
                        setError(error)
                    })
                    if (result) {
                        setResponse(result)
                        setError()
                    }
                    break
                case 'invitation':
                    var result = await DatabaseService.postInvitations({
                        invitations: {csv: {base64: Buffer.from(reader.result).toString('base64')}},
                        overwrite: true
                    })
                    .catch((error)=> {
                        console.error(error)
                        setError(error)
                    })
                    if (result) {
                        setResponse(result)
                        setError()
                        setUpdate(!update)
                    }
                    break
            }
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }
        reader.readAsText(file, 'UTF-8');
    }

    const openInvitationModal = () => {
        setInvitationModal(true)
    }

    const cancelModal = () => {
        setInvitationModal(false)
        setResponse()
        setError()
    }

    const panes = [
        {
          menuItem: t('Public'),
          pane: {
            content: (
                <Tab.Pane>
                    <List
                        getData={fetchPublicInvitations}
                        searchData={fetchPublicInvitations}
                        update={update}
                        type='table'
                        actions={[
                        ]}
                        fields={invitationFields}
                    />
                </Tab.Pane>
            )
          },
        },
        {
          menuItem: t('Single Use'),
          pane: {
            content: (
                <Tab.Pane>
                    <List
                        getData={fetchSingleUserInvitations}
                        searchData={fetchSingleUserInvitations}
                        update={update}
                        type='table'
                        actions={[
                        ]}
                        fields={invitationFields}
                    />
                </Tab.Pane>
            )
          },
        },
        {
            menuItem: t('All Invitations'),
            pane: {
                content: (
                    <Tab.Pane>
                        <List
                            getData={fetchInvitations}
                            searchData={fetchInvitations}
                            update={update}
                            type='table'
                            fields={invitationFields}
                        />
                    </Tab.Pane>
                )
            },
        }
    ]

    return (
        <div style={styles.content}>
            <div style={styles.topPanel}>
                <div>
                    <h2>{t('Add')}</h2>
                    <Button onClick={openInvitationModal}>{t('Add Invitation')}</Button>
                </div>
            </div>
            <Tab panes={panes} renderActiveOnly={false}/>
            <InvitationUploadForm
                open={invitationModal}
                close={cancelModal}
                upload={uploadInvitation}
                response={response}
                error={error}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {invitations: getInvitations(state.invitations)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInvitations: (params, force) => {
            return dispatch(fetchInvitations(params, force))
        },
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('invitations')(InvitationsContainer)));