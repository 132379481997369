import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, fonts, buttons} from 'style';
import ProgressBar from 'modules/misc/ProgressBar.js';
var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
        marginTop: spacings.base
    },
    progressBar: {
        height: '24px',
        backgroundColor: '#fff',

    },
    progresswrapper : {
        position : "relative",
        top : "-24px"
    },
};
const colorMap = {
    success : '#5cb85c',
    info : '#5bc0de',
    warning : '#f0ad4e',
    danger : '#d9534f'
}
const RiskProfiles = ({t, 
                  busy, 
                  riskProfiles,
                  onHRA}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div><Button style={style.button} onClick={()=>onHRA()} >{t('See Health Risk Profile')}</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('Risk Profile')}</div>

            <div style={style.section}>
                
            <Grid stackable>
            <Grid.Row>
                <Grid.Column width={4}><b>{t('Title')}</b></Grid.Column>
                <Grid.Column width={8}><b>{t('Percentage out of', {count:riskProfiles.totalEmployee})}</b></Grid.Column>
                <Grid.Column width={4}><b>{t('Percentage')}</b>
                </Grid.Column>
            </Grid.Row> 
                {riskProfiles.risks.map((risk)=>(
                        <Grid.Row key={risk.id}>
                             <Grid.Column width={4}>{risk.name}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div style={style.progresswrapper}><span style={{float:"left"}}>low</span> <span style={{float:"right"}}>high</span><br/>
                                <ProgressBar style={style.progressBar} value={risk.progressValue} total={100} barColor={colorMap[risk.progressType]} borderColor={colorMap[risk.progressType]}/>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4} style={{color:colorMap[risk.progressType],float:"right",fontSize:'35px'}}>
                                    {risk.averagePercentage}
                            </Grid.Column>                   
                        </Grid.Row>
                ))}       
            </Grid>
            </div>
        </div>
    )
);

export default withTranslation('riskprofiles')(RiskProfiles);
