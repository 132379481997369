import {INIT_NAVBAR_STATE,
        SET_NAVBAR_ENABLE,
        SET_NAVBAR_BACK_BUTTON_ENABLE,
        SET_NAVBAR_DONE_BUTTON_ENABLE,
        SET_NAVBAR_CLOSE_BUTTON_ENABLE,
        SET_NAVBAR_MENU_ENABLE,
        SET_NAVBAR_REWARDS_ENABLE,
        SET_NAVBAR_TITLE}  from 'actions/types'

//Action Creators
export const initNavbarState = (state) => {
    return { type:INIT_NAVBAR_STATE, ...state};
}
export const setNavbarEnable = (enable) => {
    return { type:SET_NAVBAR_ENABLE, enable:enable};
}
export const setNavbarBackButtonEnable = (enable) => {
    return { type:SET_NAVBAR_BACK_BUTTON_ENABLE, enable:enable};
}
export const setNavbarDoneButtonEnable = (enable, iconOpts) => {
    return { type:SET_NAVBAR_DONE_BUTTON_ENABLE, enable:enable, doneIconOpts:iconOpts};
}
export const setNavbarCloseButtonEnable = (enable, iconOpts) => {
    return { type:SET_NAVBAR_CLOSE_BUTTON_ENABLE, enable:enable, closeIconOpts:iconOpts};
}
export const setNavbarTitle = (title) => {
    return { type:SET_NAVBAR_TITLE, title:title};
}
