import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Card, Button, Grid, Header, Message, Label, Segment, Checkbox} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, buttons, shadow} from 'style';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import CategoriesContainer from 'modules/categories/CategoriesContainer.js';


var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    segment: {
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    grid: {
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        //boxShadow: shadow,
        padding: spacings.base,
    },
    objectiveTitle: {
        display: 'inline',
        fontSize: fontSizes.bodyL,
        //paddingLeft: spacings.base,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '16px',
    },
    image: {
        display:'inline-block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '40px',
        height: '40px',
        backgroundSize:'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
    },
    toggle: {
        verticalAlign: 'middle',
    },
    noPadding : {
        paddingRight : '0px',
        width: '70px'
    },
    togglePacks:{
        //float: 'right',
        margin: spacings.micro,

    },
    card: {
        boxShadow:'none',
        width:'100%'
    },
    cardContent : {
        padding:"0"
    }
};

const CategoryCard = ({
    t, 
    busy, 
    category,
    onToggleCategory,
    noToggle
}) => (
    <Card style={style.card}>
        <Card.Content style={style.cardContent}>
            <Grid verticalAlign={'middle'} style={style.fullHeight} columns={16}>
                <Grid.Row key={category.categoryid}>
                {!noToggle && <Grid.Column textAlign={'center'} style={style.noPadding}>
                    <Checkbox className='green' disabled={!category.selectable} style={style.togglePacks} toggle checked = {Boolean(category.companyCategory && category.companyCategory.enabled)}  onClick={e=>{e.stopPropagation();onToggleCategory(category)}}/>
                </Grid.Column> }
                    <Grid.Column textAlign={'center'} style={style.noPadding}>
                        <div style={{...style.image, backgroundImage:Utils.url(Boolean(category.badge && category.badge.url) ? category.badge.url : defaultObjectiveImage)}}/>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={style.objectiveTitle}>
                            {category.title}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Card.Content>
    </Card>
);

export default withTranslation('categories')(CategoryCard);
