import React from 'react';
import { withTranslation } from 'react-i18next';
import {Menu, Icon} from 'semantic-ui-react';
import Color from 'color';
import Utils from '../../utils/Utils.js'
import {colors, spacings, fontSizes} from 'style';

export const width = 200;
var s = {
    content: {
        position: 'fixed', 
        float:'left', 
        height: '100%', 
        backgroundColor:'rgb(242, 242, 242)', 
        margin:'0px', 'top':'0', 
        width: width+'px',
    },
    menu: {
        borderRadius: '0',
        height:'100%',
        overflow: 'auto',
    },
    link: {
        fontWeight: '600',
        color: 'rgb(144, 164, 174)'
    },
    linkIcon: {
        color: colors.themeBlue,
        float: 'left',
        margin: '0 5px 0 0',
    },
    header: {
        padding: spacings.small,
        color: 'rgb(144, 164, 174)',
        fontSize: fontSizes.bodyL,
        lineHeight: '1.5',
    }
};


const SideBar = ({t, style, links, company, onClick}) => (
    <div style={{...s.content, ...style}}>
        <Menu style={s.menu} vertical fluid inverted>
            {Boolean(company) && 
                <div style={s.header}>{company.name}</div>
            }
            {links.map((link)=>(<a style={s.link} href={link.href} key={link.href} className={link.className} onClick={link.onClick || onClick} ><Icon style={s.linkIcon} name={link.icon} className={link.iconClassName}/>{t(link.title)}</a>))}
        </Menu>
    </div>
);

export default withTranslation('sidebar')(SideBar);
