import {colors, fontSizes, spacings, shadow, buttons} from 'style';

const style = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: colors.themeGrey,
    },
    description: {
        fontSize: fontSizes.body,
        color: colors.themeDarkerGrey,
    },
    button: {
        ...buttons.base,
        display: 'block',
        marginLeft:  'auto',
        marginRight: 'auto',
    },
}

export default style;
