import React, { Component, useState, useEffect, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import moment from 'moment';
import {List} from 'components/list'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Label, Tab, Checkbox, Grid, Button, Dropdown, Input, Icon} from 'semantic-ui-react';
import EditAnnouncementModalContainer from './modals/EditAnnouncementModalContainer.js';
import AnnouncementDetailsModalContainer from './modals/AnnouncementDetailsModalContainer.js';
import {getFutureAnnouncements, getPublicAnnouncements, getTargetedAnnouncements} from 'reducers/announcements';
import {fetchAnnouncements} from 'actions/announcements';
import AwardBonusGemsModal from './modals/AwardBonusGemsModal.js';

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'fr',  text:'Français', value:'fr'},
];

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    createButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    detailsButton: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    historyItem: {/*
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
        padding: spacings.base,
        borderBottom: '1px solid ' + colors.themeDarkerGrey,*/
    },
    historyTitle: {
        color: colors.themeBlue,
    },
    historyCount: {
        paddingTop: spacings.small,
        fontSize: fontSizes.bodyL,
        fontWeight: 'bold',
    },
    historyStatus: {
        fontSize: fontSizes.body,
        fontWeight: 'bold',
    },
    historySubtitle: {
        fontWeight: 'bold',
    },
    checkmark: {
        float: 'left',
        width: '42px',
        height: '42px',
        paddingTop: '8px',
        boxShadow: 'none',
        borderWidth: '3px',
        borderStyle: 'solid',
        color: colors.themeTeal,
        borderColor: colors.themeTeal,
        borderRadius: '50%',
        backgroundColor: '#fff',
    },
    queue: {
        // overflow: 'auto',
        // maxHeight: '900px',
    },
    queueItem: {
        /*padding: spacings.base,
        borderTop: '1px solid rgba(34,36,38,.15)',*/
    },
    queueItemContent: {
        display: 'inline-block',
    },
    queueIcon: {
        fontSize: '40px',
        color: colors.pointsYellow,
        float: 'left',

    },
    announcementForm: {
        border: '1px solid rgba(34,36,38,.15)',
        boxShadow: shadow,
        padding: spacings.base,
    },
    langPrompt: {
        paddingRight: spacings.tiny,
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    segmentCheckbox: {
        marginRight: spacings.tiny,
    },
    segmentDropdown: {
        minWidth: '130px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: '4px',
        textAlign: 'right',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    segmentOptions:{
        maxHeight: '400px',
        overflowY: 'scroll',
    },
    optionTitle: {
        textAlign: 'left',
        fontWeight: 'bold',
        cursor: 'default',
        padding: spacings.tiny,
        margin: '0',
    },
    option: {
        textAlign: 'left',
        cursor: 'pointer',
        paddingLeft: spacings.small,
        paddingRight: spacings.tiny,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
        margin: '0',
    },
    grid: {
        margin: '0',
    },
    pointsLabelContainer: {
        width: '100%', 
        paddingTop: spacings.tiny,
    },
    rewardLabel: {
        textAlign: 'right',
        color: '#fff',
        backgroundColor: colors.themeBlue,
        fontWeight: 'normal',
        position: 'relative',
        width: '100px',
        paddingRight: '20px',
        borderRadius: '100px',
    },
    pointsLabel: {
        backgroundColor: colors.pointsYellow,
    },
    starIcon: {
        position: 'absolute',
        left: '6px',
        top: '6px',
        fontSize: '1.5em',
        opacity: '1',
        color: '#FFF'
    },
    resourceIcon: {
        position: 'absolute',
        left: '6px',
        top: '6px',
        fontSize: '1.3em',
        opacity: '1',
        color: '#FFF'
    },

};

const announcementFields = [
    {
        name: 'Title',
        key: 'title',
        default: true,
        searchable: true
    },
    {
        name: 'Type',
        key: 'type'
    },
    {
        name: 'Total Sent',
        key: 'segmentCount',
        default: true
    },
    {
        name: "Total Users Who've Claimed Gems",
        key: 'segmentCompleted',
        default: true
    },
    {
        name: 'Scheduled For',
        key: 'sendAt',
        type: 'date',
        default: true
    },
    {
        name: 'Gems',
        key: 'resources',
        default: true
    },
    {
        name: 'Stars',
        key: 'points'
    }
]

const AnnouncementsContainer = ({ t, dispatch, fetchAnnouncements }) => {
    const [busy, setBusy] = useState(false)
    const [editAnnouncement, setEditAnnouncement] = useState()
    const [announcementForDetails, setAnnouncementForDetails] = useState()
    const [historyStart, setHistoryStart] = useState(0)
    const [historyLimit, setHistoryLimit] = useState(24)
    const [history, setHistory] = useState([])
    const [totalHistory, setTotalHistory] = useState([])
    const [queue, setQueue] = useState([])
    const [channels, setChannels] = useState([])
    const [recurrences, setRecurrences] = useState([])
    const [showBonusGemsModal, setShowBonusGemsModal] = useState(false)

    const onCloseBonusGemsModal = () => {
        setShowBonusGemsModal(false)
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true));
        loadData({getHistory:true, getQueue:true}, true);
    }, [])

    const loadData = ({getHistory, getQueue}, spinner) => {

        var queueParams = {
            state : 'pending',
            channels : true,
            recurrences : true,
            limit : 100, // TODO: this needs to be paginated,
            sortField : 'sendAt',
            sortOrder : 'ascending'
        };

        var historyParams = {
            state : 'sent',
            start: historyStart,
            limit: historyLimit
        };

        if (spinner)
            setBusy(true)

        Promise.all([
                getHistory ? DatabaseService.getAnnouncements(historyParams) : Promise.resolve(null),
                getQueue   ? DatabaseService.getAnnouncements(queueParams)   : Promise.resolve(null),
            ])
            .then(([historyResult, queueResult]) => {
                if (getHistory) {
                    setHistory(historyResult.announcements)
                    setTotalHistory(historyResult.summary.total)
                }
                if(getQueue) {
                    setQueue(queueResult.announcements)
                    setChannels(queueResult.channels)
                    setRecurrences(queueResult.recurrences)
                }
            })
            .catch((error)=> {
               console.error('ERROR:');
               console.error(error);
            })
            .then(()=>{
                if (spinner)
                    setBusy(false)
            });
    }

    const onEditAnnouncement = (announcement) => {
        setEditAnnouncement(announcement)
    }

    const onShowDetails = (announcement) => {
        setAnnouncementForDetails(announcement)
    }

    const onRefresh = () => {
        loadData({getHistory:true, getQueue:true}, false);
    }
    const panes = [
        {
            menuItem: t('Targeted'),
            pane: {
                content: (
                    <Tab.Pane>
                        <List
                            getData={(params) => fetchAnnouncements({...params, targeted: true})}
                            searchData={(params) => fetchAnnouncements({...params, targeted: true})}
                            type='table'
                            fields={announcementFields.concat({
                                name: 'Email',
                                key: 'email',
                                searchable: true
                            })}
                            actions={[{
                                name: 'list',
                                invoke: (announcement) => onShowDetails(announcement)
                            }]}
                        />
                    </Tab.Pane>
                )
            },
        },
        {
            menuItem: t('Public'),
            pane: {
                content: (
                    <Tab.Pane>
                        <List
                            getData={(params) => fetchAnnouncements({...params, public: true})}
                            searchData={(params) => fetchAnnouncements({...params, public: true})}
                            type='table'
                            fields={announcementFields}
                            actions={[{
                                name: 'list',
                                invoke: (announcement) => onShowDetails(announcement)
                            }]}
                        />
                    </Tab.Pane>
                )
            },
        },
        {
            menuItem: t('Upcoming'),
            pane: {
              content: (
                  <Tab.Pane>
                      <List
                          getData={(params) => fetchAnnouncements({...params, future: true})}
                          searchData={(params) => fetchAnnouncements({...params, future: true})}
                          type='table'
                          fields={announcementFields}
                          actions={[{
                              name: 'edit',
                              invoke: (announcement) => onEditAnnouncement(announcement)
                          }]}
                      />
                  </Tab.Pane>
              )
            },
        }
    ]

    

    return (
        busy ? (
            <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
            </div>
        ) : (
        <div style={style.content}>
            <div style={style.header}>
                {t('Announcements')}
                <Button style={style.createButton} onClick={()=>onEditAnnouncement({})}>
                    {t('Create Announcement')}
                </Button>
                {
                    localStorage.master=='true' && 
                    <Button style={style.createButton} onClick={()=>setShowBonusGemsModal(true)}>
                        {t('Award Bonus Gems')}
                    </Button>
                }
            </div>
            
            <br />
            <br />
            <div style={style.section}>
                <Tab panes={panes} renderActiveOnly={false}/>
            </div>
            {Boolean(editAnnouncement) &&
                <EditAnnouncementModalContainer
                    announcement={editAnnouncement}
                    recurrences={recurrences}
                    channels={channels}
                    languages={languages}
                    onRefresh={onRefresh}
                    onClose={()=>onEditAnnouncement(null)}
                />
            }

            {Boolean(announcementForDetails) &&
                <AnnouncementDetailsModalContainer
                    announcement={announcementForDetails}
                    onClose={()=>onShowDetails(null)}
                />
            }

            {
                Boolean(showBonusGemsModal) && 
                <AwardBonusGemsModal onClose={onCloseBonusGemsModal} />
            }
        </div>
        )
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        future: getFutureAnnouncements(state.announcements),
        public: getPublicAnnouncements(state.announcements),
        individual: getTargetedAnnouncements(state.announcements)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAnnouncements: (params, force) => {
            return dispatch(fetchAnnouncements(params, force))
        },
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('announcements')(AnnouncementsContainer)));
