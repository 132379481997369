import React from 'react';
import { withTranslation } from 'react-i18next';
import {Button, Dropdown, Checkbox, Modal, Input, Message} from 'semantic-ui-react';

import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import AnnouncementForm from '../AnnouncementForm.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'

var style = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: colors.themeGrey,
    },
    saveButton: {
        ...buttons.base,
        ...buttons.mediumWidth,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    buttonRow: {
        height: '50px',
    },
};


const EditAnnouncementModal = ({ t, 
                                 onDeleteAnnouncement,
                                 onClose,
                                 deleteModalRef,
                                 required,
                                 roles,
                                 ...props
                               }) => (
    <Modal open style={style.content} onClose={onClose} size='small'>
        <Modal.Content>
            <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation = {onDeleteAnnouncement} />
            <Modal.Description>
                <AnnouncementForm {...props} roles={roles}>
                    <div style={style.buttonRow}>
                    {props.update &&
                        <Button style={buttons.delete} type='button' onClick={() => {deleteModalRef.current.showDeletePrompt()}}>{t('Delete')}</Button>            
                    }
                    <Button style={style.saveButton} disabled={Boolean(props.loadingMembers) || required} type='submit'>{t('Save')}</Button>            
                    </div>
                </AnnouncementForm>
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default withTranslation('announcements')(EditAnnouncementModal);
