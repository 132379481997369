import React from 'react';
import { Link } from 'react-router-dom';
import {Menu, Icon, Label} from 'semantic-ui-react';

import {colors, shadow, fontSizes, spacings} from 'style';

var style = {
    menu: {
        borderRadius: '0',
        backgroundColor: 'rgb(27, 28, 29)',
        height: '100%',
    },
    resourcesLink: {
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0)',
        padding: '5px 10px 5px 5px',
        margin: '-2px -8px -2px -8px',
        borderRadius: '20px',
        border: '1px solid white',
    },
    pointsLink: {
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0)',
        padding: '5px 10px 5px 5px',
        margin: '-2px -8px -2px -8px',
        borderRadius: '20px',
        border: '1px solid white',
    },
    iconWrapper: {
        display: 'inline-block',
        position: 'relative',
        width: '30px',
        height: '14px',
    },
    pointsIcon: {
        fontSize: '18px',
        position: 'absolute',
        top: '0px',
        left: '0px'
    },
    resourcesIcon: {
        position: 'absolute',
        top: '2px',
        left: '5px'
    },
    titleWrapper: {
        position: 'absolute',
        left: '50%',
        top: '0',
    },
    title: {
        position: 'relative',
        left: '-50%',
        fontSize: '1.2em',
        color: '#fff',
        opacity: '1',
    },
    icon: {
        color: '#fff',
        opacity: '1',
    },
    bold: {
        fontWeight: 'bold',
    },
    badgeNumber: {
        position: 'absolute',
        top:   '5px',
        right: '5px',   
    },
    item: {
        color: '#fff',
    }
};

var modes = {
    normal: {
        menu: {
            boxShadow: 'none',
            color: '#fff',
        },
        logoWrapper: {
            display: 'none',
        }
    },
    light: {
        menu: {
            height: '85px',
            borderRadius: '0',
            backgroundColor: '#fff',
            color: colors.themeDarkGrey,
        },
        icon: {
            color: colors.themeDarkGrey,
            opacity: '1',
        },
        title: {
            display: 'none',
        },
        logo: {
            color: colors.themeTeal,
            fontSize: fontSizes.h2,
            
            position: 'relative',
            left: '-50%',
        },
        logoWrapper: {
            position: 'absolute',
            left: '50%',
            top: '0',
            lineHeight: '85px',
        },
        item: {
            color: colors.themeDarkGrey,
        }
    }
};

const getStyle = (mode, ...classNames) => {
    return classNames.reduce((result, className) => ({...result, ...style[className], ...(modes[mode] && modes[mode][className])}), {});
};

const NavBar = (props) => {
    if (!props.enable) {
        return null;
    }
    var m = props.mode;

    return (
        <Menu className="NavBar" style={getStyle(m, 'menu')}>
            {props.menuEnable &&
                <Menu.Item style={getStyle(m, 'item')} name='Menu' onClick={props.onClickMenuButton}><Icon style={getStyle(m, 'icon', 'bold')} size='large' name={props.sideBarOpen ? 'x' : null} className={props.sideBarOpen ? null : 'icon-menu' }/></Menu.Item>
            }
            {props.backButtonEnable && !props.closeButtonEnable &&
                <Menu.Item style={getStyle(m, 'item')} name='Back' onClick={props.onBack}><Icon style={getStyle(m, 'icon', 'bold')} size='large' name='chevron left'/></Menu.Item>
            }
            {props.closeButtonEnable &&
                <Menu.Item style={getStyle(m, 'item')} name='Close' onClick={props.onClose}><Icon style={getStyle(m, 'icon', 'bold')} size='large' name={props.closeIconOpts ? props.closeIconOpts.name : 'x'} className={props.closeIconOpts ? props.closeIconOpts.className : ''}/></Menu.Item>
            }

            <div style={getStyle(m, 'titleWrapper')}>
                <div style={getStyle(m, 'title')} className="item right floated borderless">{props.title}</div>
            </div>
            <div style={getStyle(m, 'logoWrapper')}>
                <i style={getStyle(m, 'logo')} className='icon-myHealthSphere-logo' />
            </div>
        
            <Menu.Menu position='right'>
                {Boolean(props.rewardsEnable && !props.doneEnable && props.points!==null) &&
                    <Link className="item" to="/leaderboards">
                        <div style={getStyle(m, 'pointsLink')}>
                            <div style={style.iconWrapper}>
                                <Icon style={style.pointsIcon} className="notClickable icon-star"/>
                            </div> 
                            {props.points} 
                        </div>
                    </Link>
                }
                {Boolean(props.rewardsEnable && !props.doneEnable && props.resources!==null) &&
                    <Link className="item" to="/rewards">
                        <div style={getStyle(m, 'resourcesLink')}>
                            <span style={style.iconWrapper}>
                                <Icon style={style.resourcesIcon} className='icon-Optimollar'/>
                            </span>
                            {props.resources}
                        </div>
                    </Link>
                }
                {props.rewardsEnable && !props.doneEnable &&
                    <Link className="item" to="/announcements">
                        <Icon style={getStyle(m, 'icon')} size="large" className='icon-news'/>
                        {Boolean(props.badgeNumbers.companyAnnouncementsBadge) &&
                        <Label style={getStyle(m, 'badgeNumber')} circular color='red' size='tiny'>{props.badgeNumbers.companyAnnouncementsBadge}</Label>
                        }
                    </Link>
                }
                {props.doneButtonEnable &&
                    <Menu.Item style={getStyle(m, 'item')} name='Done' onClick={props.onDone}>
                        {Boolean(props.doneIconOpts==null || (props.doneIconOpts.name!='' && props.doneIconOpts.className!='')) &&
                            <Icon style={getStyle(m, 'icon')} size='large' name={props.doneIconOpts ? props.doneIconOpts.name : 'checkmark'} className={props.doneIconOpts ? props.doneIconOpts.className : ''}/>
                        }
                        {props.doneIconOpts && props.doneIconOpts.label}
                    </Menu.Item>
                }
                {props.uploadEnable &&
                    <Menu.Item style={getStyle(m, 'item')} name='Upload' onClick={props.onUpload}><Icon style={getStyle(m, 'icon', 'bold')} size='large' name='upload'/></Menu.Item>
                }
            </Menu.Menu>
        </Menu>   
    );
}

export default NavBar;
