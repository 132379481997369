
import React, { Component } from 'react';

import DatabaseService from 'services/DatabaseService.js';
import Utils from '../../utils/Utils.js'
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {setNavbarCloseButtonEnable, setNavbarDoneButtonEnable} from 'actions/navbar.js';

import NavBar from './NavBar.js';

var eventListeners = {
    onClose:[],
    onDone:[]
}

const seconds = 1000;
const minutes = 60*seconds;

class NavBarContainer extends Component
{
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this);
        this.onClose= this.onClose.bind(this);
        this.onDone= this.onDone.bind(this);
        this.toggleSideBar = this.toggleSideBar.bind(this);
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }



    onBack(event) {
        this.props.history.goBack();
    }

    onClose() {
        eventListeners.onClose.forEach(onClose => onClose());
    }

    onDone() {
        eventListeners.onDone.forEach(onDone => onDone());
    }

    toggleSideBar() {
        this.props.dispatch({type:"TOGGLE_SIDEBAR"});
    }

    render() {
        return <NavBar title={this.props.title}
                       enable={this.props.enable}
                       rewardsEnable={this.props.rewardsEnable}
                       menuEnable={this.props.menuEnable}
                       backButtonEnable={this.props.backButtonEnable}
                       closeButtonEnable={this.props.closeButtonEnable}
                       closeIconOpts={this.props.closeIconOpts}
                       doneButtonEnable={this.props.doneButtonEnable}
                       uploadEnable={this.props.uploadEnable}
                       doneIconOpts={this.props.doneIconOpts}
                       sideBarOpen={this.props.sideBarOpen}
                       resources={this.props.resources}
                       points={this.props.points}
                       badgeNumbers={this.props.badgeNumbers}
                       mode={this.props.mode}
                       onBack={this.onBack}
                       onClose={this.onClose}
                       onDone={this.onDone}
                       onUpload={this.props.onUpload}
                       onClickMenuButton={this.toggleSideBar} />; 
    }
}

function mapStateToProps(state, ownProps) {
    var resources = state.user.userProfile ? (state.user.userProfile.resources || 0) : null;
    var points    = state.user.userProfile ? (state.user.userProfile.points    || 0) : null;
    var badgeNumbers = state.user.badgeNumbers;
    return {...state.navbar, 
            resources:resources, 
            points:points, 
            sideBarOpen: state.sidebar.enable,
            badgeNumbers:badgeNumbers};
}

export default withRouter(connect(mapStateToProps)(NavBarContainer));




class NavControl extends Component
{
    constructor(props) {
        super(props);
        //console.log('NavControl constructor', this);
        this.onClose = this.onClose.bind(this);
        this.onDone= this.onDone.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        //console.log('NavControl componentWillReceiveProps', nextProps);
        var closeOptsKey = this.props.closeIconOpts ? this.props.closeIconOpts.key : null;
        var nextCloseOptsKey = nextProps.closeIconOpts ? nextProps.closeIconOpts.key : null;
        var onCloseEn = Boolean(this.props.onClose);
        var nextOnCloseEn = Boolean(nextProps.onClose);
        if(closeOptsKey!==nextCloseOptsKey || onCloseEn!==nextOnCloseEn) {
            //console.log('NavControl update closebutton', nextOnCloseEn, nextProps.closeIconOpts);
            this.props.dispatch(setNavbarCloseButtonEnable(nextOnCloseEn, nextProps.closeIconOpts));
        }
        var doneOptsKey = this.props.doneIconOpts ? this.props.doneIconOpts.key : null;
        var nextDoneOptsKey = nextProps.doneIconOpts ? nextProps.doneIconOpts.key : null;
        var onDoneEn = Boolean(this.props.onDone);
        var nextOnDoneEn = Boolean(nextProps.onDone);
        if(doneOptsKey!==nextDoneOptsKey || onDoneEn!==nextOnDoneEn) {
            this.props.dispatch(setNavbarDoneButtonEnable(nextOnDoneEn, nextProps.doneIconOpts));
        }
    }

    componentDidMount() {
        //console.log('NavControl componentDidMount', this);
        eventListeners.onClose.push(this.onClose);
        eventListeners.onDone.push(this.onDone);
        if(this.props.onClose || this.props.closeIconOpts) {
            this.props.dispatch(setNavbarCloseButtonEnable(Boolean(this.props.onClose), this.props.closeIconOpts));
        }
        if(this.props.onDone || this.props.doneIconOpts) {
            this.props.dispatch(setNavbarDoneButtonEnable(Boolean(this.props.onDone), this.props.doneIconOpts));
        }
    }
    componentWillUnmount() {
        eventListeners.onClose = eventListeners.onClose.filter(onClose => onClose!==this.onClose);
        this.props.dispatch(setNavbarCloseButtonEnable(false));
        eventListeners.onDone = eventListeners.onDone.filter(onDone => onDone!==this.onDone);
        this.props.dispatch(setNavbarDoneButtonEnable(false));
    }
    onClose() {
        if(this.props.onClose) {
            this.props.onClose();
        }
    }
    onDone() {
        if(this.props.onDone) {
            this.props.onDone();
        }
    }
    render() {
        return React.Children.only(this.props.children);
    }
};
const NavControlConnect = connect()((NavControl));
export {NavControlConnect as NavControl};
