import {SET_OBJECTIVES, ADD_OBJECTIVE} from 'actions/types';

var objectivesInitialState = {list:[], indexed:{}, listLoaded:false, records:{}};
export default (state = objectivesInitialState, action) => {
    switch(action.type) {
        case SET_OBJECTIVES: 
            return {...state,
                    list:action.objectives.map((c)=>(c.id)),
                    indexed: action.objectives.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    listLoaded:true
                    };
        case ADD_OBJECTIVE: 
            return {...state,
                    indexed: {...state.indexed, [action.objective.id]:action.objective}
                    };
        default:            
            return state;
    }
}

export const isListLoaded = state => state.listLoaded;
export const getObjectives = state => state.list.map( id => state.indexed[id] );
export const getObjective  = (state, id) => state.indexed[id];
