import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Exports from './Exports.js';

class ExportsContainer extends Component {

    constructor(props){
		super(props);
        this.state = {
            busy:false

        };

        console.log(this.props)
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
    }

    render() {
        return (
            <Exports
                {...this.state}
                companyId={localStorage.companyId}
                sessionToken={this.props.token}
            />
      );
  }
}

const mapExportProps = (state, ownProps) => {
    return { token   :state.user.token};
}

export default withRouter(connect(mapExportProps)(withTranslation('exports')(ExportsContainer)));
