import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Card, Button, Grid, Header, Message, Label, Dropdown} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, buttons, shadow} from 'style';
import SortIcon from 'modules/misc/SortIcon.js';
import EditTeamModalContainer from './modals/EditTeamModalContainer.js';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        textAlign: 'left',
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    employeeTableWrapper: {
        marginTop: spacings.tiny,
    },
    employeeTable: {
        minWidth: '100%',
        textAlign: 'left',
        padding: spacings.small,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        color: colors.themeDarkerGrey,
        borderSpacing: '0px',
        borderCollapse: 'collapse',
    },
    employeeTableTR: {
    },
    employeeTableTD: {
        padding: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
    employeeTableTH: {
        padding: spacings.tiny,
        backgroundColor: colors.themeGrey,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        cursor: 'pointer',
    },
    footer: {
        color: colors.themeDarkerGrey,
        paddingTop: spacings.base,
        fontSize: fontSizes.bodyS,
    },
    footerButtons: {
        float: 'right',
    },
    footerButton: {
        backgroundColor: '#fff',
        border: '1px solid ' + colors.themeDarkGrey1_2,
    },
    headings: {
        display: 'inline-block',
        paddingBottom: spacings.base,
        verticalAlign: 'top',
    },
    editButton: {
        ...buttons.short,
        lineHeight: '1',
        marginLeft: spacings.base,
        verticalAlign: 'top',
    },
    editIcon: {
        margin: '0',
        opacity: '1',
        fontSize: '30px',
    },
    imageWrapper: {
        backgroundColor: '#fff',
        display: 'inline-block',
        textAlign: 'center',
        marginRight: spacings.base,
        marginBottom: spacings.base,
        verticalAlign: 'top',
        border: '5px solid #fff',
        boxShadow: shadow,
    },
    teamAvatar: {
        display: 'block',
        height: '100px',
    },
    teamIcon: {
        display: 'block',
        fontSize: '100px',
    },
    removeTeamMemberButton: {
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.warningRed,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
};

const TeamDetails = ({t,
                      team,
                      teams,
                      employees,
                      start,
                      end,
                      atStart,
                      atEnd,
                      busy, 
                      loading,
                      sortBy,
                      sortField,
                      sortAsc,
                      showEditTeam,
                      onChangeTeam,
                      onDeleteTeamMember,
                      onNextPage,
                      onShowEditTeam,
                      onRefresh,
                      totalEmployees,
                      }) => (
    loading ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.imageWrapper}>
            {Boolean(team.avatar && team.avatar.url) ? (
                <img alt='' style={style.teamAvatar} src={team.avatar.url} />
            ) : (
                <i style={style.teamIcon} className='icon-team' />
            )}
            </div>
            <div style={style.headings}>
                <span style={style.header}>{team.name}</span>
                <Button style={style.editButton} onClick={e=>{e.stopPropagation(); onShowEditTeam(true)}}>
                    <Icon style={style.editIcon} name='edit'/>
                </Button>
                <div style={style.subheader}>{t('members_subheader', {count: team.employeesCount})}</div>
                {/*<div style={style.subheader}>{t('points_subheader', {count: team.points})}</div>*/}
            </div>
            <div style={style.section}>
                <div style={style.employeeTableWrapper}>
                <table style={style.employeeTable} cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr style={style.employeeTableTR}>
                            <th style={style.employeeTableTH} onClick={()=>sortBy('email')}>
                                <SortIcon field='email' sortField={sortField} asc={sortAsc} />
                                {t('Email')}
                            </th>
                            <th style={style.employeeTableTH} onClick={()=>sortBy('fullName')}>
                                <SortIcon field='fullName' sortField={sortField} asc={sortAsc} />
                                {t('Name')}
                            </th>
                            { localStorage.master === 'true' &&
                                <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                    {t('Position')}
                                </th>
                            }
                           
                            <th style={{...style.employeeTableTH, cursor: 'auto'}}>
                                {/* {t('Settings')} */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map(employee=>(
                            <tr style={style.employeeTableTR} key={employee.id}>
                                <td style={style.employeeTableTD}>{employee.email}</td>
                                <td style={style.employeeTableTD}>{employee.fullName}</td>
                                { localStorage.master === 'true' &&
                                    <td style={style.employeeTableTD}>{employee.position}</td>
                                }
                                <td style={{...style.employeeTableTD, textAlign: 'center'}}>
                                    <Button type="button" style={style.removeTeamMemberButton} onClick={()=>onDeleteTeamMember(employee, team)}>
                                        <Icon name='trash' />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                <div style={style.footer}>
                    <span style={style.footerButtons}>
                        <Button disabled={atStart} onClick={()=>onNextPage(false)}>{t('Previous')}</Button>
                        <Button disabled={atEnd}   onClick={()=>onNextPage(true) }>{t('Next')}</Button>
                    </span>
                    <div style={{...(busy && {visibility: 'hidden'})}}>{t('table_footer', {start:start, end:end, total:totalEmployees})}</div>
                </div>
            </div>
            {Boolean(showEditTeam) &&
                <EditTeamModalContainer team={team}
                                        onClose={()=>onShowEditTeam(false)}
                                        onRefresh={onRefresh} />
            }
        </div>
    )
);

export default withTranslation('teams')(TeamDetails);
