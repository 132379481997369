import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import {Button, Form, Grid, Message, Label, Segment} from 'semantic-ui-react';


import Utils from 'utils/Utils.js'
import backgroundImage from 'img/register-dark.png';
import styleDef from './style.js';

var style = { 
    ...styleDef,
    container: {
        minHeight: '100%',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundImage: Utils.url(backgroundImage),
    },
    column: {
        maxWidth: '400px'
    },
};

const ForgotPassword = ({t, 
                valid,
                email,
                onSubmit, 
                onEmailChange, 
                emailError,
                error}) => (
    <div style={style.container}>
        <br />
        <Form style={style.form} noValidate onSubmit={onSubmit}>
            <Grid style={style.content} textAlign="center">
                <Grid.Row style={style.row} verticalAlign='top'> 
                    <Grid.Column style={style.bannerColumn} verticalAlign='top'>
                        <div style={style.banner}>
                            <i className='icon-myHealthSphere-logo' style={style.logo} />
                            <br />
                            <div style={style.welcome}>{t('Reset your Password')}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row> 
                <Grid.Row> 
                    <Grid.Column style={style.column} verticalAlign='middle'>
                        <div style={style.inputLabel}>{t('Email Address')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(emailError.length ? style.inputError : {})}}
                                type="text"
                                name="userEmail"
                                value={email}
                                onChange={onEmailChange}
                                required
                            />
                            {emailError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{emailError}</Label>
                            }
                        </Form.Field>
                        {error.length > 0 &&
                            <Message negative>{error}</Message>
                        }
                        <br />
                        <br />
                        <Button style={valid ? style.tealButton : style.greyButton} type="submit">{t('Reset')}</Button>
                        <br />
                        <br />
                    </Grid.Column>
                </Grid.Row> 
            </Grid>
        </Form>
        <br />
    </div>
);

export default withTranslation('authentication')(ForgotPassword);
