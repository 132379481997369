import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import {Link} from 'react-router-dom';
import {Button, Form, Input, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';


import Utils from 'utils/Utils.js'
import backgroundImage from 'img/register-dark.png';
import {colors, fontSizes} from 'style';
import styleDef from './style.js';

var style = { 
    ...styleDef,
    container: {
        minHeight: '100%',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundImage: Utils.url(backgroundImage),
    },
    link: {
        cursor: 'pointer'
    },
    column: {
        maxWidth: '400px'
    },
};

const LogIn = ({t, 
                valid,
                email, 
                password, 
                onSubmit, 
                onForgotPassword,
                onEmailChange, 
                onPasswordChange,
                emailError, 
                passwordError, 
                error,
                notification}) => (
    <div style={style.container}>
        <br />
        <Form style={style.form} noValidate onSubmit={onSubmit}>
            <Grid style={style.content} textAlign="center">
                <Grid.Row style={style.row} verticalAlign='top'> 
                    <Grid.Column style={style.bannerColumn} verticalAlign='top'>
                        <div style={style.banner}>
                            <i className='icon-myHealthSphere-logo' style={style.logo} />
                            <br />
                            <div style={style.welcome}>{t('Welcome!')}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row> 
                <Grid.Row> 
                    <Grid.Column style={style.column} verticalAlign='middle'>
                        <div style={style.inputLabel}>{t('Email Address')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(emailError.length ? style.inputError : {})}}
                                type="text"
                                name="userEmail"
                                value={email}
                                onChange={onEmailChange}
                                required
                            />
                            {emailError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{emailError}</Label>
                            }
                        </Form.Field>        
                        <div style={style.inputLabel}>{t('Password')}</div>
                        <Form.Field style={style.field}>        
                            <input 
                                style={{...style.input, 
                                        ...(passwordError.length ? style.inputError : {})}}
                                type="password"
                                name="userPassword"
                                value={password}
                                onChange={onPasswordChange}
                                required
                            />
                            {passwordError.length > 0 &&
                                <Label basic color="red" pointing='right' style={style.errorLabel}>{passwordError}</Label>
                            }
                        </Form.Field>        
                        {error.length > 0 &&
                            <Message negative>{error}</Message>
                        }
                        <br />
                        <br />
                        <Button style={valid ? style.tealButton : style.greyButton} type="submit">{t('Log In')}</Button>
                        <br />
                        <br />
                        <a style={style.link} onClick={onForgotPassword}>{t('Forgot Password?')}</a>
                        <br />
                        {/*
                        <Trans i18nKey="new_here"> 
                            New here? <Link to='/register' style={style.anchor}>Sign Up</Link>
                        </Trans>
                        <br />
                        <br />
                        */}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {
                notification && 
                <Message>{notification}</Message>
            }
        </Form>
        <br />
    </div>
);

export default withTranslation('authentication')(LogIn);
