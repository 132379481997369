import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {SelectionModal} from 'components/modals'

const InvitationUploadForm = ({t, open, close, response, error, upload}) => {
    const [step, setStep] = useState(0)
    const [uploadType, setUploadType] = useState()
    const fileSelector = useRef()

    const cancelModal = () => {
        setStep(0)
        close()
    }

    const uploadFile = async (type) => {
        setUploadType(type)
        fileSelector.current.click()
    }

    const finishUpload = (event) => {
        upload(event, uploadType)
    }

    const nextForm = () => {
        if (step === 3) {
            cancelModal()
        } else {
            setStep(step => step+1)
        }
    }

    useEffect(() => {
        if (open) {
            setStep(1)
        }
    }, [open])

    useEffect(() => {
        if (response) {
            nextForm()
        }
    }, [response])

    return (
        <div>
            <input ref={fileSelector} style={{display: 'none'}} type="file" accept='.csv' onChange={finishUpload}/>
            <SelectionModal
                open={step === 1}
                fields={[
                    {
                        icon: 'upload',
                        text: 'Upload Rewards',
                        onClick: () => uploadFile('reward')
                    }
                ]}
                header={t('upload_reward_prompt')}
                cancel={cancelModal}
                buttons={[
                    {
                        text: 'Skip',
                        onClick: nextForm
                    }
                ]}
                error={error}
            />
            <SelectionModal
                open={step === 2}
                fields={[
                    {
                        icon: 'upload',
                        text: 'Upload Invitations',
                        onClick: () => uploadFile('invitation')
                    }
                ]}
                header={t('upload_invitation_prompt')}
                cancel={cancelModal}
                error={error}
            />
        </div>
    )
}

export default withTranslation('invitations')(InvitationUploadForm)