import {SET_PLUGINS} from 'actions/types';

var pluginsInitialState = {list:[], indexed:{}, loaded:false};
export default (state = pluginsInitialState, action) => {
    switch(action.type) {
        case SET_PLUGINS: 
            return {...state,
                    list:action.plugins.map((c)=>(c.id)),
                    indexed: action.plugins.reduce((m,c)=>{m[c.id]=c;return m;},{...state.indexed}),
                    loaded:true
                    };
        default:            
            return state;
    }
}



//selectors
export const isListLoaded = state => state.listLoaded;
export const getPlugins = state => state.list.map( id => state.indexed[id] );
export const getPlugin  = (state, id) => state.indexed[id];

export const getPluginsByTypeTag = (state, tag) => {
    return getPlugins(state).filter((plugin)=>(plugin.pluginType && plugin.pluginType.tag===tag));
};
