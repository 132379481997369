import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Message, Grid, Input, Button, Checkbox, Icon, Label, Dropdown, Tab, Form, Popup, TextArea} from 'semantic-ui-react';
import _ from 'underscore';
import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import ImageWithClose from 'modules/misc/ImageWithClose.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import Challenge from './Challenge.js';
import CategoryLevel from './CategoryLevel.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';



var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    section: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    buttonRow: {
        textAlign: 'center',
    },
    createButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    
    addButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },

    submitButton: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
        float: 'right',
    },
   
    addButtonRowVersion: {
        display: 'inline-block',
        backgroundColor: colors.themeGrey,
        color: colors.themeBlue,
        fontSize: fontSizes.h4,
        padding: '0',
        margin: '0',
        float: 'right',
    },

    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    
    title: {
        fontSize: fontSizes.h3
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    deleteButton: {
        ...buttons.delete,
        float: 'left'
    },
    image: {
        boxShadow: shadow,
        borderRadius: '9px',
        width: '40px',
        height: '40px',
        backgroundSize:'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        height: '200px',
        margin: spacings.base,
    },
    toggle: {
        verticalAlign: 'middle',
    },
    grid: {
        maxWidth: '600px',
        marginBottom: spacings.large,
    },
    objectiveTitle: {
        display: 'inline',
        paddingLeft: spacings.base,
    },

    addRow: {
        borderRadius: spacings.tiny,
        fontSize: fontSizes.bodyL, 
        padding: spacings.small,
        margin: spacings.tiny,
        borderRadius: spacings.micro,
        color: colors.themeBlue,
        border: '1px dashed #007fb9',
        backgroundColor: colors.themeGrey,
        fontStyle: 'italic',

    },

    charactersLeftDesc: {
        float : 'right',
        marginBottom: '10px'
    }
};


const Qualities = _.range(10).map(i => {
    var value = i + 1;
    return {key: value, value: value, text:String(value)};
});

export const PathwayPane = ({t, data, locale, valueChange, onChange}) => (
<div>
    <br />
    <Popup trigger={
            <Input  value={data && data.title || ''} 
            name= {'locales.'+locale+'.title'}
            placeholder = {t('title_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "100"/>
    }
        position = 'right center'
        content = {(100-valueChange) + ' characters left'}
        on='focus' />
    <br />
    <br />
    
    <Popup trigger={
            <TextArea 
            name = {"locales."+locale+".description"}
            placeholder ={t('description_prompt')}
            style={style.textarea}
            value={data && data.description || ''} 
            onChange={onChange}
            onFocus={onChange}
            maxLength = "800"></TextArea>
    }
        position = 'top right'
        content = {(800-valueChange) + ' characters left'}
        on='focus' />
    
</div>
);

const Pathway = ({t,
                fileSelector,
                finishUpload,
                error,
                update,
                valid,
                master,
                busy,
                categoryLevels,
                challenges,
                locale,
                locales,
                tag,
                languages,
                defaultTimes,
                defaultTime,
                objectives,
                autoSubscribe,
                companyId,
                hasPathway,
                objectiveCategories,
                uploadPhotoProps,
                badge,
                onDeleteActivityPack,
                leaderboardBanner,
                onToggle,
                onTogglePublic,
                onToggleHasPathway,
                onChangeDropdown,
                onChange,
                onSelectChallengeType,
                onAddLevel,
                onAddSection,
                onAddChallenge,
                onAddChallengeAnswer,
                onSubmit,
                onHideTab,
                onDeleteTab,
                onUploadPhoto,
                onConfirmPhoto,
                onCancelPhoto,
                getBasepath,
                deleteModalRef,
                valueChange,
                refLevel,
                refSection,
                refChallenge,
                refChallengeAnswer,
                onAutoCaption}) => {
        if (busy) {
            return (
                <div style={style.loaderContainer}>
                    <LoadInline centered vcentered /> 
                </div>
            )
        } else {
            return (
                <div style={style.content}>
                    {
                        error && 
                        <Message error>
                            <Message.Header>{error}</Message.Header>
                        </Message>
                    }
                    <input ref={fileSelector} style={{display: 'none'}} type="file" accept='.json' onChange={finishUpload}/>
                    <div style={style.section}>
                        <Form onSubmit={onSubmit}>
                            <span>{t('unique_tag_prompt')}</span>
                            <Input
                                name = {"tag"}
                                placeholder ={t('tag_prompt')}
                                value={tag || ''} 
                                onChange={onChange}
                                onFocus={onChange}
                                maxLength = "100"
                            />
                            <br />
                            <br />

                            <span style={style.langPrompt}>{t('preferred_language_prompt')}</span>
                            <Dropdown name='locale'
                                      options={languages}
                                      value={locale}
                                      onChange={onChangeDropdown}
                                      selection
                                      closeOnChange/>
        
                            <br />
                            <br />
        
                            <Tab panes={languages.map(language=>({
                                                            menuItem:language.text,
                                                            render:() => <PathwayPane t={t} 
                                                                            locale={language.value}
                                                                            data={locales[language.value]}
                                                                            onChange={onChange}
                                                                            valueChange = {valueChange} />}))} />
                               
                            <div>{t('badge_prompt')}</div>
                            {Boolean(badge && badge.enabled!==false && (badge.url || badge.dataURL)) &&
                                <ImageWithClose style={style.imagePreview} 
                                                src={badge.dataURL || badge.url} alt='' 
                                                onClose={()=>onCancelPhoto('badge')} />
                            }
                            <Button type='button' 
                                    style={style.uploadPhoto} 
                                    onClick={()=>onUploadPhoto({field:'badge.dataURL', width:128, height:128})}>
                                {t('upload_photo_button_text')}
                            </Button>
                            <br />
                            <br />
        
                            <div>{t('banner_prompt')}</div>
                            {Boolean(leaderboardBanner && leaderboardBanner.enabled!==false && (leaderboardBanner.url || leaderboardBanner.dataURL)) &&
                                <ImageWithClose style={style.imagePreview} 
                                                src={leaderboardBanner.dataURL || leaderboardBanner.url} alt='' 
                                                onClose={()=>onCancelPhoto('leaderboardBanner')} />
                            }
                            <Button type='button' 
                                    style={style.uploadPhoto} 
                                    onClick={()=>onUploadPhoto({field:'leaderboardBanner.dataURL', width:1280, height:720})}>
                                {t('upload_photo_button_text')}
                            </Button>
                            <br />
                            <br />
        
                            <div>{t('default_time_prompt')}</div>
                            <Dropdown name='defaultTime'
                                      options={defaultTimes}
                                      value={defaultTime}
                                      onChange={onChangeDropdown}
                                      selection
                                      closeOnChange/>
                            <br />
                            <br />
        
                            <div>{t('objectives_prompt')}</div>
                            <Grid style={style.grid}>
                            {objectives.map(objective=>(
                                <Grid.Row key={objective.id}>
                                    <Grid.Column width={2}>
                                        <div style={{...style.image, backgroundImage:Utils.url(Boolean(objective.image && objective.image.url) ? objective.image.url : defaultObjectiveImage)}} />
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        <span>
                                            <Checkbox name={'objectiveCategories.'+objective.id+'.enabled'} className='green' style={style.toggle} toggle checked={Boolean(Utils.deref(objectiveCategories, objective.id+'.enabled'), false)} onChange={onToggle}/>
                                        </span>
                                        <div style={style.objectiveTitle}>{objective.title}</div>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        {Boolean(Utils.deref(objectiveCategories, objective.id+'.enabled')) &&
                                            <div>
                                                <span>{t('relevancy_prompt')}</span>
                                                <Dropdown name={'objectiveCategories.'+objective.id+'.quality'}
                                                          options={Qualities}
                                                          value={Utils.deref(objectiveCategories, objective.id+'.quality')}
                                                          onChange={onChangeDropdown}
                                                          selection
                                                          compact
                                                          closeOnChange/>
                                            </div>
                                        }
                                        
                                    </Grid.Column>
                                </Grid.Row>
                            ))}
                            </Grid>
        
                            <div>{t('auto_subscribe_prompt')}</div>
                            <Checkbox name='autoSubscribe'
                                      className='green' 
                                      toggle 
                                      title={t('autosubscribe_toggle_goal_title')} 
                                      checked={Boolean(autoSubscribe)} 
                                      onChange={onToggle}/>
                            <br />
                            <br />
        
                            {master &&
                            <div>
                                <div>{t('public_prompt')}</div>
                                <Checkbox className='green' 
                                          toggle
                                          title={t('public_toggle_goal_title')} 
                                          checked={Boolean(!companyId)} 
                                          onChange={onTogglePublic}/>
                                <br />
                                <br />
                            </div>
                            }
        
                            <div>{t('pathway_prompt')}</div>
                            <Checkbox className='green' 
                                      toggle 
                                      checked={Boolean(hasPathway)} 
                                      onChange={onToggleHasPathway}/>
                            <br />
                            <br />
        
                            {Boolean(hasPathway) &&
                            <div>
                                <div>{t('levels_prompt')}</div>
        
                                {categoryLevels.map((categoryLevel, i)=>(
                                    <CategoryLevel key={categoryLevel.id || categoryLevel.key} 
                                        levelIndex={i} 
                                        languages={languages}
                                        categoryLevel={categoryLevel}
                                        onAddSection={onAddSection}
                                        onAddChallenge={onAddChallenge}
                                        onAddChallengeAnswer={onAddChallengeAnswer}
                                        onChange={onChange}
                                        onChangeDropdown={onChangeDropdown}
                                        onToggle={onToggle}
                                        onSelectChallengeType={onSelectChallengeType}
                                        onHideTab={onHideTab}
                                        valueChange = {valueChange}
                                        onDeleteTab={onDeleteTab}
                                        onUploadPhoto={onUploadPhoto}
                                        onCancelPhoto={onCancelPhoto}
                                        getBasepath={getBasepath}
                                        locale={locale}
                                        refLevel={refLevel}
                                        refSection = {refSection}
                                        refChallenge = {refChallenge}
                                        refChallengeAnswer = {refChallengeAnswer}
                                        onAutoCaption = {onAutoCaption}/> 
                                ))}                        
                                
                                <div style = {style.addRow}>
                                {t('add_level')}
                                <Button type='button' style={style.addButtonRowVersion} onClick={onAddLevel}>
                                    <Icon name="plus"></Icon>
                                </Button>
                                </div>
        
                                <Message positive>{t('level_message')}</Message>
                                <br />
                                <br />
                            </div>
                            }
                            {Boolean(!hasPathway) &&
                            <div>
                                <div>{t('activities_prompt')}</div>
        
                                {challenges.map((challenge, i)=>(
                                    <Challenge key={challenge.key || challenge.id}
                                        challengeIndex={i}
                                        challenge={challenge}
                                        onChange={onChange}
                                        onChangeDropdown={onChangeDropdown}
                                        onToggle={onToggle}
                                        languages={languages}
                                        onSelectChallengeType={onSelectChallengeType}
                                        onHideTab={onHideTab}
                                        onDeleteTab={onDeleteTab}
                                        valueChange = {valueChange}
                                        onAddChallengeAnswer={onAddChallengeAnswer}
                                        onUploadPhoto={onUploadPhoto}
                                        onCancelPhoto={onCancelPhoto}
                                        getBasepath={getBasepath}
                                        locale={locale}
                                        refChallenge = {refChallenge}
                                        refChallengeAnswer = {refChallengeAnswer}
                                        onAutoCaption = {onAutoCaption}/> 
                                ))}
                                <div style = {style.addRow}>
                                {t('add_activity')}
                                <Button type='button' style={style.addButtonRowVersion} onClick={onAddChallenge}>
                                    <Icon name="plus"></Icon>
                                </Button>
                                </div>
                                <br />
                                <br />
                                <br />
                                
                            
                            </div>
                            }
                            <div style={style.buttonRow}>
                            <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation = {onDeleteActivityPack}/>
                            <Button type="button" onClick={() => {deleteModalRef.current.showDeletePrompt()}} style={style.deleteButton} disabled={!valid}>
                                 {t('delete')}
                            </Button>
                            <Button type='submit' style={style.submitButton} disabled={!valid}>{update ? t('Save') : t('Create')}</Button>
                            
                            </div>
                            <br />
                            <br />
                        </Form>
                    </div>
        
                    {Boolean(uploadPhotoProps) &&
                        <ChoosePhotoContainer
                            onConfirmPhoto={photo=>onConfirmPhoto(uploadPhotoProps.field, photo)}
                            width={uploadPhotoProps.width}
                            height={uploadPhotoProps.height}
                            onClose={onUploadPhoto}
                        />
                    }
                </div>
            )
        }
    }

export default withTranslation('categories')(Pathway);
