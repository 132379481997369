import Utils from 'utils/Utils.js';
import defaultCategoryImage from 'img/default-challenge-image.jpg';

export const getNotificationFrequency = (t, notification) => {
    var pattern = notification!=null ? notification.pattern : 0;
    switch(pattern) {
        case 127: return t("categories:::Daily");
        case 65:  return t("categories:::Weekends");
        case 62:  return t("categories:::Weekdays");
        default:  return t("categories:::Custom");
    }
}

/*
export const isEnabled = (category) => {
    return Boolean(category!=null && category.userCategory!=null && category.userCategory.enabled==true)
}
*/
   
export const getNotification = (category) => {
    if(Boolean(category!=null && category.notifications && category.notifications.length==1)) {
        return category.notifications[0];
    } else {
        return null;
    }
}

export const formatTime = (time) => {
    time = Math.round(time);
    var hour = (Math.floor(time/60)+11)%12+1;
    var minute = (time % 60);
    return String(hour) + ':' + Utils.padZeros(String(minute),2) + (time / 60 >= 12 ? "PM" : "AM" );
}

export const getCategoryImageUrl = (category) => {
    if(category.leaderboardBanner && category.leaderboardBanner.url) {
        return category.leaderboardBanner.url;
    } else {
        return defaultCategoryImage;
    }
}

