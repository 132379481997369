import 'react-widgets/dist/css/react-widgets.css';
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Icon, Checkbox, Button, Modal, Dropdown, Grid, Card, Message, TextArea, Container, Input, Divider, Popup, Tab} from 'semantic-ui-react';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Calendar from 'react-widgets/lib/Calendar'
import moment from 'moment-timezone'

// ...Or if you prefer to use the Less or Sass files directly
// import 'react-widgets/lib/less/react-widgets.less';
// import 'react-widgets/lib/scss/react-widgets.scss';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import {getCompetitionImageUrl } from '../tools.js';
import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import ChallengeCard from '../ChallengeCard.js';
import CategoryCard from '../CategoryCard.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import RoleUtils from 'utils/RoleUtils'
//import styleDef from './style.js';
import defaultCategoryImage from 'img/default-challenge-image.jpg';

momentLocalizer(moment)

var style = {
    //...styleDef,
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

    },
    content: {

    },
    checkbox: {
        marginRight: spacings.tiny,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    description: {
        ...fonts.bodyL,
        lineHeight: '1.5',
        padding: spacings.base,
        textAlign: 'left',
        color: colors.themeDarkerGrey,
    },
    columns: {
    },
    textarea: {
        resize: 'vertical',
        minHeight: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    leftColumn: {
        display: 'inline-block',
        width: '33%',
        verticalAlign: 'top',
    },
    rightColumn: {
        display: 'inline-block',
        width: '67%',
        verticalAlign: 'top',
    },
    challengeList: {
        overflowY: 'auto',
        height: '300px',
    },
    challengeImage: {
        position: 'absolute',
        left: '-45px',
        top: '10px',
        height: '40px',
        width: '60px',
        backgroundSize:'100%',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        
        borderRadius: '6px',
        boxShadow: shadow,
    },
    challengeRow: {
        position: 'relative',
        marginLeft: '50px',
        height: '60px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    challengeTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bannerImage: {
        marginTop: '50px',
        width: '150px',
        borderRadius: '6px',
        boxShadow: shadow,
    },
    button: {
        //marginLeft: spacings.small,
        //marginRight: spacings.small,
        ...buttons.base,
    },

    button3: {
        //marginLeft: spacings.small,
       // marginRight: spacings.small,
        ...buttons.secondary,
        ...buttons.shortWidth,
    },
    gridSubmit : {
        textAlign : 'right'
    },
    h4 : {
        ...fonts.h4,
        color : colors.themeBlue,
        marginTop : spacings.base,
        marginBottom : spacings.base
    },
    body : {
        ...fonts.bodyL,
        color : colors.themeDarkerGrey
    },
    back : {
       fontSize: fontSizes.h4,
       //position: 'absolute',
       color: colors.themeDarkerGrey,
       cursor: 'pointer'
    },
    starIcon : {
        color: colors.pointsYellow,
        verticalAlign: 'baseline'
    },
    resourcesIcon : {
        color: colors.pointsBlue,
    },
    teamIcon: {
        fontSize: '20px',
        top: '-2px',
        left: '12px',
        position: 'absolute',
    },
    image: {
        position: 'absolute',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '40px',
        height: '40px',
        top: '-10px',
        left: '2px'
        // backgroundSize:'cover',
        // backgroundPositionX: 'center',
        // backgroundPositionY: 'center',
        // backgroundRepeat: 'no-repeat',
    },
    pointsCell : {
        padding : '0px'
    },
    teamSegmentRow: { listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    removeTeamSegmentButton: {
        float: 'right',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.warningRed,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
    slimRow : {
        paddingBottom: spacings.micro,
        paddingTop :spacings.micro
    },
    buttonsRow: {
        marginTop:spacings.large
    },
    banner: {
        width: '100%',
        //borderTopRightRadius: '9px',
        //borderTopLeftRadius: '9px',
        aspectRatio: 16 / 9,
        //height: '300px',
        objectFit: 'cover'
    },
    bannerText : {
        position: "absolute",
        bottom: "0",
        left: "0",
        textAlign : 'center',
        width: "100%",
        padding:spacings.tiny
    },
    segmentList: {
        height: '275px',
        overflowY: 'scroll',
        paddingLeft: '0px',
    },
    segmentRow: {
        listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    addButton: {
        float: 'right',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.themeBlue,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
    segmentName: {
        //fontWeight: 'bold',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: spacings.base,
    },
    bannerTextTitle : {
        color: colors.themeWhite,
        fontSize: fontSizes.h3,
    },
    bannerTextDescription : {
        color: colors.themeDarkGrey,
        fontSize: fontSizes.body,
    },
    contentPic: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '800px',
        lineHeight: '1.5',
        ...fonts.body,
    },
    segment: {
        backgroundColor: 'white',
        borderRadius: '9px',
        //boxShadow: shadow,
        margin: '-1.5rem',
        //textAlign: 'center',
    },
    cardContent: {
        padding: spacings.base,
    },
    competitionTitle: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    checkmark: {
        color : colors.themeTeal,
    },
    checkmarkDim: {
        color : colors.themeDarkGrey1_2,
    },
    h3: {
        fontSize: fontSizes.h3,
        color: colors.themeBlue,
        padding: spacings.base,
    },
    numberInput : {
        //width:'150px',
        //padding:0
    },
    inputStyle : {width:'125px'},
    slider  : {verticalAlign:'middle', marginLeft : spacings.small, marginRight : spacings.small},
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },

};

// Pre-launch
// - This email will be sent before the contest begins to outline contest details.
// Launch
// - This email communicates that the contest starts today.
// Second Wave
// - This email is sent to all employees that have not yet enrolled in the contest encouraging them to join.
// End of challenge
// - This email is sent to all contest participants with the final results.

var announcementTypes = {
    0 : { label : 'Custom email', info: '', getMax: function(){}, getMin: function(){}},
    1 : { label : 'Pre-launch email', info: 'This email will be sent before the contest begins to outline contest details. It is recommended to send this 7 days prior to the challenge start date.', getMax: function(season){return season.startsAt}, getMin: function(season){}},
    2 : { label : 'Launch email', info: 'This email communicates that the contest starts today. It is recommended to send this on the date the challenge begins.', getMax: function(season){return season.endsAt}, getMin: function(season){return season.startsAt}},
    3 : { label : 'Second wave email', info: 'This email is sent to all employees that have not yet enrolled in the contest encouraging them to join. It is recommended to send this 7 days after the challenge start date.', getMax: function(season){return season.endsAt}, getMin: function(season){return season.startsAt}},
    4 : { label : 'End of challenge email', info: 'This email is sent to all members with the final results. It is recommended to send this the day after the challenge ends.', getMax: function(season){}, getMin: function(season){return season.endsAt}}
}

const sections = {
    EMAIL: 'email',
}

const accessMap = {
    admin: [
        sections.EMAIL
    ],
    'corporate wellness': [
    ],
    'corporate wellness standard': [
    ]
}


const MileStone = ({
    t,
    milestone,
    prop,
    edit,
    index,
    onChangeRankPoints
}) => {
    return (!!milestone[prop]) && <div>{edit ?
        <Input 
            style={{...style.numberInput}}
            input={<input style={style.inputStyle}/>}
            name='points' 
            type='number' value={ prop === 'duration' ? Math.floor(milestone[prop]/60) : milestone[prop] } 
            onChange={(e, p)=>{onChangeRankPoints(e, { ...p, value : prop === 'duration' ? p.value * 60 : p.value }, 'competition.competitionMilestones['+index+'].'+prop)}}
            label={t(prop)}
            labelPosition='right'
        />
        : <span>{prop === 'duration' ? Math.floor(milestone[prop]/60) : milestone[prop]} {t(prop)}</span> } 
        </div>
};

const LanguagePane = ({t, season, seasonData, competitionData, locale, onChange}) => (
    <div>
        <br/>
       <div>
     {!season.id && <Input  value={competitionData && competitionData.title || ''} 
             name= {'season.leaderboard.locales.'+locale+'.title'}
             onChange={onChange}
             onFocus={onChange}
             label='Challenge Title:'
             maxLength = "100"/> || season.leaderboard?.locales?.[locale]?.title}
       </div> 
       <div> 
            {!season.id && <Input  value={seasonData && seasonData.title || ''} 
             name= {'season.locales.'+locale+'.title'}
             onChange={onChange}
             onFocus={onChange}
             label='Sub Title:'
             maxLength = "100"/> || season.locales?.[locale]?.title }
     
     </div>

     <div> 
            {!season.id && <TextArea  value={seasonData && seasonData.description || ''} 
             name= {'season.locales.'+locale+'.description'}
             onChange={onChange}
             onFocus={onChange}
             style={style.textarea}
             label='Description:'
             maxLength = "800"/> || season.locales?.[locale]?.description }
     
     </div>
       <br/>
    </div>
);

const EditCompetitionModal = ({
    t,
    now,
    busy,
    languages,
    season,
    competition,
    competitions,
    announcements,
    milestones,
    categories,
    teams,
    rewards,
	competitionOptions,
	onClose,
    onDateChange,
    onDelete,
    onSubmit,
    onEndCompetition,
    onClickCompetition,
    onClickChange,
    deleteModalRef,
    onToggleCategory,
    onChangeRankPoints,
    onChangeCheckbox,
    onChangeTeamCheckbox,
    onChangeSearch,
    onTimeZoneChange,
    onAddSegmentTeam,
    onRemoveSegmentTeam,
    roles,
    loadingTeamSegments,
    addedTeamsSegments,
    teamsSegments,
    onLanguageInputChange,
    onUploadPhoto,
    onConfirmPhoto,
    choosePhotoIsOpen,
    uploading,
    onExport
}) => {
    const checkAccess = (section) => {
        return RoleUtils.checkUIAccess(roles, accessMap, section)
    }
    return (
        <Modal open style={style.content} onClose={onClose} size={season.id || competition ? 'small' : 'large'}>
            <Modal.Header style={style.modalHeader}>
            {competition && !season.id && <Icon style={style.back} onClick={!season.id && onClickChange} name="chevron left"/>}
            {t(season && season.id ? 'Update Competition' : 'Schedule New Competition')}
            {competition && onExport && localStorage.master ==='true' &&
                <Button
                    style={style.editButton}
                    className="categories-editButton"
                    onClick={() => onExport(competition)}
                    
                    >
                    <Icon style={style.editIcon} name='download' />
                    {t('export')}
                </Button>
            }
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {busy &&
                        <LoadInline centered vcentered className='notInDimmer' /> 
                    }
                    {!busy &&
                        <div>
                            
                            {!competition && competitions && !!competitions.length &&
                                <Card.Group>
                                    {competitions.map(function(competition) {
                                        return <ChallengeCard
                                            key={competition.id}
                                            competition={competition}
                                            onClick={(event)=>onClickCompetition(event, competition.id)}
                                            onExport={onExport}
                                            //centered={true}
                                        />
                                    })}
                                </Card.Group> }
                            { competition && 
                                <div>
                                        
                        
                                                {/* <ChallengeCard
                                                    key={competition.id}
                                                    competition={competition}
                                                    onClick={(event)=>onClickCompetition(event, competition.id)}
                                                    centered={true}
                                                /> */}
                                                {/* <div style={{...style.banner, backgroundImage:"linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), " + Utils.url(getCompetitionImageUrl({competition}))}}>
                                                    <div style={style.bannerText}>
                                                        <div style={style.bannerTextTitle}>Step Together</div>
                                                        <div style={style.bannerTextDescription}><Icon style={style.checkmark} name={(competition.leaderboard || competition).type == 'teams' ? 'users' : 'user'}/>
                {(competition.leaderboard || competition).type == 'teams' ? t('Team Competition') : t('Individual Competition')}</div>
                                                    </div>
                                                    
                                                    
                                                </div> */}

                                                <div style={style.contentPic}>
                                                    <div style={style.segment}>
                                                    <img style={style.banner} alt='' src={season.leaderboard?.banner?.url || season.leaderboard?.banner?.dataURL || competition.banner?.url || defaultObjectiveImage} />

                                                    {!season.id && <Button type='button'
                                                        style={style.uploadPhoto}
                                                        onClick={onUploadPhoto}>
                                                        {t('upload_photo_button_text')}
                                                    </Button>}
{choosePhotoIsOpen && 
                                                    <ChoosePhotoContainer onConfirmPhoto={onConfirmPhoto}
                        width={1280}
                        height={720}
                        onClose={onUploadPhoto} />}
                                                    <div style={style.cardContent}>
                                                        
                                                    
                                                    <Tab panes={languages.map(language=>({
                                                            menuItem:language.text,
                                                            render:() => <LanguagePane t={t} 
                                                                            season={season}
                                                                            locale={language.value}
                                                                            seasonData={season?.locales?.[language.value]}
                                                                            competitionData={season.leaderboard?.locales?.[language.value]}
                                                                            onChange={onLanguageInputChange} />}))} />
                                                        <div style={{...style.h3, ...style.competitionTitle}}></div>
                                                        
                                                        <div style={style.h4}>Type</div>

                                                        <Icon style={season.leaderboard.type === 'teams' ? style.checkmarkDim : style.checkmark} name='user' /> <Checkbox label='Individual Challenge' radio checked={season.leaderboard.type === 'company'} style={style.slider} onChange={(e, p)=>{onChangeRankPoints(e, {value : p.value}, 'season.leaderboard.type')}} value={'company'} disabled={!!season.id} />
                                                        <br/>
                                                        <Icon style={season.leaderboard.type === 'teams' ? style.checkmark : style.checkmarkDim} name='users' /> <Checkbox label='Team Challenge' radio checked={season.leaderboard .type === 'teams'} style={style.slider} onChange={(e, p)=>{onChangeRankPoints(e, {value : p.value}, 'season.leaderboard.type')}} value={'teams'} disabled={!!season.id}  />
                                                        
                                                        <div style={style.h4}>Community Feed</div>

                                                        <Checkbox className='green' label='Enable Community Feed' toggle checked={season.hasFeed == true} style={style.togglePacks} onChange={(e, p)=>{onChangeRankPoints(e, {value : p.checked}, 'season.hasFeed'); if(p.checked == false )onChangeRankPoints(e, {value : p.checked}, 'season.feed.allowAssets')}} disabled={!!season.id} />

                                                        <Checkbox className='green' label='Allow Images on Community Feed (Note: Optimity does not moderate images for inappropriate content)' toggle checked={season.feed?.allowAssets === true} style={style.togglePacks} onChange={(e, p)=>{onChangeRankPoints(e, {value : p.checked}, 'season.feed.allowAssets')}} disabled={!!season.id || !season.hasFeed} />

                                                    </div>
                                                    </div>
                                            </div>
                                        
                                            {false && season.active && <Message warning>
                                                <Message.Header>This challenge is live!</Message.Header>
                                                <p>You cannot modify challenges once they are live. You may either delete the challenge or end the challenge early if desired.</p>
                                            </Message>}

                                    {categories && <div>
                                        <div style={style.h4}>{t('Applicable Categories')}</div>
                                        <Card.Group>{categories.map(a => {
                                                return <CategoryCard category={a} key = {a.id} onToggleCategory={onToggleCategory} />
                                        })}</Card.Group>
                                    </div>}

                                    <div>
                                    <div style={style.h4}>{t('Teams')}</div>
                                    <div><Checkbox className='green' label='Segmented' toggle checked={season.leaderboard.type === 'teams' || season.isSegmented} style={style.togglePacks} onChange={(e, p)=>onChangeRankPoints(e, {value : p.checked}, 'season.isSegmented')} disabled={!!season.id} /><br /><br /></div>

                                    { !season.id && (season.leaderboard .type === 'teams' || season.isSegmented) && <div style={style.columnContainer}>
                                        <div style={style.leftColumn}>
                                            <div>{t('add_segmentteam_prompt')}</div>
                                            <Input placeholder={t('search_placeholder')}  onChange={onChangeSearch} fluid />


                                            {Boolean(!loadingTeamSegments) &&
                                            <ul style={style.segmentList}>
                                                {teamsSegments.map((segment,i) => (
                                                    <li key={segment.id} style={{...style.segmentRow, ...(i==0 && {borderTop:'none'}) }}>
                                                        {Boolean(!segment.segmentQueryId) ? (
                                                                <Icon name='user' title={t('in_team_hover')} />
                                                            ) : (
                                                                <Icon name='user' title={t('no_team_hover')} />
                                                        )}
                                                        <Button type="button" style={style.addButton} onClick={()=>onAddSegmentTeam(segment)}>
                                                            <Icon name='add' />
                                                        </Button>
                                                        <div style={style.segmentName}>
                                                            {segment.name && <div>{segment.name}</div>}
                                                            {Boolean(segment.segmentQueryId) ? ( "Segment" ) : ( "Team" )}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            }
                                            {Boolean(loadingTeamSegments) &&
                                                <div style={style.loaderWrapper}>
                                                    <LoadInline centered vcentered className="notInDimmer" style={style.loader} />
                                                </div>
                                            }
                                            
                                        </div>
                                        <div style={style.rightColumn}>
                                            <div>{t('segmentteam_prompt')}</div>
                                            <ul style={style.teamMemberList}>
                                            {addedTeamsSegments.map((segment,i) => (
                                                <li key={segment.id} style={{...style.teamSegmentRow, ...(i==0 && {borderTop:'none'}) }}>
                                                    <Button type="button" style={style.removeTeamSegmentButton} onClick={()=>onRemoveSegmentTeam(segment)}>
                                                        <Icon name='trash' />
                                                    </Button>
                                                    <div style={style.segmentName}>
                                                        {segment.name && <div>{segment.name}</div>}
                                                        {Boolean(segment.segmentQueryId) ? ( "Segment" ) : ( "Team" )}
                                                    </div>
                                                    
                                                </li>
                                            ))}
                                            </ul>
                                        </div>
                                    </div> }

                                    { season.id &&
                                    <div>
                                        <div style={style.h4}>{t('Teams')}</div>
                                        { season.seasonTeams.map((seasonTeam, index) => {
                                            return <div>{seasonTeam.team.name}</div>
                                        }) }
                                    </div>
                                    }

                                    </div>

                                    

                                    <Grid >
                                        <Grid.Row>
                                            <Grid.Column width={season.id ? 8:  16}>
                
                                                    <div style={style.h4}>{t('Target Milestones')}</div>

                                                    { season.leaderboard.type !== 'teams' && milestones && milestones.length && <Grid style={style.body}>{milestones.map(function(a, index) {
                                                        return <Grid.Row key={index} style={style.slimRow}>
                                                                <Grid.Column width={2} verticalAlign="middle"><Checkbox className='green' toggle checked={competition.competitionMilestones[index].enabled} style={style.togglePacks} onChange={(e, p)=>onChangeRankPoints(e, {value : p.checked}, 'competition.competitionMilestones['+index+'].enabled')} disabled={!!season.id} /></Grid.Column>
                                                                <Grid.Column width={5}>
                                                                    {/* <MileStone milestone={a} prop={'points'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t}/> */}
                                                                    <MileStone milestone={a} prop={'resources'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                    <MileStone milestone={a} prop={'actions'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints } t={t}/>
                                                                    <MileStone milestone={a} prop={'activities'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                    <MileStone milestone={a} prop={'steps'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                    <MileStone milestone={a} prop={'glasses'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                    <MileStone milestone={a} prop={'duration'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                    <MileStone milestone={a} prop={'floors'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints}  t={t}/>
                                                                    <MileStone milestone={a} prop={'photos'} index={index} edit={!season.id} onChangeRankPoints={onChangeRankPoints} t={t} />
                                                                </Grid.Column>
                                                                <Grid.Column width={3} style={style.pointsCell}>
                                                                    {   localStorage.master === 'true' &&
                                                                        <div>
                                                                            {season.id ? <span><Icon style={style.starIcon} className="notClickable icon-star"/>{a.rewardPoints}</span> : <Input 
                                                                            icon={<Icon style={style.starIcon} className="notClickable icon-star"/>}
                                                                            iconPosition='left'
                                                                            style={style.numberInput}
                                                                            name='points' 
                                                                            input={<input size="2"/>}
                                                                            type='number' value={a.rewardPoints} 
                                                                            onChange={(e, p)=>{onChangeRankPoints(e, p, 'competition.competitionMilestones['+index+'].rewardPoints')}} /> }
                                                                        </div>
                                                                    }
                                                                    
                                                                {/* </Grid.Column>
                                                                <Grid.Column width={3} style={style.pointsCell}> */}
                                                                    {season.id ? <span><Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>{a.rewardResources}</span> : <Input 
                                                                    icon={<Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>}
                                                                    iconPosition='left'
                                                                    style={style.numberInput}
                                                                    name='points' 
                                                                    input={<input  size="2"/>}
                                                                    type='number' value={a.rewardResources} 
                                                                    onChange={(e, p)=>{onChangeRankPoints(e, p, 'competition.competitionMilestones['+index+'].rewardResources')}} /> }
                                                                {/* </Grid.Column>
                                                                <Grid.Column width={1} style={style.pointsCell}> */}
                                                                    {season.id ? <span>R: {a.rewardActionId}</span> : <Input 
                                                                    style={style.numberInput}
                                                                    name='points' 
                                                                    input={<input  size="2"/>}
                                                                    type='number' value={a.rewardActionId}
                                                                    placeholder='RewardActionId' 
                                                                    onChange={(e, p)=>{onChangeRankPoints(e, p, 'competition.competitionMilestones['+index+'].rewardActionId')}} /> }
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                    })}</Grid> || <em>({t('None')})</em> }
                                            
                                            </Grid.Column>
                                            <Grid.Column width={season.id ? 8:  10}>

                                                    <div style={style.h4}>{t('Ranking Bonuses')}</div>
                                                    <div><Checkbox className='green' label='Enable Leaderboard' toggle checked={season.hasLeaderboard} style={style.togglePacks} onChange={(e, p)=>onChangeRankPoints(e, {value : p.checked}, 'season.hasLeaderboard')} disabled={!!season.id} /><br /><br /></div>
                                                    { season.hasLeaderboard && season.leaderboard.type !== 'teams' && rewards && rewards.length && <Grid style={style.body}>{rewards.map(function(a, index) {
                                                        return <Grid.Row key={index} style={style.slimRow}>
                                                            <Grid.Column width={2} verticalAlign="middle"><Checkbox className='green' toggle checked={competition.competitionRewards[index].enabled} style={style.togglePacks} onChange={(e, p)=>onChangeRankPoints(e, {value : p.checked}, 'competition.competitionRewards['+index+'].enabled')} disabled={!!season.id} /></Grid.Column>
                                                            <Grid.Column width={season.id ? 3:  2} verticalAlign={'middle'}>
                                                                #{a.rank || rewards.length + '+'}
                                                            </Grid.Column>
                                                            {   localStorage.master === 'true' &&
                                                                <Grid.Column width={season.id ? 4:  6} style={style.pointsCell}>
                                                                
                                                                    {season.id ? <span><Icon style={style.starIcon} className="notClickable icon-star"/>{a.rewardPoints}</span> : <Input 
                                                                    icon={<Icon style={style.starIcon} className="notClickable icon-star"/>}
                                                                    iconPosition='left'
                                                                    style={style.numberInput}
                                                                    name='points'
                                                                    input={<input style={style.inputStyle}/>}
                                                                    type='number' value={a.rewardPoints} 
                                                                    onChange={(e, p)=>{onChangeRankPoints(e, p, 'competition.competitionRewards['+index+'].rewardPoints')}} /> }
                                                                </Grid.Column>
                                                            }
                                                            <Grid.Column width={season.id ? 4:  6} style={style.pointsCell}>
                                                                {season.id ? <span><Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>{a.rewardResources}</span> : <Input 
                                                                icon={<Icon style={style.resourcesIcon} className="notClickable icon-Optimollar"/>}
                                                                iconPosition='left'
                                                                style={style.numberInput}
                                                                input={<input style={style.inputStyle}/>}
                                                                name='points' 
                                                                type='number' value={a.rewardResources} 
                                                                onChange={(e, p)=>{onChangeRankPoints(e, p, 'competition.competitionRewards['+index+'].rewardResources')}} /> }
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                    })}</Grid> || <em>({t('None')})</em> }
                                            
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <div>
                                        <div style={style.h4}>{t('Duration')}</div>
                                        <Grid verticalAlign={'middle'} style={style.fullHeight} columns={13}>
                                            <Grid.Row>
                                                <Grid.Column style={style.noPadding} width={5}>
                                                    IANA Timezone
                                                </Grid.Column>
                                                <Grid.Column style={style.noPadding} width={6}>
                                                    {!season.id && <div>
                                                        <Dropdown
                                                            options={moment.tz.names().map((a)=>({key:a,text:a,value:a}))} 
                                                            value={season.timeZone}
                                                            search
                                                            fluid
                                                            selection
                                                            onChange={(ev, value)=>{onTimeZoneChange(value.value, 'season.timeZone')}}
                                                        />
                                                        <a href="https://time.is" target="_blank">Search for an IANA timezone</a>
                                                        <Popup
                                                            trigger={<Icon name='question circle outline' />}
                                                            content={<ol>
                                                                <li>Click the link.</li>
                                                                <li>Search for the desired location (by city is best).</li>
                                                                <li>Scroll down to the line that says "The IANA time zone identifier for ... is ..."</li>
                                                                <li>Search for that time zone identifier in the dropdown above.</li>
                                                            </ol>}
                                                            position='bottom center'
                                                            wide='very'
                                                        />
                                                    </div> || season.timeZone }
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column style={style.noPadding} width={5}>
                                                    Pre-Enrollment Date (Inclusive)
                                                </Grid.Column>
                                                <Grid.Column style={style.noPadding} width={6}>
                                                {!season.id && <DateTimePicker
                                                        value={new Date(season.preEnrollmentAt)}
                                                        step={60}
                                                        readOnly={!!season.id}
                                                        onChange={(value)=>onDateChange(value, 'season.preEnrollmentAt')}
                                                        onKeyPress={(event)=>{event.preventDefault()}}
                                                        max={new Date(season.startsAt)}
                                                        min={now}
                                                    /> || moment.tz(season.preEnrollmentAt, season.timeZone).format("llll") }
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column style={style.noPadding} width={5}>
                                                    Start Date (Inclusive)
                                                </Grid.Column>
                                                <Grid.Column style={style.noPadding} width={6}>
                                                {!season.id && <DateTimePicker
                                                        value={new Date(season.startsAt)}
                                                        step={60}
                                                        readOnly={!!season.id}
                                                        onChange={(value)=>onDateChange(value, 'season.startsAt')}
                                                        onKeyPress={(event)=>{event.preventDefault()}}
                                                        max={new Date(season.endsAt)}
                                                        min={season.preEnrollmentAt}
                                                    /> || moment.tz(season.startsAt, season.timeZone).format("llll") }
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column style={style.noPadding} width={5}>
                                                    End Date (Exclusive)
                                                </Grid.Column>
                                                <Grid.Column style={style.noPadding} width={6}>
                                                    {!season.id &&<DateTimePicker
                                                        value={new Date(season.endsAt)}
                                                        step={60}
                                                        readOnly={!!season.id}
                                                        onChange={(value)=>onDateChange(value, 'season.endsAt')}
                                                        onKeyPress={(event)=>{event.preventDefault()}}
                                                        min={new Date(season.startsAt)}
                                                    /> || moment.tz(season.endsAt, season.timeZone).format("llll") }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                    

                                    { false && checkAccess(sections.EMAIL) && announcements && !!announcements.length && <div>
                                        <div style={style.h4}>{t('Campaign Messaging')}</div>
                                        { announcements.map((announcement, index) => <Grid verticalAlign={'middle'} key={announcement.id || index} style={style.fullHeight} columns={13}>
                                            <Grid.Row>
                                                {!season.id && <Grid.Column textAlign={'center'} style={style.noPadding} width={2}>
                                                    <Checkbox className='green' style={style.togglePacks} toggle checked = {announcement.enabled}  onClick={(e, p)=>{onChangeCheckbox(e, p, 'announcements['+index+'].enabled')}}/>
                                                </Grid.Column> }
                                                <Grid.Column style={style.noPadding} width={5}>
                                                    {announcementTypes[announcement.type].label}
                                                    <Popup
                                                        trigger={<Icon name='question circle outline' />}
                                                        content={announcementTypes[announcement.type].info}
                                                        position='right center'
                                                    />
                                                </Grid.Column>
                                                <Grid.Column style={style.noPadding} width={6}>
                                                    {!season.id && <DateTimePicker
                                                        value={new Date(announcement.sendAt)}
                                                        step={30}
                                                        readOnly={!!season.id}
                                                        onChange={(value)=>onDateChange(value, 'announcements['+index+'].sendAt')}
                                                        onKeyPress={(event)=>{event.preventDefault()}}
                                                        max={announcementTypes[announcement.type].getMax(season)}
                                                        min={announcementTypes[announcement.type].getMin(season)}
                                                    /> || moment.tz(announcement.sendAt, season.timeZone).format("llll") }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid> )}
                                    </div> }

                                    <DeletePromptContainer ref = {deleteModalRef} onDeleteConfirmation = {onDelete}/>
                                    
                                    
                                    <Grid style={style.buttonsRow}>
                                        <Grid.Row columns={'equal'}>
                                            { season.id && <Grid.Column>  
                                                <Button style={buttons.delete} onClick={() => {deleteModalRef.current.showDeletePrompt()}} disabled={uploading}> {season && t('Delete Season')}</Button>
                                            </Grid.Column> }
                                            <Grid.Column style={style.gridSubmit}>{ uploading && 'Saving...' }
                                                {!season.id && <Button style={style.button} onClick={onSubmit} disabled={uploading}> {t('Schedule Competition')}</Button>}
                                                {season.id && season.active && <Button style={style.button} onClick={onEndCompetition} disabled={uploading}>{t('End Competition')}</Button>}
                                                {false && season.id && !season.active && <Button style={style.button} onClick={onSubmit}>{t('Update Competition')}</Button>}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            }
                        </div>
                    }
                </Modal.Description>
            </Modal.Content>
        </Modal>
);
}
export default withTranslation('competitions')(EditCompetitionModal);
