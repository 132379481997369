import React, { Component } from 'react';

import UserService from 'services/UserService.js';
import ChoosePhoto from './ChoosePhoto';
import Utils from 'utils/Utils.js';
import DatabaseService from '../../services/DatabaseService';

const DefaultCropWidth = 500;
const DefaultCropHeight= 500;

class ChoosePhotoContainer extends Component {

    constructor(props){
		super(props);
		this.state = {error:'', photo:'', croppedPhoto:'', showVideoCapture:false, isLoading:false};
        this.initialState = this.state;
		this.onClose = this.onClose.bind(this);
		this.onChoosePhoto = this.onChoosePhoto.bind(this);
		this.onTakePhoto = this.onTakePhoto.bind(this);
		this.setFileSelector = this.setFileSelector.bind(this);
		this.setCropper = this.setCropper.bind(this);
		this.setVideo = this.setVideo.bind(this);
		this.setCanvas = this.setCanvas.bind(this);
		this.onCapturePhoto= this.onCapturePhoto.bind(this);
		this.onFileSelect = this.onFileSelect.bind(this);
		this.onConfirmPhoto = this.onConfirmPhoto.bind(this);
		this.onCrop = this.onCrop.bind(this);
		this.onCropperReady = this.onCropperReady.bind(this);
        this.onSelectOriginalPhoto = this.onSelectOriginalPhoto.bind(this);
    }

    componentWillUnmount() {
        this.stopWebCam();
    }


    onClose() {
        this.stopWebCam();
        this.clearPhoto();
        this.setState(this.initialState);
        this.props.onClose();
    }


    onEnterPressed = (event) =>{
        var key = event.keyCode || event.which;
        if(key === 13 && event.target.value){
            this.setState({isLoading: true});
            return DatabaseService.remoteImage({url: event.target.value})
            .then((image)=>{
                this.setState({photo:image.dataURL, isLoading: false});
                
            })
            .catch((error)=> {
                window.alert('Not valid image URL');
                this.setState({isLoading: false});
                console.error(error);
            })
            
        }
    }

    //HTML5 File selector related functions
	onChoosePhoto() {
        this.stopWebCam();
        this.fileSelector.click();
	}
    setFileSelector(ref) {
        this.fileSelector = ref;
    }
    setCropper(ref) {
        this.cropper = ref.cropper;
    }
    onFileSelect(event) {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload= (e) => { this.setState({photo:e.target.result}) };
        reader.readAsDataURL(file);
    }
    onCrop() {
        var options = {width : this.props.width  || DefaultCropWidth, 
                       height: this.props.height || DefaultCropHeight,
                       imageSmoothingEnabled: true,
                       imageSmoothingQuality: 'high'};
        var croppedPhoto = this.cropper ? this.cropper.getCroppedCanvas(options).toDataURL() : this.state.photo
        this.setState({croppedPhoto:croppedPhoto});
    }
    onCropperReady() {
        setTimeout(this.onCrop, 1);
    }



    //Capture an image from video related functions
	onTakePhoto() {
        this.clearPhoto();
        this.setState({showVideoCapture:true});
	}
    setVideo(video) {
        if(this.video!==video) {
            this.video= video;
            navigator.mediaDevices.getUserMedia({ video: true }).then(
                (stream) => {
                    if(this.video) {
                        this.video.srcObject = stream;
                        //deprecated: this.video.src = window.URL.createObjectURL(stream);
                        this.video.play();
                        this.stream = stream;
                    }
                })
                .catch((error) => {
                    window.alert("Error capturing video: " + error);
                });
        }
    }
    setCanvas(canvas) {
        this.canvas = canvas;
    }
    onCapturePhoto(){
        if(this.canvas && this.video) {
            var context = this.canvas.getContext('2d');
            this.canvas.width = this.video.videoWidth;
            this.canvas.height= this.video.videoHeight;
            context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
            var photo = this.canvas.toDataURL();
            this.setState({photo:photo});
        }
    }
    stopWebCam() {
        if(this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
            this.video = null;
            this.canvas = null;
        }
        this.setState({showVideoCapture:false});
    }
    clearPhoto() {
        console.log('clearPhoto');
        this.setState({photo:'', croppedPhoto:''});
    }


    /*onUpload() {
        if(!this.state.uploading) {
            this.setState({uploading:true});
            var onSaveUserPhoto = this.props.onSaveUserPhoto || UserService.saveUserPhoto;
            onSaveUserPhoto(this.state.croppedPhoto).then(
                () => this.onClose(),
                error => console.log(error));
        }
    }*/

    onConfirmPhoto() {
        this.props.onConfirmPhoto(this.state.croppedPhoto);
        this.onClose();
    }

    onSelectOriginalPhoto() {
        this.setState({photo: this.state.photo, croppedPhoto: this.state.photo})
    }

    render() {
        var cropWidth = this.props.width || DefaultCropWidth; 
        var cropHeight = this.props.height || DefaultCropHeight; 
        return (
         <ChoosePhoto aspectRatio={cropWidth / cropHeight}
                      error={this.state.error}
                      open={this.props.open===undefined || this.props.open===true}
                      setFileSelector={this.setFileSelector}
                      setCropper={this.setCropper}
                      setVideo={this.setVideo}
                      setCanvas={this.setCanvas}
                      onClose={this.onClose}
                      onChoosePhoto={this.onChoosePhoto}
                      onTakePhoto={this.onTakePhoto}
                      onCrop={this.onCrop}
                      onCropperReady={this.onCropperReady}
                      onFileSelect={this.onFileSelect}
                      supportsVideoCapture={Utils.supportsVideoCapture()}
                      showVideoCapture={this.state.showVideoCapture}
                      onCapturePhoto={this.onCapturePhoto}
                      onConfirmPhoto={this.onConfirmPhoto}
                      photo={this.state.photo}
                      onEnterPressed = {this.onEnterPressed}
                      isLoading = {this.state.isLoading}
                      croppedPhoto={this.state.croppedPhoto}
                      onSelectOriginalPhoto={this.onSelectOriginalPhoto}
                      />
      );
  }
}

export default ChoosePhotoContainer;
