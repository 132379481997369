import {SET_COMPETITIONS, ADD_COMPETITION, ADD_COMPETITIONS} from 'actions/types';

const initial = {
	competitions:[],
	indexed:{},
	listLoaded:false
};

// All state changes need to be deep copied through all props that are changing
function Handle(state = initial, action) {
    switch(action.type) {
        case SET_COMPETITIONS:
        	return {
        		...state,
				active : action.active,
				history : action.history,
				upcoming : action.upcoming,
        		//indexed : action.competitions.reduce( function(m,c,ci) { m[c.id]=ci; return m; }, {} ),
        		listLoaded : true
        	};
        /*case ADD_COMPETITION: 
        	return {
        		...state,
        		competitions : action.competitions.push(action.competition),
                indexed : {
                	...state.indexed,
                	[action.competition.id] : action.competitions.length
                }
            };*/
        /*case ADD_COMPETITIONS:
        	return {
        		...state,
        		//competitions : action.competitions.concat(action.competitions),
                //indexed : action.categories.reduce( function(m,c,ci) { m[c.id]=ci; return m; }, { ...state.indexed })
            };*/
        default:            
            return state;
    }
}

function IsListLoaded(state) { return state.listLoaded };
function GetCompetitions(state) { return {
	active : state.active,
	history : state.history,
	upcoming : state.upcoming
}; };
function GetActive(state) { return state.active; };
function GetHistory(state) { return state.history; };
function GetUpcoming(state) { return state.upcoming; };//state.list.map( id => state.indexed[id] );
//function GetCompetition(state, id) { return state.competitions[state.indexed[id]]; };

// Exports

export default Handle;
export const isListLoaded = IsListLoaded;
export const getCompetitions = GetCompetitions;
export const getActive = GetActive;
export const getHistory = GetHistory;
export const getUpcoming = GetUpcoming;
