import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import RewardBudgetModal from './RewardBudgetModal.js';


class RewardBudgetModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { value:500, valid:true, complete:false };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onSubmit(event) {
        event && event.preventDefault();
        this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.fundTangoCardAccount({amount:this.state.value})
            .catch(error => {
                console.error(error);
            })
            .then(()=>{
                this.setState({complete:true});
                this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    onChange(event) {
        var value = event.currentTarget.value;
        var valid = this.isValid(parseFloat(value));
        this.setState({value, valid});
    }

    onClose() {
        if(this.state.complete) {
            this.props.onRefresh();
        }
        this.props.onClose();
    }

    isValid(value) {
        return (_.isFinite(value) &&
                value > 0);
    }

    render() {
        return (
            <RewardBudgetModal {...this.props} 
                               value={this.state.value}
                               valid={this.state.valid}
                               complete={this.state.complete}
                               onSubmit={this.onSubmit}
                               onChange={this.onChange}
                               onClose={this.onClose}/>
      );
    }
}

export default connect()(withTranslation('rewards')(RewardBudgetModalContainer));
