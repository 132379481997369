import React, { useState, createRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import AddAdminModal from './AddAdminModal.js';

const AddAdminModalContainer = ({onClose, companyRoles}) => {
    const [search, setSearch] = useState('')
    const [employees, setEmployees] = useState([])
    const [loadingEmployees, setLoadingEmployees] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState()
    const [adminRoleId, setAdminRoleId] = useState()
    const [error, setError] = useState()

    const doSearch = () => {
        if(search) {
            setLoadingEmployees(true)
            DatabaseService.getEmployees({
                    'like.fullName' : search,
                    'like.email' : search,
                    admin : false
                })
                .then(result => {
                    setEmployees(result.employees.map(employee => {
                        return {
                            ...employee,
                            title: employee.email
                        }
                    }))
                })
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(result => {
                    setLoadingEmployees(false)
                });
        } else {
            setEmployees([])
        }
    }

    const validateFields = (submit) => {
        var foundError
        if (!selectedEmployee) {
            foundError= 'No employee specified'
        } else if (!_.isNumber(adminRoleId)) {
            foundError = 'No role specified'
        }
        if (!foundError || submit) {
            setError(foundError)
        }
        return !foundError
    }

    const onAddAdmin = () => {
        if (validateFields(true)) {
            DatabaseService.addCompanyAdmin({ companyEmployeeId: selectedEmployee.id, roleId: adminRoleId })
            .then(result => {
                onClose()
            })
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
        }
    }

    const onSelectEmployee = (employee) => {
        setSelectedEmployee(employee)
        setSearch(employee.email)
        validateFields()
    }

    const onChangeSearch = (event) => {
        var search = event.target.value;
        setSearch(search)
        setSelectedEmployee()
        validateFields()
    }

    const onChangeAdminRole = (event, data) => {
        var roleId = data.value;
        setAdminRoleId(roleId)
    }

    useEffect(() => {
        doSearch()
    }, [search])

    return (
        <AddAdminModal
                        onClose={onClose}
                        search={search}
                        employees={employees}
                        companyRoles={companyRoles}
                        loadingEmployees={loadingEmployees}
                        onSelectEmployee={onSelectEmployee}
                        selectedEmployee={selectedEmployee}
                        onChangeSearch={onChangeSearch}
                        onChangeAdminRole={onChangeAdminRole}
                        adminRoleId={adminRoleId}
                        onAddAdmin={onAddAdmin}
                        error={error}
                        />
    );
}

export default connect()(withTranslation('rewards')(AddAdminModalContainer));
