import {SET_FUTURE_ANNOUNCEMENTS, SET_PUBLIC_ANNOUNCEMENTS, SET_TARGETED_ANNOUNCEMENTS} from 'actions/types';

var announcementsInitialState = {future:[], public: [], targeted: [], indexed:{}, listLoaded:false};
export default (state = announcementsInitialState, action) => {
    switch(action.type) {
        case SET_FUTURE_ANNOUNCEMENTS: 
            return {
                ...state,
                future:action.announcements.map((c)=>(c.id)),
                count: action.count
            };
        case SET_PUBLIC_ANNOUNCEMENTS:
            return {
                ...state,
                public:action.announcements.map((c)=>(c.id)),
                count: action.count
            }
        case SET_TARGETED_ANNOUNCEMENTS:
            return {
                ...state,
                targeted:action.announcements.map((c)=>(c.id)),
                count: action.count
            }
        default:
            return state;
    }
}

export const getFutureAnnouncements = state => state.future;
export const getPublicAnnouncements = state => state.public;
export const getTargetedAnnouncements = state => state.targeted;

