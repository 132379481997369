import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setSidebarEnable} from 'actions/sidebar.js';
import {initNavbarState} from 'actions/navbar.js';
import DatabaseService from 'services/DatabaseService.js';

import {spacings, layout, fontSizes, colors} from 'style';
import { Button } from 'semantic-ui-react';

const style={
    content: {
        width: '100%',
        height: '100%',
    },
    body: {
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: spacings.base,
        maxWidth: layout.screenWidth,    
        backgroundColor: 'white',
    },
    message: {
        textAlign: 'center',
        fontSize: fontSizes.h2, 
        lineHeight: '1.5',
        color: colors.themeBlue,
    },
    backButton: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: spacings.micro,
        right: spacings.base,
        color: colors.themeWhite,
        fontSize: fontSizes.small,
        zIndex: 5000
    }
}

class PageNotFound extends Component {

    componentWillMount() {
        this.props.dispatch(initNavbarState({enable:true, backButtonEnable:true}));
        this.props.dispatch(setSidebarEnable(false));
    }

    componentWillUnmount() {
        this.props.dispatch(initNavbarState({enable:false}));
    }

    async onSignOut(e){
        e.preventDefault();
        await DatabaseService.signOut();
        window.location.href = '/login'
    }

    render() {
      return (
          <div style={style.content}>
            <div style={style.body}>
                <div style={style.message}>Page Not Found</div>
                <Button onClick={this.onSignOut} style={style.backButton}>
                    Sign Out
                </Button>
            </div>
          </div>
      );
  }
}

export default connect()(PageNotFound);
