import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {fetchTeams} from 'actions/teams.js';
import {getTeam, getTeams} from 'reducers/teams.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import {initNavbarState} from 'actions/navbar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import TeamDetails from './TeamDetails.js';


class TeamDetailsContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false,
                      loading:false,
                      sortField: 'fullName',
                      sortAsc: true,
                      employees:[],
                      currentPage: 0,
                      limit:10,
                      totalEmployees:null,
                      showEditTeam: false,
                     };
        this.onChangeTeam = this.onChangeTeam.bind(this);
        this.onDeleteTeamMember = this.onDeleteTeamMember.bind(this);
        this.onNextPage = this.onNextPage.bind(this);
        this.onShowEditTeam   = this.onShowEditTeam.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(initNavbarState({enable:true, backButtonEnable:true}));
        this.props.dispatch(setSidebarEnable(false));
        this.loadData(true);
    }

    componentWillUnmount() {
        this.props.dispatch(initNavbarState({enable:false}));
    }

    sortBy(field) {
        var sortAsc = true;
        if(this.state.sortField===field) {
            sortAsc = !this.state.sortAsc;
        }
        this.setState({currentPage: 0, sortField:field, sortAsc}, this.loadData);
    }

    onDeleteTeamMember(employee, team ) {

        //removeTeamEmployee
        //this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true})

        console.log(employee)
        console.log(team)

        DatabaseService.removeTeamEmployee({
            teamId: team.id,
            companyEmployeeId: employee.id 
        })
        .then(()=>{
            this.loadData(false);
        })
        .catch((error)=> {
            window.alert('An error occurred - check logs');
            console.error(error);
        })
        .then(()=>{
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
        });

    }

    onChangeTeam(employee, teamId) {
        if(teamId && teamId!==Number(this.props.match.params.id)) {
            this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
                DatabaseService.addTeamEmployee({
                    teamId: teamId,
                    companyEmployeeId: employee.id 
                })
                .then(()=>{
                    this.loadData(false);
                })
                .catch((error)=> {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(()=>{
                    this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                });
        }
    }

    onNextPage(forward) {
        var totalEmployees = this.state.totalEmployees;
        var maxPages = Math.ceil(totalEmployees / this.state.limit);
        var currentPage = this.state.currentPage;
        if(forward) {
            currentPage = Math.min(maxPages, currentPage+1);
        } else {
            currentPage = Math.max(0, currentPage-1);
        }
        this.setState({currentPage}, ()=>this.loadData(false));
    }

    onShowEditTeam(en) {
        this.setState({showEditTeam:en});
    }

    onRefresh() {
        return this.loadData(false);
    }

    loadData(spinner) {

        var params = {
                teamId: this.props.match.params.id,
                start: this.state.currentPage*this.state.limit,
                limit: this.state.limit,
                sortField: this.state.sortField,
                sortOrder: this.state.sortAsc ? 'ascending' : 'descending',
            }


        this.setState({busy:true, loading:(spinner)});
        Promise.all([DatabaseService.getTeamEmployees(params),
                     this.props.fetchTeams()])
            .then(([result])=>{
                this.setState({employees: result.employees,
                               totalEmployees: result.summary.total,
                              });
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
               this.setState({busy:false, loading:false});
            });
    }

    render() {
        var totalEmployees = this.state.totalEmployees;
        var maxPages = Math.ceil(totalEmployees / this.state.limit);
        return (
            <TeamDetails {...this.props}
                         {...this.state}
                         onChangeTeam={this.onChangeTeam}
                         onDeleteTeamMember={this.onDeleteTeamMember}
                         onNextPage={this.onNextPage}
                         sortBy={this.sortBy}
                         start={this.state.currentPage*this.state.limit+1}
                         end={this.state.currentPage*this.state.limit+this.state.employees.length}
                         atStart={this.state.currentPage===0}
                         atEnd={(this.state.currentPage+1)>=maxPages}
                         onShowEditTeam={this.onShowEditTeam}
                         onRefresh={this.onRefresh}
                         />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
    var team = getTeam(state.teams, ownProps.match.params.id) || {};
    var teams = getTeams(state.teams);
    return {team, teams};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTeams: () => dispatch(fetchTeams()),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('teams')(TeamDetailsContainer)));
