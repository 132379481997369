import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Button, Form, Input, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, buttons, spacings} from 'style';
import CreateCompanyFormContainer from './CreateCompanyFormContainer.js';
import backgroundImage from 'img/register-dark.png';
import styleDef from './style.js';

var style = {
    ...styleDef,
    container: {
        minHeight: '100%',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundImage: Utils.url(backgroundImage),
    },
    deleteButton: {
        ...buttons.long,
        width: '49.14px',
        height: '49.14px',
        backgroundColor: '#fff',
        padding: '0',
        margin: '0 0 0 0',
        fontSize: '24px',
        textAlign: 'center',
    },
    trashIcon: {
        margin: '0',
        color: colors.warningRed,
    },
    printButton: {
        display: 'inline-block',
        ...buttons.long,
        width: '49.14px',
        height: '49.14px',
        backgroundColor: '#fff',
        color: colors.themeDarkerGrey,
        padding: '0',
        margin: '0 0 0 ' + spacings.base,
        fontSize: '24px',
        textAlign: 'center',
    },
    printIcon: {
        margin: '0',
    },
    usersIcon: {
        marginLeft: spacings.tiny,
        display: 'inline',
    },
    companyRow: {
     /*   position: 'relative',
        padding: '0',
        marginLeft: '0',
        marginRight: '0',
        marginTop: spacings.base,
        marginBottom: spacings.base,*/
    },
    addIcon: {
        position: 'relative',
        top: '-4px',
    },
    grid: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '600px',
        marginTop: spacings.base,
        marginBottom: spacings.base
        //marginTop: spacings.base,
    },
    counter: {
        width: '140px',
        textAlign: 'right',
        padding: '0.785714em 1.5em',
        verticalAlign: 'middle',
        color: '#fff',
        fontSize: fontSizes.bodyL,    
    },
    addCompany : {
        ...styleDef.button,
        marginTop : spacings.base,
        width :"100%"
        
    },
    company : {
        ...styleDef.button,
        width : "100%",
        borderRadius : "2rem"
    },
    backButton: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: spacings.base,
        right: spacings.base,
        color: colors.themeDarkGrey,
        fontSize: fontSizes.small,
    }
};

const Companies = ({t,
                    busy,
                    companies, 
                    showCreateCompany,
                    onSelectCompany,
                    onSetCompany,
                    onRemoveCompany,
                    onExportCompanyTree,
                    onClickCreateCompany,
                    onSignOut
                    }) => (
    <div style={style.container}>
        <div style={style.content}>
            {busy && 
                <div style={style.loaderContainer}>
                    <LoadInline centered vcentered /> 
                </div>
            }
            {!busy && 
                <div>
                    {showCreateCompany ? (
                        <CreateCompanyFormContainer
                            onSetCompany={onSetCompany}
                            onSelectCompany={onSelectCompany} 
                            onClose={()=>onClickCreateCompany(false)} />
                    ) : (
                        <div>
                            <Button onClick={onSignOut} style={style.backButton}>
                                Sign Out
                            </Button>
                            <div style={style.title}>
                                {t('My Organizations')}
                                <Button style={style.printButton} onClick={()=>onExportCompanyTree()}>
                                    <Icon style={style.printIcon} 
                                          name="print" 
                                          title={t('export_company_csv_tooltip')} />
                                </Button>
                            </div>
                            <Grid stackable style={style.grid}>
                                {companies.map(company => (
                                    <Grid.Row key={company.id} style={style.companyRow}>
      
                                        <Grid.Column width={16}>
                                            <Button style={style.company} onClick={()=>onSelectCompany(company)}>
                                                <Grid stackable>
                                                    <Grid.Row>
                                                        <Grid.Column style={{fontSize:fontSizes.h4}}>{company.name}</Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>{company.seats}
                                                            <Icon style={style.usersIcon} 
                                                                  name="users" />
                                                        </Grid.Column>
                                                        { company.companyTier && <Grid.Column width={8}>{t('tier')}: {company.companyTier.title}
                                                        </Grid.Column> }
                                                    </Grid.Row>
                                                </Grid>
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                ))}
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button style={style.addCompany} onClick={()=>onClickCreateCompany(true)}>
                                            <Icon name="add" size="large" style={style.addIcon}/>
                                            {t('create_organization_button_text')}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                           
                        </div>
                    )}
                </div>
            }
        </div>
    </div>
);

export default withTranslation('companies')(Companies);
