import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Card, Button, Grid, Header, Message, Label, Segment} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, buttons, shadow} from 'style';
import CategoryCard from './CategoryCard.js';
import CategoryInfoModalContainer from './modals/CategoryInfoModalContainer.js';
import './categories.css';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },

};

const Categories = ({t, 
                  busy, 
                  categories,
                  selectedCategories,
                  categoryToDisplay,
                  onShowCategoryInfo,
                  onToggleCategory,
                  onClickAddNewGoal,
                  onClickAddNewPathway,
                  onClickManageObjectives,
                  onEditCategory,
                  canUserEditCategory}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.header}>{t('Goals')}</div>
            {/*
            <Button style={style.button} onClick={onClickAddNewGoal}>
                {t('Add New Goal')}
            </Button>
            */}
            <Button style={style.button} onClick={onClickAddNewPathway}>
                {t('Add New Pathway')}
            </Button>
            <Button style={style.button} onClick={onClickManageObjectives}>
                {t('Manage Objectives')}
            </Button>

            <br />
            <br />
            <br />
            
            <div>
                <div style={style.subheader}>{t('selected_header', {count: selectedCategories.length, total:categories.length})}</div>
                <hr />
                <Card.Group>
                {selectedCategories.map(category=>(
                    <CategoryCard key={category.id}
                                  category={category}
                                  onEdit={canUserEditCategory(category) ? onEditCategory : null}
                                  onClickCategory={()=>onShowCategoryInfo(category)}
                                  onToggleCategory={onToggleCategory}/>
                ))}
                </Card.Group>
            </div>

            <br />
            <br />
            <br />
            
            <div>
                <div style={style.subheader}>{t('library_header', {total: categories.length})}</div>
                <hr />
                <Card.Group>
                {categories.map(category=>(
                    <CategoryCard key={category.id}
                                  category={category}
                                  onEdit={canUserEditCategory(category) ? onEditCategory : null}
                                  onClickCategory={()=>onShowCategoryInfo(category)}
                                  onToggleCategory={onToggleCategory}/>
                ))}
                </Card.Group>
            </div>

            {Boolean(categoryToDisplay) &&
                <CategoryInfoModalContainer 
                    category={categoryToDisplay}
                    onClose={()=>onShowCategoryInfo(null)} />
            }

        </div>
    )
);

export default withTranslation('categories')(Categories);
