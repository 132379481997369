import store from 'state/store.js';
import Utils from './Utils.js';

const RoleUtils = {
    getStartingPath: (roles) => {
        if (roles) {
            if (roles.includes('sales')) {
                return '/members'
            } else if (roles.includes('leadsgen')) {
                return '/members'
            } else {
                return '/dashboard'
            }
        }
    },

    getPathFilter: (roles) => {
        const roleAllowedRoutes = store.getState().user?.roleAllowedRoutes || {}
        
        let allowedRoutes = []
        
        if (localStorage.master === 'true') {
            allowedRoutes = roleAllowedRoutes['admin']
        } else if (roles.includes('sales')) {
            allowedRoutes = roleAllowedRoutes['members']
        } else if (roles.includes('leadsgen')) {
            allowedRoutes = roleAllowedRoutes['leadsgen']
        } else if (roles.includes('operations')) {
            allowedRoutes = roleAllowedRoutes['operations']
        } else if (roles.includes('corporate wellness')) {
            allowedRoutes = roleAllowedRoutes['corporate wellness']
        } else if (roles.includes('corporate wellness standard')) {
            allowedRoutes = roleAllowedRoutes['corporate wellness standard']
        } else if (roles.includes('admin')) {
            allowedRoutes = roleAllowedRoutes['admin']
        }

        return allowedRoutes || []
    },

    checkUIAccess: (roles, list, name) => {
        let access = []
        let matchedRoles = Object.keys(list)
        matchedRoles = matchedRoles.filter(role => roles.includes(role))
        if (matchedRoles.length === 0) {
            //default admin
            return list.admin.includes(name)
        }
        return matchedRoles.findIndex(role => list[role].includes(name)) !== -1
    }
}

export default RoleUtils;
