import React, { Component, useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import {getCategories} from 'reducers/categories.js';
import {fetchCategories, toggleCategory} from 'actions/categories.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {fetchSurveys} from 'actions/surveys';

import {Button, Modal,Grid,Dropdown, Header, Input, Message, Search} from 'semantic-ui-react';
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';

import SegmentConditionRow from './SegmentConditionRow'

import Form from "@rjsf/semantic-ui"

var style = {
    ...styleDef,
    loaderContainer: {
        paddingTop: '150px',
        paddingBottom: '150px',
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.short,
    },
    container : {
        marginTop : '30px',
        textAlign : 'center',
        marginRight : 'auto',
        marginLeft : 'auto'
        
    },
    profilestag : {
        display:'inline-block',
        padding:'10px',
        borderRadius: '100px',
        color: '#fff',
        backgroundColor:'#FFA500',
        marginRight:'1'
    },
    loadingIcon: {
        backgroundColor: 'transparent'
    },
    errorMessage: {
        color: 'red'
    }
};

const AddSegmentModalContainer = ({t, dispatch, onRefresh, onClose, onMessage, fetchSurveys}) => {
    const [terms, setTerms] = useState([{uid: _.uniqueId()}])
    const [complete, setComplete] = useState(false)
    const [formData, setFormData] = useState()
    const [segmentOptions, setSegmentOptions] = useState([])
    const [busy, setBusy] = useState(false)
    const [searchDebounce, setSearchDebounce] = useState()
    const [generatedCount, setGeneratedCount] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [liveValidate, setLiveValidate] = useState(false)
    const [isPreviewing, setIsPreviewing] = useState(false)
    const [allowPreview, setAllowPreview] = useState(false)

    const loadData = async () => {
        setBusy(true)
        try {
            var segmentOptionsResult = await DatabaseService.getSegmentOptions();

            setSegmentOptions(segmentOptionsResult.segmentationOptions.map(option => {
                return {
                    ...option,
                    text: option.text || option.name
                }
            }))
        } catch(error) {
            window.alert('An error occurred - check logs');
            console.error(error);
        } finally {
            setBusy(false)
        }
    }

    const close = (created) => {
        if(created) {
            onRefresh();
            onMessage(t('create_segment_started'))
        }
        onClose();
    }

    const debounceSearch = async (searchField, query, ret) => {
        //useEffect(() => {

            clearTimeout(searchDebounce)
            setSearchDebounce(setTimeout(() => {
                search(searchField, query, ret);
            }, 500));
        //    return () => clearTimeout(searchDebounce);
        //})
    }

    const search = async (searchField, query, ret) => {
        let result
        let likeParams = {}
        let segmentOption = segmentOptions.find(segment => segment.value == searchField)
        if (segmentOption?.searchResults) {
            query = query.toLowerCase()
            result = segmentOption?.searchResults.filter(option => option.name.toLowerCase().startsWith(query) || option.key.toLowerCase().startsWith(query))
            result.forEach(option => {
                option.id = option.key
                option.title = `${option.name} (${option.key})`
            })
            return ret(result)
        }
        switch (searchField) {
            case 'survey_page':
                likeParams = {
                    'like.title': query,
                    'like.description': query,
                    'like.tag': query
                }
                result = await DatabaseService.searchSurveyQuestions({
                    limit: 10,
                    ...likeParams
                })
                result.forEach(challenge => {
                    //if (!challenge.title) {
                        challenge.title = challenge.surveyPage.description
                    //}
                    challenge.description = challenge.surveyPage.tag
                })
                return ret(result)
            case 'survey':
                likeParams = {
                    'like.title': query,
                    'like.tag': query
                }
                result = await DatabaseService.getSurveys({
                    limit: 10,
                    ...likeParams
                })
                result.surveys.forEach(survey => {
                    survey.description = survey.tag
                })
                return ret(result.surveys)
            case 'segment':
                result = await DatabaseService.getSegments({
                    query: query,
                    limit: 10
                })
                result = result.rows
                result.forEach(segment => {
                    segment.title = segment.name
                    segment.tags = segment.segmentQuery?.tags?.split(',') || []
                })
                return ret(result)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        return () => clearTimeout(searchDebounce);
    })

    const updateTerm = async (term, key, value, disableCreate) => {
        setIncomplete()
        if (key) {
            setTerms(terms => terms.map(t => t === term ? {...t, [key]:value} : t))
        } else {
            setTerms(terms => terms.map(t => t === term ? {...value, uid: term.uid} : t))
        }
        if (!term.fields && !disableCreate) {
            let uid = _.uniqueId()
            setTerms(prevTerms => prevTerms.concat({uid: uid}))
        }
    }

    const deleteTerm = async (term) => {
        setIncomplete()
        setTerms(terms => terms.filter(t => t.uid !== term.uid))
    }

    const buildSegment = async () => {
        let conditions = {}
        terms.filter(term => term.complete).forEach(term => {
            let operator = term.operator
            let value = term.value
            let search = term.search
            switch (term.type) {
                case 'age':
                    conditions.age = conditions.age || {}
                    if (operator === '>') {
                        conditions.age['>'] = value
                    } else if (operator === '<') {
                        conditions.age['<'] = value
                    }
                    break
                case 'postal_code':
                    conditions.postalCodes = conditions.postalCodes || {}
                    if (operator === 'include') {
                        if (!conditions.postalCodes['include'])  {
                            conditions.postalCodes['include'] = value
                        } else {
                            conditions.postalCodes['include'] += `,${value}`
                        }
                    } else if (operator === 'exclude') {
                        if (!conditions.postalCodes['exclude'])  {
                            conditions.postalCodes['exclude'] = value
                        } else {
                            conditions.postalCodes['exclude'] += `,${value}`
                        }
                    }
                    break
                case 'survey_questions':
                    conditions.surveyPages = conditions.surveyPages || {}
                    if (operator === 'or') {
                        if (!conditions.surveyPages['single']) {
                            conditions.surveyPages['single'] = '[]'
                        }
                        conditions.surveyPages['single'] = conditions.surveyPages['single'].substr(0, conditions.surveyPages['single'].length-1) + `['${search.tag}', ${value}]]`
                        conditions.surveyPages['single'] = conditions.surveyPages['single'].replace('][', '],[')
                    } else {
                        if (!conditions.surveyPages[operator]) {
                            conditions.surveyPages[operator] = '[]'
                        }
                        conditions.surveyPages[operator] = conditions.surveyPages[operator].substr(0, conditions.surveyPages[operator].length-1) + `['${search.tag}', ${value}]]`
                        conditions.surveyPages[operator] = conditions.surveyPages[operator].replace('][', '],[')
                    }
                    break
                case 'survey_completions':
                    if (operator === 'not complete') {
                        if (!conditions.surveysNotComplete) {
                            conditions.surveysNotComplete = `'${search.tag}'`
                        } else {
                            conditions.surveysNotComplete += `,'${search.tag}'`
                        }
                    }
                    break
                case 'segment_inclusions':
                    conditions.segments = conditions.segments || {}
                    if (operator === 'include') {
                        if (!conditions.segments['include']) {
                            conditions.segments['include'] = `${search.id}`
                        } else {
                            conditions.segments['include'] += ',' + search.id
                        }
                    } else {
                        if (!conditions.segments['exclude']) {
                            conditions.segments['exclude'] = `${search.id}`
                        } else {
                            conditions.segments['exclude'] += ',' + search.id
                        }
                    }
                    break
                case 'policyholder':
                    if (operator === 'include') {
                        conditions.policyHolder = {include: true}
                    } else {
                        conditions.policyHolder = {exclude: true}
                    }
                    break
                case 'researchProgram':
                    if (operator === 'include') {
                        conditions.researchProgram = {include: true}
                    } else {
                        conditions.researchProgram = {exclude: true}
                    }
                    break
                case 'unanswered':
                    if (operator === 'postal code') {
                        operator = 'postalCodes'
                    }
                    conditions[operator] = conditions[operator] || {}
                    conditions[operator]['include_null'] = true
                    break
                case 'province':
                case 'state':
                case 'abtest':
                    if (!conditions[term.type]) {
                        conditions[term.type] = {}
                    }
                    if (!conditions[term.type][term.operator]) {
                        conditions[term.type][term.operator] = term.search.key
                    } else {
                        conditions[term.type][term.operator] += ',' + term.search.key
                    }
                    break
                case 'employeeAttributes':
                    if (!conditions[term.type]) {
                        conditions[term.type] = {}
                    }
               
                    conditions[term.type][search.id] = {[term.operator]: value}
                    
                    break
                default:
                    if (!conditions[term.type]) {
                        conditions[term.type] = {}
                    }
                    conditions[term.type][operator] = value || true
                    break
            }
        })
        return {conditions, companyId: parseInt(localStorage.companyId)}
    }

    const generateSegment = async () => {
        setErrorMessage('')
        setComplete(false)
        let params = await buildSegment()

        if (_.isEmpty(params.conditions)) {
            setErrorMessage('Please complete filters before preview')
            return
        }
        try {
            
            setIsPreviewing(true)
            let result = await DatabaseService.viewSegment(params)
            setGeneratedCount(result.count)
            if (result.count != null) {
                setComplete(true)
            }
        } catch (e) {
            console.error(e)
            if (e.status === 408) {
                setErrorMessage("Preview is taking long, Please try again.")
            } else {
                setErrorMessage(e.message)
            }
        } finally {
            setIsPreviewing(false)
        }
    }

    const setIncomplete = () => {
        setGeneratedCount()
        setComplete(false)
    }

    const createSegment = async () => {
        setErrorMessage('')
        let params = await buildSegment()
        if (formData && formData.segmentName) {
            params.segmentName = formData.segmentName
            params.dynamic = formData.dynamic
            params.endDate = formData.expiresAt
            await DatabaseService.buildSegment(params)
            close(true)
        } else {
            setLiveValidate(true)
        }
    }

    const areTermsIncomplete = useMemo(() => {
        
        const validTerms = terms.filter(term => term.type)

        if (validTerms.length === 0)
            return true

        return validTerms.some(term => term.complete != true )
        
    }, [terms])

    return (
        <Modal open style={style.content} onClose={() => close()} size='large'>
            <Modal.Header>{t('Add New Segment')}</Modal.Header>
            {busy ? (
                <div style={style.loaderContainer}>
                    <LoadInline centered vcentered className="notInDimmer" /> 
                </div>
            ) : (
            <Modal.Content>
                <Modal.Description>
                    <div>
                    <Form
                        schema={{
                            "title": "Create Segmentation",
                            "type": "object",
                            "required": [
                              "segmentName"
                            ],
                            "properties": {
                                "segmentName": {
                                    "type": "string",
                                    "title": "Name"
                                },
                                "dynamic": {
                                    "type": "boolean",
                                    "title": "Dynamic"
                                }
                            },
                            "dependencies": {
                                "dynamic": {
                                    "oneOf": [
                                        {
                                          "properties": {
                                            "dynamic": {
                                              "enum": [
                                                false
                                              ]
                                            }
                                          }
                                        },
                                        {
                                          "properties": {
                                            "dynamic": {
                                              "enum": [
                                                true
                                              ]
                                            },
                                            "expiresAt": {
                                                "description": t('expiry'),
                                                "type": "string",
                                                "format": "date-time"
                                            }
                                          }
                                        }
                                      ]
                                }
                            }
                          }}
                          formData={formData}
                          onChange={({formData}, e) => setFormData(formData)}
                          liveValidate={liveValidate}
                    >
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={4}><b>{'Type'}</b></Grid.Column>
                                <Grid.Column width={4}><b>{t('Field Name')}</b></Grid.Column>
                                <Grid.Column width={3}><b>{t('Operator')}</b></Grid.Column>
                                <Grid.Column width={4}><b>{t('Value')}</b></Grid.Column>
                                <Grid.Column width={2}></Grid.Column>
                            </Grid.Row> 
                            {
                                terms.map(term => {
                                    return <SegmentConditionRow
                                        key={term.uid}
                                        term={term}
                                        search={debounceSearch}
                                        segmentOptions={segmentOptions}
                                        updateTerm={updateTerm}
                                        deleteTerm={deleteTerm}
                                        setDirty={() => {
                                            updateTerm(term, 'complete', false, true)
                                            setIncomplete()
                                        }}
                                    />
                                })
                            }
                        </Grid>
                    </Form>
                    </div>
                    <div style={style.container}>
                    </div>
                    {
                        _.isNumber(generatedCount) &&
                        <div style={style.container}>
                            <p>{generatedCount < 30 && generatedCount !== 0 ? '<30' : generatedCount} people in this segment</p>
                        </div>
                    }
                    {
                        errorMessage &&
                        <div style={style.container}>
                            <p style={style.errorMessage}>{errorMessage}</p>
                        </div>
                    }
                    <div style={style.container}>
                    {isPreviewing && <i style={style.loadingIcon} class="loading spinner icon"></i>}
                    <Button type="button" style={style.button} disabled={isPreviewing || areTermsIncomplete} onClick={generateSegment}>{t('Generate Segment')}</Button> 
                    <Button type="button" style={style.button} onClick={createSegment} disabled={!complete}>{t('Save')}</Button> 
                    </div>  
                </Modal.Description>
            </Modal.Content>
            )}
        </Modal>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {categories:getCategories(state.categories)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: (params, force) => dispatch(fetchCategories(params, force)),
        fetchSurveys: (params, force) => dispatch(fetchSurveys(params, force)),
        dispatch: dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('segments')(AddSegmentModalContainer));