import DatabaseService from 'services/DatabaseService.js';
import {SET_JOBS} from 'actions/types';

//Action Creators
export const setJobs = (jobs, count) => {
    return { type:SET_JOBS, jobs:jobs, count: count};
}

//Thunks
export const fetchJobs = (params) => (dispatch) => {
    return new Promise(function(resolve, reject) {
        if (params.query && params.query !== '' && params.queryField !== '') {
            params[`like.${params.queryField}`] = params.query
        }
        if (params.offset) {
            params.start = params.offset
        }
        DatabaseService.getReportingjobs(params).then(
            function(result){
                let jobs = result.rows
                dispatch(setJobs(jobs, result.count))
                resolve({data: jobs, count: result.count})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
