import React, { Component } from 'react';
import LeaderboardExportSelectModal from './LeadboardExportSelectModal.js';

class LeadboardExportSelectModalContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LeaderboardExportSelectModal {...this.props} />
        );
    }
}


export default LeadboardExportSelectModalContainer;
