import {ADD_OBJECTIVE_CATEGORY, 
        ADD_OBJECTIVE_CATEGORIES,
        SET_CATEGORIES,
        ADD_CATEGORIES,
        ADD_CATEGORY} from 'actions/types';

const getIndex = oc => oc.objectiveId + '/' + oc.categoryId; 

var objectiveCaregoriesInitialState = {indexed:{}};
export default (state = objectiveCaregoriesInitialState, action) => {
    switch(action.type) {
        case ADD_OBJECTIVE_CATEGORY: 
            return {...state,
                    indexed: {...state.indexed, [getIndex(action.objectiveCategory)]:action.category}
                    };
        case ADD_OBJECTIVE_CATEGORIES: 
            return {...state,
                    indexed: action.objectiveCaregories.reduce((m,c)=>{m[getIndex(c)]=c;return m;},{...state.indexed})
                    };



        //normalize categories
        case SET_CATEGORIES: //fallthrough
        case ADD_CATEGORIES: 
            return {...state,
                    indexed: action.categories.map(c=>c.objectiveCategories)
                                              .reduce((m,objectiveCategories)=>{
                                                            if(objectiveCategories!==undefined && objectiveCategories!==null) {
                                                                objectiveCategories.forEach(objectiveCategory => m[getIndex(objectiveCategory)]=objectiveCategory);
                                                            }
                                                            return m;}
                                                      ,{...state.indexed})
                    };
        case ADD_CATEGORY: 
            return {...state,
                    indexed: (action.category.objectiveCategories||[]).reduce((m,c)=>{m[getIndex(c)]=c;return m;},{...state.indexed})
                    };

        default:            
            return state;
    }
}

export const getObjectiveCategoriesByObjectiveId  = (state, objectiveId) => Object.values(state.indexed).filter(oc => oc.objectiveId===objectiveId);
