import DatabaseService from 'services/DatabaseService.js';
import {SET_SEGMENTS} from 'actions/types';
import moment from 'moment'

//Action Creators
export const setSegments = (segments, count) => {
    return { type:SET_SEGMENTS, segments:segments, count: count};
}


//Thunks
export const fetchSegments = (params) => (dispatch) => {
    return new Promise(function(resolve, reject) {
        if (params.query && params.query !== '' && params.queryField !== '') {
            params[`like.${params.queryField}`] = params.query
        }
        if (params.offset) {
            params.start = params.offset
        }
        DatabaseService.getSegments(params).then(
            function(result){
                let segments = result.rows.map(row => {
                    return {
                        ...row,
                        id : row.id,
                        name: row.name,
                        value : 38,
                        progressValue : 0,
                        tags : row.segmentQuery?.tags?.split(",") || [],
                        members : row.segmentQuery?.count,
                        lastUpdate : row.segmentQuery?.createdAt,
                        dynamic: row.segmentQuery?.dynamic && 'Dynamic',
                        expiry: row.segmentQuery?.dynamic ? row.segmentQuery.expiresAt ? moment(row.segmentQuery.expiresAt).format('MMM DD, YYYY h:mm a') : 'No Expiry' : ''
                    }
                })
                dispatch(setSegments(segments, result.count))
                resolve({data: segments, count: result.count})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
