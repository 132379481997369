import DatabaseService from 'services/DatabaseService.js';
import {SET_TEAMS} from 'actions/types';
import {isListLoaded,
        getTeams}  from 'reducers/teams.js'
import {setUserProfile} from 'actions/user.js';

//Action Creators
export const setTeams= (teams) => {
    return { type:SET_TEAMS, teams:teams };
}


//Thunks
export const fetchTeams= (params, force) => (dispatch, getState) => {
    if(!force) {
        var state = getState();
        if(isListLoaded(state.teams)) {
            return Promise.resolve(getTeams(state.teams));
        }
    }

    return new Promise(function(resolve, reject) {
        DatabaseService.getTeams(params).then(
            function(result){ 
                dispatch(setTeams(result.teams));
                resolve(result); 
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
