import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import jstz from 'jstz';

import UserService from '../../services/UserService.js';
import {initNavbarState} from 'actions/navbar.js';
import {setSidebarEnable} from 'actions/sidebar.js';

import LogIn from './LogIn.js'

class LogInContainer extends Component {

    constructor(props){
		super(props);
		this.state = {valid:false,
                      email:'',
					  password:'',
                      emailError:'',
                      passwordError:'',
                      error:'',
                      showForgotPassword:false};

		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onShowForgotPassword= this.onShowForgotPassword.bind(this);
		this.onCloseForgotPassword= this.onCloseForgotPassword.bind(this);
        this.isValid = this.isValid.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this)
    }

    componentWillMount() {
      console.log(setSidebarEnable(false))
        this.props.dispatch(setSidebarEnable(false));
        this.props.dispatch(initNavbarState({enable:false}));
    }

	onEmailChange(event) {
		this.setState({email: event.target.value, emailError:''}, this.isValid);
	}

	onPasswordChange(event) {
		this.setState({password: event.target.value, passwordError:''}, this.isValid);
	}

	onSubmit(event) {
		console.log('onSubmit:' + JSON.stringify(this.state));

        if(this.isValid(true)) {
            this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:true});
            UserService.signIn(this.state.email, this.state.password)
                .catch((error) => {
                    this.setState({error:String(error),
                                   password:''});
                    console.error(error);
                    //window.alert('error: ' + error);
                })
                .then(()=>{ //Always
                    this.props.dispatch({type:'SET_LOADING_OVERLAY_ENABLE', enable:false});
                });
        }

		event.preventDefault();
    }
    
    onForgotPassword() {
        this.props.history.push('/forgotpassword')
    }

    onShowForgotPassword(event) {
        this.setState({showForgotPassword:true});
        event.preventDefault();
    }

    onCloseForgotPassword() {
        this.setState({showForgotPassword:false});
    }

	isValid(submit) {
		var valid = true;

		this.setState({emailError:'',passwordError:''});

		if((this.state.email||'').length === 0){
            if(submit) {
			    this.setState({emailError:'Please enter your email address'});
            }
            valid = false;
		}

		if((this.state.password||'').length === 0){
            if(submit) {
			    this.setState({passwordError:'Please enter your password'});
            }
            valid = false;
		}

        this.setState({valid:valid});

		return valid;
	}


    render() {
        var t = this.props.t;
        return (
             <LogIn valid={this.state.valid}
                    email={this.state.email}
                    password={this.state.password}
                    onSubmit={this.onSubmit}
                    onEmailChange={this.onEmailChange}
                    onPasswordChange={this.onPasswordChange}
                    onForgotPassword={this.onForgotPassword}
                    emailError={this.state.emailError}
                    passwordError={this.state.passwordError}
                    error={this.state.error}
                    notification={this.props.location.state && this.props.location.state.notification}
                    showForgotPassword={this.state.showForgotPassword}
                    onShowForgotPassword={this.onShowForgotPassword}
                    onCloseForgotPassword={this.onCloseForgotPassword} />
      );
  }
}

export default withRouter(connect()(withTranslation('authentication')(LogInContainer)));
