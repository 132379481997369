import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon, Card, Button, Grid, Header, Message, Label, Segment, Checkbox} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, fonts, spacings, buttons, shadow} from 'style';
import EditObjectiveModalContainer from './modals/EditObjectiveModalContainer.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    segment: {
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    header: {
        ...fonts.h3,
        color: colors.themeDarkerGrey,
    },
    subheader: {
        ...fonts.h4,
        color: colors.themeDarkerGrey,
    },
    grid: {
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
        padding: spacings.base,
    },
    objectiveTitle: {
        display: 'inline',
        fontSize: fontSizes.bodyL,
        paddingLeft: spacings.base,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '16px',
    },
    image: {
        boxShadow: shadow,
        borderRadius: '9px',
        width: '40px',
        height: '40px',
        backgroundSize:'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
    },
    toggle: {
        verticalAlign: 'middle',
    }
};

const Objectives = ({t, 
                  busy, 
                  objectives,
                  objectiveToEdit,
                  onShowEditObjective,
                  onToggleObjective,
                  onCloneObjective,
                  onRefresh}) => (
    busy ? (
        <div style={style.loaderContainer}>
            <LoadInline centered vcentered /> 
        </div>
    ) : (
        <div style={style.content}>
            <div style={style.segment}>
                <Button style={style.button} onClick={()=>onShowEditObjective({})}>
                    {t('Add Objective')}
                </Button>
                
                <br />
                <br />
                <div style={style.section}>
                    <Grid style={style.grid}>
                    {objectives.map(objective=>(
                        <Grid.Row key={objective.id}>
                            <Grid.Column width={2}>
                                <div style={{...style.image, backgroundImage:Utils.url(Boolean(objective.image && objective.image.url) ? objective.image.url : defaultObjectiveImage)}} />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <span>
                                    <Checkbox className='green' style={style.toggle} toggle checked={Boolean(objective.companyObjective && objective.companyObjective.enabled)} onChange={()=>onToggleObjective(objective)}/>
                                </span>
                                <div style={style.objectiveTitle}>{objective.title}</div>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right'>
                                <Button style={style.iconButton}>
                                    <Icon name="copy" title={t('Clone')} onClick={()=>onCloneObjective(objective)} />
                                </Button>
                                <Button style={style.iconButton} onClick={()=>onShowEditObjective(objective)}>
                                    <Icon name="edit" title={t('Edit')} />
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    ))}
                    </Grid>
                </div>
            </div>



            {Boolean(objectiveToEdit) &&
                <EditObjectiveModalContainer 
                    objective={objectiveToEdit}
                    onClose={()=>onShowEditObjective(null)}
                    onRefresh={onRefresh}/>
            }
        </div>
    )
);

export default withTranslation('categories')(Objectives);
