import React from 'react';

import {colors} from 'style';

const getStyle = ({borderWidth, borderColor, innerBorderColor, height, borderRadius, barColor}) => ({
    container: {
        overflow: 'hidden',
        height: (height || '10px'),
        boxShadow: '0 0 0 '+(borderWidth!==undefined?borderWidth:1)+'px ' + (borderColor || colors.pointsYellow),
        borderColor: innerBorderColor || '#fff',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: (borderRadius || '10px'),
    },
    bar: {
        height: '100%',
        backgroundColor: (barColor || colors.pointsYellow),
        borderRadius: (borderRadius || '10px'),
        transition: 'width ease 0.5s'
    }
});

const ProgressBar = (props) => {
    var {value, total, style} = props;
    var w = total ? Math.max(0, Math.min(100, 100*value/total)) : 0;
    var s = getStyle(props);
    return (
        <div style={{...s.container, ...style}}>
            <div style={{...s.bar, width:(w+'%')}} />
        </div>
        );
};

export default ProgressBar;
