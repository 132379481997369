import React, {useState, useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {SET_LOADING_SPINNER_ENABLE, SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import DatabaseService from 'services/DatabaseService.js'
import {Button, Message, Tab} from 'semantic-ui-react';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {setSidebarEnable} from 'actions/sidebar.js';
import {List} from 'components/list'
import moment from 'moment'

import {getJobs} from 'reducers/jobreporting';
import {fetchJobs} from 'actions/jobreporting';

const styles = {
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        backgroundColor: colors.themeGrey,
    },
    topPanel: {
        display: 'flex',
        marginBottom: 16
    }
}

const jobFields = [
    {
        name: 'Job Type',
        key: 'jobType',
        default: true
    },
    {
        name: 'Name',
        key: 'name',
        default: true,
        searchable: true
    },
    {
        name: 'ID',
        key: 'messageId'
    },
    {
        name: 'Date',
        key: 'createdAt',
        type: 'date',
        default: true
    },
    {
        name: 'Status',
        key: 'status',
        default: true
    },
    {
        name: 'Progress',
        key: 'progress',
        default: true
    },
    {
        name: 'Total Steps',
        key: 'totalSteps',
        default: true
    },
    {
        name: 'Message',
        key: 'message',
        default: true
    }
]

const JobsContainer = ({t, dispatch, fetchJobs}) => {
    const [ws, setWs] = useState()

    const subscribeJobs = async () => {
        let result = await DatabaseService.getPubSubUrl({hub: 'notification'})
        let token = result.token
        let ws = new WebSocket(token.url, 'json.webpubsub.azure.v1');
        //setWs(ws)
        ws.onopen = () => {
            console.log('connected')
            /*
            ws.send(JSON.stringify({
                type: 'joinGroup',
                group: 'test',
                ackId : 1
            }));
            */
        }
        ws.onmessage = data => {
            console.log(data)
        }
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true))
        subscribeJobs()
    }, [])

    return (
        <div style={styles.content}>
            <List
                getData={fetchJobs}
                type='table'
                fields={jobFields}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {jobs: getJobs(state.jobreporting)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchJobs: (params, force) => {
            return dispatch(fetchJobs(params, force))
        },
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobsContainer));