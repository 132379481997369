import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Card, Icon, Grid, Input, Button, Checkbox, Label, Dropdown, Tab, Form, Popup} from 'semantic-ui-react';
import _ from 'underscore';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import ImageWithClose from 'modules/misc/ImageWithClose.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';
import ChallengeAnswer from './ChallengeAnswer.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'
import defaultObjectiveImage from 'img/default-challenge-image.jpg';


var style = {
    content: {
        margin: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey,
        borderRadius: '6px',
    },
    body: {
        padding: spacings.base,
    },
    titleBar: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontSize: fontSizes.bodyL,
        padding: spacings.small,
        backgroundColor: colors.themeGrey,
    },
    addButton: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    trashIcon: {
        color: colors.warningRed,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '24px',
        float: 'right',
    },
    typeCard: {
        textAlign: 'center',
        padding: spacings.base,
        width: '150px',
        maxWdith: '150px',
        color: '#000',
    },
    typeCardActive: {
        backgroundColor: colors.themeTeal,
        color: '#fff',
    },
    typeLabel: {
        paddingBottom: spacings.base,
    },
    typeIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '40px',
        lineHeight: '1',
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        height: '200px',
        margin: spacings.base,
    },
    addRow: {
        borderRadius: spacings.tiny,
        fontSize: fontSizes.bodyL, 
        padding: spacings.small,
        margin: spacings.tiny,
        borderRadius: spacings.micro,
        color: colors.themeBlue,
        border: '1px dashed #007fb9',
        backgroundColor: colors.themeGrey,
        fontStyle: 'italic',

    },
    addButtonRowVersion: {
        display: 'inline-block',
        backgroundColor: colors.themeGrey,
        color: colors.themeBlue,
        fontSize: fontSizes.h4,
        padding: '0',
        margin: '0',
        float: 'right',
    },
    charactersLeft: {
        float : 'right',
        marginBottom: '5px'
    }

};

const Stars = _.range(21)
               .map(i => 5*i)
               .map(stars => ({key:stars, value:stars, text:String(stars)}));

const Resources = _.range(21)
                   .map(i => 5*i)
                   .map(stars => ({key:stars, value:stars, text:String(stars)}));

const Durations = [15, 30, 60, 90, 120].map(d => ({key: d, value: d, text:String(d)}));

const Types = [
    {id:1,  label:"Read & Confirm",                      icon:"icon-check"},
    {id:11, label:"Multiple Choice",                     icon:"icon-clipboard"},
    {id:2,  label:"Count Down Timer",                    icon:"icon-timer"},
    {id:7,  label:"Count Down Timer + Input",            icon:"icon-timer"},
    {id:6,  label:"Count Up Timer",                      icon:"icon-timer"},
    {id:12, label:"Log Differential",                    icon:"icon-myHealthSphere-logo"},
    {id:13, label:"Log Differential + Count Down Timer", icon:"icon-myHealthSphere-logo"},
    {id:5,  label:"Camera",                              icon:"icon-camera3"},
    {id:8,  label:"Camera + Input",                      icon:"icon-camera3"},
    {id:16, label:"Camera + Multiple Choice",            icon:"icon-camera3"},
    {id:15, label:"Image",                               icon:"icon-photo"},
    {id:17, label:"Image + Input",                       icon:"icon-photo"},
    {id:18, label:"Image + Multiple Choice",             icon:"icon-photo"},
    {id:9,  label:"All Day Log w/ Max",                  icon:"icon-add"},
    {id:14, label:"All Day Log w/o Max",                 icon:"icon-add"},
];


export const ChallengePane = ({t, data, locale, basepath, onChange, valueChange}) => (
    <div>
    <br />
    <Popup trigger={
         <Input  value={data && data.title || ''} 
         name = {basepath + '.locales.'+locale+'.title'}
         placeholder = {t('title_prompt')}
         onChange={onChange}
         onFocus={onChange}
         maxLength = "75"/>
    }
        position = 'right center'
        content = {(75-valueChange) + ' characters left'}
        on='focus' />
    <br />
    <br />
  
    <Popup trigger={
          <textarea 
          name = {basepath +".locales."+locale+".description"}
          placeholder ={t('description_prompt')}
          style={style.textarea}
          value={data && data.description || ''} 
          onChange={onChange}
          onFocus={onChange}
          maxLength = "1000" />
    }
        position = 'top right'
        content = {(1000-valueChange) + ' characters left'}
        on='focus' />
   
    </div>
);

const ApplicableFields = {
    ChallengeAnswers: {
        11:true,
        16:true,
        18:true,
    },
    Duration: {
        2:true,
        7:true,
        13:true,
    },
    MaxLoggedActions: {
        9:true,
    },
    ActionsRequiredForPrimaryMilestone: {
        14:true,
    },
};


const Challenge = ({t,
                    levelIndex,
                    sectionIndex,
                    challengeIndex,
                    challenge,
                    onDeleteTab,
                    onHideTab,
                    languages,
                    onChange,
                    onChangeDropdown,
                    valueChange,
                    onToggle,
                    onSelectChallengeType,
                    onAddChallengeAnswer,
                    onUploadPhoto,
                    onCancelPhoto,
                    getBasepath,
                    locale,
                    refChallenge,
                    refChallengeAnswer,
                    onAutoCaption
                    }) => (
    <div style={style.content}>
        <div style={style.titleBar}>
            <Button type="button" onClick={()=>{refChallenge.current.showDeletePrompt(levelIndex, sectionIndex, challengeIndex);}}
                                                style={{...style.iconButton, ...style.trashIcon}}>
                <Icon name="trash"/>
            </Button>
            <Button type="button" onClick={()=>onHideTab(levelIndex, sectionIndex, challengeIndex)} style={{...style.iconButton, ...style.windowIcon}}>
                <Icon name={challenge.isOpen ? "window minimize" : "window maximize"}/>
            </Button>
            <div>{t('activity_title')}{challenge.locales[locale] && challenge.locales[locale].title && ": " + challenge.locales[locale].title}</div>
        </div>
                <DeletePromptContainer ref = {refChallenge} onDeleteConfirmation = {onDeleteTab}/>

        {(true || Boolean(challenge.isOpen)) &&
        <div style={style.body}>
            <span>{t('unique_tag_prompt')}</span>
            <Input
                name = {getBasepath(levelIndex, sectionIndex, challengeIndex) + ".tag"}
                placeholder ={t('tag_prompt')}
                value={challenge && challenge.tag || ''} 
                onChange={onChange}
                onFocus={onChange}
                maxLength = "100"
            />
            <br />
            <br />
            <Tab panes={languages.map(language=>({
                                            menuItem:language.text,
                                            render:() => <ChallengePane t={t} 
                                                            locale={language.value}
                                                            data={challenge.locales[language.value]}
                                                            basepath={getBasepath(levelIndex, sectionIndex, challengeIndex)}
                                                            onChange={onChange}
                                                            valueChange={valueChange}/>}))} />

            <br />
            <br />

            <div>{t('image_prompt')}</div>
            {Boolean(challenge.image && challenge.image.enabled!==false && (challenge.image.url || challenge.image.dataURL)) &&
                <ImageWithClose style={style.imagePreview} 
                                src={challenge.image.dataURL || challenge.image.url} alt=''
                                onClose={()=>onCancelPhoto(getBasepath(levelIndex, sectionIndex, challengeIndex)+'.image')}/>
            }
            <Button type='button' 
                    style={style.uploadPhoto} 
                    onClick={()=>onUploadPhoto({field:getBasepath(levelIndex, sectionIndex, challengeIndex)+'.image.dataURL', width:1280, height:720})}>
                {t('upload_photo_button_text')}
            </Button>
            <br /><br />
            <Popup trigger={
                <Input fluid 
                           placeholder={t('label')}
                           name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.image.label'} 
                           value={ (challenge.image && challenge.image.label) || ''}
                           maxLength="100"
                           onChange={onChange} />
            }
                position = 'right center'
                content = {(100-valueChange) + ' characters left'}
                on='focus' />

            <br />
            <Button  type='button'  onClick={()=>onAutoCaption(getBasepath(levelIndex, sectionIndex, challengeIndex)+'.image.label', challenge.image.dataURL || challenge.image.url)}>Autogenerate Caption</Button>
            <br />

            <div>{t('stars_prompt')}</div>
            <Dropdown options={Stars}
                      name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.points'}
                      value={challenge.points || ''}
                      onChange={onChangeDropdown}
                      selection
                      closeOnChange/>
            <br />
            <br />

            <div>{t('dollars_prompt')}</div>
            <Dropdown options={Resources}
                      name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.resources'}
                      value={challenge.resources || ''}
                      onChange={onChangeDropdown}
                      selection
                      closeOnChange/>
            <br />
            <br />

            <div>{t('type_prompt')}</div>
            <Card.Group>
            {Types.map(type=>(
                <Card key={type.id} style={{...style.typeCard, ...(challenge.type===type.id && style.typeCardActive)}} onClick={()=>onSelectChallengeType(levelIndex, sectionIndex, challengeIndex, type.id)}>
                    <div style={style.typeLabel}>{type.label}</div>
                    <Icon style={style.typeIcon} className={type.icon} />
                </Card>
            ))}
            </Card.Group>
            <br />
            <br />

            {Boolean(ApplicableFields.ChallengeAnswers[challenge.type]) &&
            
                <div>
                    {t('multiple_choice_answer_key')}
                    {(challenge.challengeAnswers||[]).map((ca, i)=>(
                        <ChallengeAnswer key={ca.key || ca.id}
                                       levelIndex={levelIndex} 
                                       sectionIndex={sectionIndex} 
                                       challengeIndex={challengeIndex}
                                       challengeAnswerIndex={i}
                                       challengeAnswer={ca}
                                       onChange={onChange}
                                       onChangeDropdown={onChangeDropdown}
                                       onToggle={onToggle}
                                       languages={languages}
                                       onSelectChallengeType={onSelectChallengeType}
                                       onHideTab={onHideTab}
                                       onDeleteTab={onDeleteTab}
                                       getBasepath={getBasepath}
                                       locale={locale}
                                       refChallengeAnswer = {refChallengeAnswer}/> 
                    ))}
                    <div style = {style.addRow}>
                       {t('add_challenge_answer_text')}
                        <Button type='button' style={style.addButtonRowVersion} onClick={()=>onAddChallengeAnswer(levelIndex, sectionIndex, challengeIndex)} >
                            <Icon name="plus"></Icon>
                        </Button>
                </div>
                </div>
            }
            {Boolean(ApplicableFields.Duration[challenge.type]) &&
                <div>
                    <div>{t('duration_prompt')}</div>
                    <Dropdown options={Durations}
                              name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.duration'}
                              value={challenge.duration || ''}
                              onChange={onChangeDropdown}
                              selection
                              closeOnChange/>
                </div>
            }
            {Boolean(ApplicableFields.MaxLoggedActions[challenge.type]) &&
                <div>
                    <div>{t('max_logged_actions_prompt')}</div> 
                    <Input fluid 
                           placeholder={t('actions_max_placeholder')}
                           type='number'
                           name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.actionsMax'} 
                           value={challenge.actionsMax || ''} 
                           onChange={onChange} />
                </div>
            }
            {Boolean(ApplicableFields.ActionsRequiredForPrimaryMilestone[challenge.type]) &&
                <div>
                    <div>{t('actions_required_prompt')}</div> 
                    <Input fluid 
                           placeholder={t('actions_max_placeholder')}
                           type='number'
                           name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.actionsMax'} 
                           value={challenge.actionsMax || ''} 
                           onChange={onChange} />
                </div>
            }
            <br />
            <br />


            <div>{t('source_name_prompt')}</div> 
            <Input fluid 
                   name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.reference'} 
                   value={challenge.reference || ''} 
                   onChange={onChange} />
            <br />
            <br />

            <div>{t('source_url_prompt')}</div> 
            <Input fluid
                   name={getBasepath(levelIndex, sectionIndex, challengeIndex)+'.referenceUrl'} 
                   value={challenge.referenceUrl || ''} 
                   onChange={onChange} />
            <br />
            <br />

        </div>
        }

    </div>
);

export default withTranslation('categories')(Challenge);
