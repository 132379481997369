import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Icon, Card, Tab, Button, Modal, Input, Checkbox, Dropdown, Label, Popup} from 'semantic-ui-react';
import _ from 'underscore';

import {getCategoryImageUrl } from '../tools.js';
import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import styleDef from './style.js';

var style = {
    ...styleDef,
    saveButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    updateButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    deleteButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
        color: colors.warningRed,
        backgroundColor: '#fff',
    },
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        margin: spacings.base,
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    columns: {
    },
    column: {
        display: 'inline-block',
        width: '50%',
    },
    card: {
        width: '145px',
        color: '#fff',
        backgroundColor: colors.themeTeal,
    },
    iconHeader: {
        padding:'8px', 
        width:'100%',
        color: '#fff',
        backgroundColor: colors.themeTeal,
    },
    imageBanner: {
        position: 'absolute',
        height:'79px', 
        width:'100%',
        backgroundSize:'100%',
        backgroundPositionY: 'center',
        bottom: '37px',
        left: '0px',
    },
    meta: {
        color:'#fff', 
    },
    toggle: {
        position: 'absolute',
        bottom: '8px',
        left: '8px',
    },
    relevancyWrapper: {
        paddingTop: '1px',
        paddingBottom: spacings.tiny,
        paddingLeft: spacings.tiny,
        paddingRight: spacings.tiny,
        backgroundColor: colors.themeBlue,
        borderRadius: '3px',
        position: 'absolute',
        bottom: '0px',
        right: '0px',
    },
    relevancyDropdownLabel: {
        fontSize: fontSizes.bodyS,
    },
    relevancyDropdown: {
        width: '100%',
        textAlign: 'right',
    },
    header: {
        fontSize: '1em',
        color:'#fff', 
    },
    cardContent: {
        border: 'none',
        minHeight: '116px',
    },
    toggleContainer: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        width: '100%',
        height: '37px',
        backgroundColor: colors.themeBlue,
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
    },
    toggleContainerExpanded: {
        height: '49px',
    }
};


const Qualities = _.range(10).map(i => {
    var value = i + 1;
    return {key: value, value: value, text:String(value)};
});

var titleCharsShow = false;

const ObjectiveLocalePane = ({t, data, locale, onChange, valueChange}) => (
    <div>
        <br />
         <Popup trigger={
             <Input  value={data ? data.title : ''} 
             name= {'locales.'+locale+'.title'}
             placeholder = {t('title_prompt')}
             onChange={onChange}
             onFocus={onChange}
             maxLength = "100"/>
        }
            position = 'right center'
            content = {(100-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        
    </div>
);



const EditObjectiveModal = ({t,
                          languages,
                          locale,
                          locales,
                          categories,
                          objectiveCategories,
                          image,
                          autoSubscribe,
                          companyId,
                          update,
                          valid,
                          master,
                          busy,
                          uploadPhotoProperty,
                          onChange, 
                          onChangeLocale,
                          onChangeDropdown,
                          onToggle,
                          onTogglePublic,
                          onToggleAutoSubscribe,
                          onClose, 
                          onUploadPhoto,
                          onConfirmPhoto,
                          onSubmit,
                          onDelete,
                          valueChange}) => (
    <Modal open style={style.content} onClose={onClose} size='large'>
        <Modal.Header>{update ? t('updateobjective_modal_header') : t('addnewobjective_modal_header')}</Modal.Header>
        <Modal.Content>
        <form onSubmit={onSubmit}>
            <Modal.Description>
                <br />
                <div>{t('preferred_language_prompt')}</div>
                <Dropdown options={languages}
                          value={locale}
                          onChange={onChangeLocale}
                          selection
                          closeOnChange/>
                <br />
                <br />
                <Tab panes={languages.map(language=>({
                                                menuItem:language.text,
                                                render:() => <ObjectiveLocalePane t={t} 
                                                                locale={language.value}
                                                                data={locales[language.value]}
                                                                onChange={onChange}
                                                                valueChange = {valueChange} />}))} />
                
                <br />

                <div>{t('badge_prompt')}</div>
                {Boolean(image && (image.url || image.dataURL)) &&
                    <img style={style.imagePreview} src={image.dataURL || image.url} alt='' />
                }
                <Button type='button' 
                        style={style.uploadPhoto} 
                        onClick={()=>onUploadPhoto('image.dataURL')}>
                    {t('upload_photo_button_text')}
                </Button>
                <br />
                <br />

                <div>{t('auto_subscribe_prompt')}</div>
                <Checkbox className='green' 
                          toggle 
                          title={t('autosubscribe_toggle_objective_title')} 
                          checked={Boolean(autoSubscribe)} 
                          onChange={onToggleAutoSubscribe}/>
                <br />
                <br />
                
                {master &&
                <div>
                    <div>{t('public_prompt')}</div>
                    <Checkbox className='green' 
                              toggle 
                              title={t('public_toggle_objective_title')} 
                              checked={Boolean(!companyId)} 
                              onChange={onTogglePublic}/>
                    <br />
                    <br />
                </div>
                }
                
                <div>{t('goals_prompt')}</div>
                <hr />
                {busy &&
                    <LoadInline centered vcentered className='notInDimmer' /> 
                }
                {!busy && 
                    <Card.Group>
                    {categories.map(category => (
                        <Card key={category.id} style={style.card}>
                            <div style={style.iconHeader}>
                                {category.title}
                            </div>
                            <div style={{...style.imageBanner, backgroundImage:Utils.url(getCategoryImageUrl(category))}} />
                            <Card.Content style={style.cardContent}>
                                <Card.Header style={style.header}>
                                    {category.levelReq > 1 &&
                                        <Card.Meta style={style.meta}>Level {category.levelReq}</Card.Meta>
                                    }
                                    <div style={{...style.toggleContainer, ...(Boolean((objectiveCategories[category.id]||{}).enabled) ? style.toggleContainerExpanded : null)}}>
                                        <Checkbox className='green' 
                                                  name={'objectiveCategories.'+category.id+'.enabled'}
                                                  toggle 
                                                  style={style.toggle} 
                                                  checked={Boolean((objectiveCategories[category.id]||{}).enabled)} 
                                                  onChange={onToggle} />
                                        {Boolean((objectiveCategories[category.id]||{}).enabled) &&
                                            <div style={style.relevancyWrapper}>
                                                <div style={style.relevancyDropdownLabel}>{t('relevancy_prompt')}</div>
                                                <Dropdown style={style.relevancyDropdown}
                                                          inline
                                                          name={'objectiveCategories.'+category.id+'.quality'}
                                                          options={Qualities}
                                                          value={Utils.deref(objectiveCategories, category.id+'.quality')}
                                                          onChange={onChangeDropdown}
                                                          compact
                                                          closeOnChange/>
                                            </div>
                                        }
                                    </div>
                                </Card.Header>
                            </Card.Content>
                        </Card>
                    ))}
                    </Card.Group>
                }
            
                <br />
                <br />
                {update &&
                    <Button style={style.deleteButton} type='button' onClick={onDelete}>{t('Delete')}</Button>            
                }
                {update ? (
                    <Button type='submit' style={style.updateButton} disabled={!valid || busy}>{t('Save')}</Button>
                ) : (
                    <Button type='submit' style={style.saveButton} disabled={!valid || busy}>{t('Create')}</Button>
                )}
                <br />
                {Boolean(uploadPhotoProperty) &&
                    <ChoosePhotoContainer onConfirmPhoto={photo=>onConfirmPhoto(uploadPhotoProperty, photo)}
                                          onClose={onUploadPhoto}/>
                }
            </Modal.Description>
        </form>
        </Modal.Content>
    </Modal>
);

export default withTranslation('categories')(EditObjectiveModal);
