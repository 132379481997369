import {SET_SIDEBAR_ENABLE, TOGGLE_SIDEBAR} from 'actions/types'

export default (state = {enable:false}, action) => {
    switch(action.type) {
        case SET_SIDEBAR_ENABLE: 
            return {...state, enable:action.enable};
        case TOGGLE_SIDEBAR: 
            return {...state, enable:(state.enable===false)};
        default:            
            return state;
    }
}
