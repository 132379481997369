import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {getCategories} from 'reducers/categories.js';
import {fetchCategories, toggleCategory} from 'actions/categories.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';

import Categories from './Categories.js';


class CategoriesContainer extends Component {

    constructor(props){
		super(props);
        this.state = {busy:false, 
                      categoryToDisplay:null};
        this.onShowCategoryInfo = this.onShowCategoryInfo.bind(this);
        this.onToggleCategory = this.onToggleCategory.bind(this);
        this.onClickAddNewGoal = this.onClickAddNewGoal.bind(this);
        this.onClickAddNewPathway = this.onClickAddNewPathway.bind(this);
        this.onClickManageObjectives = this.onClickManageObjectives.bind(this);
        this.onEditCategory = this.onEditCategory.bind(this);
        this.canUserEditCategory = this.canUserEditCategory.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(setSidebarEnable(true));
        this.loadData();
    }

    onShowCategoryInfo(category) {
        if(!category || category.type <= 1)
            this.setState({categoryToDisplay:category});
    }

   /* onToggleCategory(category) {
        /*
        var companyCategory = category.companyCategory || {enabled:false};
        var updatedCompanyCategory = {...companyCategory, enabled:!companyCategory.enabled};
        var updatedCategory = {...category, companyCategory:updatedCompanyCategory};
        var updatedCategories = this.state.categories.map(c => c.id===category.id ? updatedCategory : c  );
        this.setState({categories:updatedCategories})
        */


    onClickAddNewGoal() {
        this.props.history.push('/goals/new');
    }

    onClickAddNewPathway() {
        this.props.history.push('/pathways/new');
    }

    onClickManageObjectives() {
        this.props.history.push('/objectives');
    }

    onEditCategory(category) {
        this.props.history.push('/pathways/' + category.id);
    }

    canUserEditCategory(category) {
        return Boolean(category.ownerCompanyId === Number(localStorage.companyId) || 
                       localStorage.master === "true") && category.type <= 1;
    }

    loadData() {
        this.setState({busy:true});
        this.props.fetchCategories({}, true)
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
               this.setState({busy:false});
            });
    }

    onToggleCategory(category) {
        this.props.toggleCategory(category.id)
            .catch((error)=> {
                if(error.code == 1100){
                    window.alert(error.message)
                }else {
                    window.alert('An error occurred - check logs');
                }
                console.error(error);
            })
    }

    render() {
        var categories = this.props.categories || [];
        return (
            <Categories {...this.props}
                        {...this.state}
                        selectedCategories={categories.filter(category => Boolean(category.companyCategory && category.companyCategory.enabled))}
                        onShowCategoryInfo={this.onShowCategoryInfo}
                        onClickAddNewGoal={this.onClickAddNewGoal}
                        onClickAddNewPathway={this.onClickAddNewPathway}
                        onClickManageObjectives={this.onClickManageObjectives}
                        onEditCategory={this.onEditCategory}
                        canUserEditCategory={this.canUserEditCategory}
                        onToggleCategory={this.onToggleCategory}/>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {categories:getCategories(state.categories)};
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: (params, force) => dispatch(fetchCategories(params, force)),
        toggleCategory: id => dispatch(toggleCategory(id)),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('categories')(CategoriesContainer)));
