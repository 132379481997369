import React from 'react';
import { withTranslation } from 'react-i18next';

import Utils from 'utils/Utils.js';
import {colors, spacings, shadow, fontSizes, fonts} from 'style';

var s = {
    container: {
        position: 'relative',
        display: 'inline-block',
    },
    button: {
        position: 'absolute',
        top: '30px',
        right: '30px',
        backgroundColor: 'rgba(255,255,255,0.5)',
        borderRadius: '50%',
        border: 'none',
        width: '18px',
        height: '18px',
        margin: '0px',
        padding: '0px',
        cursor: 'pointer',
    },
    icon: {
        width: '18px',
        height: '18px',
        margin: '0px',
        padding: '0px',
    }
};



const ImageWithClose = ({t,
                        style,
                        buttonStyle,
                        alt,
                        src,
                        onClose}) => (
        <div>
            <div style={s.container}>
                <img style={{...style}} src={src} alt={alt || ''} />
                <button style={{...s.button, ...buttonStyle}} onClick={onClose}>
                    <i style={s.icon} className='close icon' /> 
                </button>
            </div>
        </div>
  );

export default withTranslation('misc')(ImageWithClose);
