import _ from 'underscore';

export class Vector2 {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    set(x, y) {
        this.x = x;
        this.y = y;
    }
    length() {
      return Math.sqrt(this.sqrLength());
    }
    sqrLength() {
      return this.x * this.x + this.y * this.y;
    }
    add(_vec) {
      this.x += _vec.x;
      this.y += _vec.y;
    }
    sub(_vec) {
      this.x -= _vec.x;
      this.y -= _vec.y;
    }
    div(_f) {
      this.x /= _f;
      this.y /= _f;
    }
    mul(_f) {
      this.x *= _f;
      this.y *= _f;
    }
    dot(_vec) {
        return this.x*_vec.x + this.y*_vec.y;
    }
    normalize() {
      var sqrLen = this.sqrLength();
      if (sqrLen != 0) {
        var factor = 1.0 / Math.sqrt(sqrLen);
        this.x *= factor;
        this.y *= factor;
      }
    }
    getNormalized() {
      var sqrLen = this.sqrLength();
      if (sqrLen != 0) {
        var factor = 1.0 / Math.sqrt(sqrLen);
        return new Vector2(this.x * factor, this.y * factor);
      }
      return new Vector2(0, 0);
    }
}


class MathUtils {
    coalesceNumber(num, def) {
        if(num===undefined || 
           num===null || 
           !_.isFinite(num)) {
            return def;
        }
            
        return num;
    }
    clamp(x, min, max) {
        if(x < min) {
            return min;
        } else if (x > max) {
            return max;
        } else {
            return x;
        }
    }
}

export default new MathUtils();
