import React from 'react';
import { withTranslation } from 'react-i18next';
import {colors, fontSizes, fonts, spacings, shadow, buttons} from 'style';
import {Icon, Button, Table, Image} from 'semantic-ui-react';
import _ from 'underscore'
import moment from 'moment'

const styles = {
    trashIcon: {
        background: 'none'
    }
}

const tableRow = ({item, actions, fields, onClick, icons}) => {
    return (
        <Table.Row onClick={onClick} style={{flexDirection: 'row', ...(item.styles ? item.styles : {})}}>
                {fields.map(field => {
                    var value = _.get(item, field.key)
                    if (field.key.includes('.')) {
                        value = item
                        var path = field.key.split('.')
                        path.forEach((pathField) => {
                            value = value?.[pathField]
                        })
                    }
                    return (<Table.Cell style={field.cellStyle}>
                        {
                            field.type === 'date' && value ?
                            moment(value).format('MMMM D YYYY, h:mm a') :
                            field.type === 'image' && value ?
                            <Image size='mini' style={{maxWidth: 100}} src={value} ui={false} /> :
                            field.type === 'custom' && field.valueFunc ? 
                            field.valueFunc(value) :
                            value?.toString()
                        }
                    </Table.Cell>)
                })}
                <Table.Cell style={{display: 'flex', flexDirection: 'row'}}>
                    {
                        actions && actions.map(action => (
                            <Button style={{...styles.trashIcon, color: action.color}} onClick={() => action.invoke(item)}>
                                <Icon name={action.name} />
                            </Button>
                        ))
                    }
                    {
                        icons && icons.map(icon => (
                            <div>
                                <Icon style={icon.style} name={icon.name} />
                            </div>
                        ))
                    }
                </Table.Cell>
        </Table.Row>
    )
}

export const TableRow = withTranslation('card')(tableRow)