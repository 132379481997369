import DatabaseService from 'services/DatabaseService.js';
import {SET_CAMPAIGNS} from 'actions/types';
import {getCampaigns, isListLoaded}  from 'reducers/campaigns.js';
import moment from 'moment-timezone'

//Action Creators
export const setCampaigns = (campaigns, count) => {
    return { type:SET_CAMPAIGNS, campaigns:campaigns, count: count};
}

//Thunks
export const fetchCampaigns = (params, force) => (dispatch, getState) => {

    if (params.query && params.query !== '' && params.queryField !== '') {
        if (Array.isArray(params.queryField)) {
            for (var i in params.queryField) {
                params[`like.${params.queryField[i]}`] = params.query
            }
            delete params.query
        } else {
            params[`like.${params.queryField}`] = params.query
        }
    }
    
    if(!force) {
        var state = getState();
        if(isListLoaded(state.campaigns)) {
            return Promise.resolve(getCampaigns({data: state.campaigns, count: state.count}));
        }
    }
    return new Promise(function(resolve, reject) {
        DatabaseService.getCampaigns(params).then(
            function(result){
                console.log(result)
                result.campaigns = result.rows.map(campaign => {
                    return {
                        image: campaign.survey?.asset?.url,
                        'survey.title': campaign.survey?.title,
                        'survey.tag': campaign.survey?.tag,
                        'team.name': campaign.team?.name,
                        title: campaign.survey?.title,
                        tag: campaign.survey?.tag,
                        category: campaign.survey?.surveyCategory?.name,
                        startDate: campaign.startsAt ? moment(campaign.startsAt).format('MMM DD, YYYY h:mm a') : 'unscheduled',
                        endDate: campaign.endsAt ? moment(campaign.endsAt).format('MMM DD, YYYY h:mm a') : 'No Expiry',
                        banner: campaign.startsAt ? moment(campaign.startsAt).format('MMM DD, YYYY h:mm a') : 'unscheduled',
                        public: !campaign.companyId ? "Public" : "",
                        fields: [
                            {
                                label: '',
                                text: campaign.survey?.tag
                            },
                            ...(campaign.endsAt ? [{
                                label: 'Ends At',
                                text: moment(campaign.endsAt).format('MMM DD, YYYY h:mm a'),
                            }] : [])
                        ],
                        description: campaign.survey?.tag,
                        ...campaign
                    }
                })
                dispatch(setCampaigns(result.campaigns, result.count))
                resolve({data: result.campaigns, count: result.count})
            },
            function(error){ 
                console.error(error);
                reject(error); 
            });
    });
}
