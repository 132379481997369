import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import EditTeamModal from './EditTeamModal.js';


class EditTeamModalContainer extends Component {
    constructor(props) {
        super(props);
        var team = this.props.team;
        var teamState = Boolean(team && team.id) ? (
            {
                id:   team.id,
                name: team.name     || '',
                avatarAssetId: team.avatarAssetId,
                avatar: team.avatar,
            }
        ) : (
            {
                name: '',
                avatarAssetId: null,
                avatar: null,
            }
        );
        this.state = {
                        employees:[],
                        teamMembers: [],
                        search: '',
                        valid:false,
                        uploadPhotoProps:null,
                        teamAssets: [],
                        teamAssetMap: {},
                        chars_left: 0,
                        ...teamState,
                     };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.doSearchDebounced = _.debounce(this.doSearch, 300)
        this.onAddTeamMember = this.onAddTeamMember.bind(this);
        this.onRemoveTeamMember = this.onRemoveTeamMember.bind(this)
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onConfirmPhoto = this.onConfirmPhoto.bind(this);
        this.onSelectAsset = this.onSelectAsset.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.deleteModalRef = React.createRef();
    }

    componentWillMount() {
        this.loadData();
    }

    onUploadPhoto(uploadPhotoProps) {
        this.setState({uploadPhotoProps});
    }

    onConfirmPhoto(uploadPhotoProps, photo) {
        this.setState(state => (Utils.set({...state}, uploadPhotoProps.field, photo)));
    }

    loadData() {
        DatabaseService.getAssets({type:'team'})
            .then(result => {
                var teamAssets = result.assets;
                var teamAssetMap = teamAssets.reduce((m,ta)=>{
                        m[ta.id] = ta;
                        return m;
                    },{}) 
                this.setState({teamAssets, teamAssetMap});
            })
            .catch(error => {
                window.alert('An error occurred, check logs');
                console.error(error);
            });
    }

    onSubmit(event) {
        event && event.preventDefault();
        var update = Boolean(this.state.id);
        var companyEmployeeIds = this.state.teamMembers.map(employee => employee.id);
        var method = update ? 'updateTeam' : 'addTeam';
        var team = {
            name: this.state.name,
            avatarAssetId: this.state.avatarAssetId,
            avatar: this.state.avatar,
        };


        this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService[method]({team:team, teamId: this.state.id, companyEmployeeIds:companyEmployeeIds})
            .then(()=>{
                return this.props.onRefresh();
            })
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                this.props.onClose();
            });
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        this.setState({chars_left: event.target.value.length})
        this.setState({[event.target.name]: event.target.value});
    }

    onDelete(deleteYes) {
        if(deleteYes){
        this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:true});
        DatabaseService.removeTeam({teamId: this.state.id})
            .then(()=>{
                 this.props.history.push('/teams');
            })
            .catch(error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                this.props.dispatch({type:SET_LOADING_OVERLAY_ENABLE, enable:false});
                this.props.onClose();
            });
        }
    }

    doSearch() {
        if(this.state.search) {
            this.setState({loadingEmployees:true});
            DatabaseService.getEmployees({
                    'like.fullName' : this.state.search,
                    'like.email' : this.state.search
                })
                .then(result => {
                    this.setState({employees:result.employees});
                })
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(result => {
                    this.setState({loadingEmployees:false});
                });
        } else {
            this.setState({employees:[]})
        }
    }

    onAddTeamMember(employee) {
        this.setState(state=>{
            if(_.findWhere(state.teamMembers,{id:employee.id})===undefined) {
                return {teamMembers:[employee].concat(state.teamMembers),
                        employees:[],
                        search:''}
            } else {
                return {};
            }
        });
    }

    onRemoveTeamMember(employee) {
        this.setState(state=>({teamMembers:(state.teamMembers.filter(e => e.id!==employee.id))}));
    }

    onChangeSearch(event) {
        var search = event.target.value;
        this.setState({search: search}, this.doSearchDebounced);
    }

    getAvatar(avatar, avatarAssetId) {
        if(avatar && avatar.dataURL) {
            return avatar.dataURL;
        } else {
            if(avatarAssetId) {
                var ta = this.state.teamAssetMap[avatarAssetId];
                if(ta) {
                    return ta.url;
                }
            }
        }
        return undefined;
    }

    onSelectAsset(asset) {
        console.log('onSelectAsset');
        this.setState({avatar:null, avatarAssetId:asset.id});
    }

    render() {
        return (
            <EditTeamModal {...this.props} 
                           {...this.state}
                           update={Boolean(this.state.id)}
                           onSubmit={this.onSubmit}
                           onChange={this.onChange}
                           onChangeSearch={this.onChangeSearch}
                           onClose={this.onClose}
                           onDelete={this.onDelete}
                           onAddTeamMember={this.onAddTeamMember}
                           onRemoveTeamMember={this.onRemoveTeamMember}
                           onUploadPhoto={this.onUploadPhoto}
                           onConfirmPhoto={this.onConfirmPhoto}
                           onSelectAsset={this.onSelectAsset}
                           getAvatar={this.getAvatar}
                           deleteModalRef = {this.deleteModalRef}
                           valueChange = {this.state.chars_left}/>
      );
    }
}

export default withRouter(connect()(withTranslation('announcements')(EditTeamModalContainer)));
