import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Card, Icon, Grid, Input, Button, Checkbox, Label, Dropdown, Tab, Form} from 'semantic-ui-react';
import _ from 'underscore';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, buttons, shadow} from 'style';
import CategoryLevelSection from './CategoryLevelSection.js';
import defaultObjectiveImage from 'img/default-challenge-image.jpg';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'

var style = {
    content: {
        margin: spacings.tiny,
        border: '1px solid ' + colors.themeDarkGrey,
        borderRadius: '6px',
    },
    body: {
        padding: spacings.base,
    },
    titleBar: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontSize: fontSizes.bodyL,
        padding: spacings.small,
        backgroundColor: colors.themeGrey,
    },
    trashIcon: {
        color: colors.warningRed,
    },
    iconButton: {
        background: 'none',
        padding: '0',
        margin: '0',
        fontSize: '24px',
        float: 'right',
    },
    typeCard: {
        textAlign: 'center',
        padding: spacings.base,
        width: '150px',
        maxWdith: '150px',
        color: '#000',
    },
    typeCardActive: {
        backgroundColor: colors.themeTeal,
        color: '#fff',
    },
    typeLabel: {
        paddingBottom: spacings.base,
    },
    typeIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '40px',
        lineHeight: '1',
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },

};


export const ChallengeAnswerPane = ({t, data, locale, basepath, onChange}) => (
    <div>
        <div>{t('label_prompt')}</div> 
        <Input fluid name={basepath + '.locales.'+locale+'.label'} value={data ? data.label : ''} onChange={onChange} />
    </div>
);


const ChallengeAnswer = ({t,
                    levelIndex,
                    languages,
                    sectionIndex,
                    challengeIndex,
                    challengeAnswerIndex,
                    challengeAnswer,
                    onDeleteTab,
                    onHideTab,
                    onChange,
                    getBasepath,
                    locale,
                    refChallengeAnswer
                    }) => (
    <div style={style.content}>
        <div style={style.titleBar}>
            <Button type="button" onClick={()=>{refChallengeAnswer.current.showDeletePrompt(levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex);}} 
                                                style={{...style.iconButton, ...style.trashIcon}}>
                <Icon name="trash"/>
            </Button>
            <Button type="button" onClick={()=>onHideTab(levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex)} style={{...style.iconButton, ...style.windowIcon}}>
                <Icon name={challengeAnswer.isOpen ? "window minimize" : "window maximize"}/>
            </Button>
            <div>{t('answer_title')}{challengeAnswer.locales[locale] && challengeAnswer.locales[locale].label && ": " + challengeAnswer.locales[locale].label}</div>
        </div>
        <DeletePromptContainer ref = {refChallengeAnswer} onDeleteConfirmation = {onDeleteTab}/>

        {Boolean(challengeAnswer.isOpen) &&
        <div style={style.body}>
            <Tab panes={languages.map(language=>({
                    menuItem:language.text,
                    render:() => <ChallengeAnswerPane t={t} 
                                    locale={language.value}
                                    data={challengeAnswer.locales[language.value]}
                                    basepath={getBasepath(levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex)}
                                    onChange={onChange} />}))} />
            <br />
            <br />

            <div>{t('value_prompt')}</div> 
            <Input fluid
                   name={getBasepath(levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex)+'.value'} 
                   value={challengeAnswer.value || ''} 
                   onChange={onChange} />
            <br />
            <br />

        </div>
        }

    </div>
);

export default withTranslation('categories')(ChallengeAnswer);
