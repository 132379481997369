import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Checkbox, Button, Modal, Form, Input} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import styleDef from './style.js';
import defaultAvatar from 'img/default-avatar.png';

var style = {
    ...styleDef,
    checkbox: {
        marginRight: spacings.tiny,
    },
};



const EditEmployeeModal = ({t,
                           firstName,
                           lastName,
                           email,
                           team,
                           position,
                           division,
                           office,
                           city,
                           country,
                           policyHolder,
                           update,
                           valid,
                           onSubmit,
                           onChange,
                           onClose,
                           isInsuranceCarrier}) => (
    <Modal open style={style.content} onClose={onClose} size='small'>
        <Modal.Header>{update ? t('Edit Member') : t('Add Member')}</Modal.Header>
        <Modal.Content>
        <Form onSubmit={onSubmit}>
            <Modal.Description>

                <Form.Group>
                    <Form.Field>
                        <label>{t('First Name')}</label> 
                        <Input name='firstName' value={firstName} onChange={onChange} maxLength = "200"/>
                    </Form.Field>

                    <Form.Field>
                        <label>{t('Last Name')}</label> 
                        <Input name='lastName' value={lastName} onChange={onChange} maxLength = "200"/>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <label>{t('Email')}</label> 
                        <Input name='email' value={email} onChange={onChange} maxLength = "50"/>
                    </Form.Field>

                    {
                         false  && 
                        <Form.Field>
                            <label>{t('Team')}</label> 
                            <Input name='team' value={team} onChange={onChange} maxLength = "50"/>
                        </Form.Field>
                    }
                </Form.Group>
                {
                    false && <>
                
                    <Form.Group>
                        <Form.Field>
                            <label>{t('Position')}</label> 
                            <Input name='position' value={position} onChange={onChange} maxLength = "50"/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('Division')}</label> 
                            <Input name='division' value={division} onChange={onChange} maxLength = "50"/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>{t('Office')}</label> 
                            <Input name='office' value={office} onChange={onChange} maxLength = "50" />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>{t('City')}</label> 
                            <Input name='city' value={city} onChange={onChange} maxLength = "50"/>
                        </Form.Field>

                        <Form.Field>
                            <label>{t('Country')}</label> 
                            <Input name='country' value={country} onChange={onChange} maxLength = "50"/>
                        </Form.Field>
                    </Form.Group>
                </>
                }
                {
                    isInsuranceCarrier &&
                    <Form.Group>
                        <Form.Field>
                            <Checkbox
                                label={t('Policyholder')}
                                name={'policyHolder'}
                                checked={policyHolder}
                                onChange={onChange}/>
                        </Form.Field>
                    </Form.Group>
                }

                <br />
                <br />
                <Button style={style.button} disabled={!valid}>{t('Save')}</Button>
                <br />
            </Modal.Description>
        </Form>
        </Modal.Content>
    </Modal>
);

export default withTranslation('employees')(EditEmployeeModal);
