import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image, Icon, Tab, Button, Dropdown, Checkbox, Modal, Input, Message, Label, Popup, TextArea, Form } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment'
import { colors, fontSizes, fonts, spacings, shadow, buttons } from 'style';
import LoadInline from 'modules/loading/LoadInline.js';
import ImageWithClose from 'modules/misc/ImageWithClose.js';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import Utils from 'utils/Utils.js';
import DateUtils from 'utils/DateUtils.js';
import YearMonthForm from 'modules/misc/YearMonthForm.js';
import defaultAvatar from 'img/default-avatar.png';
import RoleUtils from '../../utils/RoleUtils'

const style = {
    announcementForm: {
        padding: spacings.base,
    },
    langPrompt: {
        paddingRight: spacings.tiny,
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    segmentCheckbox: {
        marginRight: spacings.tiny,
    },
    segmentDropdown: {
        minWidth: '130px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: '4px',
        textAlign: 'right',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    segmentOptions: {
        maxHeight: '400px',
        overflowY: 'scroll',
    },
    optionTitle: {
        textAlign: 'left',
        fontWeight: 'bold',
        cursor: 'default',
        padding: spacings.tiny,
        margin: '0',
    },
    option: {
        textAlign: 'left',
        cursor: 'pointer',
        paddingLeft: spacings.small,
        paddingRight: spacings.tiny,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
        margin: '0',
    },


    columnContainer: {
    },
    leftColumn: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '33%',
    },
    rightColumn: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '67%',
        paddingLeft: spacings.small,
    },
    employeeList: {
        height: '200px',
        overflowY: 'scroll',
        paddingLeft: '0px',
    },
    memberList: {
        height: '250px',
        overflowY: 'scroll',
    },
    memberRow: {
        listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    memberName: {
        fontWeight: 'bold',
        display: 'inline-block',
        width: '150px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: spacings.base,
    },
    avatar: {
        display: 'inline-block',
        width: '100px',
        boxShadow: shadow,
        border: '5px solid #fff',
    },
    employeeRow: {
        listStyle: 'none',
        borderTop: '1px solid ' + colors.themeDarkGrey,
        paddingTop: spacings.tiny,
        paddingBottom: spacings.tiny,
    },
    employeeName: {
        fontWeight: 'bold',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: spacings.base,
        verticalAlign: 'middle'
    },
    unregistered: {
        display: 'inline-block',
        width: '100px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    removeMemberButton: {
        float: 'right',
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        margin: '4px 8px 0 8px',
        color: colors.warningRed,
        backgroundColor: '#fff',
        fontSize: '20px',
    },
    timePicker: {
        display: 'inline-block',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: '4px',
    },
    hours: {
        display: 'inline-block',
        border: 'none',
        width: '60px',
        padding: '12px',
    },
    minutes: {
        marginLeft: '12px',
        display: 'inline-block',
        border: 'none',
        width: '60px',
        padding: '12px',
    },
    ampm: {
        display: 'inline-block',
        border: 'none',
        cursor: 'pointer',
        padding: '12px',
        minWidth: '60px',
    },
    dayPicker: {
        border: '1px solid rgba(34,36,38,.15)',
        borderRadius: '.28571429rem',
        padding: '.67857143em 1em'
    },
    pane: {
        padding: spacings.small,
        border: '1px solid ' + colors.themeDarkGrey1_2,
        borderTop: 'none'
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        height: '200px',
        margin: spacings.base,
    },
    uploadPhoto: {
        display: 'inline-block',
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.base,
    },
    addButton: {
        verticalAlign: 'middle'
    }
}

const MASTER_NAVIGATION_TYPES = [
    { key: "", value: "", text: "" },
    { key: "/home", value: "/home", text: "Home" },
    { key: "/steps", value: "/activities/steps", text: "Steps" },
    { key: "/water", value: "/activities/water", text: "Water" },
    { key: "/daily-quest", value: "/routines/daily-quest", text: "Daily Quest" },
    { key: "/objectives", value: "/objectives", text: "Objectives" },
    { key: "/categories", value: "/categories", text: "Customize Activity Pack" },
    { key: "/connections", value: "/connections", text: "Connections" },
    { key: "/feed/company", value: "/feed/company", text: "Community Feed" },
    { key: "/feed/me", value: "/feed/me", text: "My Progress" },
    { key: "/feedback", value: "/feedback", text: "Feedback" },
    { key: "/invitations", value: "/invitations", text: "Invite Friend" },
    { key: "/leaderboards", value: "/leaderboards", text: "Leaderboards" },
    { key: "/objectives", value: "/objectives", text: "Select Goal" },
    { key: "/profile", value: "/profile", text: "Profile" },
    { key: "/rewards", value: "/rewards", text: "Rewards" },
    { key: "/settings", value: "/settings", text: "Settings" },
    { key: "/url", value: "/url", text: "URL" },
    { key: "/custom", value: "/custom", text: "Custom" },
]

const NAVIGATION_TYPES = [
    { key: "/url", value: "/url", text: "URL" }
]

const sections = {
    LOCALES: 'locales',
    NAVIGATION_FIELD: 'navigation field',
}

const accessMap = {
    admin: [
        sections.LOCALES,
        sections.NAVIGATION_FIELD
    ],
    'corporate wellness': [
        sections.LOCALES,
        sections.NAVIGATION_FIELD
    ],
    'corporate wellness standard': [
        sections.LOCALES,
        sections.NAVIGATION_FIELD
    ]
}

export const AnnouncementPane = ({ 
    t, 
    data, 
    locale, 
    currentLocale, 
    onChange, 
    onChangeLocale, 
    onChangeDropdown,
    valueChange,
    isExistingAnnouncement,
    checkAccess,
    announcementType }) => (
    <div style={style.pane}>
        <div>
            <Popup trigger={
                <Input
                    name={'locales.' + locale + '.title'}
                    placeholder={'*' + t('title_prompt')}
                    value={data ? data.title : ''}
                    onChange={onChange}
                    onFocus={onChange}
                    onBlur={onChange}
                    maxLength="100"
                />
            }
                position='right center'
                content={(100 - valueChange) + ' characters left'}
                on='focus'
            />
        </div>
        <br />
        <div>
            <Popup trigger={
                <TextArea
                    name={"locales." + locale + ".description"}
                    placeholder={'*' + t('description_prompt')}
                    //style={style.textarea}
                    value={data ? data.description : ''}
                    onChange={onChange}
                    onFocus={onChange}
                    onBlur={onChange}
                    maxLength="400"
                />
            }
                position='top right'
                content={(400 - valueChange) + ' characters left'}
                on='focus' />
        </div>
        {checkAccess(sections.NAVIGATION_FIELD) && Boolean(announcementType === 1 && (!isExistingAnnouncement || localStorage.master === "true" || data.navigationType === "/url")) &&
            <div>
                <div style={{paddingTop: '1.5em', paddingBottom: '0.5em'}}><h5>{t('navigation')}</h5></div>
                <Input name={`locales.${locale}.buttonLabel`}
                    type="text"
                    placeholder = {`*${t('button_label')}...`}                   
                    value={data ? data.buttonLabel : ''}
                    onChange={onChange} />
                <br />
                <br />
                <div>{t('navigation_type')}:</div>
                {Boolean(localStorage.master === 'true') &&
                <Dropdown name={`locales.${locale}.navigationType`}
                    options={MASTER_NAVIGATION_TYPES}
                    value={data ? data.navigationType : ''}
                    onChange={onChangeDropdown}
                    selection
                    closeOnChange />
                }
                {Boolean(localStorage.master !== 'true') &&
                <Dropdown name={`locales.${locale}.navigationType`}
                    options={NAVIGATION_TYPES}
                    value={data ? data.navigationType : ''}
                    onChange={onChangeDropdown}
                    selection
                    closeOnChange />
                }

                {Boolean(data.navigationType === '/url') &&
                    <div>
                        <br />
                        <Input name={`locales.${locale}.navigationURL`}
                            type="text"
                            label={{ basic: true, content: `${t('link')}` }}
                            value={data ? data.navigationURL : ''}
                            onChange={onChange} />
                    </div>
                }
                {Boolean(data.navigationType === '/custom') &&
                    <div>
                        <br />
                        <Input name={`locales.${locale}.navigationRoute`}
                            type="text"
                            label={{ basic: true, content: `${t('route')}` }}
                            value={data ? data.navigationRoute : ''}
                            onChange={onChange} />
                    </div>
                }                
            </div>
        }

        <br />
        { checkAccess(sections.LOCALES) && 
        <div>
            <Checkbox
                label={t('use_as_default')}
                name={'default_translation_' + locale}
                value={locale}
                checked={locale === currentLocale}
                onChange={onChangeLocale} />
            <Popup
                trigger={<Icon name='question circle outline' />}
                content={t('translation:::preferred_language')}
                position='right center'
            />
        </div>
        }
    </div>
);

const AnnouncementForm = ({ t,
    children,
    update,
    locales,
    points,
    resources,
    announcementType,
    carousel,
    sendTo,
    channels,
    sendToSegment,
    recurrences,
    recurrenceType,
    languages,
    locale,
    sendAt,
    loadingMembers,
    members,
    search,
    employees,
    notificationOnly,
    initialMonthYear,
    onChangeDropdown,
    onChange,
    onChangeSearch,
    onChangeAnnouncementType,
    onChangeNotificationOnly,
    onChangeSendTo,
    onChangeDate,
    onSelectSendToSegment,
    onSubmit,
    onAddMember,
    onRemoveMember,
    valueChange,
    onChangeCarousel,
    onChangeLocale,
    banner,
    choosePhotoIsOpen,
    onCancelPhoto,
    onUploadPhoto,
    onConfirmPhoto,
    circleColor,
    navigationType,
    navigationURL,
    navigationRoute,
    buttonLabel,
    isExistingAnnouncement,
    roles
}) => {
    const checkAccess = (section) => {
        return RoleUtils.checkUIAccess(roles, accessMap, section)
    }
    return (
        <Form onSubmit={onSubmit}>
            <div style={style.announcementForm}>
                <Tab panes={ (checkAccess(sections.LOCALES) ? languages : languages.slice(0, 1)).map(language => ({
                    menuItem: [<div key={language.text}> {language.text} <Icon id={language.text} size='large' name='translate' color={circleColor[language.value].color} /></div>],
                    render: () => <AnnouncementPane t={t}
                        checkAccess={checkAccess}
                        locale={language.value}
                        currentLocale={locale}
                        data={locales[language.value]}
                        onChange={onChange}
                        valueChange={valueChange}
                        onChangeLocale={onChangeLocale}
                        onChangeDropdown={onChangeDropdown}
                        isExistingAnnouncement={isExistingAnnouncement}
                        announcementType={announcementType} />
                }))} />

                <br />
                <br />
                <Checkbox
                    label={t('notification_only')}
                    name='notificationOnly'
                    checked={notificationOnly}
                    onChange={onChangeNotificationOnly} />
                <br />
                <br />
                
                { !notificationOnly &&
                    <div>
                        <div>{t('banner_prompt')}</div>
                        {Boolean(banner && banner.enabled !== false && (banner.url || banner.dataURL)) &&
                            <ImageWithClose style={style.imagePreview}
                                src={banner.dataURL || banner.url}
                                onClose={onCancelPhoto} />
                        }
                        <Button type='button'
                            style={style.uploadPhoto}
                            onClick={onUploadPhoto}>
                            {t('upload_photo_button_text')}
                        </Button>
                    </div>
                }
                <br />
                <br />
                <div>{t('announcement_type_prompt')}</div>
                <Checkbox
                    radio
                    label={t('Alert')}
                    name='announcementType'
                    value={1}
                    checked={announcementType === 1}
                    onChange={onChangeAnnouncementType} />
                <br />
                <Checkbox
                    radio
                    label={t('Reward with Gems')}
                    name='announcementType'
                    value={2}
                    checked={announcementType === 2}
                    onChange={onChangeAnnouncementType} />
                {Boolean(announcementType === 2) &&
                    <div>
                        <br />
                        <Input name='points'
                            label={{ basic: true, content: t('leaderboard_point_prompt') }}
                            type='number' value={points}
                            onChange={onChange} />
                        <br />
                        <br />
                        <Input name='resources'
                            label={{ basic: true, content: t('reward_point_prompt') }}
                            type='number'
                            value={resources}
                            onChange={onChange} />
                    </div>
                }
                <br />
                <br />

                <div>{t('send_to_prompt')}</div>
                <Checkbox
                    radio
                    label={t('segment_prompt')}
                    name='sendTo'
                    value={0}
                    checked={sendTo === 0}
                    onChange={onChangeSendTo}
                    style={style.segmentCheckbox} />

                <Dropdown closeOnChange
                    style={style.segmentDropdown}
                    text={sendToSegment ? sendToSegment.value : t('All')} disabled={sendTo !== 0}>
                    <Dropdown.Menu style={style.segmentOptions}>
                        <div key='all'>
                            <div style={style.option}
                                onClick={() => onSelectSendToSegment(null)}>
                                {t('All')}
                            </div>
                        </div>
                        {channels.map((channel, i) => (
                            <div key={i}>
                                <div style={style.optionTitle}>{channel.label}</div>
                                {channel.options.map((option, j) => (
                                    <div key={j}
                                        style={style.option}
                                        onClick={() => onSelectSendToSegment({ id: option[0], value: option[1], isSegment: option[2] })}>
                                        {option[1]}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <br />

                <Checkbox
                    radio
                    label={t('custom_audience_prompt')}
                    name='sendTo'
                    value={1}
                    checked={sendTo === 1}
                    onChange={onChangeSendTo} />
                {Boolean(sendTo === 1) &&
                    <div style={style.columnContainer}>
                        <div style={style.leftColumn}>
                            <div>{t('search_members_prompt')}</div>
                            <Input placeholder={t('search_placeholder')} value={search} onChange={onChangeSearch} fluid />

                            <ul style={style.employeeList}>
                                {employees.map((employee, i) => (
                                    <li key={employee.id} style={{ ...style.employeeRow, ...(i == 0 && { borderTop: 'none' }) }}>
                                        <Button type="button" style={style.addButton} onClick={() => onAddMember(employee)}>
                                            <Icon name='add' />
                                        </Button>
                                        <div style={style.employeeName}>
                                            {employee.fullName}<br /><small>{employee.email}</small>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div style={style.rightColumn}>
                            <div>{t('members_prompt')}</div>
                            {loadingMembers ? (
                                <LoadInline centered className='notInDimmer' />
                            ) : (
                                    <ul style={style.memberList}>
                                        {members.map((employee, i) => (
                                            <li key={employee.id} style={{ ...style.memberRow, ...(i == 0 && { borderTop: 'none' }) }}>
                                                <Button type="button" style={style.removeMemberButton} onClick={() => onRemoveMember(employee)}>
                                                    <Icon name='trash' />
                                                </Button>
                                                <Image style={style.avatar} src={Utils.deref(employee, 'userProfile.avatar.url', defaultAvatar)} />
                                                <div style={style.memberName}>
                                                    {employee.fullName}
                                                </div>
                                                {(!employee.userProfileId) &&
                                                    <div style={style.unregistered}>{t('(Unregistered)')}</div>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                )}
                        </div>
                    </div>
                }
                <br />
                <br />

                <div>{t('scheduled_date_and_time')}</div>
                <br />
                <div>
                <Checkbox
                    radio
                    label={t('schedule_now')}
                    name='schedule'
                    value={0}
                    checked={sendAt == null}
                    onChange={()=>onChangeDate()}
                    style={style.segmentCheckbox} />
                </div>
                <div>
                <Checkbox
                    radio
                    label={t('schedule_specific_time')}
                    name='schedule'
                    value={1}
                    checked={sendAt != null}
                    onChange={()=>onChangeDate(moment().add(1, 'hour').startOf('hour').toDate())}
                    style={style.segmentCheckbox} />
                </div>

                <DateTimePicker
                    value={sendAt && sendAt.toDate()}
                    step={60}
                    onChange={onChangeDate}
                    onKeyPress={(event)=>{event.preventDefault()}}
                    max={new Date(moment().add(3, 'years'))}
                    min={moment().add(1, 'hour').startOf('hour').toDate()}
                />
                <br />
                <br />                

                {
                    localStorage.master === 'true' &&
                    <div>
                        <div>{t('recurrence_prompt')}</div>
                        <Dropdown name='recurrenceType'
                            options={recurrences.map(r => ({ key: r.type, text: r.label, value: r.type }))}
                            value={recurrenceType}
                            onChange={onChangeDropdown}
                            selection
                            closeOnChange />
                        <br />
                        <br />
                    </div>
                }
                
                

                {children}

                {Boolean(choosePhotoIsOpen) &&
                    <ChoosePhotoContainer onConfirmPhoto={onConfirmPhoto}
                        width={1280}
                        height={720}
                        onClose={onUploadPhoto} />
                }

            </div>
        </Form>
    );
}
export default withTranslation(['announcements', 'translation'])(AnnouncementForm);
