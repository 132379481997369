
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Dimmer, Loader} from 'semantic-ui-react';



class LoadOverlay extends Component {
    render () {
        if(!this.props.enable) {
            return null;
        }

        return <Dimmer active style={{position:'fixed', zIndex:'1005'}}><Loader/></Dimmer>;
    }
}

const mapStateToProps = (state, ownProps) => ({enable:state.loading.overlay});

export default connect(mapStateToProps)(LoadOverlay);
