import { combineReducers } from 'redux';


import rewards          from './rewards.js'  
import teams            from './teams.js'  
import categories, {getCategory}       from './categories.js'  
import companies        from './companies.js'  
import objectives       from './objectives.js'  
import user             from './user.js'  
import sidebar          from './sidebar.js'  
import navbar           from './navbar.js'  
import leaderboards     from './leaderboards.js'
import loading          from './loading.js'  
import plugins          from './plugins.js'  
import competitions          from './competitions.js'  
import objectiveCategories, {getObjectiveCategoriesByObjectiveId} from './objectiveCategories.js'
import surveys          from './surveys'
import campaigns        from './campaigns'
import employees        from './employees'
import jobreporting     from './jobreporting';
import invitations      from './invitations'
import segments         from './segments'
import announcements    from './announcements'


/*
    NB: Reducers must be pure functions (no side effects)
*/

const combinedReducer = combineReducers({
    rewards,
    teams,
    categories,
    companies,
    objectives,
    user,
    leaderboards,
    sidebar,
    navbar,
    loading,
    plugins,
    competitions,
    objectiveCategories,
    surveys,
    campaigns,
    jobreporting,
    employees,
    invitations,
    segments,
    announcements
});

const mainReducer = (state, action) => {
    if (action.type === 'USER_SIGNOUT' || action.type==='CLEAR_STORE') {
        state = undefined;
    }
    return combinedReducer(state, action);
}

export default mainReducer;


//Composite selectors
export const getCategoriesByObjectiveId = (state, objectiveId) => {
    return getObjectiveCategoriesByObjectiveId(state.objectiveCategories, objectiveId).map(oc => getCategory(state.categories, oc.categoryId));
};
